import { VariableSizeList as AiSupportChatList } from 'react-window';
import { ChatBox } from '../../../../../UI/StyledMobileChat';
import { GradientChatLayout } from '../../../../chat/GradientChatLayout';
import { AiSupportChatMessage } from '../../../../../../middleware/aiAssistance/model';
import { MobileAiSupportChatRow } from '../../../../chat/aiSupportChatCommonComponents/AiResponseChatRows';
import { useScrollChatContext } from '../../../../chat/chatAutoScrolling/useScrollChatContext';
import { useAutoScrollChat } from '../../../../chat/chatAutoScrolling/useAutoScrollChat';
import AutoSizer from 'react-virtualized-auto-sizer';

export const MobileAiSupportChatBox = (props: { aiSupportMessages: AiSupportChatMessage[] }) => {
  const { aiSupportMessages } = props;
  const { listRef, getSize, getHeight } = useScrollChatContext();
  const { onScrolling } = useAutoScrollChat(aiSupportMessages.length, listRef);

  return (
    <GradientChatLayout
      isOpen={true}
      hasMessages={aiSupportMessages.length > 0}
      chatboxHeight={getHeight(aiSupportMessages?.length)}
      variant={{ width: '100%' }}
    >
      <ChatBox style={{ overflowX: 'hidden', padding: '0 12px' }} height={getHeight(aiSupportMessages.length)}>
        <AutoSizer>
          {({ height, width }) => (
            <AiSupportChatList
              ref={listRef}
              className="mobileChatScrollbar"
              onItemsRendered={onScrolling}
              innerElementType="div"
              itemData={aiSupportMessages}
              itemCount={aiSupportMessages.length}
              itemSize={getSize}
              height={
                getHeight(aiSupportMessages.length) < 50 ? getHeight(aiSupportMessages.length) : (height as number)
              }
              width={width as number}
            >
              {MobileAiSupportChatRow}
            </AiSupportChatList>
          )}
        </AutoSizer>
      </ChatBox>
    </GradientChatLayout>
  );
};
