import { useDeviceMetadata } from '../providers/DeviceMetadataProvider';
import { Box, Container, Paper, Typography, styled, useTheme } from '@mui/material';
import { MutableRefObject, ReactNode, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';

export const DraggablePanel = (props: {
  children: ReactNode;
  nodeRef: MutableRefObject<HTMLDivElement | null>;
  onToggle?: (isToggled: boolean) => void;
}) => {
  const { isMobileView } = useDeviceMetadata();
  const [y, setY] = useState(0);

  const handleStop = (_event: DraggableEvent, dragElement: DraggableData) => setY(dragElement.y);
  const resetAfterStop = () => setY(0);

  return (
    <Draggable
      nodeRef={props.nodeRef}
      axis="y"
      defaultClassName=".handle"
      position={{ x: 0, y: y }}
      disabled={!isMobileView}
      onStop={(e, data) => {
        const isProductListDragged = data.y > 0;
        handleStop(e, data);
        if (props.onToggle && isProductListDragged) {
          props.onToggle(false);
          setTimeout(() => {
            resetAfterStop();
          }, 1000);
        }
      }}
      bounds={{ top: 0 }}
      cancel=".clickable"
    >
      {props.children}
    </Draggable>
  );
};

export const PanelPaper = styled(Paper)(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '100%',
  scrollbarWidth: 'thin',
  scrollbarColor: `rgba(81, 85, 88, .25) ${theme.palette.grey['500']}`,

  '&::-webkit-scrollbar': { width: '7px' },
  '&::-webkit-scrollbar-track': { background: 'rgba(81, 85, 88, .25)' },
  '&::-webkit-scrollbar-thumb': { backgroundColor: theme.palette.grey['500'], borderRadius: '5px' },
}));

export const PanelHandle = () => {
  const theme = useTheme();

  const ProductPaperStylesMobileDraggableArea = {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '-12px',
    position: 'sticky',
    zIndex: 10,
    top: 0,
  };

  const ProductPaperStylesMobileDraggableAreaInline = {
    backgroundColor: '#d3d4d5',
    width: '100px',
    height: '6px',
    borderRadius: '3px',
  };

  return (
    <Container sx={ProductPaperStylesMobileDraggableArea} className="handle">
      <span style={ProductPaperStylesMobileDraggableAreaInline} />
    </Container>
  );
};

export const PanelTitle = (props: { children: ReactNode }) => {
  const { isMobileView } = useDeviceMetadata();
  const theme = useTheme();

  const ProductsPaperStylesBox = {
    top: isMobileView ? 40 : 0,
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: isMobileView ? undefined : `1px solid ${theme.palette.grey['800']}`,
    backgroundColor: theme.palette.common.white,
    mb: '12px',
  };

  const ProductPaperStylesText = {
    color: isMobileView ? theme.palette.text.primary : theme.palette.grey['800'],
    marginLeft: isMobileView ? '26px' : '42px',
    fontWeight: 700,
    fontSize: theme.typography.sectionTitle?.fontSize,
    fontFamily: theme.typography.sectionTitle?.fontFamily,
  };

  return (
    <Box sx={{ ...ProductsPaperStylesBox, position: 'sticky' }}>
      <Typography variant="overline" sx={ProductPaperStylesText}>
        {props.children}
      </Typography>
    </Box>
  );
};
