import { Box, Typography } from '@mui/material';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';
import { useVideoCountDown } from './useVideoCountdown';
import { styles } from './style';

const CountdownTitle = (props: { text: string }) => {
  const { isMinimized } = useEmbededViewControl();
  return (
    <Typography sx={!isMinimized ? styles.countDownTitleDesktop : styles.countDownTitleMobile}>{props.text}</Typography>
  );
};

export const MinimizedCountdown = () => {
  const { getCountdownTime } = useVideoCountDown();

  return (
    <Box sx={styles.constainer}>
      <Box className="count-down-timer" sx={styles.minimized}>
        <Box sx={styles.boxMinimized}>
          <CountdownTitle text={getCountdownTime().title} />
          {getCountdownTime().timeLeft}
        </Box>
      </Box>
    </Box>
  );
};

export const FullScreenCountdown = () => {
  const { getCountdownTime } = useVideoCountDown();
  const { isMinimized } = useEmbededViewControl();

  if (isMinimized) {
    return null;
  } else {
    return (
      <Box sx={styles.constainer}>
        <Box className="count-down-timer" sx={styles.desktop}>
          <Box sx={styles.boxDesktop}>
            <CountdownTitle text={getCountdownTime().title} />
            {getCountdownTime().timeLeft}
          </Box>
        </Box>
      </Box>
    );
  }
};
