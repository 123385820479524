import { PlayerType } from '../../../../../models/VideoViewLayerModels';
import { Icon, IconButton, useTheme } from '@mui/material';

export const ExtendOptionsWidget = ({ setOptionsEntended }: { setOptionsEntended: (isExtended: boolean) => void }) => {
  const theme = useTheme();
  const playerType = theme.components?.playerDisplayStyle;

  return (
    <IconButton onClick={() => setOptionsEntended(true)}>
      <Icon
        fontSize="small"
        className="ls-default ls-more"
        sx={{ px: 0.5, color: playerType === PlayerType.MODERN ? 'common.white' : 'text.primary' }}
      />
    </IconButton>
  );
};
