import { useSearchParamContext } from '../components/providers/SearchParamsProvider';
import { CarouselPlayer } from '../components/mediaLibrary/carousel/Carousel';
import { CarouselContextProvider } from '../components/mediaLibrary/carousel/useCarouselContext';
import { MediaLibraryLandingPage } from './mediaLibraryPage/MediaLibraryLandingPage';
import { MediaLibraryDataProvider } from '../components/mediaLibrary/MediaLibraryProvider';
import { EmbededPdpMiniaturePage } from './EmbedPdp/EmbededPdpMiniaturePage';
import { ShowContextProvider } from '../middleware/video/useShow';
import { ShowDetailPage } from './show/ShowDetailPage';
import { useRef } from 'react';
import { DisplayStyle, StoryblockLandingPage } from './Storyblock/StoryblockLandingPage';
import { HeartbeatContextProvider } from '../middleware/heartbeat/useHeartbeatContext';

export const EntryPage = () => {
  const { activeShowUuId, mediaLibraryId, activeClipId, playlist, displayStyle, embededPdpConfig, isSwipeEnabled } =
    useSearchParamContext();
  const enableVideoPlayer = activeShowUuId || activeClipId;
  const enableMediaLibrary = !!mediaLibraryId;
  const isMultiPlayer = playlist.length > 1;
  const isStoryblock = displayStyle === DisplayStyle.STORYBLOCK;
  const enablePDP = embededPdpConfig;
  const unmuteIconRef = useRef(true);

  const SinglePlayer = () => (
    <CarouselContextProvider isPlayerVisible={true} carouselSwiper={null} unmuteIconRef={unmuteIconRef}>
      <ShowContextProvider activeShowUuId={activeShowUuId} activeClipId={activeClipId}>
        <ShowDetailPage />
      </ShowContextProvider>
    </CarouselContextProvider>
  );

  const MediaLibrary = () => (
    <MediaLibraryDataProvider mediaLibraryId={mediaLibraryId}>
      <MediaLibraryLandingPage />
    </MediaLibraryDataProvider>
  );

  const Storyblock = () => (
    <MediaLibraryDataProvider mediaLibraryId={mediaLibraryId}>
      <StoryblockLandingPage />
    </MediaLibraryDataProvider>
  );

  if (enableVideoPlayer) {
    return (
      <HeartbeatContextProvider>
        {isMultiPlayer && isSwipeEnabled ? <CarouselPlayer /> : <SinglePlayer />}
      </HeartbeatContextProvider>
    );
  } else if (enableMediaLibrary) {
    return isStoryblock ? <Storyblock /> : <MediaLibrary />;
  } else if (enablePDP) {
    return <EmbededPdpMiniaturePage />;
  } else {
    return null;
  }
};
