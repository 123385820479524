import { useScrollChatContext } from '../../../../chat/chatAutoScrolling/useScrollChatContext';
import { useRef, useState } from 'react';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { VariableSizeList as AiSupportChatList } from 'react-window';
import { DesktopAiSupportChatRow } from '../../../../chat/aiSupportChatCommonComponents/AiResponseChatRows';
import { ChevronIcon } from '../../../../../UI/ChevronIcon';
import { AiSupportProps } from '../../../../chat/aiSupportChatCommonComponents/AiResponseChatBox';
import {
  DEFAULT_CLASSIC_PLAYER_CHAT_BOX_HEIGHT,
  DesktopChatAiSupportDivider,
  MIN_CHAT_BOX_HEIGHT,
} from '../../../../chat/aiSupportChatCommonComponents/DesktopChatAiSupportDivider';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useChatTranslation } from '../../../../../../i18n/i18nHooks';
import { useAutoScrollChat } from '../../../../chat/chatAutoScrolling/useAutoScrollChat';

export const DesktopAiSupportChatBox = (props: AiSupportProps) => {
  const chatBoxRef = useRef<HTMLDivElement | null>(null);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();

  const [chatBoxHeight, setChatBoxHeight] = useState(DEFAULT_CLASSIC_PLAYER_CHAT_BOX_HEIGHT);
  const { aiSupportMessages, setAiChatMessageToggled } = props;
  const { listRef, getSize } = useScrollChatContext();
  const { onScrolling } = useAutoScrollChat(aiSupportMessages.length, listRef);
  const { t } = useChatTranslation();

  const aiChatMessageToggleHandler = () => {
    if (chatBoxHeight > MIN_CHAT_BOX_HEIGHT) {
      setChatBoxHeight(MIN_CHAT_BOX_HEIGHT);
      setAiChatMessageToggled(false);
    } else {
      setChatBoxHeight(DEFAULT_CLASSIC_PLAYER_CHAT_BOX_HEIGHT);
    }
  };

  return (
    <Stack height="auto" width="100%" mb={1}>
      <DesktopChatAiSupportDivider
        setChatBoxHeight={setChatBoxHeight}
        chatBoxRef={chatBoxRef}
        sectionRef={sectionRef}
      />
      <Stack
        ref={sectionRef}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mx="12px"
        mt="12px"
        mb={chatBoxHeight > MIN_CHAT_BOX_HEIGHT ? '12px' : 0}
      >
        <Typography sx={{ color: theme.palette.grey['800'] }} fontWeight={700} fontSize="14px">
          {t('aiSupport.title')}
        </Typography>
        <IconButton aria-label="ai-support-toggler" onClick={aiChatMessageToggleHandler}>
          <ChevronIcon direction={chatBoxHeight > MIN_CHAT_BOX_HEIGHT ? 'up' : 'down'} />
        </IconButton>
      </Stack>
      <Stack ref={chatBoxRef} height={chatBoxHeight}>
        <AutoSizer>
          {({ height, width }) => (
            <AiSupportChatList
              className="desktopChatScrollbar"
              onItemsRendered={onScrolling}
              ref={listRef}
              innerElementType="div"
              itemData={aiSupportMessages}
              itemCount={aiSupportMessages?.length}
              itemSize={getSize}
              height={height as number}
              width={width as number}
            >
              {DesktopAiSupportChatRow}
            </AiSupportChatList>
          )}
        </AutoSizer>
      </Stack>
    </Stack>
  );
};
