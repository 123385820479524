import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';

export const NotFoundPage = () => {
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  return (
    <Box>
      <Typography>{t('notFoundPage.description')}</Typography>
      <Box>
        <Button onClick={() => setSearchParams('activeShowId', undefined)}>{t('notFoundPage.goTo.startPage')}</Button>
      </Box>
    </Box>
  );
};
