import { Box, Card, Icon, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { PinnedMsg } from '../../../../../models/chat.model';
import { ChatAvatar } from '../../../../UI/ChatAvatar';
import { MessageConverted } from '../../../chat/MessageConverted';

interface pinnedMessageProps {
  readonly pinnedMessage: PinnedMsg;
}

export const DesktopChatPinnedMessage = (props: pinnedMessageProps) => {
  const { pinnedMessage } = props;
  const message = pinnedMessage.msg?.message || '';
  const userName = pinnedMessage.msg?.username;
  const isModeratorMessage = pinnedMessage.msg?.customAttributes?.isModerator === 'true';
  const theme = useTheme();

  const sxStackBoxCard: SxProps = {
    flexShrink: 0,
    px: '8px',
    py: '6px',
    pr: '16px',
    width: '-webkit-fill-available',
    backgroundColor: theme.components?.chatMessage?.desktop?.pinned?.background,
  };

  const sxStackBoxCardInnerStackName: SxProps = {
    textTransform: 'none',
    fontSize: '0.9rem',
    fontWeight: '700',
    color: isModeratorMessage
      ? theme.components?.chatMessage?.desktop?.pinned?.moderatorNameColor
      : theme.components?.chatMessage?.desktop?.pinned?.userNameColor,
  };
  const sxStackBoxCardInnerStackIcon: SxProps = {
    color: theme.components?.chatMessage?.desktop?.pinned?.textColor,
    fontSize: '1rem',
  };

  const sxProductLinkMessage: SxProps = {
    marginRight: '4px',
    lineBreak: 'anywhere',
    textDecoration: isModeratorMessage ? 'hover' : 'none',
    pointerEvents: isModeratorMessage ? 'auto' : 'none',
    color: isModeratorMessage
      ? theme.components?.chatMessage?.desktop?.pinned?.linkModeratorColor
      : theme.components?.chatMessage?.desktop?.pinned?.linkUserColor,
  };

  const sxTextMessage: SxProps = {
    fontSize: '0.9rem',
    textTransform: 'none',
    marginRight: '4px',
    wordBreak: 'break-word',
    color: theme.components?.chatMessage?.desktop?.pinned?.textColor,
  };

  return (
    <Stack id="pinned-message">
      <Stack direction="row">
        {pinnedMessage.msg?.customAttributes?.avatarUrl && (
          <ChatAvatar backgroundURL={pinnedMessage.msg?.customAttributes?.avatarUrl} />
        )}
        <Box sx={{ width: '100%', mb: 2 }}>
          <Card elevation={0} sx={sxStackBoxCard}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography sx={sxStackBoxCardInnerStackName}>{userName}</Typography>
              <Icon baseClassName="ls-default" className="ls-pin" sx={sxStackBoxCardInnerStackIcon} />
            </Stack>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <MessageConverted
                message={message}
                isModerator={isModeratorMessage}
                sxProductLinkMessage={sxProductLinkMessage}
                sxTextMessage={sxTextMessage}
              />
            </div>
          </Card>
        </Box>
      </Stack>
    </Stack>
  );
};
