import { Box, useTheme } from '@mui/material';
import Logo from '../../../theming/logo/ospLogo.svg';
import { PlayerZIndex } from '../../../models/VideoViewLayerModels';

const PERCENTAGE = 100;
const DEFAULT_LOGO_SIZE = 30;

export const CompanyLogo = () => {
  const theme = useTheme();
  const logoSizePercentage = theme.components?.logoPercentSize;
  const logoHeight = `${((logoSizePercentage || 100) / PERCENTAGE) * DEFAULT_LOGO_SIZE}px`;

  return (
    <Box
      sx={{
        position: 'absolute',
        top: theme.components?.squareButton?.spacing,
        left: '50%',
        width: 'fit-content',
        zIndex: PlayerZIndex.ABOVE,
        transform: 'translateX(-50%)',
      }}
    >
      {theme.components?.logoURL ? (
        <img src={theme.components?.logoURL} alt="logo" height={logoHeight} />
      ) : (
        <img src={Logo} height={logoHeight} alt="logo" />
      )}
    </Box>
  );
};
