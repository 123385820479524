import { SxProps } from '@mui/material';
import { StyledIcon } from './StyledIcon';

interface ChevronIconProps {
  direction?: 'right' | 'left' | 'up' | 'down';
  sx?: SxProps;
}

const setAngle = (chevronDirection: string | undefined) => {
  if (chevronDirection === 'right') return '90';
  if (chevronDirection === 'left') return '270';
  if (chevronDirection === 'up') return '180';
  if (chevronDirection === 'down') return '0';
  return undefined;
};

export const ChevronIcon = (props: ChevronIconProps) => {
  return <StyledIcon className={'ls-default ls-arrow-up'} angle={setAngle(props.direction)} {...props} />;
};
