import { VideoType } from '../../../graphqlClient/mediaLibraryRequest';

export enum PLAYER_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inActive',
}

export interface CarouselVideo {
  videoId: string;
  videoType: VideoType;
  activeStatus: PLAYER_STATUS;
}
