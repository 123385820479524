import { Box, Icon, IconButton, styled } from '@mui/material';
import { ChevronIcon } from '../../UI/ChevronIcon';
import { PlayerState } from 'amazon-ivs-player';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { MouseEvent, useEffect } from 'react';
import { usePlayableVideoStreamContext } from '../../../middleware/video/usePlayableVideoStreamContext';
import { useSearchParamContext } from '../../providers/SearchParamsProvider';
import { useClientEventFunction } from '../../../middleware/clientEvent/useClientEventFunctions';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { MinimizedCountdown } from './VideoCountdown';
import { useShow } from '../../../middleware/video/useShow';

interface VideoPanelMinimizedProps {
  readonly muted: boolean;
  readonly streamingState: PlayerState;
  readonly showUpcomimgComponents: boolean;
  readonly play: () => void;
  readonly pause: () => void;
  readonly handleMuteChange: (value: boolean) => void;
}

interface ButtonProps {
  readonly spacing: string;
  readonly desktop: boolean;
  readonly onClick?: undefined | ((event: MouseEvent<HTMLButtonElement>) => void);
}
interface MuteButtonProps extends ButtonProps {
  readonly play: () => void;
  readonly pause: () => void;
  readonly handleMuteChange: (value: boolean) => void;
  readonly muted: boolean;
  readonly streamingState: PlayerState;
}

const handleVideoBody = (prop: string) => {
  const miniPLayerBody = document.getElementById('videoBody');
  if (miniPLayerBody) {
    miniPLayerBody.style.overflow = prop;
  }
};

export const VideoPanelMinimized = (props: VideoPanelMinimizedProps) => {
  const { streamingState, muted, showUpcomimgComponents, play, pause, handleMuteChange } = props;
  const { onMaximizePlayer } = useEmbededViewControl();
  const { useNativePipPlayer } = useSearchParamContext();
  const { isMobileView } = useDeviceMetadata();
  const isDesktop = !isMobileView;
  const ButtonSpacing = isDesktop ? '12px' : '6px';
  handleVideoBody('hidden');

  const handleMaximizePipPlayer = useNativePipPlayer
    ? (event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        handleVideoBody('auto');
        onMaximizePlayer();
      }
    : undefined;

  return (
    <Box
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
        width: '100%',
        height: '100%',
        position: 'relative',
        bgcolor: 'var(--dark-transparent-50)',
      }}
      onClick={useNativePipPlayer ? onMaximizePlayer : undefined}
    >
      <MinimizedLayer id="container-layer">
        <MaximizeButton desktop={isDesktop} spacing={ButtonSpacing} onClick={handleMaximizePipPlayer} />
        <CloseButton desktop={isDesktop} spacing={ButtonSpacing} onClick={handleMaximizePipPlayer} />
        {showUpcomimgComponents && <MinimizedCountdown />}
        <PlayToggleButton
          muted={muted}
          handleMuteChange={handleMuteChange}
          streamingState={streamingState}
          play={play}
          pause={pause}
          desktop={isDesktop}
          spacing={ButtonSpacing}
        />
      </MinimizedLayer>
    </Box>
  );
};

const MinimizedLayer = styled(Box)(({ theme }) => ({
  '&:hover': {
    cursor: 'pointer',
  },
  width: '100%',
  height: '100%',
  position: 'relative',
  backgroundColor: theme.components?.video?.backgrounds?.minimizedPanel,
}));

const MinimizedViewButton = styled(IconButton)(({ theme }) => ({
  zIndex: '10',
  position: 'absolute',

  '.MuiIcon-root': {
    fontSize: '18px',
    color: theme.palette.common.white,
  },
}));

const MaximizeButton = ({ spacing, desktop, onClick }: ButtonProps) => {
  return (
    <MinimizedViewButton
      onClick={onClick}
      sx={{
        top: spacing,
        left: spacing,
        '.MuiIcon-root': {
          fontSize: desktop ? '35px' : '28px',
        },
      }}
    >
      <ChevronIcon direction={'down'} />
    </MinimizedViewButton>
  );
};

const CloseButton = ({ spacing, desktop, onClick }: ButtonProps) => {
  return (
    <MinimizedViewButton
      onClick={onClick}
      sx={{
        top: spacing,
        right: spacing,
        '.MuiIcon-root': {
          fontSize: desktop ? '28px' : '23px',
        },
      }}
    >
      <Icon baseClassName="ls-default" className="ls-close" />
    </MinimizedViewButton>
  );
};

const PlayToggleButton = (props: MuteButtonProps) => {
  const { isPauseMuted } = useEmbededViewControl();
  const { spacing, desktop, streamingState, muted, play, pause, handleMuteChange } = props;
  const { pausePlayer } = useClientEventFunction();
  const { showStarted, showEnded, showPaused } = usePlayableVideoStreamContext();
  const { isVod } = useShow();

  const isPlaying = streamingState === PlayerState.PLAYING;

  const muteToggleHandler = () => handleMuteChange(!muted);

  const clickHandler = (e?: MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    isVod ? playToggleHandler() : muteToggleHandler();
  };

  const playToggleHandler = () => {
    if (isPlaying) {
      pause();
      pausePlayer(true);
    } else {
      play();
      pausePlayer(false);
    }
  };

  useEffect(() => {
    if (isPauseMuted.value) {
      clickHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPauseMuted]);

  return (
    <MinimizedViewButton
      onClick={clickHandler}
      sx={{
        bottom: spacing,
        left: spacing,
        '.MuiIcon-root': {
          fontSize: desktop ? '33px' : '26px',
        },
      }}
    >
      {!(!showStarted && !showEnded && !showPaused) && <CtaIcon muted={muted} isPlaying={isPlaying} isVod={isVod} />}
    </MinimizedViewButton>
  );
};

const CtaIcon = (props: { muted: boolean; isPlaying: boolean; isVod: boolean }) => {
  const { muted, isPlaying, isVod } = props;

  const mutedToggleIcon = {
    className: muted ? 'ls-default ls-mute' : 'ls-default ls-volume',
    styles: muted ? { overflow: 'visible', '&::before': { marginLeft: '-1px' } } : { overflow: 'visible' },
  };
  const playToggleIcon = {
    className: isPlaying ? 'ls-default ls-stop' : 'ls-default ls-play',
    styles: isPlaying ? { overflow: 'visible', '&::before': { marginLeft: '-1px' } } : { overflow: 'visible' },
  };
  return (
    <Icon
      fontSize="small"
      className={isVod ? playToggleIcon.className : mutedToggleIcon.className}
      sx={isVod ? playToggleIcon.styles : mutedToggleIcon.styles}
    />
  );
};
