import { Box, ClickAwayListener, Collapse, Container, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useControlPanelWidgetContext } from './Widget/ControlPanelProvider';
import { useRef } from 'react';
import { DraggablePanel, PanelHandle, PanelPaper, PanelTitle } from '../../../UI/StyledMobilePanel';
import { ShareWidget } from './Widget/ShareWidget';
import { MuteWidget } from './Widget/MuteWidget';

interface ExpansionControlProps {
  readonly isOpened: boolean;
  readonly onToggle: (state: boolean) => void;
}

const InnerControlBox = styled(Box)({
  width: 'calc(100% - 16px)',
  height: '40px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#F8F8F8',
  padding: '8px',
  margin: '8px 0',
  cursor: 'pointer',
});

export default function ExpansionControl({ isOpened, onToggle }: ExpansionControlProps) {
  const { t } = useTranslation();
  const { muted, handleMuteChange, handleOpenShareModalDialog } = useControlPanelWidgetContext();
  const nodeRef = useRef(null);

  return (
    <ClickAwayListener onClickAway={() => onToggle(false)} touchEvent="onTouchStart">
      <Collapse
        className={isOpened ? 'MuiHelper-fullWidth' : ''}
        classes={{
          wrapperInner: 'MuiHelper-fullWidth',
          wrapper: 'MuiHelper-fullWidth',
        }}
        in={isOpened}
        sx={{ width: 1, transition: '150ms !important', bottom: 0, left: 0, position: 'absolute', zIndex: 10 }}
      >
        <DraggablePanel nodeRef={nodeRef} onToggle={onToggle}>
          <PanelPaper ref={nodeRef} sx={{ borderRadius: '16px 16px 0px 0px' }}>
            <PanelHandle />
            <PanelTitle> {t('video.controlPannel.more')}</PanelTitle>
            <Container
              sx={{
                width: '100%',
                paddingLeft: '16px',
                paddingRight: '16px',
              }}
            >
              <InnerControlBox className="clickable" onClick={() => handleMuteChange(!muted)}>
                <Typography variant={'body1'}>{t('video.controlPannel.volume')}</Typography>
                <MuteWidget disableClick={true} />
              </InnerControlBox>

              <InnerControlBox className="clickable" onClick={handleOpenShareModalDialog}>
                <Typography variant={'body1'}>{t('video.controlPannel.share')}</Typography>
                <ShareWidget disableClick={true} />
              </InnerControlBox>
            </Container>
          </PanelPaper>
        </DraggablePanel>
      </Collapse>
    </ClickAwayListener>
  );
}
