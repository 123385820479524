import { Icon, IconButton, Stack } from '@mui/material';
import { ChevronIcon } from '../../UI/ChevronIcon';
import { PlayerZIndex } from '../../../models/VideoViewLayerModels';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';

export const VideoToggleButtons = () => {
  const { onClosePlayer, onMinimizePlayer } = useEmbededViewControl();

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        position: 'absolute',
        right: '13px',
        top: '16px',
        zIndex: PlayerZIndex.ABOVE,
      }}
    >
      <IconButton aria-label="minimize-btn" onClick={onMinimizePlayer}>
        <ChevronIcon direction="up" sx={{ fontSize: '16px', color: 'common.white' }} />
      </IconButton>
      <IconButton aria-label="close-btn" onClick={onClosePlayer}>
        <Icon baseClassName="ls-default" className="ls-close" sx={{ fontSize: '16px', color: 'common.white' }} />
      </IconButton>
    </Stack>
  );
};
