import { useChatTranslation } from '../../../../../../i18n/i18nHooks';
import { useDeviceMetadata } from '../../../../../providers/DeviceMetadataProvider';
import { Icon, IconButton, InputAdornment, alpha, useTheme } from '@mui/material';
import { ChangeEvent, CompositionEventHandler, KeyboardEventHandler, RefObject } from 'react';
import { StyledOutlinedInput } from '../../../../../UI/StyledOutlinedInput';

interface DesktopChatAiSupportTextFieldProps {
  readonly textInput: string;
  readonly isLoading: boolean;
  readonly textFieldRef: RefObject<HTMLInputElement | null>;
  readonly onSubmitHandler: () => void;
  readonly onKeyDownHandler: KeyboardEventHandler<HTMLInputElement>;
  readonly onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  readonly onCompositionStartHandler: CompositionEventHandler<HTMLInputElement>;
  readonly onCompositionEndHandler: CompositionEventHandler<HTMLInputElement>;
}

export const DesktopChatAiSupportTextField = (props: DesktopChatAiSupportTextFieldProps) => {
  const {
    textInput,
    isLoading,
    textFieldRef,
    onChangeHandler,
    onKeyDownHandler,
    onSubmitHandler,
    onCompositionStartHandler,
    onCompositionEndHandler,
  } = props;
  const { isMobileView } = useDeviceMetadata();
  const { t } = useChatTranslation();
  const theme = useTheme();

  return (
    <StyledOutlinedInput
      sx={{
        mb: 2,
        mt: 1,
        boxSizing: 'border-box',
        borderRadius: '26px',
        bgcolor: alpha(theme.palette.common.white, 0.7),
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        '& input::placeholder': {
          fontSize: isMobileView ? '3.5vw' : '16px',
        },
      }}
      autoComplete="off"
      id="web-ai-support-chat"
      type="text"
      inputRef={textFieldRef}
      disabled={isLoading}
      placeholder={t('aiSupport.placeholder')}
      value={textInput}
      fullWidth={true}
      onChange={onChangeHandler}
      onCompositionStart={onCompositionStartHandler}
      onCompositionEnd={onCompositionEndHandler}
      onKeyDown={onKeyDownHandler}
      endAdornment={
        <InputAdornment position="end">
          <IconButton aria-label="send message icon" onClick={onSubmitHandler} edge="end">
            <Icon sx={{ overflow: 'visible' }} className="ls-default ls-send" />
          </IconButton>
        </InputAdornment>
      }
      inputProps={{
        maxLength: 500,
      }}
    />
  );
};
