import { useState } from 'react';

interface UseToggle {
  isToggled: boolean;
  setToggle: (isToggled: boolean) => void;
  toggle: () => void;
}

export const useToggle = (): UseToggle => {
  const [isToggled, setToggle] = useState<boolean>(false);

  const toggle = () => {
    setToggle(!isToggled);
  };

  return {
    isToggled,
    setToggle,
    toggle,
  };
};
