import { Box } from '@mui/material';

interface ChatAvatarProps {
  isMobile?: boolean;
  backgroundURL: string;
}

export const ChatAvatar = (props: ChatAvatarProps) => {
  return (
    <Box>
      <Box
        sx={{
          background: `url(${props.backgroundURL})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          borderRadius: '4px',
          width: props.isMobile ? '24px' : '45px',
          height: props.isMobile ? '24px' : '45px',
          marginRight: props.isMobile ? '3px' : '6px',
        }}
      />
    </Box>
  );
};
