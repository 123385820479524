import { useShow } from '../video/useShow';
import { Dispatch, SetStateAction, useCallback } from 'react';
import { ChatEventName, ChatMessage, ChatMessageType, IVSReturnedChat, PinnedMsg } from '../../models/chat.model';
import { usePlayableVideoStreamContext } from '../video/usePlayableVideoStreamContext';
import { useHighlightedItemsContext } from '../highlightedItems/useHighlightedItems';

interface EventProps {
  readonly setPinnedMessage: Dispatch<PinnedMsg>;
  readonly setMessages: Dispatch<SetStateAction<ChatMessage[]>>;
  readonly setBlockedList: Dispatch<SetStateAction<string[]>>;
}

export const useHandleChatEvent = (props: EventProps) => {
  const { setShowStarted, setShowEnded, setShowPaused, setLiveShowStreaming } = usePlayableVideoStreamContext();
  const { setMessages, setPinnedMessage, setBlockedList } = props;
  const { updateVodChatLogsUrl, updateVodManifestUrl } = useShow();
  const { updateHighlightedItems } = useHighlightedItemsContext();

  const mapChatEvent = useCallback((data: IVSReturnedChat) => {
    const newEvent: ChatMessage = {
      type: ChatMessageType.Event,
      timestamp: data.SendTime,
      username: data.Attributes?.username ?? '',
      userId: data.Attributes?.userId ?? '',
      message: data.Attributes?.message ?? '',
      messageId: data.Id,
      customAttributes: data.Attributes,
    };
    return newEvent;
  }, []);

  const handleEvent = useCallback(
    (data: IVSReturnedChat) => {
      switch (data.EventName) {
        case ChatEventName.DeleteMessage: {
          const messageIdToDelete = data.Attributes?.MessageID;
          setMessages((prevState) => prevState.filter((it) => it.messageId !== messageIdToDelete));
          break;
        }
        case ChatEventName.PinMessage:
          setPinnedMessage({ pin: true, msg: mapChatEvent(data) });
          break;
        case ChatEventName.UnPinMessage:
          setPinnedMessage({ pin: false, msg: undefined });
          break;
        case ChatEventName.BlockUser: {
          const newBlockedUser = data.Attributes?.clientIp as string;
          setBlockedList((prevState) => [...(prevState ?? []), newBlockedUser]);
          break;
        }
        case ChatEventName.NotifyEndShow:
          setShowStarted(false);
          setShowEnded(true);
          setShowPaused(false);
          updateHighlightedItems([]);
          break;
        case ChatEventName.StreamStart:
          setLiveShowStreaming(true);
          break;
        case ChatEventName.StreamEnd:
          setLiveShowStreaming(false);
          break;
        case ChatEventName.EndVodGeneration:
          updateVodManifestUrl(data.Attributes?.vodManifestUrl as string);
          break;
        case ChatEventName.EndChatLogsGeneration:
          updateVodChatLogsUrl(data.Attributes?.chatLogsUrl as string);
          break;

        default:
          console.info('Unhandled event received:', data);
      }
    },
    [
      mapChatEvent,
      setBlockedList,
      setLiveShowStreaming,
      setMessages,
      setPinnedMessage,
      setShowEnded,
      setShowPaused,
      setShowStarted,
      updateHighlightedItems,
      updateVodChatLogsUrl,
      updateVodManifestUrl,
    ],
  );

  return { handleEvent };
};
