import { MAX_CHARACTER_LENGTH } from '../../../chat/chatAutoScrolling/useScrollChatContext';
import { Box, Card, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { ChatAvatar } from '../../../../UI/ChatAvatar';
import { ChatMessage } from '../../../../../models/chat.model';
import { MessageConverted } from '../../../chat/MessageConverted';
import { useScrollChatSize } from '../../../chat/chatAutoScrolling/useScrollChatSize';

interface DesktopChatMessageProps {
  readonly data: ChatMessage;
  readonly setSize: (index: number, size: number) => void;
  readonly index: number;
  readonly toggleLongText: (index: number, isRepliedMsg?: boolean) => void;
}

export const DesktopChatRepliedMessage = (repliedMessage: DesktopChatMessageProps) => {
  const { data, index, setSize, toggleLongText } = repliedMessage;
  const { rowRef } = useScrollChatSize(index, setSize);
  const toBeRepliedMessage = data.customAttributes?.toBeRepliedMessage ?? '';
  const toBeRepliedUser = data.customAttributes?.toBeRepliedUser ?? '';
  const toBeRepliedUserId = data.customAttributes?.toBeRepliedUserId ?? '';
  const moderatorMessage = data.message ?? '';
  const isModeratorSendMessage = data.userId === toBeRepliedUserId;
  const theme = useTheme();

  const handleToggleLongText = () => toggleLongText(index);
  const handleToggleLongRepliedText = () => toggleLongText(index, true);

  const sxBoxWrapper: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    px: '12px',
    mb: '8px',
  };
  const sxBox: SxProps = { width: '100%' };
  const sxBoxCard: SxProps = {
    backgroundColor: theme.components?.chatMessage?.desktop?.replied?.respond?.background,
    borderRadius: 1,
    flexShrink: 0,
    px: '8px',
    py: '8px',
    width: 'fit-content',
  };
  const sxBoxCardStackWrapper: SxProps = {
    display: 'inline-flex',
    flexDirection: 'column',
    spacing: 1,
  };
  const sxBoxCardStack: SxProps = {
    display: 'inline-block',
    px: '6px',
    py: '8px',
    borderRadius: '4px',
    p: 1,
    background: theme.components?.chatMessage?.desktop?.replied?.background,
  };
  const sxBoxCardStackInnerBox: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
  };
  const sxBoxCardStackRespondInnerBox: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '8px 0 0',
  };
  const sxBoxCardStackRepliedUserName: SxProps = {
    textTransform: 'none',
    color: theme.components?.chatMessage?.desktop?.replied?.userNameColor,
    fontWeight: '700',
    fontSize: '0.9rem',
    pr: '8px',
  };
  const BoxCardStackModeratorName: SxProps = {
    textTransform: 'none',
    color: theme.components?.chatMessage?.desktop?.replied?.respond?.moderatorNameColor,
    marginRight: '8px',
    fontWeight: '700',
    fontSize: '0.9rem',
  };

  const sxProductLinkMessage: SxProps = {
    textDecoration: isModeratorSendMessage ? 'none' : 'hover',
    pointerEvents: isModeratorSendMessage ? 'none' : 'auto',
    marginRight: '4px',
    lineBreak: 'anywhere',
    color: theme.components?.chatMessage?.desktop?.replied?.linkColor,
  };

  const sxTextMessage: SxProps = {
    marginRight: '4px',
    wordBreak: 'break-word',
    color: theme.components?.chatMessage?.desktop?.replied?.textColor,
    textTransform: 'none',
    fontSize: '0.9rem',
  };

  const sxRespondProductLinkMessage: SxProps = {
    marginRight: '4px',
    lineBreak: 'anywhere',
    color: theme.components?.chatMessage?.desktop?.replied?.respond?.linkColor,
  };

  const sxRespondTextMessage: SxProps = {
    marginRight: '4px',
    wordBreak: 'break-word',
    color: theme.components?.chatMessage?.desktop?.replied?.respond?.textColor,
    textTransform: 'none',
    fontSize: '0.9rem',
  };

  return (
    <Box sx={sxBoxWrapper}>
      {data.customAttributes?.avatarUrl && <ChatAvatar backgroundURL={data.customAttributes?.avatarUrl} />}
      <Box ref={rowRef} sx={sxBox}>
        <Card elevation={0} sx={sxBoxCard}>
          <Stack sx={sxBoxCardStackWrapper}>
            <Stack sx={sxBoxCardStack}>
              <Box
                sx={{
                  ...sxBoxCardStackInnerBox,
                  cursor: data.message.length > MAX_CHARACTER_LENGTH ? 'pointer' : 'auto',
                }}
                onClick={data.message.length > MAX_CHARACTER_LENGTH ? handleToggleLongRepliedText : undefined}
              >
                <Typography variant="overline" sx={sxBoxCardStackRepliedUserName}>
                  {toBeRepliedUser}
                </Typography>
                <MessageConverted
                  message={toBeRepliedMessage}
                  isModerator={isModeratorSendMessage}
                  isLongTextToggled={data.customAttributes?.isLongTextToggled}
                  sxProductLinkMessage={sxProductLinkMessage}
                  sxTextMessage={sxTextMessage}
                />
              </Box>
            </Stack>
            <Box
              sx={{
                ...sxBoxCardStackRespondInnerBox,
                cursor: data.message.length > MAX_CHARACTER_LENGTH ? 'pointer' : 'auto',
              }}
              onClick={data.message.length > MAX_CHARACTER_LENGTH ? handleToggleLongText : undefined}
            >
              <Typography variant="body1" sx={BoxCardStackModeratorName}>
                {data.username}
              </Typography>
              <MessageConverted
                isLongTextToggled={data.isLongTextToggled}
                message={moderatorMessage}
                isModerator={true}
                isProductLinkMessageClass={true}
                sxProductLinkMessage={sxRespondProductLinkMessage}
                sxTextMessage={sxRespondTextMessage}
                rel="noopener noreferrer"
              />
            </Box>
          </Stack>
        </Card>
      </Box>
    </Box>
  );
};
