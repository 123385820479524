import { SvgIcon } from '@mui/material';
import { ReactComponent as RotateDeviceIcon } from '../../../assets/icons/RotateDeviceIcon.svg';
import { DeviceOrientation } from '../../../utils/useDeviceOrientationChange';
import { AspectRatio, PlayerZIndex } from '../../../models/VideoViewLayerModels';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { usePlayableVideoStreamContext } from '../../../middleware/video/usePlayableVideoStreamContext';

export const RotateVideoHint = (props: { hideHint: boolean }) => {
  const { deviceOrientation, isDesktopView } = useDeviceMetadata();
  const { videoAspectRatio } = usePlayableVideoStreamContext();

  if (
    isDesktopView ||
    videoAspectRatio === AspectRatio.PORTRAIT ||
    deviceOrientation === DeviceOrientation.LANDSCAPE ||
    props.hideHint
  ) {
    return null;
  } else {
    return (
      <SvgIcon sx={{ position: 'absolute', right: '24px', bottom: '10px', zIndex: PlayerZIndex.ABOVE }}>
        <RotateDeviceIcon />
      </SvgIcon>
    );
  }
};
