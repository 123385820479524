import { Icon, IconButton } from '@mui/material';
import { ReactComponent as ChatSlashIcon } from '../../../../../assets/icons/ChatSlashIcon.svg';
import { useControlPanelWidgetContext } from './ControlPanelProvider';
import { useShow } from '../../../../../middleware/video/useShow';

export const ChatLayoutToggleWidget = () => {
  const { handleToggleChatGrid, isChatCardOpen } = useControlPanelWidgetContext();
  const { isClip } = useShow();

  if (isClip) {
    return null;
  } else {
    return (
      <IconButton onClick={handleToggleChatGrid}>
        {isChatCardOpen ? (
          <Icon fontSize="small" className="ls-default ls-message-solid" sx={{ p: '2.5px', color: 'common.white' }} />
        ) : (
          <ChatSlashIcon width="25px" height="25px" />
        )}
      </IconButton>
    );
  }
};
