import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const StyledTextField = styled(TextField)<{ component?: React.ElementType }>(({ theme }) => ({
  '.MuiInputBase-input': {
    lineHeight: '1.375rem',
    color: theme.components?.chatBox?.desktop?.input?.textColor,
    fontWeight: 400,
    fontSize: '1rem',
    padding: theme.components?.chatBox?.desktop?.input?.padding,
    '&::placeholder': {
      fontSize: '14px',
      color: theme.components?.chatBox?.desktop?.input?.placeholderColor,
    },
  },
  '& .Mui-error .MuiOutlinedInput-notchedOutline': {
    // borderWidth: '2px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderRadius: theme.components?.defaultValues?.borderRadius,
  },
  '.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '1px !important',
    borderColor: `${theme.components?.chatBox?.mobile?.input?.borderColorActive} !important`,
  },
  '&.Mui-success .MuiOutlinedInput-notchedOutline': {
    borderWidth: '2px !important',
    borderColor: `${theme.components?.defaultValues?.color?.success} !important`,
  },
}));
