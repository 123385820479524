import { Box, Icon, Skeleton, Stack } from '@mui/material';
import { MediaPlayer, create } from 'amazon-ivs-player';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useClientEventFunction } from '../../middleware/clientEvent/useClientEventFunctions';
import { useHover } from '../../utils/useHover';
import { useSearchParamContext } from '../../components/providers/SearchParamsProvider';
import { getVodManifest } from '../../graphqlClient/showRequest';
import IvsPlayerConfig from '../../middleware/video/IvsPlayerConfig.json';

export const EmbededPdpMiniaturePage = () => {
  const { selectPdp } = useClientEventFunction();
  const { isHovered, handleMouseEnter, handleMouseLeave } = useHover();
  const { embededPdpConfig, displayTime } = useSearchParamContext();
  const [miniatureImage, setMiniatureImage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const miniaturePlayer = useRef<MediaPlayer | null>(null);
  const videoEl = useRef<HTMLVideoElement>(null);

  const onHandleMouseEnterVideo = () => {
    handleMouseEnter();
    miniaturePlayer.current?.play();
  };

  const onHandleMouseLeaveVideo = useCallback(() => {
    handleMouseLeave();
    miniaturePlayer.current?.seekTo(displayTime);
    miniaturePlayer.current?.pause();
  }, [displayTime, handleMouseLeave]);

  const onHandleMoucseClickVideo = useCallback(() => {
    if (embededPdpConfig) {
      miniaturePlayer.current?.seekTo(displayTime);
      miniaturePlayer.current?.pause();
      const showUuid = embededPdpConfig.id;
      selectPdp(showUuid, displayTime.toString());
    }
  }, [displayTime, selectPdp, embededPdpConfig]);

  useEffect(() => {
    miniaturePlayer.current = create(IvsPlayerConfig);
    return () => {
      miniaturePlayer.current?.pause();
      miniaturePlayer.current?.delete();
      miniaturePlayer.current = null;
    };
  }, []);

  useEffect(() => {
    if (!embededPdpConfig) return;
    setIsLoading(true);
    const vodManifestUrl = embededPdpConfig.vodManifestUrl;

    getVodManifest(vodManifestUrl)()
      .then((res) => {
        const streamUrl = res.shows[0].vodUrl;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        miniaturePlayer.current?.attachHTMLVideoElement(videoEl.current as HTMLVideoElement);
        miniaturePlayer.current?.load(streamUrl);
        miniaturePlayer.current?.setMuted(true);
        miniaturePlayer.current?.seekTo(displayTime);

        videoEl.current?.addEventListener('canplaythrough', async () => {
          requestAnimationFrame(() => {
            canvas.width = videoEl.current?.videoWidth as number;
            canvas.height = videoEl.current?.videoHeight as number;
            ctx?.drawImage(videoEl.current as HTMLVideoElement, 0, 0);
            setMiniatureImage(canvas.toDataURL('image/jpeg'));
            setIsLoading(false);
          });
        });
        return;
      })
      .catch(() => setIsLoading(false));
  }, [displayTime, embededPdpConfig]);

  if (!embededPdpConfig) {
    return null;
  } else {
    return (
      <Stack
        width={`${embededPdpConfig?.miniatureSize.width}px`}
        height={`${embededPdpConfig?.miniatureSize.height}px`}
        overflow="hidden"
        id="embeded-pdp-wrapper"
      >
        {isLoading && (
          <Skeleton
            variant="rounded"
            width={`${embededPdpConfig?.miniatureSize.width}px`}
            height={`${embededPdpConfig?.miniatureSize.height}px`}
            sx={{ borderRadius: 5 }}
          />
        )}
        <Box
          onMouseEnter={onHandleMouseEnterVideo}
          onMouseLeave={onHandleMouseLeaveVideo}
          onClick={onHandleMoucseClickVideo}
          sx={{
            display: isLoading ? 'none' : 'block',
            position: 'relative',
            height: `${embededPdpConfig?.miniatureSize.height}px` || '300px',
            width: `${embededPdpConfig?.miniatureSize.width}px` || '200px',
            overflow: 'hidden',
            borderRadius: isHovered ? 0 : 5,
            transition: 'all 0.3s',
            '&:hover': {
              cursor: 'pointer',
            },
          }}
        >
          <Box
            id="embeded-pdp-video-player"
            ref={videoEl}
            component="video"
            controls
            poster={miniatureImage}
            sx={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
              opacity: isHovered ? 1 : 0.8,
            }}
          />
          <Icon
            className="fa-sharp fa-regular fa-circle-play"
            sx={{
              opacity: isHovered ? 0.5 : 1,
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              color: 'common.white',
              fontSize: '40px',
              zIndex: 1,
            }}
          />
        </Box>
      </Stack>
    );
  }
};
