import { Box, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { ChatMessage } from '../../../../../models/chat.model';
import { ChatAvatar } from '../../../../UI/ChatAvatar';
import { MessageConverted } from '../../../chat/MessageConverted';
import { MAX_CHARACTER_LENGTH } from '../../../chat/chatAutoScrolling/useScrollChatContext';
import { useScrollChatSize } from '../../../chat/chatAutoScrolling/useScrollChatSize';

interface DesktopChatMessageProps {
  readonly data: ChatMessage;
  readonly setSize: (index: number, size: number) => void;
  readonly index: number;
  readonly toggleLongText: (index: number, isRepliedMsg?: boolean) => void;
}

export const DesktopChatRepliedMessage = (repliedMessage: DesktopChatMessageProps) => {
  const { data, setSize, index, toggleLongText } = repliedMessage;
  const { rowRef } = useScrollChatSize(index, setSize);
  const toBeRepliedMessage = data.customAttributes?.toBeRepliedMessage ?? '';
  const toBeRepliedUser = data.customAttributes?.toBeRepliedUser;
  const theme = useTheme();
  const message = data.message ?? '';
  const toBeRepliedUserId = data.customAttributes?.toBeRepliedUserId ?? '';
  const isModeratorSendMessage = data.userId === toBeRepliedUserId;
  const toBeRepliedModerator =
    data.customAttributes?.isModerator && data.customAttributes?.isModerator === 'true' && !isModeratorSendMessage;

  const handleToggleLongText = () => toggleLongText(index);
  const handleToggleLongRepliedText = () => toggleLongText(index, true);

  const sxBoxWrapper: SxProps = {
    display: 'flex',
    flexDirection: 'row',
  };

  const sxBox: SxProps = {
    display: 'block',
    width: 'fit-content',
    backgroundColor: theme.components?.chatMessage?.mobile?.replied?.respond?.background,
    borderRadius: 1,
    px: '8px',
    py: '4px',
  };
  const sxBoxStackWrapper: SxProps = {
    display: 'inline-flex',
    flexDirection: 'column',
    spacing: 1,
  };
  const sxBoxStack: SxProps = {
    display: 'inline-block',
    borderRadius: 1,
    border: `1px solid ${theme.components?.chatMessage?.mobile?.replied?.borderColor}`,
    p: '4px 8px',
    spacing: 1,
  };
  const sxBoxStackInnerBox: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
  };
  const sxBoxStackInnerBoxResponse: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '8px 0 0',
  };
  const sxBoxStackRepliedName: SxProps = {
    marginRight: '4px',
    display: 'inline',
    fontSize: '0.75rem',
    fontWeight: '700',
    lineHeight: '130%',
    mr: '8px',
    color: toBeRepliedModerator
      ? theme.components?.chatMessage?.mobile?.replied?.moderatorNameColor
      : theme.components?.chatMessage?.mobile?.replied?.userNameColor,
  };
  const sxBoxStackModeratorName: SxProps = {
    fontSize: '0.75rem',
    fontWeight: '700',
    lineHeight: '130%',
    textTransform: 'none',
    color: theme.components?.chatMessage?.mobile?.replied?.respond?.moderatorNameColor,
    marginRight: '5px',
  };

  const sxProductLinkMessage: SxProps = {
    marginRight: '4px',
    lineBreak: 'anywhere',
    fontSize: '0.75rem',
    lineHeight: '130%',
    textDecoration: toBeRepliedModerator ? 'none' : 'hover',
    pointerEvents: toBeRepliedModerator ? 'none' : 'auto',
    color: theme.components?.chatMessage?.mobile?.replied?.respond?.linkColor,
  };
  const sxTextMessage: SxProps = {
    marginRight: '4px',
    wordBreak: 'break-word',
    display: 'inline',
    color: theme.components?.chatMessage?.mobile?.replied?.textColor,
    fontSize: '0.75rem',
    lineHeight: '130%',
    textTransform: 'none',
  };

  const sxProductLinkMessageResponse: SxProps = {
    color: theme.components?.chatMessage?.mobile?.replied?.respond?.linkColor,
    fontSize: '0.75rem',
    lineHeight: '130%',
  };
  const sxTextMessageResponse: SxProps = {
    marginRight: '4px',
    wordBreak: 'break-word',
    color: theme.components?.chatMessage?.mobile?.replied?.respond?.textColor,
    fontSize: '0.75rem',
    lineHeight: '130%',
    textTransform: 'none',
  };

  return (
    <Box sx={sxBoxWrapper}>
      {data.customAttributes?.avatarUrl && (
        <ChatAvatar isMobile={false} backgroundURL={data.customAttributes?.avatarUrl} />
      )}
      <Box sx={sxBox} ref={rowRef}>
        <Stack sx={sxBoxStackWrapper}>
          <Stack sx={sxBoxStack}>
            <Box
              sx={{ ...sxBoxStackInnerBox, cursor: message.length > MAX_CHARACTER_LENGTH ? 'pointer' : 'auto' }}
              onClick={message.length > MAX_CHARACTER_LENGTH ? handleToggleLongRepliedText : undefined}
            >
              <Typography sx={sxBoxStackRepliedName}>{toBeRepliedUser}</Typography>
              <MessageConverted
                message={toBeRepliedMessage}
                isModerator={isModeratorSendMessage}
                isProductLinkMessageClass={true}
                sxProductLinkMessage={sxProductLinkMessage}
                sxTextMessage={sxTextMessage}
                isLongTextToggled={data.customAttributes?.isLongTextToggled}
                rel="noopener noreferrer"
              />
            </Box>
          </Stack>

          <Box
            sx={{ ...sxBoxStackInnerBoxResponse, cursor: message.length > MAX_CHARACTER_LENGTH ? 'pointer' : 'auto' }}
            onClick={message.length > MAX_CHARACTER_LENGTH ? handleToggleLongText : undefined}
          >
            <Typography sx={sxBoxStackModeratorName}>{data.username}</Typography>
            <MessageConverted
              isLongTextToggled={data.isLongTextToggled}
              message={message}
              isModerator={true}
              isProductLinkMessageClass={true}
              sxProductLinkMessage={sxProductLinkMessageResponse}
              sxTextMessage={sxTextMessageResponse}
              rel="noopener noreferrer"
            />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
