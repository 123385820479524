import './theming/all.scss';
import './theming/helper.scss';
import { QueryClient, QueryClientProvider } from 'react-query';
import { I18nextProvider } from 'react-i18next';
import { GraphQLClientContextProvider } from './graphqlClient/GraphQLClient';
import { BrowserRouter } from 'react-router-dom';
import { UserIdProvider } from './utils/userIdProvider';
import { SearchParamsProvider } from './components/providers/SearchParamsProvider';
import { MediaPlayer, PlayerConfig } from 'amazon-ivs-player';
import { LoadingSpinner } from './components/UI/LoadingSpinner';
import { DeviceMetadataProvider } from './components/providers/DeviceMetadataProvider';
import { createRoot } from 'react-dom/client';
import i18n from './i18n/i18n';
import React, { Suspense } from 'react';
import App from './App';

declare global {
  interface Window {
    IVSPlayer: {
      isPlayerSupported: boolean;
      create: (config?: PlayerConfig) => MediaPlayer;
    };
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Suspense fallback={<LoadingSpinner />}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <SearchParamsProvider>
            <QueryClientProvider client={queryClient}>
              <GraphQLClientContextProvider>
                <UserIdProvider>
                  <DeviceMetadataProvider>
                    <App />
                  </DeviceMetadataProvider>
                </UserIdProvider>
              </GraphQLClientContextProvider>
            </QueryClientProvider>
          </SearchParamsProvider>
        </BrowserRouter>
      </I18nextProvider>
    </Suspense>
  </React.StrictMode>,
);
