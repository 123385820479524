import { Box, Icon, IconButton, InputAdornment, styled, useTheme } from '@mui/material';
import { AccessToChatDialog } from '../../../chat/accessToChatDialog/AccessToChatDialog';
import { StyledTooltip } from '../../../../UI/StyledTooltip';
import { StyledOutlinedInput } from '../../../../UI/StyledOutlinedInput';
import { ControlPanel } from '../../videoControl/ControlPanel';
import { Products } from '../../productList/Products';
import { useChatTextfied } from '../../../../../middleware/chat/useChatTextfield';
import { useIVSChatContext } from '../../../../../middleware/chat/useIVSChat';
import { useEmbededViewControl } from '../../../../../middleware/playerState/useEmbededViewControl';
import { useCarouselContext } from '../../../../mediaLibrary/carousel/useCarouselContext';
import { useShow } from '../../../../../middleware/video/useShow';
import { useDeviceMetadata } from '../../../../providers/DeviceMetadataProvider';

export const MobileChatTextField = () => {
  const { products } = useShow();

  const {
    inputRef,
    message,
    isUsernameValid,
    checkUsernameLoading,
    termsDialogOpen,
    tooltips,
    openTooltips,
    setUserNameValid,
    handleKeyDown,
    handleCompositionStart,
    handleCompositionEnd,
    handleClick,
    onMessageClick,
    onTooltipClose,
    onAccessChat,
    validateUsername,
    functionOnClose,
    getTextFieldPlaceholder,
    onChangeMessage,
  } = useChatTextfied();

  const { hasAcceptTerms, isSocketActive, setHasAcceptTerms } = useIVSChatContext();
  const { enableCarousel, disableCarousel } = useCarouselContext();
  const { isProductListOpen, onPressToggleProductList } = useEmbededViewControl();
  const { isMobileView } = useDeviceMetadata();

  const theme = useTheme();
  const mobileButtonStyles = {
    color:
      message.length > 0
        ? theme.components?.chatBox?.mobile?.button?.active
        : theme.components?.chatBox?.mobile?.button?.default,
    '&:active::before': {
      color: theme.components?.chatBox?.mobile?.button?.active,
    },
    fontSize: '1.25rem',
    overflow: 'visible',
  };

  return (
    <StyledTooltip
      title={tooltips}
      open={openTooltips}
      onClose={onTooltipClose}
      placement="top"
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [-30, -60],
            },
          },
        ],
      }}
    >
      <TextFieldFrame sx={{ display: 'flex', gap: '8px' }}>
        {termsDialogOpen && (
          <AccessToChatDialog
            hasAcceptTerms={hasAcceptTerms}
            setHasAcceptTerms={setHasAcceptTerms}
            onClose={functionOnClose}
            onAccessChat={onAccessChat}
            isUsernameValid={isUsernameValid}
            setUserNameValid={setUserNameValid}
            checkUsernameLoading={checkUsernameLoading}
            validateUsername={validateUsername}
          />
        )}
        <StyledOutlinedInput
          inputRef={inputRef}
          sx={{
            '& input::placeholder': {
              fontSize: isMobileView ? '3.5vw' : '16px',
            },
          }}
          id="outlined-adornment-password"
          type="text"
          fullWidth={true}
          value={message}
          onChange={onChangeMessage}
          onKeyDown={handleKeyDown}
          onCompositionStart={handleCompositionStart}
          onCompositionEnd={handleCompositionEnd}
          onClick={handleClick}
          onFocus={disableCarousel}
          onBlur={enableCarousel}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="send message icon" onClick={onMessageClick} edge="end">
                <Icon sx={mobileButtonStyles} className="ls-default ls-send" />
              </IconButton>
            </InputAdornment>
          }
          disabled={!isSocketActive}
          placeholder={getTextFieldPlaceholder()}
        />
        <ControlPanel justifyContent={'right'} position={0} />
        <Products
          productsList={products}
          onProductListToggle={onPressToggleProductList}
          isToggled={isProductListOpen}
        />
      </TextFieldFrame>
    </StyledTooltip>
  );
};

const TextFieldFrame = styled(Box)(({ theme }) => ({
  backgroundColor: theme.components?.chatBox?.mobile?.background,
  borderTopLeftRadius: 5,
  borderTopRightRadius: 5,
  padding: '0.75em',
}));
