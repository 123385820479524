import { styled } from '@mui/material/styles';
import { Icon } from '@mui/material';

interface StyledIconProps {
  angle?: string;
}

export const StyledIcon = styled(Icon)((props: StyledIconProps) => ({
  fontSize: '1.063rem',
  transform: props.angle && `rotate(${props.angle}deg)`,
}));
