import { Stack } from '@mui/material';
import { ControlPanel } from '../ControlPanel';
import { AspectRatio } from '../../../../models/VideoViewLayerModels';
import { PlayBackSection } from '../PlayBackSection';
import { useShow } from '../../../../middleware/video/useShow';
import { usePlayableVideoStreamContext } from '../../../../middleware/video/usePlayableVideoStreamContext';

interface VideoControlsProps {
  readonly paused?: boolean;
  readonly position: number;
  readonly duration: number;
  readonly isLiveShowEnded: boolean;
  readonly isVodEnded: boolean;
  readonly isFeatureEnabled: boolean;
  readonly showUpcomimgComponents: boolean;
  readonly onTimeChangeCommittedHandler: (time: number) => void;
  readonly onTogglePlay: () => void;
}

export const DesktopVideoControlLayout = (props: VideoControlsProps) => {
  const { isVod, isClip } = useShow();
  const { videoAspectRatio } = usePlayableVideoStreamContext();
  const {
    isFeatureEnabled,
    showUpcomimgComponents,
    position,
    paused,
    duration,
    isLiveShowEnded,
    isVodEnded,
    onTimeChangeCommittedHandler,
    onTogglePlay,
  } = props;

  const isPortraitView = !isClip && (videoAspectRatio === AspectRatio.PORTRAIT || videoAspectRatio === null);

  if (!showUpcomimgComponents && isFeatureEnabled) {
    return (
      <Stack
        height="100%"
        width={isPortraitView ? 'unset' : '100%'}
        direction="column"
        justifyContent="flex-end"
        sx={{
          aspectRatio: '9 / 16',
          boxSizing: 'border-box',
          mx: '32px',
          px: isPortraitView ? '32px' : 'unset',
        }}
      >
        <Stack mb={2} spacing={1} alignItems="flex-end">
          <ControlPanel />
          {isVod && (
            <PlayBackSection
              isLiveShowEnded={isLiveShowEnded}
              isVodEnded={isVodEnded}
              paused={paused}
              position={position}
              duration={duration}
              onTimeChangeCommittedHandler={onTimeChangeCommittedHandler}
              onTogglePlay={onTogglePlay}
            />
          )}
        </Stack>
      </Stack>
    );
  } else {
    return null;
  }
};
