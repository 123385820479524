import { Box, Typography, styled } from '@mui/material';

export const ChatBox = styled(Box)({
  maxHeight: '25vh',
  display: 'flex',
  flexDirection: 'column',
  msOverflowStyle: 'none' /* IE and Edge */,
  scrollbarWidth: 'none' /* Firefox */,
  '::-webkit-scrollbar': {
    display: 'none',
  },
});

export const ChatBubble = styled('div')(() => ({
  display: 'block',
  minWidth: 0,
  padding: '4px 8px',
  borderRadius: '4px',
}));

export const StyledMobileChatButton = styled(Typography)(({ theme }) => ({
  display: 'flex',
  width: 'fit-content',
  alignItems: 'center',
  flexDirection: 'row',
  justifyContent: 'center',
  gap: '7px',
  padding: '4px 8px',
  marginBottom: '8px !important',
  borderRadius: theme.components?.defaultValues?.borderRadius,
  backgroundColor: theme.components?.chatBoxTriggerBackground,
  color: theme.palette?.text.primary,
  fontWeight: 'bold',
  fontSize: theme.typography.sectionTitle?.fontSize,
  fontFamily: theme.typography.sectionTitle?.fontFamily,
}));
