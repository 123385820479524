interface ShareSocialMediaWidgetParams {
  readonly hostUrl: URL | undefined;
  readonly withTime: boolean;
  readonly asyncPosition: number;
  readonly uuid: string;
  readonly isClip: boolean;
}

export const updateHostUrl = (params: ShareSocialMediaWidgetParams): string => {
  const { hostUrl, withTime, asyncPosition, uuid, isClip } = params;

  let newSharedUrl = '';

  if (hostUrl) {
    if (withTime) {
      hostUrl.searchParams.set('oslsDisplayTime', asyncPosition.toString());
    } else {
      hostUrl.searchParams.delete('oslsDisplayTime');
    }
    hostUrl.searchParams.set('oslsActiveShow', uuid);
    hostUrl.searchParams.set('isClip', isClip.toString());
    newSharedUrl = hostUrl.toString();
  }

  return newSharedUrl;
};

export const secondsToMms = (seconds: number): string => {
  if (!seconds) return '00:00';
  const duration = seconds;
  const minutes = (duration % 3600) / 60;
  const minutesFormatted = minutes < 10 ? `0${Math.floor(minutes)}` : Math.floor(minutes);
  const secondsFormatted = (duration % 3600) % 60;
  const secondsFormattedFinal = secondsFormatted < 10 ? `0${secondsFormatted}` : secondsFormatted;
  return `${minutesFormatted}:${secondsFormattedFinal}`;
};
