import { Box, Icon, IconButton, Stack, useTheme } from '@mui/material';
import { MutableRefObject, useCallback } from 'react';
import { Products } from '../productList/Products';
import { useShow } from '../../../../middleware/video/useShow';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';
import { useClientEventFunction } from '../../../../middleware/clientEvent/useClientEventFunctions';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';
import { useModulePermissionCheck } from '../../../../utils/useModulePermissionCheck';
import { CONTROL_PANEL_BG_COLOR } from '../../../../theming/theme';
import { ControlPanel } from './ControlPanel';
import { ClassicPlayerTimeSlider } from '../../videoStream/videoControlPanel/PlayerTimeSlider';

interface VideoControlProps {
  readonly isVod: boolean;
  readonly videoElement: MutableRefObject<HTMLVideoElement | undefined>;
  readonly position: number;
  readonly onTimeChangeCommittedHandler: (time: number) => void;
  readonly onPauseHandler: () => void;
  readonly onPlayHandler: () => void;
}

export const PANEL_HEIGHT = 46;

export const VideoControlLayout = (props: VideoControlProps) => {
  const { isVod, videoElement, position, onPauseHandler, onPlayHandler, onTimeChangeCommittedHandler } = props;
  const { isProductListOpen, onPressToggleProductList } = useEmbededViewControl();
  const { isMobileView } = useDeviceMetadata();
  const { pausePlayer } = useClientEventFunction();
  const { isAiAssistanceSupported } = useModulePermissionCheck();
  const { products } = useShow();

  const theme = useTheme();
  const duration = videoElement.current?.duration ? +videoElement.current?.duration.toFixed(0) : 0;
  const paused = videoElement.current?.paused;

  const onTogglePlay = useCallback(() => {
    if (paused) {
      onPlayHandler();
      pausePlayer(false);
    } else {
      onPauseHandler();
      pausePlayer(true);
    }
  }, [onPauseHandler, onPlayHandler, pausePlayer, paused]);

  const defaultStyle = {
    borderRadius: '6px 6px 0px 0px',
    backgroundColor: theme.components?.video?.backgrounds?.controlPanel,
  };

  const aiSupportedStyle = {
    borderRadius: 0,
    backgroundColor: CONTROL_PANEL_BG_COLOR,
  };

  return (
    <Stack
      width="100%"
      direction="column"
      justifyContent="center"
      sx={isAiAssistanceSupported && isMobileView ? aiSupportedStyle : defaultStyle}
    >
      <Box sx={{ px: '12px', py: 0 }}>
        <Stack
          direction="row"
          justifyContent={isVod ? 'space-between' : 'center'}
          alignItems={'center'}
          height={`${PANEL_HEIGHT}px`}
          gap={'12px'}
        >
          {isVod && (
            <Stack direction="row" alignItems="center" spacing={1.5}>
              <IconButton size="small" onClick={onTogglePlay}>
                <Icon
                  fontSize="small"
                  baseClassName="ls-default"
                  className={paused ? 'ls-play' : 'ls-stop'}
                  sx={{ px: 0.5, color: theme.palette?.text?.primary }}
                />
              </IconButton>
            </Stack>
          )}

          {isVod && (
            <ClassicPlayerTimeSlider
              position={position}
              duration={duration}
              onChangeCommitted={onTimeChangeCommittedHandler}
            />
          )}

          <ControlPanel justifyContent={isVod ? 'right' : 'center'} direction="row" position={position} />

          {isMobileView && (
            <Products
              productsList={products}
              seekTo={onTimeChangeCommittedHandler}
              onProductListToggle={onPressToggleProductList}
              isToggled={isProductListOpen}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
