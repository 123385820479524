import { TouchEvent } from 'react';
import { Icon, Stack, Typography, useTheme } from '@mui/material';
import { ProductApiResponse } from '../../../models/show.model';
import { useJumpToHighlightTime } from '../../../utils/useJumpToLighlightTime';
import { useShow } from '../../../middleware/video/useShow';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { animated } from 'react-spring';

interface JumpToHighlightButtonProps {
  readonly item: ProductApiResponse;
  readonly isHover: boolean;
  readonly formatTime: (time: number) => string;
  readonly seekTo?: (time: number) => void;
  readonly setIsHover: (value: boolean) => void;
  readonly onProductListToggle?: (enable: boolean) => void;
}

const fadeInAnimation = {
  animation: 'fadeInCenter 0.5s ease',
  '@keyframes fadeInCenter': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
};

const CLOSE_DURATION = 500;

export const JumpToHighlightButton = (props: JumpToHighlightButtonProps) => {
  const { item, isHover, setIsHover, formatTime, seekTo, onProductListToggle } = props;
  const { isMobileView } = useDeviceMetadata();
  const { isVod } = useShow();

  const theme = useTheme();
  const highlightTime = item?.highlightTime;
  const displayJumpToHighlightIcon =
    isVod && highlightTime !== 0 && highlightTime !== undefined && highlightTime !== null;

  const {
    playIconSpring,
    highlightTimeSpring,
    jumpToHighlightedTimeHandler,
    onMouseEnterHandler,
    onMouseLeaveHandler,
  } = useJumpToHighlightTime({
    seekTo,
    highlightTime: highlightTime ?? 0,
    setIsHover,
  });

  const onTouchStartHandler = () => setIsHover(true);

  const onTouchEndHandler = (e: TouchEvent<HTMLDivElement>) => {
    if (onProductListToggle) {
      setTimeout(() => {
        jumpToHighlightedTimeHandler(e);
        onProductListToggle(false);
        setIsHover(false);
      }, CLOSE_DURATION);
    }
  };

  if (displayJumpToHighlightIcon && !isMobileView) {
    return (
      <Stack spacing={0.5} alignItems="center" justifyContent="center">
        <Stack
          sx={{
            alignSelf: 'start',
            border: `1px solid ${theme.palette.grey['200']}`,
            width: '48px',
            height: '40px',
            padding: '7px 4px',
            borderRadius: '50%',
            transition: 'background-color 0.5s ease',
            background: isHover ? theme.palette.grey['200'] : theme.palette.common.white,
          }}
          direction="column"
          justifyContent="center"
          alignItems="center"
          onClick={jumpToHighlightedTimeHandler}
          onMouseEnter={onMouseEnterHandler}
          onMouseLeave={onMouseLeaveHandler}
        >
          <animated.div style={{ ...playIconSpring }}>
            <Icon
              className="ls-default ls-play"
              sx={{
                background: 'transparent',
                color: theme.palette.common.black,
                fontSize: '22px',
                ml: '3px',
                mt: '2px',
              }}
            />
          </animated.div>

          <animated.div style={{ ...highlightTimeSpring }}>
            <Typography
              fontSize={'10px'}
              sx={{
                fadeInAnimation,
                color: theme.palette.common.black,
                fontSize: '11px',
                fontStyle: ' normal',
                fontWeight: '300',
                lineHeight: ' 16.8px',
                display: isHover ? 'block' : 'none',
                mb: '8px',
              }}
            >
              {formatTime(item.highlightTime ?? 0)}
            </Typography>
          </animated.div>
        </Stack>
      </Stack>
    );
  } else if (displayJumpToHighlightIcon && isMobileView) {
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        onTouchStart={onTouchStartHandler}
        onTouchEnd={onTouchEndHandler}
        gap={0.5}
      >
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            border: `1px solid ${theme.palette.grey['200']}`,
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            transition: 'background-color 0.5s ease',
            background: isHover ? theme.palette.grey['200'] : theme.palette.common.white,
          }}
        >
          <animated.div style={{ ...playIconSpring }}>
            <Icon
              className="ls-default ls-play"
              sx={{
                background: 'transparent',
                color: theme.palette.common.black,
                fontSize: '20px',
                ml: '3px',
                mt: '2px',
              }}
            />
          </animated.div>
        </Stack>
        <animated.div style={{ ...highlightTimeSpring }}>
          <Typography
            fontSize={'10px'}
            sx={{
              fadeInAnimation,
              color: theme.palette.common.black,
              fontSize: '11px',
              fontStyle: ' normal',
              fontWeight: '300',
              lineHeight: ' 16.8px',
              display: isHover ? 'block' : 'none',
            }}
          >
            {formatTime(item.highlightTime ?? 0)}
          </Typography>
        </animated.div>
      </Stack>
    );
  } else {
    return null;
  }
};
