import { AiResponseAlert } from '../../chat/aiSupportChatCommonComponents/AiResponseAlert';
import { useState } from 'react';
import { Divider, Stack, useTheme } from '@mui/material';
import { ControlPanel } from '../ControlPanel';
import { HighlightedProducts } from '../../videoStream/HighlightedProducts';
import { MobileChatTextField } from '../chat/mobileChat/MobileChatTextField';
import { MobileChatWrapper } from '../chat/mobileChat';
import { MobileChatPinnedMessage } from '../chat/mobileChat/MobileChatPinnedMessage';
import { MobileChatAiSupportTextField } from '../chat/mobileChat/aiSupport/MobileChatAiSupportTextField';
import { MobileAiSupportChatBox } from '../../chat/aiSupportChatCommonComponents/AiResponseChatBox';
import { PlayBackSection } from '../PlayBackSection';
import { useIVSChatContext } from '../../../../middleware/chat/useIVSChat';
import { useToggle } from '../../../../utils/useToggle';
import { useAiSupport } from '../../../../middleware/aiAssistance/useAiSupport';
import { useShow } from '../../../../middleware/video/useShow';
import { useModulePermissionCheck } from '../../../../utils/useModulePermissionCheck';

interface VideoControlsProps {
  readonly paused?: boolean;
  readonly position: number;
  readonly duration: number;
  readonly isLiveShowEnded: boolean;
  readonly isVodEnded: boolean;
  readonly isFeatureEnabled: boolean;
  readonly showUpcomimgComponents: boolean;
  readonly onTimeChangeCommittedHandler: (time: number) => void;
  readonly onTogglePlay: () => void;
}

export const MobileVideoControlLayout = (props: VideoControlsProps) => {
  const [isAlertOpen, setAlertOpen] = useState(true);
  const {
    showUpcomimgComponents,
    duration,
    paused,
    position,
    isLiveShowEnded,
    isFeatureEnabled,
    isVodEnded,
    onTimeChangeCommittedHandler,
    onTogglePlay,
  } = props;
  const { isAiAssistanceSupported } = useModulePermissionCheck();
  const { isToggled, setToggle: aiChatMessageToggleHandler } = useToggle();
  const { pinnedMessage } = useIVSChatContext();
  const { isVod, isClip } = useShow();
  const {
    textInput,
    isLoading,
    textFieldRef,
    aiSupportMessages,
    onChangeHandler,
    onKeyDownHandler,
    onSubmitHandler,
    onCompositionEndHandler,
    onCompositionStartHandler,
  } = useAiSupport();

  const theme = useTheme();

  if (!showUpcomimgComponents && isFeatureEnabled) {
    return (
      <Stack
        spacing={2}
        direction="column"
        justifyContent="flex-end"
        alignItems="center"
        width="100%"
        height="100%"
        sx={{ mb: 3 }}
      >
        <Stack
          direction="column"
          justifyContent="flex-end"
          alignItems="flex-start"
          spacing={1}
          width="calc(100% - 24px)"
        >
          {isAiAssistanceSupported && !isClip && <MobileChatWrapper />}
          {isAiAssistanceSupported && (
            <Divider
              orientation="horizontal"
              sx={{
                width: '100%',
                border: `1px solid ${theme.palette.grey['200']} `,
                boxShadow: '6px 0px 18px 0px rgba(192, 192, 192, 0.10)',
              }}
            />
          )}

          <Stack direction="row" justifyContent="flex-end" alignItems="flex-end" width="100%">
            {isAiAssistanceSupported && <MobileAiSupportChatBox aiSupportMessages={aiSupportMessages} />}
            {!isAiAssistanceSupported && !isClip && <MobileChatWrapper />}
            {!isVodEnded && !isLiveShowEnded && <ControlPanel />}
          </Stack>
          {pinnedMessage.pin && !isClip && <MobileChatPinnedMessage pinnedMessage={pinnedMessage} />}
        </Stack>

        {!isToggled && isAiAssistanceSupported && (
          <AiResponseAlert isAlertOpen={isAlertOpen} setAlertOpen={setAlertOpen} />
        )}

        <Stack spacing={1} direction="column" justifyContent="center" alignItems="center" width="calc(100% - 24px)">
          <HighlightedProducts />
          {!isVod && <MobileChatTextField />}
          {isAiAssistanceSupported && (
            <MobileChatAiSupportTextField
              textInput={textInput}
              isLoading={isLoading}
              textFieldRef={textFieldRef}
              onSubmitHandler={onSubmitHandler}
              onKeyDownHandler={onKeyDownHandler}
              onChangeHandler={onChangeHandler}
              onCompositionEndHandler={onCompositionEndHandler}
              onCompositionStartHandler={onCompositionStartHandler}
              aiChatMessageToggleHandler={aiChatMessageToggleHandler}
            />
          )}
          {isVod && (
            <PlayBackSection
              isLiveShowEnded={isLiveShowEnded}
              isVodEnded={isVodEnded}
              paused={paused}
              position={position}
              duration={duration}
              onTimeChangeCommittedHandler={onTimeChangeCommittedHandler}
              onTogglePlay={onTogglePlay}
            />
          )}
        </Stack>
      </Stack>
    );
  } else {
    return null;
  }
};
