import { Box, Stack, Typography, useTheme } from '@mui/material';
import { MediaLibraryClipPlaylist } from '../../graphqlClient/showRequest';
import { DesktopTimeLabel, MobileTimeLabel } from '../UI/StyledTimeLabel';
import { MutableRefObject } from 'react';
import { ProductApiResponse } from '../../models/show.model';
import { ClipCard as LabelCard } from '../UI/StyledCard';
import { CardTitle } from './storyBlock/CardTitle';
import { PreviewPlayerLoader, useMediaLibraryPreviewPlayer } from '../../middleware/video/useMediaLibraryPreviewPlayer';
import { Video } from '../UI/VideoElement';
import { useDeviceMetadata } from '../providers/DeviceMetadataProvider';
import { useMediaLibraryContext } from './MediaLibraryProvider';

interface ClipPlaylistProps {
  readonly cardRef: MutableRefObject<HTMLDivElement | null>;
  readonly clip: MediaLibraryClipPlaylist;
  readonly isStoryBlock?: boolean;
  readonly isVisible: boolean;
  readonly onSelectClip: (clip: MediaLibraryClipPlaylist, isClip: boolean) => void;
}

const ClipHighlightedItem = (props: { highlightedItem: ProductApiResponse[] }) => {
  const { isMobileView } = useDeviceMetadata();
  const highlightedItem = props.highlightedItem[0];
  return (
    <Stack
      position="absolute"
      bottom={0}
      height={isMobileView ? '58px' : '92px'}
      width="100%"
      bgcolor="rgba(255, 255, 255, 0.7)"
      borderRadius={0.5}
      direction="row"
      alignItems="center"
    >
      <Box
        bgcolor="#fff"
        sx={{
          mx: '12px',
          my: '10px',
          objectFit: 'contain',
          borderRadius: 0.5,
        }}
      >
        {highlightedItem.image && (
          <Box
            component="img"
            sx={{ maxWidth: isMobileView ? '46px' : '72px', maxHeight: isMobileView ? '46px' : '72px' }}
            alt={highlightedItem.title}
            src={highlightedItem.image}
          />
        )}
      </Box>
      <Stack direction="column">
        <Typography
          sx={{
            width: '100%',
            display: '-webkit-box',
            WebkitLineClamp: '3',
            WebkitBoxOrient: 'vertical',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            lineHeight: '100%',
            whiteSpace: 'break-spaces',
            fontSize: isMobileView ? '11px' : '14px',
            color: 'text.primary',
            fontWeight: 700,
          }}
        >
          {highlightedItem.title}
        </Typography>
        <Typography fontSize={isMobileView ? '11px' : '14px'} color="text.primary">
          {highlightedItem.brand}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const ClipCard = (props: ClipPlaylistProps) => {
  const { clip, cardRef, onSelectClip, isStoryBlock, isVisible } = props;
  const { isMobileView } = useDeviceMetadata();
  const { isHovered, isLoading, previewVideoEl, handleMouseEnter, handleMouseLeave, handleTouchStart, handleTouchEnd } =
    useMediaLibraryPreviewPlayer({
      previewClipId: clip.id,
      enablePreview: !isStoryBlock,
      isVisible,
    });
  const {
    mediaLibrary: { displayShowDates, displayShowStateLabel },
  } = useMediaLibraryContext();

  const theme = useTheme();
  const shouldShowPreview = isHovered && !isStoryBlock;

  const handleClickClip = () => {
    isMobileView ? handleTouchEnd() : handleMouseLeave();
    onSelectClip(clip, true);
  };

  return (
    <Stack direction="column">
      <Box
        ref={cardRef}
        onClick={handleClickClip}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        sx={{
          width: isMobileView && isStoryBlock ? '230px' : 'auto',
          height: isMobileView ? (isStoryBlock ? '450px' : '320px') : '533px',
          position: 'relative',
          '&:hover': {
            cursor: 'pointer',
          },
        }}
      >
        <Video
          ref={previewVideoEl}
          id="clip-card-preview-video"
          poster={shouldShowPreview ? undefined : clip.thumbnailUrl}
          muted
          playsInline={true}
          controls={false}
          sx={{
            aspectRatio: '9 / 16',
            background: shouldShowPreview ? undefined : `url(${clip.thumbnailUrl})`,
            backgroundColor: 'common.black',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            borderRadius: 1.5,
            position: 'relative',
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: isMobileView ? (isStoryBlock ? '450px' : '320px') : '533px',
            top: 0,
            left: 0,
          }}
        >
          {isLoading && <PreviewPlayerLoader />}

          {(!isStoryBlock || displayShowStateLabel) && (
            <Box sx={{ width: '100%', ml: '12px', mt: '9px' }}>
              <LabelCard isMediaLibraryCoverLabel={true} />
            </Box>
          )}

          {clip.publishedItems && !isStoryBlock && <ClipHighlightedItem highlightedItem={clip.publishedItems} />}
          {isStoryBlock && (
            <CardTitle
              title={clip.title}
              isMobileView={isMobileView}
              displayShowDates={displayShowDates}
              description={clip.description}
            />
          )}

          {isStoryBlock &&
            displayShowDates &&
            (isMobileView ? (
              <MobileTimeLabel showtime={clip.createDate} status={clip.status} />
            ) : (
              <DesktopTimeLabel showtime={clip.createDate} status={clip.status} />
            ))}
        </Box>
      </Box>
      {!isStoryBlock && (
        <Typography
          py={isMobileView ? 2 : 2.5}
          fontSize={theme.typography.showAndClipTitle?.fontSize}
          fontFamily={theme.typography.showAndClipTitle?.fontFamily}
          color={theme.components?.mediaLibrary?.showAndClipTitleColor}
          fontWeight={700}
          display="block"
        >
          {clip.title}
        </Typography>
      )}
    </Stack>
  );
};
