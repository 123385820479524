import { Chip, Icon, Stack } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { Playlist } from '../../graphqlClient/mediaLibraryRequest';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper';
import { useDeviceMetadata } from '../providers/DeviceMetadataProvider';
import { OrderedTag, useMediaLibraryContext } from './MediaLibraryProvider';

interface TagProps {
  readonly tag: OrderedTag;
  readonly slideHandler?: () => void;
}

const filterClipsByTags = (clipPlaylist: Playlist, tags: OrderedTag[]) => {
  return clipPlaylist.clips.filter((clip) => {
    if (tags.length >= 2) {
      return tags.every((tag) => clip.tags.find((clipTag) => clipTag.id === tag.id));
    } else {
      return tags.some((tag) => clip.tags.find((clipTag) => clipTag.id === tag.id));
    }
  });
};

const filterShowsByTags = (showPlaylists: Playlist, tags: OrderedTag[]) => {
  return showPlaylists.shows.filter((show) => {
    if (tags.length >= 2) {
      return tags.every((tag) => show.tags.find((showTag) => showTag.id === tag.id));
    } else {
      return tags.some((tag) => show.tags.find((showTag) => showTag.id === tag.id));
    }
  });
};

export const FilterTags = () => {
  const { isMobileView } = useDeviceMetadata();
  const { tagList, isFiltering, mediaLibrary, selectedTags, setTagList, setPlaylists, setIsResultNotFound } =
    useMediaLibraryContext();
  const [swiper, setSwiper] = useState<null | SwiperClass>(null);

  const onSwiper = useCallback((swiper: SwiperClass) => setSwiper(swiper), []);
  const slideHandler = () => swiper?.slideTo(0);

  useEffect(() => {
    setTagList(mediaLibrary.filterTags.map((tag, index) => ({ ...tag, index: index, isSelected: false })));
  }, [mediaLibrary.filterTags, setTagList]);

  useEffect(() => {
    if (isFiltering) {
      const updatedPlaylists = mediaLibrary.playlists.map((playlist: Playlist) => {
        return {
          ...playlist,
          shows: playlist.shows.length !== 0 ? filterShowsByTags(playlist, selectedTags) : [],
          clips: playlist.clips.length !== 0 ? filterClipsByTags(playlist, selectedTags) : [],
        };
      });
      setPlaylists(updatedPlaylists);
      setIsResultNotFound(
        updatedPlaylists.every((pl) => pl.shows.length === 0) && updatedPlaylists.every((pl) => pl.clips.length === 0),
      );
    } else {
      setIsResultNotFound(false);
      setPlaylists(mediaLibrary.playlists);
    }
  }, [selectedTags, isFiltering, mediaLibrary, setIsResultNotFound, setPlaylists]);

  return (
    <Stack width="100%" direction="row" useFlexGap flexWrap="wrap" spacing={1} my={5} justifyContent="center">
      {isMobileView ? (
        <Swiper
          onSwiper={onSwiper}
          slidesPerView="auto"
          direction="horizontal"
          spaceBetween={8}
          style={{ width: '100%', height: '100%', justifyContent: 'row', paddingRight: '12px' }}
        >
          {tagList.map((tag, index) => (
            <SwiperSlide key={index} style={{ width: 'fit-content' }}>
              <TagButton tag={tag} slideHandler={slideHandler} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        tagList.map((tag, index) => <TagButton key={index} tag={tag} />)
      )}
    </Stack>
  );
};

const TagButton = (props: TagProps) => {
  const { tag, slideHandler } = props;
  const { tagList, setTagList, setSelectedTags } = useMediaLibraryContext();
  const isSelected = tag.isSelected;

  const handleClick = useCallback(() => {
    if (!isSelected) {
      const sortedTags = [
        tagList.find((item: OrderedTag) => item === tag) || tag,
        ...tagList.filter((item: OrderedTag) => item !== tag),
      ];
      const mappedTags = sortedTags.map((sortedTags) =>
        sortedTags.id === tag.id ? { ...sortedTags, isSelected: true } : sortedTags,
      );
      setTagList(mappedTags);
      setSelectedTags((prevFilteredTags: OrderedTag[]) => [...prevFilteredTags, { ...tag, isSelected: true }]);
      slideHandler && slideHandler();
    }
  }, [tag, isSelected, tagList, setSelectedTags, setTagList, slideHandler]);

  const handleDelete = useCallback(() => {
    setSelectedTags((prevFilteredTags: OrderedTag[]) =>
      prevFilteredTags.filter((filteredTag) => filteredTag.id !== tag.id),
    );
    setTagList((prevTagList: OrderedTag[]) => {
      const mappedTags = prevTagList.map((prevTag) =>
        prevTag.id === tag.id ? { ...prevTag, isSelected: false } : prevTag,
      );
      const sortedTags = [...mappedTags].sort((a, b) => {
        if (a.isSelected && !b.isSelected) {
          return -1;
        } else if (!a.isSelected && b.isSelected) {
          return 1;
        } else {
          const indexA = a.index !== undefined ? a.index : 0;
          const indexB = b.index !== undefined ? b.index : 0;
          return indexA - indexB;
        }
      });
      return sortedTags;
    });
  }, [tag, setSelectedTags, setTagList]);

  return (
    <Chip
      label={tag.name}
      onClick={handleClick}
      onDelete={isSelected ? handleDelete : undefined}
      deleteIcon={<Icon className="ls-default" baseClassName="ls-xmark-solid" />}
      color={isSelected ? 'primary' : 'default'}
      sx={{
        padding: `8px ${isSelected ? '12px' : '20px'}  8px 20px`,
        fontSize: '16px',
        '& .MuiChip-deleteIcon': {
          fontSize: '16px',
          lineHeight: 'normal',
          color: '#fff',
          marginLeft: 1,
        },
        '& .MuiChip-label': {
          color: isSelected ? '#fff' : 'initial',
          padding: 0,
        },
      }}
    />
  );
};
