import { Box, Paper, Typography, useTheme } from '@mui/material';
import { DesktopChatTextField } from './DesktopChatTextField';
import { DesktopChatPinnedMessage } from './DesktopChatPinnedMessage';
import { styled } from '@mui/material/styles';
import { VariableSizeList as ChatList } from 'react-window';
import { ScrollChatProvider, useScrollChatContext } from '../../../chat/chatAutoScrolling/useScrollChatContext';
import { DesktopChatRows } from '../../../chat/ChatRows';
import { DefaultProperties } from '../../../../../properties/defaultProperties';
import { DesktopChatAiSupportWrapper } from '../../../chat/aiSupportChatCommonComponents/DesktopChatAiSupportWrapper';
import { useModulePermissionCheck } from '../../../../../utils/useModulePermissionCheck';
import { useIVSChatContext } from '../../../../../middleware/chat/useIVSChat';
import { useChatTranslation } from '../../../../../i18n/i18nHooks';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useMemorizedChatData } from '../../../chat/chatAutoScrolling/useMemorizedChatData';
import { useAutoScrollChat } from '../../../chat/chatAutoScrolling/useAutoScrollChat';

const DesktopChatContainer = styled(Box)(() => ({
  overflow: 'hidden',
  flexGrow: 1,
}));

export const DesktopChatWrapper = () => {
  const { messages, pinnedMessage } = useIVSChatContext();
  const { isAiAssistanceSupported } = useModulePermissionCheck();
  const { t } = useChatTranslation();
  const theme = useTheme();
  const totalMessages: number = messages?.length;

  return (
    <Paper
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 0,
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          height: '45px',
          flexShrink: 0,
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${theme.palette.grey['800']}`,
          mb: 1,
        }}
      >
        <Typography
          variant="overline"
          sx={{ color: theme.palette.grey['800'] }}
          marginLeft="16.5px"
          fontWeight={700}
          fontSize={theme.typography.sectionTitle?.fontSize}
          fontFamily={theme.typography.sectionTitle?.fontFamily}
        >
          {t('chat')}
        </Typography>
      </Box>

      {totalMessages !== 0 && (
        <ScrollChatProvider defaultProperty={DefaultProperties.chatMessageHeightDesktop}>
          <Chatbox />
        </ScrollChatProvider>
      )}

      {pinnedMessage.pin && <DesktopChatPinnedMessage pinnedMessage={pinnedMessage} />}
      {isAiAssistanceSupported && <DesktopChatAiSupportWrapper />}
      <DesktopChatTextField />
    </Paper>
  );
};

const Chatbox = () => {
  const { listRef, getSize } = useScrollChatContext();
  const { itemData } = useMemorizedChatData();
  const { onScrolling } = useAutoScrollChat(itemData.items.length, listRef);
  const totalMessages = itemData.items.length;

  return (
    <DesktopChatContainer>
      <AutoSizer>
        {({ height, width }) => (
          <ChatList
            ref={listRef}
            onItemsRendered={onScrolling}
            innerElementType="div"
            className="desktopChatScrollbar"
            itemData={itemData}
            itemCount={totalMessages}
            itemSize={getSize}
            height={height as number}
            width={width as number}
          >
            {DesktopChatRows}
          </ChatList>
        )}
      </AutoSizer>
    </DesktopChatContainer>
  );
};
