import { useCallback, useEffect, useState } from 'react';
import { ChatEventName, ChatLogResponse, ChatMessage, ChatMessageType } from '../../models/chat.model';
import { useIVSChatContext } from './useIVSChat';
import { useQqlLiveChatLog } from '../../graphqlClient/showRequest';
import { useShow } from '../video/useShow';

const getChatType = (data: ChatLogResponse) => {
  if (data.Attributes?.messageType) {
    return ChatMessageType.ReplyMessage;
  } else {
    return ChatMessageType.Message;
  }
};

export const useLiveChatLogMethods = () => {
  const mapChatLog = useCallback((data: ChatLogResponse) => {
    const newMessage: ChatMessage = {
      type: getChatType(data),
      timestamp: data.SendTime,
      username: data.Sender?.Attributes.username ? data.Sender?.Attributes.username : data.Attributes?.username ?? '',
      userId: data.Sender?.UserId ?? '',
      message: data.Content ? data.Content : data.Attributes?.message ?? '',
      messageId: data.Id,
      customAttributes: data.Attributes,
    };
    return newMessage;
  }, []);

  const mapChatLogEvent = useCallback((data: ChatLogResponse) => {
    const newMessage: ChatMessage = {
      type: ChatMessageType.Event,
      timestamp: data.SendTime,
      username: data.Sender?.Attributes.username ? data.Sender?.Attributes.username : data.Attributes?.username ?? '',
      userId: data.Sender?.UserId ?? '',
      message: data.Content ? data.Content : data.Attributes?.message ?? '',
      messageId: data.Id,
      customAttributes: data.Attributes,
    };
    return newMessage;
  }, []);
  return { mapChatLog, mapChatLogEvent };
};

export const useLiveChatLog = () => {
  const [liveChatLog, setLiveChatLog] = useState<ChatLogResponse[]>([]);
  const { setMessages, setPinnedMessage } = useIVSChatContext();
  const { mapChatLog, mapChatLogEvent } = useLiveChatLogMethods();
  const { getLiveChatLog } = useQqlLiveChatLog();
  const { id: showId, channelArn, chatroomArn, isVod } = useShow();

  useEffect(() => {
    if (!isVod) {
      getLiveChatLog(channelArn, chatroomArn, showId)
        .then((res) => setLiveChatLog(res))
        .catch((err) => err);
    } else {
      setLiveChatLog([]);
    }
  }, [channelArn, chatroomArn, isVod, showId, getLiveChatLog]);

  useEffect(() => {
    if (!isVod && liveChatLog) {
      liveChatLog.forEach((chat: ChatLogResponse) => {
        switch (chat.Type) {
          case ChatMessageType.Message: {
            setMessages((prevMessages: ChatMessage[]) => [...prevMessages, mapChatLog(chat)]);
            break;
          }
          case ChatMessageType.Event: {
            switch (chat.EventName) {
              case ChatEventName.PinMessage: {
                setPinnedMessage({ pin: true, msg: mapChatLogEvent(chat) });
                break;
              }
              case ChatEventName.UnPinMessage: {
                setPinnedMessage({ pin: false, msg: undefined });
                break;
              }
              case ChatEventName.DeleteMessage: {
                setMessages((prevMessages: ChatMessage[]) => {
                  return prevMessages.filter((it) => it.messageId !== chat.Attributes?.MessageID);
                });
                break;
              }
            }
            break;
          }
          default: {
            console.log(`unsupported chat: ${chat}`);
          }
        }
      });
    }
  }, [isVod, liveChatLog, mapChatLog, mapChatLogEvent, setMessages, setPinnedMessage]);

  return { liveChatLog };
};
