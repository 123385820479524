import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const StyledButton = styled(Button)<{ component?: React.ElementType }>({
  lineHeight: '1.375rem',
  fontWeight: 700,
  fontSize: '1rem',
  padding: '5px 32px',
  borderRadius: '8px',
  textTransform: 'none',
  boxShadow: 'none',
});
