import { Link, SxProps } from '@mui/material';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useSearchParamContext } from '../../providers/SearchParamsProvider';

interface ProductLinkMessageProps {
  readonly url: string;
  readonly rel: string;
  readonly sx: SxProps;
  readonly className?: string;
}

export const ProductLinkMessage = ({ url, rel, sx, className }: ProductLinkMessageProps) => {
  const { onClickProduct } = useEmbededViewControl();
  const { useNativePipPlayer } = useSearchParamContext();

  const handleProductClick = () =>
    onClickProduct({
      id: -1,
      title: '',
      image: '',
      url,
    });

  return (
    <Link
      href={useNativePipPlayer ? url : undefined}
      fontSize={'0.9rem'}
      onClick={handleProductClick}
      rel={rel}
      target="_blank"
      className={className}
      sx={{
        cursor: 'pointer',
        ...sx,
      }}
    >
      {url}
    </Link>
  );
};
