import axios from 'axios';

export interface AiServerRequest {
  readonly highlightedProductUrls: string[];
  readonly allProductUrls: string[];
  readonly showId: string;
  readonly question: string;
}

export interface AiServerResponse {
  readonly prompt: string;
  readonly response: string;
}

export const sendRequestToAiServer = (requestBody: AiServerRequest | null) => {
  const url = !process.env.REACT_APP_ENV
    ? '/chat/get_response'
    : `${process.env.REACT_APP_STREAMER_URL}/chat/get_response`;

  return axios.post(url, requestBody);
};
