import { MutableRefObject, ReactNode, useCallback, useEffect, useState } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import { PlayableVideoStream } from '../videoStream/PlayableVideoStream';
import { PlayerState } from 'amazon-ivs-player';
import { CustomVideoLabel } from '../videoStream/VideoLabels/CustomVideoLabel';
import { VideoLabel } from '../videoStream/VideoLabels/VideoLabel';
import { VideoViewControlButton } from '../videoStream/VideoViewControlButton';
import { VideoOverlayFrame } from '../../UI/VideoElement';
import { VideoPanelMinimized } from '../videoStream/VideoPanelMinimized';
import { CompanyLogo } from '../videoStream/CompanyLogo';
import { VideoControlLayout } from './videoControl/VideoControlLayout';
import { HighlightedProducts } from '../videoStream/HighlightedProducts';
import { DefaultUnMuteVideoButton } from '../videoStream/DefaultUnMuteVideoButton';
import { useShow } from '../../../middleware/video/useShow';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useSearchParamContext } from '../../providers/SearchParamsProvider';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { usePlayableVideoStreamContext } from '../../../middleware/video/usePlayableVideoStreamContext';
import { useEndShowCTAButton } from '../videoStream/EndShowCTAButton';
import { useCarouselContext } from '../../mediaLibrary/carousel/useCarouselContext';

interface VideoStreamProps {
  readonly overlayUIStack: ReactNode;
  readonly muted: boolean;
  readonly showUpcomimgComponents: boolean;
  readonly videoElement: MutableRefObject<HTMLVideoElement | undefined>;
  readonly streamingState: PlayerState;
  readonly position: number;
  readonly handleMuteChange: (value: boolean) => void;
  readonly pause: () => void;
  readonly play: () => void;
  readonly seekTo: (position: number) => void;
  readonly onVideoRefInit: (videoElement?: HTMLVideoElement) => void;
}

export const ClassicPlayerVideoStream = (props: VideoStreamProps) => {
  const {
    overlayUIStack,
    videoElement,
    handleMuteChange,
    muted,
    pause,
    play,
    seekTo,
    streamingState,
    position,
    onVideoRefInit,
    showUpcomimgComponents,
  } = props;

  const theme = useTheme();
  const { isMinimized } = useEmbededViewControl();
  const { unmuteIconRef } = useCarouselContext();
  const { customVideoLabelTitle, customVideoLabelUrl } = useSearchParamContext();
  const { isMobileView } = useDeviceMetadata();
  const { isVod, status } = useShow();
  const { showStarted, showEnded, videoAspectRatio } = usePlayableVideoStreamContext();

  const [resumeControls, setResumeControls] = useState(true);
  const [controls, setControls] = useState(true);

  const isVodEnded = isVod && streamingState === PlayerState.ENDED;
  const isLiveShowEnded =
    showEnded && !isVod && (streamingState === PlayerState.ENDED || streamingState === PlayerState.IDLE);
  const noVideoResource = status === 'ready' || status === 'paused';
  const videoPlaying = streamingState === PlayerState.PLAYING;

  const displayUnmuteIcon = muted && unmuteIconRef.current && videoPlaying;

  const { ShowCTAButton } = useEndShowCTAButton({ isVodEnded, isLiveShowEnded });

  const onTimeChangeCommittedHandler = (time: number) => seekTo(time);
  const onPauseHandler = useCallback(() => pause(), [pause]);
  const onPlayHandler = useCallback(() => play(), [play]);

  const onClickUnmuteButton = (value: boolean) => {
    handleMuteChange(value);
    unmuteIconRef.current = false;
  };

  useEffect(() => {
    setResumeControls(videoElement.current !== null);
    if (videoElement?.current) setControls(false);
  }, [videoElement]);

  return (
    <Box height="100%" position="relative">
      <PlayableVideoStream
        controls={controls}
        muted={muted}
        pause={pause}
        play={play}
        seekTo={seekTo}
        streamingState={streamingState}
        position={position}
        onVideoRefInit={onVideoRefInit}
      />
      {resumeControls && (
        <VideoOverlayFrame sx={{ width: '100%' }}>
          {videoAspectRatio || noVideoResource ? (
            isMinimized ? (
              <VideoPanelMinimized
                streamingState={streamingState}
                pause={pause}
                play={play}
                muted={muted}
                handleMuteChange={handleMuteChange}
                showUpcomimgComponents={showUpcomimgComponents}
              />
            ) : (
              <Stack justifyContent="flex-end" height={1} position="relative">
                {!isMinimized && <VideoViewControlButton />}
                <CompanyLogo />
                <Stack
                  direction="column"
                  spacing={1}
                  alignItems="flex-start"
                  sx={{
                    position: 'absolute',
                    top: theme.components?.squareButton?.spacing,
                    left: '12px',
                    zIndex: 105,
                  }}
                >
                  {!isMinimized && <VideoLabel streamingState={streamingState} />}
                  {!isMinimized && !!customVideoLabelTitle && (
                    <CustomVideoLabel title={customVideoLabelTitle} url={customVideoLabelUrl} />
                  )}
                </Stack>
                <ShowCTAButton />
                {displayUnmuteIcon && <DefaultUnMuteVideoButton onClickUnmuteButton={onClickUnmuteButton} />}
                {overlayUIStack}
                {!isMobileView && <HighlightedProducts />}
                {(isVod || !isMobileView) && showStarted && (
                  // Where control panel is needed: VOD desktop, VOD mobile, LIVE desktop.
                  <VideoControlLayout
                    isVod={isVod}
                    videoElement={videoElement}
                    position={position}
                    onTimeChangeCommittedHandler={onTimeChangeCommittedHandler}
                    onPauseHandler={onPauseHandler}
                    onPlayHandler={onPlayHandler}
                  />
                )}
              </Stack>
            )
          ) : null}
        </VideoOverlayFrame>
      )}
    </Box>
  );
};
