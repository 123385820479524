import { Box, SxProps } from '@mui/material';
import { ReactNode } from 'react';

interface GradientChatLayoutProps {
  readonly children: ReactNode;
  readonly isOpen: boolean;
  readonly hasMessages: boolean;
  readonly chatboxHeight: number;
  readonly variant?: SxProps;
}

export const GradientChatLayout = (props: GradientChatLayoutProps) => {
  const { children, isOpen, hasMessages, chatboxHeight, variant } = props;
  const chatboxMaxHeight: number = Math.round(window.innerHeight * 0.35);

  return (
    <Box
      sx={{
        overflow: 'auto',
        WebkitMaskImage:
          isOpen && hasMessages && chatboxHeight >= chatboxMaxHeight
            ? 'linear-gradient(to top, transparent 0%, black 0%, black 85%, transparent 100%)'
            : 'unset',
        maskImage:
          isOpen && hasMessages && chatboxHeight >= chatboxMaxHeight
            ? 'linear-gradient(to top, transparent 0%, black 0%, black 85%, transparent 100%)'
            : 'unset',
        ...variant,
      }}
    >
      {children}
    </Box>
  );
};
