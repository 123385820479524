import { Stack, Typography, useTheme } from '@mui/material';
import { Playlist } from '../../graphqlClient/mediaLibraryRequest';
import { FilterTags } from '../../components/mediaLibrary/FilterTags';
import { FilterResultNotFoundComponent } from '../../components/mediaLibrary/FilterResultNotFoundComponent';
import { MediaLibraryLandingPagePlaylists } from '../../components/mediaLibrary/MediaLibraryLandingPagePlaylists';
import { MediaLibraryFilteredPlaylists } from '../../components/mediaLibrary/MediaLibraryFilteredPlaylists';
import { useDeviceMetadata } from '../../components/providers/DeviceMetadataProvider';
import { useMediaLibraryContext } from '../../components/mediaLibrary/MediaLibraryProvider';
import { useInitLandingPage } from '../useInitLandingPage';

export const MediaLibraryLandingPage = () => {
  const { isMobileView } = useDeviceMetadata();
  const { offsetRef } = useInitLandingPage();
  const { mediaLibrary, isSortable, isFiltering, isResultNotFound, playlists } = useMediaLibraryContext();
  const theme = useTheme();

  if (mediaLibrary) {
    return (
      <Stack height="100%" width="100%" bgcolor="#fff" overflow="hidden">
        <Stack
          ref={offsetRef}
          height="auto"
          direction="column"
          alignItems="center"
          justifyContent="center"
          ml={isMobileView ? 1.5 : 3.75}
          mr={isMobileView ? 0 : 3.75}
        >
          {!mediaLibrary.hideTitle && (
            <Typography
              mt={isMobileView ? 3 : 3.75}
              variant="h1"
              fontSize={theme.typography.mediaLibraryTitle?.fontSize}
              fontFamily={theme.typography.mediaLibraryTitle?.fontFamily}
              fontWeight={700}
              textAlign="center"
              color={theme.components?.mediaLibrary?.mediaLibraryTitleColor}
            >
              {mediaLibrary.title}
            </Typography>
          )}

          {isSortable && <FilterTags />}
          {playlists?.map((playlist: Playlist) => {
            const hasShowsOrClips = playlist.shows?.length > 0 || playlist.clips?.length > 0;
            const shouldShowFilteredPlaylist = isFiltering && hasShowsOrClips;
            const shouldShowLandingPagePlaylist = !isFiltering && hasShowsOrClips;
            return shouldShowFilteredPlaylist ? (
              <MediaLibraryFilteredPlaylists filteredData={playlist} key={playlist.id} />
            ) : shouldShowLandingPagePlaylist ? (
              <MediaLibraryLandingPagePlaylists defaultData={playlist} key={playlist.id} />
            ) : null;
          })}
          {isResultNotFound && <FilterResultNotFoundComponent />}
        </Stack>
      </Stack>
    );
  } else {
    return null;
  }
};
