import { MouseEvent, memo, useCallback, useEffect, useState } from 'react';
import { useUserIdContext } from '../../../../utils/userIdProvider';
import { useThrottle } from '../../../../utils/useDebounce';
import { HeartFloaterProps, HeartReactionSubscription, HeartType } from './HeartReaction.interface';
import { AnimatedHeart } from './HeartComponent';
import { Box, Icon, IconButton, useTheme } from '@mui/material';
import { useGraphQLClient } from '../../../../graphqlClient/GraphQLClient';
import { AnimationResult } from 'react-spring';
import { useHeartbeatContex } from '../../../../middleware/heartbeat/useHeartbeatContext';
import { useShow } from '../../../../middleware/video/useShow';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';
import { UserEventType } from '../../../../middleware/heartbeat/useHeartbeat';
import { PlayerType } from '../../../../models/VideoViewLayerModels';

const subscriptionSocketConfig = {
  url: `wss://${process.env.REACT_APP_APPSYNC_ID}.appsync-realtime-api.eu-central-1.amazonaws.com/graphql`,
  protocol: ['graphql-ws'],
  api_header: {
    host: `${process.env.REACT_APP_APPSYNC_ID}.appsync-api.eu-central-1.amazonaws.com`,
  },
};

const heartReactionConfig = {
  MAX_HEART_COUNT: 25,
  MAX_THROTTLE_RATE: 1000,
};

const getRandomNumber = (min: number, max: number) => {
  const randomNumber = Math.random() * (max - min) + min;
  const positiveOrNegative = Math.random() < 0.5 ? -1 : 1;
  return randomNumber * positiveOrNegative;
};

export const HeartFloater = memo((props: HeartFloaterProps) => {
  const { isMobile, isVod } = props;
  const { isClip } = useShow();
  const { userId } = useUserIdContext();
  const { apiKey } = useGraphQLClient();
  const { isMinimized } = useEmbededViewControl();
  const { addVodUserEvent, addLiveUserEvent } = useHeartbeatContex();
  const [hearts, setHearts] = useState<HeartType[]>([]);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const theme = useTheme();
  const playerType = theme.components?.playerDisplayStyle;

  const addHeart = useCallback((heartColor?: string) => {
    const colors = ['white'];
    const newHeart: HeartType = {
      id: +new Date(),
      left: getRandomNumber(0, 30),
      color: heartColor || colors[Math.floor(Math.random() * colors.length)],
    };
    setHearts((preHearts) =>
      preHearts.length >= heartReactionConfig.MAX_HEART_COUNT ? preHearts : [...preHearts, newHeart],
    );
  }, []);

  const sendHeart = useThrottle(() => {
    if (isVod) {
      addVodUserEvent(UserEventType.HeartReaction, '');
    } else {
      addLiveUserEvent(UserEventType.HeartReaction);
    }
  }, heartReactionConfig.MAX_THROTTLE_RATE);

  const addHeartClickEvent = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      addHeart();
      !isVod && !isClip && sendHeart();
    },
    [isVod, isClip, addHeart, sendHeart],
  );

  const cleanUpHeart = useCallback(
    (event: AnimationResult, id: number) => {
      if (event.finished) {
        if (hearts.length > 0) {
          const newHearts = hearts.filter((heart) => {
            return heart.id !== id;
          });
          setHearts([...newHearts]);
        }
      }
    },
    [hearts],
  );

  const connectWebSocket = useCallback(
    (apiKey: string) => {
      // payload should be an empty JSON object
      const payload = { id: '' };
      const base64_api_header = window.btoa(
        JSON.stringify({ ...subscriptionSocketConfig.api_header, 'x-api-key': apiKey }),
      );
      const base64_payload = window.btoa(JSON.stringify(payload));
      const appsync_url = subscriptionSocketConfig.url + '?header=' + base64_api_header + '&payload=' + base64_payload;
      const socket = new WebSocket(appsync_url, subscriptionSocketConfig.protocol);

      const subscriptionId = userId;
      const startSubscription = {
        id: subscriptionId,
        payload: {
          data: '{"query":"subscription MySubscription($type: String) {\\n onHeartReactionClicked(type: $type) {\\n id\\n userId\\n }\\n }","variables":{"type": "HeartReaction"}}',
          extensions: {
            /* The authorization needs to be wrapped here */
            authorization: {
              host: subscriptionSocketConfig.api_header.host,
              'x-api-key': apiKey,
            },
          },
        },
        type: HeartReactionSubscription.START,
      };

      socket.onopen = () => {
        console.log('WebSocket subscription connection established.');
        socket.send(JSON.stringify({ type: HeartReactionSubscription.ConnectionInit }));
      };

      socket.onerror = (error) => {
        console.log('WebSocket error: ', error);
        socket.close();
        setSocket(null);
      };

      socket.onmessage = (event) => {
        const message = event.data;
        const msgObject = JSON.parse(message);

        switch (msgObject.type) {
          case HeartReactionSubscription.ConnectionAck: //Connection Acknowledged
            socket.send(JSON.stringify(startSubscription));
            break;

          case HeartReactionSubscription.ConnectionError: //Connection Error
            setSocket(null);
            break;

          case HeartReactionSubscription.StartAck: //Subscription Acknowledged
            break;

          case HeartReactionSubscription.DATA: //Subscription Received
            msgObject.payload.data.onHeartReactionClicked.userId !== subscriptionId && addHeart();
            break;

          case HeartReactionSubscription.KA: //WS Alive
            break;

          case HeartReactionSubscription.ERROR: //Subscription Error
            socket.send(JSON.stringify({ type: HeartReactionSubscription.STOP, id: subscriptionId }));
            socket.send(JSON.stringify(startSubscription));
            break;

          default:
            console.log('Unknown Message Type', msgObject.type);
        }
      };

      socket.onclose = () => {
        console.log('WebSocket connection closed.');
        setSocket(null);
      };

      return socket;
    },
    [addHeart, userId],
  );

  useEffect(() => {
    if (!socket && !isVod && apiKey && !isMinimized) {
      const _socket = connectWebSocket(apiKey);
      setSocket(_socket);
    }
    return () => {
      if (isMinimized) socket?.close();
    };
  }, [connectWebSocket, socket, isVod, apiKey, isMinimized]);

  return (
    <Box>
      {hearts.map((heart: HeartType) => (
        <AnimatedHeart
          key={heart.id}
          onComplete={(event: AnimationResult) => cleanUpHeart(event, heart.id)}
          left={heart.left}
          color={heart.color}
          isMobile={isMobile}
        />
      ))}
      <IconButton size="small" onClick={addHeartClickEvent} id="heart-button" disabled={socket?.OPEN !== 1 && !isVod}>
        <Icon
          fontSize="small"
          className={playerType === PlayerType.MODERN ? 'ls-default ls-heart-solid' : 'ls-default ls-like'}
          sx={{
            px: 0.5,
            color: playerType === PlayerType.MODERN ? 'common.white' : theme.components?.defaultValues?.color?.text,
          }}
        />
      </IconButton>
    </Box>
  );
});

HeartFloater.displayName;
