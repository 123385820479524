import { Grid, Stack, SxProps } from '@mui/material';
import { PlayableVideoStream } from '../videoStream/PlayableVideoStream';
import { AspectRatio, PlayerProps } from '../../../models/VideoViewLayerModels';
import { BlurryVideoBackground } from './BlurryVideoBackground';
import { VideoGridOverlayLayout } from './VideoGridOverlayLayout';
import { LOADING_BACKGROUND_COLOR, MOBILE_LANDSCAPE_BACKGROUND_COLOR } from '../../../theming/theme';
import { useControlPanelWidgetContext } from '../videoStream/videoControlPanel/Widget/ControlPanelProvider';
import { usePlayableVideoStreamContext } from '../../../middleware/video/usePlayableVideoStreamContext';
import { useEffect, useRef, useState } from 'react';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { ImageOrientation, useCoverImageStatus } from '../../../utils/useCoverImageStatus';
import { useShow } from '../../../middleware/video/useShow';

const AUTO_CLOSE_TIMER_DELAY = 5000;

export const ModernPlayerVideoStream = (props: { playerProps: PlayerProps }) => {
  const [controls, setControls] = useState(true);
  const [resumeControls, setResumeControls] = useState(true);
  const { isMobileView } = useDeviceMetadata();
  const { handleMuteChange, handleOpenPlaybackSection } = useControlPanelWidgetContext();
  const { isVideoUpcoming, videoAspectRatio } = usePlayableVideoStreamContext();
  const { shouldShowCoverImage, coverImgOrientation } = useCoverImageStatus();
  const { status } = useShow();
  const {
    videoElement,
    muted,
    pause,
    play,
    seekTo,
    streamingState,
    onVideoRefInit,
    position,
    products,
    showUpcomimgComponents,
  } = props.playerProps;

  const getVideoWrapperStyle = (): SxProps => {
    if (videoAspectRatio === AspectRatio.LANDSCAPE) {
      if (isMobileView) {
        return {
          width: '100%',
          position: 'relative',
          bgcolor: MOBILE_LANDSCAPE_BACKGROUND_COLOR,
        };
      } else {
        return {
          aspectRatio: AspectRatio.LANDSCAPE,
          position: 'absolute',
          px: shouldShowCoverImage ? '0px' : '12px',
        };
      }
    } else {
      if (isMobileView) {
        return { width: '100%', position: 'relative' };
      } else {
        return {
          bgcolor:
            coverImgOrientation === ImageOrientation.PORTRAIT && !isVideoUpcoming ? LOADING_BACKGROUND_COLOR : '',
          aspectRatio: AspectRatio.PORTRAIT,
          position: 'absolute',
          left: '50%',
          top: 0,
          transform: 'translate(-50%, 0%)',
        };
      }
    }
  };

  const autoCloseTimerRef = useRef<NodeJS.Timeout | null>(null);
  const noVideoResource = status === 'ready' || status === 'paused';

  const handlePlaybackSectionAutoClose = () => {
    handleOpenPlaybackSection(true);
    if (autoCloseTimerRef.current) {
      clearTimeout(autoCloseTimerRef.current);
      autoCloseTimerRef.current = null;
    }
    autoCloseTimerRef.current = setTimeout(() => handleOpenPlaybackSection(false), AUTO_CLOSE_TIMER_DELAY);
  };

  useEffect(() => {
    setResumeControls(videoElement.current !== null);
    if (videoElement?.current) setControls(false);
  }, [videoElement]);

  return (
    <Stack height="100%" width="100%" onClick={handlePlaybackSectionAutoClose}>
      <Grid item id="video-grid" height="100%" width="100%" sx={{ ...getVideoWrapperStyle() }}>
        <BlurryVideoBackground videoElement={videoElement} />
        <PlayableVideoStream
          muted={muted}
          pause={pause}
          play={play}
          seekTo={seekTo}
          controls={controls}
          position={position}
          streamingState={streamingState}
          onVideoRefInit={onVideoRefInit}
        />
        {(videoAspectRatio || showUpcomimgComponents || noVideoResource) && (
          <VideoGridOverlayLayout
            muted={muted}
            handleMuteChange={handleMuteChange}
            pause={pause}
            play={play}
            seekTo={seekTo}
            position={position}
            resumeControls={resumeControls}
            videoElement={videoElement}
            streamingState={streamingState}
            onVideoRefInit={onVideoRefInit}
            products={products}
            showUpcomimgComponents={showUpcomimgComponents}
          />
        )}
      </Grid>
    </Stack>
  );
};
