import { useState } from 'react';
import { useAiSupport } from '../../../../../../middleware/aiAssistance/useAiSupport';
import { Stack } from '@mui/material';
import { HighlightedProducts } from '../../../../videoStream/HighlightedProducts';
import { AiResponseAlert } from '../../../../chat/aiSupportChatCommonComponents/AiResponseAlert';
import { MobileChatAiSupportTextField } from './MobileChatAiSupportTextField';
import { MobileAiSupportChatBox } from '../../../../chat/aiSupportChatCommonComponents/AiResponseChatBox';

export const MobileChatAiSupportWrapper = () => {
  const {
    textInput,
    isLoading,
    textFieldRef,
    aiSupportMessages,
    onChangeHandler,
    onKeyDownHandler,
    onSubmitHandler,
    onCompositionEndHandler,
    onCompositionStartHandler,
  } = useAiSupport();
  const [isAlertOpen, setAlertOpen] = useState(true);

  return (
    <Stack>
      <MobileAiSupportChatBox aiSupportMessages={aiSupportMessages} />
      <HighlightedProducts />
      <AiResponseAlert isAlertOpen={isAlertOpen} setAlertOpen={setAlertOpen} />
      <MobileChatAiSupportTextField
        textInput={textInput}
        isLoading={isLoading}
        textFieldRef={textFieldRef}
        onSubmitHandler={onSubmitHandler}
        onKeyDownHandler={onKeyDownHandler}
        onChangeHandler={onChangeHandler}
        onCompositionEndHandler={onCompositionEndHandler}
        onCompositionStartHandler={onCompositionStartHandler}
      />
    </Stack>
  );
};
