export enum Status {
  STREAMING = 'streaming',
  ENDED = 'ended',
  READY = 'ready',
  CANCEL = 'cancel',
  PAUSED = 'paused',
}

export enum DisplayStatus {
  UPCOMING = 'Upcoming',
  ENDED = 'Ended',
  LIVE = 'Live',
  CANCELLED = 'Cancelled',
}
