import { useSearchParamContext } from '../components/providers/SearchParamsProvider';
import { useShow } from '../middleware/video/useShow';
import { parseJSON } from '../utils/ParseJsonUtils';

interface Modules {
  readonly [moduleName: string]: ModuleConfig;
}

interface ModuleConfig {
  readonly enabled: boolean;
}

export const useModulePermissionCheck = () => {
  const { modules } = useSearchParamContext();
  const { isVod, isClip, channel } = useShow();
  const parsedModules = parseJSON<Modules>(modules, {} as Modules);

  const modulesConfig = {
    isAiAssistanceSupported: parsedModules?.isAiAssistanceSupported || false,
  };

  const isAiAssistanceSupported =
    isVod && !isClip && modulesConfig.isAiAssistanceSupported && channel.displayVodBotAssistant;

  return { isAiAssistanceSupported };
};
