import {
  ChangeEvent,
  CompositionEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useChatTranslation } from '../../i18n/i18nHooks';
import { useMutation } from 'react-query';
import { useShow } from '../video/useShow';
import { useHighlightedItemsContext } from '../highlightedItems/useHighlightedItems';
import { AiServerRequest, AiServerResponse, sendRequestToAiServer } from '../../graphqlClient/aiSupportRequest';
import { AxiosResponse } from 'axios';
import { AiSupportChatMessage, DEFAULT_WELCOME_MESSAGE } from './model';
import { ClientError } from 'graphql-request';

export const useAiSupport = () => {
  const { t } = useChatTranslation();
  const { uuid, products } = useShow();
  const { highlightedItems } = useHighlightedItemsContext();
  const [textInput, setTextInput] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [isComposing, setIsComposing] = useState(false);
  const [aiSupportMessages, setAiSupportMessages] = useState<AiSupportChatMessage[]>([
    { ...DEFAULT_WELCOME_MESSAGE, sender: t('aiSupport.sender.chatbot'), input: t('aiSupport.welcomeMessage') },
  ]);

  const textFieldRef = useRef<HTMLInputElement | null>(null);

  const { mutate: sendRequestToAiServe } = useMutation<AxiosResponse<AiServerResponse>, ClientError>({
    mutationKey: ['SendRequestToAiServer', textInput],
    mutationFn: () => {
      const requestBody: AiServerRequest = {
        highlightedProductUrls: highlightedItems.map((item) => item.url),
        allProductUrls: products.map((product) => product.url),
        showId: uuid,
        question: textInput,
      };

      return sendRequestToAiServer(requestBody);
    },
    onSuccess: (fetchedData) => {
      setAiSupportMessages((prevMessages: AiSupportChatMessage[]) =>
        prevMessages.map((message) => {
          if (message.isLoading) {
            return { ...message, input: fetchedData.data.response, isLoading: false };
          } else {
            return message;
          }
        }),
      );
      setLoading(false);
    },
    onError: () => {
      setAiSupportMessages((prevMessages: AiSupportChatMessage[]) =>
        prevMessages.map((message) => {
          if (message.isLoading) {
            return { ...message, input: t('aiSupport.errorMessage'), isLoading: false };
          } else {
            return message;
          }
        }),
      );
      setLoading(false);
    },
  });

  const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => setTextInput(e.target.value);

  const onKeyDownHandler: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (textInput !== '' && e.key === 'Enter' && !isComposing) {
      onSubmitHandler();
    }
  };

  const onCompositionStartHandler: CompositionEventHandler<HTMLInputElement> = () => setIsComposing(true);

  const onCompositionEndHandler: CompositionEventHandler<HTMLInputElement> = () => setIsComposing(false);

  const onSubmitHandler = useCallback(() => {
    if (textInput !== '') {
      setLoading(true);
      setTextInput('');
      setAiSupportMessages((prevMessages: AiSupportChatMessage[]) => [
        ...prevMessages,
        { sender: t('aiSupport.sender.you'), input: textInput, isLoading: false },
        { sender: t('aiSupport.sender.chatbot'), input: 'loading', isLoading: true },
      ]);
      sendRequestToAiServe();
    }
  }, [sendRequestToAiServe, textInput, t]);

  useEffect(() => {
    if (isLoading) return;
    textFieldRef.current?.focus();
  }, [isLoading]);

  return {
    textFieldRef,
    textInput,
    isLoading,
    aiSupportMessages,
    onSubmitHandler,
    onKeyDownHandler,
    onChangeHandler,
    onCompositionStartHandler,
    onCompositionEndHandler,
  };
};
