import { Chip, Icon, Stack, Typography, useTheme } from '@mui/material';
import { memo } from 'react';

export const CustomVideoLabel = memo((props: { title: string; url: string }) => {
  const theme = useTheme();
  const handleRedirection = () => window.open(props.url, '_blank', 'noopener, noreferrer');
  return (
    <Chip
      size="small"
      disabled={!props.url}
      onClick={handleRedirection}
      label={
        <Stack direction="row" alignItems="baseline" justifyContent="center" spacing={0.5}>
          <Typography fontSize={12} fontWeight={700} color="text.primary" lineHeight="100%">
            {props.title}
          </Typography>
          <Icon sx={{ fontSize: 11, overflow: 'visible' }} baseClassName="ls-default" className="ls-info" />
        </Stack>
      }
      sx={{
        px: 0,
        color: 'text.primary',
        fontWeight: 'bold',
        background: theme.palette.background.default,
        borderColor: 'none',
        borderRadius: 1,
        height: theme.components?.showStatus?.height,
        '.MuiChip-label': {
          zIndex: 1100,
          padding: theme.components?.showStatus?.padding,
          lineHeight: '125%',
        },
        '&.Mui-disabled': {
          opacity: 1,
        },
      }}
    />
  );
});

CustomVideoLabel.displayName = 'CustomVideoLabel';
