/* eslint-disable react/prop-types */
import { ReactNode, createContext, createRef, useCallback, useContext, useRef } from 'react';
import { VariableSizeList } from 'react-window';
import { ScrollChatContextData } from './model';

export const MAX_CHARACTER_LENGTH = 80;

const ScrollChatContext = createContext<ScrollChatContextData>({} as ScrollChatContextData);
export const useScrollChatContext = () => useContext(ScrollChatContext);

export const ScrollChatProvider = (props: { defaultProperty: number; children: ReactNode }) => {
  const { defaultProperty, children } = props;

  const listRef = createRef<VariableSizeList>();
  const sizeMap = useRef<{ [key: number]: number }>({});

  const setSize = useCallback(
    (index: number, size: number) => {
      sizeMap.current = { ...sizeMap.current, [index]: size + 7 };
      listRef.current?.resetAfterIndex(index);
    },
    [listRef, sizeMap],
  );

  const getSize = useCallback(
    (index: number) => {
      return sizeMap.current[index] ? sizeMap.current[index] : defaultProperty; //change defaultProperties when chat message height changes
    },
    [defaultProperty],
  );

  const getHeight = useCallback(
    (totalMessages: number) => {
      let result = 0;
      for (let i = 0; i < totalMessages; i++) {
        result = result + getSize(i);
      }
      return result;
    },
    [getSize],
  );

  return (
    <ScrollChatContext.Provider value={{ listRef, getHeight, setSize, getSize }}>{children}</ScrollChatContext.Provider>
  );
};
