import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { theme as defaultTheme } from './theme';
import { deepmerge } from '@mui/utils';
import { Theme } from '@mui/material';

export const useCustomTheme = () => {
  const [searchParams] = useSearchParams();
  const [customTheme, setCustomTheme] = useState(defaultTheme);

  const styleUrl = searchParams.get('styleUrl') ?? '';

  const dowloadStyle = useCallback(async () => {
    const response = await fetch(styleUrl);
    const json = await response.json();
    if (json) {
      const { theme: fetchedTheme, fontUrl, iconUrl } = json;
      if (fontUrl || iconUrl) {
        const link = document.createElement('link');
        const timeStamp = `?timer=${Date.now()}`;
        link.rel = 'stylesheet';
        link.href = 'data:text/css,';
        if (fontUrl) {
          link.href += ` @import url('${encodeURI(fontUrl + timeStamp)}');`;
        }
        if (iconUrl) {
          link.href += ` @import url('${encodeURI(iconUrl + timeStamp)}');`;
        }
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      if (fetchedTheme) {
        const mergedTheme: Theme = deepmerge(defaultTheme, fetchedTheme);

        setCustomTheme(mergedTheme);
      }
    }
  }, [styleUrl]);

  useEffect(() => {
    if (styleUrl && styleUrl !== 'undefined' && styleUrl !== 'null') {
      dowloadStyle();
    }
  }, [styleUrl, dowloadStyle]);
  return { customTheme };
};
