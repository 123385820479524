import { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { FromClientEventType, useClientEventListener } from '../../../../middleware/clientEvent/useClientEvent';
import { ListOnItemsRenderedProps, VariableSizeList } from 'react-window';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';

export const useAutoScrollChat = (totalMessages: number, listRef: RefObject<VariableSizeList>) => {
  const { isMinimized } = useEmbededViewControl();
  const [isAutoScrolling, setAutoScrolling] = useState(false);

  const isChatLogInitialized = useRef(false);
  const isScrolledToBottom = useRef(false);

  const onScrolling = useCallback(
    (props: ListOnItemsRenderedProps) => {
      const { visibleStopIndex } = props;
      if (visibleStopIndex + 1 === totalMessages) {
        setAutoScrolling(true);
      } else {
        setAutoScrolling(false);
        isScrolledToBottom.current = false;
      }
    },
    [totalMessages],
  );

  const scrollToBottom = useCallback(() => {
    if (!isScrolledToBottom.current) {
      listRef.current?.scrollToItem(totalMessages);
      isScrolledToBottom.current = true;
    }
  }, [listRef, totalMessages]);

  useClientEventListener(FromClientEventType.OnVisibilityChanged, (visibility) => {
    if (visibility === 'visible') scrollToBottom();
  });

  useEffect(() => {
    if (listRef.current?.props.itemCount && !isAutoScrolling && !isChatLogInitialized.current && !isMinimized) {
      scrollToBottom();
    } else if (isAutoScrolling) {
      isChatLogInitialized.current = true;
    }
  }, [isAutoScrolling, isMinimized, listRef, scrollToBottom]);

  useEffect(() => {
    if (isAutoScrolling) scrollToBottom();
  }, [isAutoScrolling, scrollToBottom]);
  return { onScrolling };
};
