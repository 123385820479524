import axios from 'axios';
import { useQuery } from 'react-query';
import { Channel, Clip } from '../graphqlClient/showRequest';
import { useGqlGetMediaLibraryByUuId } from '../graphqlClient/mediaLibraryRequest';
import { Status } from '../enums/show-status.enum';

export const fetchJson = async (url: string) =>
  await axios
    .get(url)
    .then((res) => res.data)
    .catch(() => []);

export interface StreamUrls {
  readonly videoStream: string;
}

export const useMediaLibraryDetails = (uuid: string) => {
  const gqlQuery = useGqlGetMediaLibraryByUuId(uuid);
  const { data } = useQuery({
    queryKey: ['mediaLibrary', uuid],
    queryFn: () =>
      gqlQuery()
        .then((data) => fetchJson(data.getPlaylistByMediaLibraryId.playlistUrl))
        .catch(() => []),
    cacheTime: 0,
    enabled: !!uuid,
  });

  return { data };
};

export const mapClipChannel = async (clip: Clip): Promise<Channel> => {
  return {
    id: 0,
    uuid: clip.id,
    channelArn: '',
    chatroomArn: '',
    companyId: clip.companyId,
    companyUuid: '',
    description: '',
    playbackUrl: '',
    status: Status.ENDED,
    title: clip.title,
    products: '',
    showtime: '',
    termsUrl: '',
    showPublishedItems: clip.publishedItems,
    highlightedItems: clip.publishedItems,
    vodUrls: '',
    enduserLocales: '',
    beforeShowImageUrl: clip.thumbnailUrl,
    pausedShowImageUrl: '',
    afterShowImageUrl: '',
    chatLogsUrl: '',
    vodManifest: await fetchJson(clip.vodManifestUrl).then((res) => res),
    vodManifestUrl: clip.vodManifestUrl,
    showEvents: '',
    endShowCtaUrl: '',
    endShowCtaText: '',
    displayEndShowCta: false,
    displayVodBotAssistant: false,
    displayAddToCalendar: false,
  };
};
