import { useChatTranslation } from '../../../../i18n/i18nHooks';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';
import { Alert, Collapse, Icon, IconButton, alpha, useTheme } from '@mui/material';
import { PlayerType } from '../../../../models/VideoViewLayerModels';

export const AiResponseAlert = (props: { isAlertOpen: boolean; setAlertOpen: (isAlertOpen: boolean) => void }) => {
  const { isAlertOpen, setAlertOpen } = props;
  const { t } = useChatTranslation();
  const { isMobileView } = useDeviceMetadata();
  const theme = useTheme();
  const playerType = theme.components?.playerDisplayStyle;
  const alerthToggleHandler = () => setAlertOpen(false);

  return (
    <Collapse in={isAlertOpen}>
      <Alert
        severity="info"
        variant="filled"
        icon={
          playerType === PlayerType.CLASSIC && <Icon baseClassName="ls-default" className="ls-info" fontSize="small" />
        }
        action={
          <IconButton aria-label="close" color="inherit" size="small" onClick={alerthToggleHandler}>
            <Icon baseClassName="ls-default" className="ls-close" sx={{ fontSize: '12px' }} />
          </IconButton>
        }
        sx={{
          backgroundColor: isMobileView ? alpha(theme.palette.grey[900], 0.7) : theme.palette.grey[900],
          borderRadius: 0,
        }}
      >
        {t('aiSupport.alert')}
      </Alert>
    </Collapse>
  );
};
