import { useTheme } from '@mui/material';
import { AiSupportChatMessage } from '../../../../middleware/aiAssistance/model';
import { DefaultProperties } from '../../../../properties/defaultProperties';
import { MobileAiSupportChatBox as ClassicPlayerMobileAiSupportChatBox } from '../../classicPlayer/chat/mobileChat/aiSupport/MobileChatAiSupportChatBox';
import { MobileAiSupportChatBox as ModernPlayerMobileAiSupportChatBox } from '../../modernPlayer/chat/mobileChat/aiSupport/MobileChatAiSupportChatBox';
import { DesktopAiSupportChatBox as ModernPlayerDesktopAiSupportChatBox } from '../../modernPlayer/chat/desktopChat/aiSupport/DesktopChatAiSupportChat';
import { DesktopAiSupportChatBox as ClassicPlayerDesktopAiSupportChatBox } from '../../classicPlayer/chat/desktopChat/aiSupport/DesktopChatAiSupportChat';
import { ScrollChatProvider } from '../chatAutoScrolling/useScrollChatContext';
import { PlayerType } from '../../../../models/VideoViewLayerModels';

export interface AiSupportProps {
  readonly aiSupportMessages: AiSupportChatMessage[];
  readonly isAiChatMessageToggled: boolean;
  readonly setAiChatMessageToggled: (toogled: boolean) => void;
}

export const DesktopAiSupportChatBox = (props: AiSupportProps) => {
  const { isAiChatMessageToggled, aiSupportMessages, setAiChatMessageToggled } = props;
  const theme = useTheme();

  return (
    <ScrollChatProvider defaultProperty={DefaultProperties.chatMessageHeightDesktop}>
      {theme.components?.playerDisplayStyle === PlayerType.CLASSIC ? (
        <ClassicPlayerDesktopAiSupportChatBox
          aiSupportMessages={aiSupportMessages}
          isAiChatMessageToggled={isAiChatMessageToggled}
          setAiChatMessageToggled={setAiChatMessageToggled}
        />
      ) : (
        <ModernPlayerDesktopAiSupportChatBox
          aiSupportMessages={aiSupportMessages}
          isAiChatMessageToggled={isAiChatMessageToggled}
          setAiChatMessageToggled={setAiChatMessageToggled}
        />
      )}
    </ScrollChatProvider>
  );
};

export const MobileAiSupportChatBox = (props: { aiSupportMessages: AiSupportChatMessage[] }) => {
  const { aiSupportMessages } = props;
  const theme = useTheme();

  return (
    <ScrollChatProvider defaultProperty={DefaultProperties.chatMessageHeightMobile}>
      {theme.components?.playerDisplayStyle === PlayerType.CLASSIC ? (
        <ClassicPlayerMobileAiSupportChatBox aiSupportMessages={aiSupportMessages} />
      ) : (
        <ModernPlayerMobileAiSupportChatBox aiSupportMessages={aiSupportMessages} />
      )}
    </ScrollChatProvider>
  );
};
