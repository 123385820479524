import { useEffect, useState } from 'react';
import { useAccessVideoCounterTranslation } from '../../../../i18n/i18nHooks';
import { useShow } from '../../../../middleware/video/useShow';
import { calculateTimeLeft, formatShowStartTime } from './utils';

interface VideoCountdown {
  readonly title: string;
  readonly timeLeft?: string;
}

const ONE_SECOND = 1000;
const ONE_DAY = 86400000;

export const useVideoCountDown = () => {
  const { t } = useAccessVideoCounterTranslation();
  const { showtime } = useShow();

  const originalDateShow = new Date(showtime);
  const dateShow = originalDateShow.setSeconds(0);
  const showStart = formatShowStartTime(originalDateShow);
  const today = new Date();
  const difference = dateShow - +today;

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(dateShow));

  const getCountdownTime = (): VideoCountdown => {
    const dateDifference =
      (new Date(originalDateShow).setHours(0, 0, 0, 0) - new Date().setHours(0, 0, 0, 0)) / ONE_DAY;
    const is24HourCountdown = difference / ONE_DAY <= 1;
    const isTomorrowCountdown = difference / ONE_DAY <= 2 && dateDifference < 2;
    const isCommingSoon = difference / ONE_DAY < 0;

    switch (true) {
      case isCommingSoon: {
        return {
          title: t('commingSoon'),
        };
      }

      case is24HourCountdown: {
        return {
          title: t('goingLiveIn'),
          timeLeft: `${timeLeft.hours}:${timeLeft.minutes}:${timeLeft.seconds}`,
        };
      }

      case isTomorrowCountdown: {
        return {
          title: t('goingLive'),
          timeLeft: `${t('tomorrow')} ${showStart.hours}:${showStart.minutes}`,
        };
      }
      default: {
        return {
          title: t('goingLive'),
          timeLeft: `${showStart.date}.${showStart.month}. ${showStart.hours}:${showStart.minutes}`,
        };
      }
    }
  };

  useEffect(() => {
    const timeout = setInterval(() => {
      setTimeLeft(calculateTimeLeft(dateShow));
    }, ONE_SECOND);

    return () => clearInterval(timeout);
  }, [dateShow]);

  return { getCountdownTime };
};
