import { useChatTranslation } from '../../../../../../i18n/i18nHooks';
import { Box, Icon, IconButton, InputAdornment, styled } from '@mui/material';
import { StyledOutlinedInput } from '../../../../../UI/StyledOutlinedInput';
import { ChangeEvent, CompositionEventHandler, KeyboardEventHandler, RefObject } from 'react';
import { CONTROL_PANEL_BG_COLOR } from '../../../../../../theming/theme';

const TextFieldFrame = styled(Box)(() => ({
  backgroundColor: CONTROL_PANEL_BG_COLOR,
  padding: '0.75em',
}));

interface MobileChatAiSupportTextFieldProps {
  readonly textInput: string;
  readonly isLoading: boolean;
  readonly textFieldRef: RefObject<HTMLInputElement | null>;
  readonly onSubmitHandler: () => void;
  readonly onKeyDownHandler: KeyboardEventHandler<HTMLInputElement>;
  readonly onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  readonly onCompositionEndHandler: CompositionEventHandler<HTMLInputElement>;
  readonly onCompositionStartHandler: CompositionEventHandler<HTMLInputElement>;
}

export const MobileChatAiSupportTextField = (props: MobileChatAiSupportTextFieldProps) => {
  const {
    textInput,
    isLoading,
    textFieldRef,
    onSubmitHandler,
    onKeyDownHandler,
    onChangeHandler,
    onCompositionEndHandler,
    onCompositionStartHandler,
  } = props;
  const { t } = useChatTranslation();

  return (
    <TextFieldFrame>
      <StyledOutlinedInput
        autoComplete="off"
        inputRef={textFieldRef}
        id="mobile-ai-support-chat"
        type="text"
        fullWidth={true}
        disabled={isLoading}
        value={textInput}
        onChange={onChangeHandler}
        onKeyDown={onKeyDownHandler}
        onCompositionStart={onCompositionStartHandler}
        onCompositionEnd={onCompositionEndHandler}
        sx={{ borderRadius: '8px' }}
        placeholder={t('aiSupport.placeholder')}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" onClick={onSubmitHandler} edge="end" size="small">
              <Icon sx={{ overflow: 'visible' }} baseClassName="ls-default" className="ls-send" fontSize="small" />
            </IconButton>
          </InputAdornment>
        }
      />
    </TextFieldFrame>
  );
};
