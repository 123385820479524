import { Icon, IconButton, Slide, Stack } from '@mui/material';
import { ModernPlayerTimeSlider } from '../videoStream/videoControlPanel/PlayerTimeSlider';
import { usePlayableVideoStreamContext } from '../../../middleware/video/usePlayableVideoStreamContext';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { useControlPanelWidgetContext } from '../videoStream/videoControlPanel/Widget/ControlPanelProvider';

interface PlayBackSectionProps {
  readonly paused?: boolean;
  readonly position: number;
  readonly duration: number;
  readonly isLiveShowEnded: boolean;
  readonly isVodEnded: boolean;
  readonly onTimeChangeCommittedHandler: (time: number) => void;
  readonly onTogglePlay: () => void;
}

export const PlayBackSection = (props: PlayBackSectionProps) => {
  const { position, paused, duration, onTimeChangeCommittedHandler, onTogglePlay } = props;
  const { isVideoSeeking } = usePlayableVideoStreamContext();
  const { isPlaybackSectionOpen } = useControlPanelWidgetContext();
  const { isMobileView } = useDeviceMetadata();

  const PlayBackComponent = (
    <Stack direction="row" justifyContent="center" alignItems="center" width="100%" spacing={2}>
      <IconButton size="small" onClick={onTogglePlay} disabled={isVideoSeeking}>
        <Icon
          fontSize="small"
          baseClassName="ls-default"
          className={paused ? 'ls-play-solid' : 'ls-stop'}
          sx={{ color: 'common.white' }}
        />
      </IconButton>
      <ModernPlayerTimeSlider
        position={position}
        duration={duration}
        onChangeCommitted={onTimeChangeCommittedHandler}
      />
    </Stack>
  );

  if (isMobileView) {
    return (
      <Slide direction="up" in={isPlaybackSectionOpen} mountOnEnter unmountOnExit>
        {PlayBackComponent}
      </Slide>
    );
  } else {
    return PlayBackComponent;
  }
};
