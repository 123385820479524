import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledSquareButton = styled(Button)(({ theme }) => ({
  minWidth: theme.components?.squareButton?.size,
  maxWidth: theme.components?.squareButton?.size,
  height: theme.components?.squareButton?.size,
  padding: '0px',
  borderRadius: theme.components?.defaultValues?.borderRadius,
  textTransform: 'none',
  boxShadow: 'none',
  position: 'absolute',
  zIndex: 105,
  color: theme.palette?.text.primary,
  backgroundColor: theme.components?.squareButton?.default,
  '&:hover': {
    backgroundColor: theme.components?.squareButton?.hover,
  },
}));
