import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Divider,
  Icon,
  Link,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { JumpToHighlightButton } from '../../productList/JumpToHighlightButton';
import { ProductApiResponse } from '../../../../models/show.model';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';
import { useState } from 'react';
import { useSearchParamContext } from '../../../providers/SearchParamsProvider';

interface ItemProps {
  item: ProductApiResponse;
  seekTo?: (time: number) => void;
  onProductListToggle?: (enable: boolean) => void;
}

export const ItemList = (props: ItemProps) => {
  const [isHover, setIsHover] = useState(false);
  const { item, seekTo, onProductListToggle } = props;
  const { isMobileView } = useDeviceMetadata();
  const { onClickProduct } = useEmbededViewControl();
  const { useNativePipPlayer } = useSearchParamContext();

  const mobile = useMediaQuery('(max-width:799px)');
  const desktop = useMediaQuery('(min-width:930px)');
  const theme = useTheme();

  const formatTime = (seconds: number) => {
    const hours = String(Math.floor(seconds / 3600));
    const minutes = String(Math.floor((seconds % 3600) / 60));
    const remainingSeconds = String(seconds % 60);
    if (parseInt(hours) > 0) {
      return `${hours}:${minutes.padStart(2, '0')}:${remainingSeconds.padStart(2, '0')}`;
    } else {
      return `${minutes.padStart(2, '0')}:${remainingSeconds.padStart(2, '0')}`;
    }
  };

  const handleProductClick = () => onClickProduct(props.item);

  return (
    <Box sx={{ mx: isMobileView ? '12px' : '16px' }}>
      <Link
        onClick={handleProductClick}
        href={useNativePipPlayer ? item.url : undefined}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <Card
          key={item.id}
          elevation={0}
          sx={{
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CardActionArea
            sx={{
              px: isMobileView ? undefined : '16px',
              display: 'flex',
              borderRadius: 2,
            }}
          >
            <Stack
              sx={{
                width: '100%',
                my: isMobileView ? '20px' : '26px',
              }}
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              spacing="16px"
            >
              <Box>
                {item.image ? (
                  <CardMedia
                    sx={{
                      width: isMobileView ? '60px' : '68px',
                      height: isMobileView ? '60px' : '68px',
                      objectFit: 'contain',
                    }}
                    component="img"
                    alt={item.description ?? ''}
                    src={item.image}
                    decoding="async"
                  />
                ) : (
                  <Icon baseClassName="ls-default ls-image" sx={{ fontSize: '60px' }} />
                )}
              </Box>

              <Stack
                width={0}
                sx={{
                  flexGrow: 1,
                  visibility: !mobile && !desktop ? 'hidden' : 'visible',
                }}
              >
                <Typography
                  noWrap={false}
                  variant="body1"
                  fontWeight={700}
                  fontSize={theme.typography.productTitle?.fontSize}
                  fontFamily={theme.typography.productTitle?.fontFamily}
                  mb="8px"
                  sx={{
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '3',
                    WebkitBoxOrient: 'vertical',
                    maxHeight: isMobileView ? '3.5rem' : '3.75rem',
                    overflow: 'hidden',
                    color: theme.components?.productItems?.name,
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  noWrap={false}
                  variant="body1"
                  sx={{ color: theme.components?.productItems?.brand }}
                  fontWeight={400}
                  fontSize={isMobileView ? '0.85rem' : '0.9rem'}
                >
                  {item.type === 'product' && item.brand}
                </Typography>
              </Stack>
              <JumpToHighlightButton
                item={item}
                seekTo={seekTo}
                isHover={isHover}
                setIsHover={setIsHover}
                formatTime={formatTime}
                onProductListToggle={onProductListToggle}
              />
            </Stack>
          </CardActionArea>
        </Card>
      </Link>
      <Divider sx={{ border: `1px solid ${theme.palette.grey['300']}`, opacity: 0.5 }} flexItem />
    </Box>
  );
};
