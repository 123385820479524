import { Stack } from '@mui/material';
import { GradientChatLayout } from '../../../chat/GradientChatLayout';
import { MobileChatRow } from '../../../chat/ChatRows';
import { ChatBox } from '../../../../UI/StyledMobileChat';
import { DefaultProperties } from '../../../../../properties/defaultProperties';
import { VariableSizeList as ChatList } from 'react-window';
import { ScrollChatProvider, useScrollChatContext } from '../../../chat/chatAutoScrolling/useScrollChatContext';
import { useIVSChatContext } from '../../../../../middleware/chat/useIVSChat';
import { useControlPanelWidgetContext } from '../../../videoStream/videoControlPanel/Widget/ControlPanelProvider';
import { useMemorizedChatData } from '../../../chat/chatAutoScrolling/useMemorizedChatData';
import { useAutoScrollChat } from '../../../chat/chatAutoScrolling/useAutoScrollChat';
import { useDeviceMetadata } from '../../../../providers/DeviceMetadataProvider';
import { DeviceOrientation } from '../../../../../utils/useDeviceOrientationChange';
import AutoSizer from 'react-virtualized-auto-sizer';

export const MobileChatWrapper = () => {
  const { messages } = useIVSChatContext();

  if (messages?.length !== 0) {
    return (
      <ScrollChatProvider defaultProperty={DefaultProperties.chatMessageHeightMobile}>
        <MobileChat />
      </ScrollChatProvider>
    );
  } else {
    return null;
  }
};

const MobileChat = () => {
  const { isChatCardOpen } = useControlPanelWidgetContext();
  const { listRef, getSize, getHeight } = useScrollChatContext();
  const { itemData } = useMemorizedChatData();
  const { onScrolling } = useAutoScrollChat(itemData.items.length, listRef);
  const totalMessages = itemData.items.length;
  const { deviceOrientation } = useDeviceMetadata();
  const hasMessages = totalMessages > 0;

  return (
    <GradientChatLayout
      isOpen={true}
      hasMessages={hasMessages}
      chatboxHeight={getHeight(totalMessages)}
      variant={{ width: '100%', mr: '12px' }}
    >
      <Stack id="modern-player-mobile-chat">
        {isChatCardOpen && (
          <ChatBox
            height={getHeight(totalMessages)}
            sx={{
              maxHeight: deviceOrientation === DeviceOrientation.LANDSCAPE ? '40vh' : '25vh',
              maxWidth: deviceOrientation === DeviceOrientation.LANDSCAPE ? '50vw' : '100%',
            }}
          >
            <AutoSizer>
              {({ height, width }) => (
                <ChatList
                  ref={listRef}
                  className="mobileChatScrollbar"
                  onItemsRendered={onScrolling}
                  innerElementType="div"
                  itemData={itemData}
                  itemCount={totalMessages}
                  itemSize={getSize}
                  height={getHeight(totalMessages) < 50 ? getHeight(totalMessages) : (height as number)}
                  width={width as number}
                >
                  {MobileChatRow}
                </ChatList>
              )}
            </AutoSizer>
          </ChatBox>
        )}
      </Stack>
    </GradientChatLayout>
  );
};
