import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Channel } from '../../graphqlClient/showRequest';
import { HighlightedItem, ProductApiResponse, ShowEvent, ShowEventId } from '../../models/show.model';

interface HighlightedItemsContextContent {
  readonly highlightedItems: ProductApiResponse[];
  readonly updateHighlightedItems: (highlightedItems: HighlightedItem[]) => void;
  readonly updateLiveHighlightedItems: (highlightedItemsString: string) => void;
  readonly updateVodHighlightedItems: (pdt: Date) => void;
}

const HighlightedItemsContext = createContext({} as HighlightedItemsContextContent);

export const useHighlightedItemsContext = (): HighlightedItemsContextContent => useContext(HighlightedItemsContext);

interface HighlightedItemsProviderProps {
  readonly channel: Channel;
  readonly products: ProductApiResponse[];
  readonly showEvents: ShowEvent[] | undefined;
  readonly children: ReactNode;
}

export const HighlightedItemsProvider = (props: HighlightedItemsProviderProps) => {
  const { channel, products, showEvents, children } = props;
  const [highlightedItems, setHighlightedItems] = useState<ProductApiResponse[]>([]);

  const updateHighlightedItems = useCallback(
    (highlightedItems: HighlightedItem[]) => {
      setHighlightedItems(
        highlightedItems.map(
          (item) =>
            products.find((product) => product.id === item.id && product.type === item.type) as ProductApiResponse,
        ),
      );
    },
    [products, setHighlightedItems],
  );

  const updateLiveHighlightedItems = useCallback(
    (highlightedItemsString: string) => {
      if (!highlightedItemsString) {
        return;
      }
      updateHighlightedItems(JSON.parse(highlightedItemsString));
    },
    [updateHighlightedItems],
  );

  const updateVodHighlightedItems = (pdt: Date) => {
    if (!showEvents) {
      return;
    }
    let highlightedItems: HighlightedItem[] = [];
    for (let index = 0; index < showEvents.length; index++) {
      if (showEvents[index].eventId !== ShowEventId.HighlightedProductsUpdate) {
        continue;
      }
      const current = new Date(showEvents[index].time);
      const next = showEvents[index + 1] ? new Date(showEvents[index + 1].time) : undefined;
      if (current.getTime() <= pdt.getTime() && (!next || pdt.getTime() < next.getTime())) {
        const { data } = showEvents[index];
        highlightedItems = data.map(({ id, type }) => ({ id, type }));
        break;
      }
    }
    updateHighlightedItems(highlightedItems);
  };

  useEffect(() => {
    const { highlightedItems } = channel;
    updateLiveHighlightedItems(highlightedItems);
  }, [channel, updateLiveHighlightedItems]);

  const value: HighlightedItemsContextContent = {
    highlightedItems,
    updateHighlightedItems,
    updateLiveHighlightedItems,
    updateVodHighlightedItems,
  };

  return <HighlightedItemsContext.Provider value={value}>{children}</HighlightedItemsContext.Provider>;
};
