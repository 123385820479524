import { OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const StyledOutlinedInput = styled(OutlinedInput)<{ component?: React.ElementType }>(({ theme }) => ({
  backgroundColor: theme.palette?.common?.white,
  borderRadius: theme.components?.chatBox?.mobile?.input?.borderRadius,

  '.MuiOutlinedInput-input': {
    padding: theme.components?.chatBox?.mobile?.input?.padding,

    '&::placeholder': {
      color: `${theme.components?.chatBox?.mobile?.input?.placeholderColor} !important`,
      opacity: 1,
    },
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.components?.chatBox?.mobile?.input?.borderColorActive,
    borderWidth: '1px !important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${theme.palette?.common?.white}`,
    borderRadius: theme.components?.chatBox?.mobile?.input?.borderRadius,
  },
}));
