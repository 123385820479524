import { PlayerType } from '../../../../../models/VideoViewLayerModels';
import { Icon, IconButton, useTheme } from '@mui/material';
import { useControlPanelWidgetContext } from './ControlPanelProvider';
import { useCarouselContext } from '../../../../mediaLibrary/carousel/useCarouselContext';

interface MuteWidgetProps {
  readonly disableClick?: boolean;
}

export const MuteWidget = ({ disableClick }: MuteWidgetProps) => {
  const { muted, handleMuteChange } = useControlPanelWidgetContext();
  const { isCarouselPlayerMuted } = useCarouselContext();
  const theme = useTheme();
  const playerType = theme.components?.playerDisplayStyle;
  const isMuted =
    isCarouselPlayerMuted?.current !== null && isCarouselPlayerMuted ? isCarouselPlayerMuted?.current : muted;

  return (
    <IconButton onClick={disableClick ? undefined : () => handleMuteChange(!isMuted)}>
      {isMuted ? (
        <Icon
          fontSize="small"
          className={playerType === PlayerType.MODERN ? 'ls-default ls-volume-off-solid' : 'ls-default ls-mute'}
          sx={{
            px: 0.5,
            color: playerType === PlayerType.MODERN ? theme.palette.common.white : theme.palette?.text?.primary,
          }}
        />
      ) : (
        <Icon
          fontSize="small"
          className={playerType === PlayerType.MODERN ? 'ls-default ls-volume-on-solid' : 'ls-default ls-volume'}
          sx={{
            px: 0.5,
            color: playerType === PlayerType.MODERN ? theme.palette.common.white : theme.palette?.text?.primary,
          }}
        />
      )}
    </IconButton>
  );
};
