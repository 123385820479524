import { useLayoutEffect, useRef } from 'react';
import { useClientEventFunction } from '../middleware/clientEvent/useClientEventFunctions';

export const useInitLandingPage = () => {
  const { initLandingPage } = useClientEventFunction();
  const offsetRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    if (offsetRef.current) {
      initLandingPage(offsetRef.current.scrollHeight);
    }
  }, [initLandingPage]);
  return { offsetRef };
};
