import { Box, Typography } from '@mui/material';
import { useVideoErrorsDialogTranslation } from '../../i18n/i18nHooks';
import { Ref, forwardRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function PowerOnDialog(props: any, ref: Ref<unknown>) {
  const { t } = useVideoErrorsDialogTranslation();
  return (
    <Box
      {...props}
      ref={ref}
      sx={{
        id: 'loadingScreenText',
        width: '100%',
        textAlign: 'center',
        marginTop: '10% !important',
      }}
    >
      <Typography
        id="loadingScreenTypo"
        variant="caption"
        component={'span'}
        fontWeight={700}
        color="white"
        fontSize={'0.9rem'}
      >
        {t('powerOn')}
      </Typography>
    </Box>
  );
}

export default forwardRef(PowerOnDialog);
