export enum ProductType {
  Product = 'product',
  NonProduct = 'nonProduct',
}

export interface HighlightedItem {
  readonly type: string | ProductType;
  readonly id: number;
}

export interface ProductApiResponse {
  readonly brand?: string | null;
  readonly description?: string | null;
  readonly id: number;
  readonly image: string | null;
  readonly price?: number;
  readonly priceCurrency?: string;
  readonly title: string;
  readonly url: string;
  readonly sku?: string;
  readonly gtin?: string;
  readonly highlightTime?: number;
  readonly type?: ProductType;
}

export interface ShowPermissions {
  readonly deleteable: boolean;
  readonly editable: boolean;
  readonly manageable: boolean;
  readonly viewable: boolean;
}

export interface ShowModel {
  readonly id: number | null;
  readonly companyId: number | null;
  readonly status: string;
  readonly showtime: Date | null;
  readonly title: string;
  readonly chatroomId: string;
  readonly permissions: ShowPermissions | null;
  readonly products: ProductApiResponse[] | null;
  readonly streamingKey: string;
}

export enum ShowEventId {
  HighlightedProductsUpdate = 'HIGHLIGHTED_ITEMS_UPDATE',
}

export enum ShowEventDataType {
  Product = 'product',
  NoneProduct = 'nonProduct',
}

export interface ShowEventData {
  type: ShowEventDataType;
  id: number;
  order: number;
}

export interface ShowEvent {
  eventId: ShowEventId;
  time: string;
  data: ShowEventData[];
}

export interface ShowEventRaw {
  eventId: ShowEventId;
  time: string;
  data: string;
}
