import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { ChatBubble } from '../../../../UI/StyledMobileChat';
import { ChatAvatar } from '../../../../UI/ChatAvatar';
import { ChatMessage } from '../../../../../models/chat.model';
import { MessageConverted } from '../../../chat/MessageConverted';
import { MAX_CHARACTER_LENGTH } from '../../../chat/chatAutoScrolling/useScrollChatContext';
import { useScrollChatSize } from '../../../chat/chatAutoScrolling/useScrollChatSize';

interface MobileChatMessageProps {
  readonly data: ChatMessage;
  readonly index: number;
  readonly setSize: (index: number, size: number) => void;
  readonly toggleLongText: (index: number) => void;
}

export const MobileChatDefaultMessage = (props: MobileChatMessageProps) => {
  const { data, setSize, index, toggleLongText } = props;
  const { rowRef } = useScrollChatSize(index, setSize, data?.isLongTextToggled);
  const message = data.message;
  const isModeratorMessage = data.customAttributes?.isModerator === 'true';
  const theme = useTheme();

  const handleToggleLongText = () => toggleLongText(index);

  const sxChatBubble: SxProps = {
    maxWidth: 'fit-content',
    background: isModeratorMessage
      ? theme.components?.chatMessage?.mobile?.moderator?.background
      : theme.components?.chatMessage?.mobile?.user?.background,
  };

  const sxChatBubbleName: SxProps = {
    display: 'inline',
    mr: '8px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: '130%',
    color: isModeratorMessage
      ? theme.components?.chatMessage?.mobile?.moderator?.nameColor
      : theme.components?.chatMessage?.mobile?.user?.nameColor,
  };

  const sxProductLinkMessage: SxProps = {
    marginRight: '4px',
    textDecoration: isModeratorMessage ? 'hover' : 'none',
    pointerEvents: isModeratorMessage ? 'auto' : 'none',
    fontSize: '0.75rem',
    lineHeight: '130%',
    color: isModeratorMessage
      ? theme.components?.chatMessage?.mobile?.moderator?.linkColor
      : theme.components?.chatMessage?.mobile?.user?.linkColor,
  };

  const sxTextMessage: SxProps = {
    textTransform: 'none',
    marginRight: '4px',
    wordBreak: 'break-word',
    fontSize: '0.75rem',
    lineHeight: '130%',
    display: 'inline',
    color: isModeratorMessage
      ? theme.components?.chatMessage?.mobile?.moderator?.textColor
      : theme.components?.chatMessage?.mobile?.user?.textColor,
  };

  return (
    <Box
      ref={rowRef}
      sx={{ cursor: message.length > MAX_CHARACTER_LENGTH ? 'pointer' : 'auto' }}
      onClick={message.length > MAX_CHARACTER_LENGTH ? handleToggleLongText : undefined}
    >
      {data.customAttributes?.avatarUrl && (
        <ChatAvatar isMobile={true} backgroundURL={data.customAttributes?.avatarUrl} />
      )}
      <ChatBubble sx={sxChatBubble}>
        <Typography component={'span'} sx={sxChatBubbleName}>
          {data.username}
        </Typography>
        <MessageConverted
          message={message}
          isLongTextToggled={data.isLongTextToggled}
          isModerator={isModeratorMessage}
          isProductLinkMessageClass={true}
          sxProductLinkMessage={sxProductLinkMessage}
          sxTextMessage={sxTextMessage}
          rel="noopener noreferrer"
        />
      </ChatBubble>
    </Box>
  );
};
