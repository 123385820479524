import { ModernPlayerLayout } from './ModernPlayerLayout';
import { ModernPlayerVideoStream } from './ModernPlayerVideoStream';
import { Grid, SxProps, Theme } from '@mui/material';
import { PlayerProps } from '../../../models/VideoViewLayerModels';
import { CAROUSEL_SLIDES_POSITION } from '../../mediaLibrary/carousel/Carousel';
import { useSearchParamContext } from '../../providers/SearchParamsProvider';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { usePlayableVideoStreamContext } from '../../../middleware/video/usePlayableVideoStreamContext';
import { useLiveChatLog } from '../../../middleware/chat/useLiveChatLog';
import { useShow } from '../../../middleware/video/useShow';
import { ImageOrientation, useCoverImageStatus } from '../../../utils/useCoverImageStatus';
import { LOADING_BACKGROUND_COLOR } from '../../../theming/theme';

export const ModernPlayer = (props: { playerProps: PlayerProps }) => {
  const { playlist } = useSearchParamContext();
  const { coverImgOrientation } = useCoverImageStatus();
  const { isMobileView } = useDeviceMetadata();
  const { isMinimized } = useEmbededViewControl();
  const { isLiveShowStreaming } = usePlayableVideoStreamContext();
  const { isVod } = useShow();
  const isCarousel = playlist.length > 1;

  const getGridStyle = (): SxProps<Theme> => {
    if (isMinimized || isMobileView) {
      return {
        display: 'contents',
      };
    } else {
      return {
        aspectRatio: '3 / 2',
        flexWrap: 'nowrap',
        maxHeight: '92%',
        maxWidth: '90%',
        position: 'relative',
        overflow: 'hidden',
        bgcolor:
          isLiveShowStreaming || isVod || coverImgOrientation === ImageOrientation.LANDSCAPE
            ? LOADING_BACKGROUND_COLOR
            : '',
      };
    }
  };

  useLiveChatLog();

  return (
    <ModernPlayerLayout>
      <Grid
        container
        onClick={(event) => event.stopPropagation()}
        sx={{ ...getGridStyle(), mx: isCarousel ? `${CAROUSEL_SLIDES_POSITION}px` : 0 }}
      >
        <ModernPlayerVideoStream playerProps={props.playerProps} />
      </Grid>
    </ModernPlayerLayout>
  );
};
