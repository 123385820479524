import { Typography } from '@mui/material';
import { GraphQLClient } from 'graphql-request';
import { PatchedRequestInit } from 'graphql-request/dist/types';
import { ReactNode, createContext, useContext, useEffect } from 'react';
import { useSearchParamContext } from '../components/providers/SearchParamsProvider';

class GraphQLClientContextData extends GraphQLClient {
  public apiKey: string;
  constructor(url: string, options?: PatchedRequestInit, publicApiKey?: string) {
    super(url, options);
    this.apiKey = publicApiKey ?? '';
  }
}

const GraphQLClientContext = createContext<GraphQLClientContextData>({} as GraphQLClientContextData);
export const useGraphQLClient = () => useContext(GraphQLClientContext);

export interface GraphQLClientContextProviderProps {
  readonly children: ReactNode;
}

export const GraphQLClientContextProvider = (props: GraphQLClientContextProviderProps) => {
  const { publicApiKey: publicApiKeyParam } = useSearchParamContext();

  const publicApiKey = window.sessionStorage.getItem('publicApiKey') || publicApiKeyParam;

  const streamGraphQLClient = new GraphQLClientContextData(
    '/graphql',
    {
      headers: {
        'x-api-key': publicApiKey || '',
      },
    },
    publicApiKey as string,
  );

  useEffect(() => {
    if (publicApiKeyParam) {
      window.sessionStorage.setItem('publicApiKey', publicApiKeyParam);
    }
  });

  if (!publicApiKey) {
    return <Typography variant="h3">You have no public Api Key for authentication</Typography>;
  } else {
    return <GraphQLClientContext.Provider value={streamGraphQLClient}>{props.children}</GraphQLClientContext.Provider>;
  }
};
