import { Grid, Stack, SxProps } from '@mui/material';
import { MobileChatWrapper } from './chat/mobileChat';
import { MobileChatTextField } from './chat/mobileChat/MobileChatTextField';
import { HighlightedProducts } from '../videoStream/HighlightedProducts';
import { ClassicPlayerVideoStream } from './ClassicPlayerVideoStream';
import { PlayerProps } from '../../../models/VideoViewLayerModels';
import { FullScreenCountdown } from '../videoStream/VideoCountdown';
import { AddToCalendarButton } from '../videoStream/AddtoCalendar/AddToCalendarButton';
import { useModulePermissionCheck } from '../../../utils/useModulePermissionCheck';
import { usePlayableVideoStreamContext } from '../../../middleware/video/usePlayableVideoStreamContext';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useShow } from '../../../middleware/video/useShow';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { useControlPanelWidgetContext } from '../videoStream/videoControlPanel/Widget/ControlPanelProvider';

export const VideoGrid = (props: PlayerProps) => {
  const { isMinimized } = useEmbededViewControl();
  const { isMobileView } = useDeviceMetadata();
  const { isClip, isVod, displayAddToCalendar } = useShow();
  const { isAiAssistanceSupported } = useModulePermissionCheck();
  const { handleMuteChange, isFeatureEnabled } = useControlPanelWidgetContext();
  const { showStarted } = usePlayableVideoStreamContext();
  const { videoElement, muted, pause, play, seekTo, streamingState, showUpcomimgComponents, onVideoRefInit, position } =
    props;

  const getGridStyle = (): SxProps => {
    if (isMobileView || isMinimized) {
      return {
        display: 'contents',
        width: '100%',
      };
    } else {
      return {
        aspectRatio: '9 / 16',
      };
    }
  };

  let overlayUIStack = null;

  const AiSupportedOverlay = !isClip && <MobileChatWrapper />;

  const DefaultOverlay = (
    <>
      {!isClip && <MobileChatWrapper />}
      <HighlightedProducts />
      {!isClip && !isVod && <MobileChatTextField />}
    </>
  );

  if (isMobileView) {
    overlayUIStack = (
      <Stack
        sx={{
          justifyContent: 'flex-end',
          height: 1,
          position: 'relative',
        }}
      >
        {isAiAssistanceSupported && !isClip && isVod && isFeatureEnabled
          ? AiSupportedOverlay
          : showStarted && DefaultOverlay}
      </Stack>
    );
  }

  if (isMinimized) {
    overlayUIStack = (
      <Stack
        sx={{
          justifyContent: 'flex-end',
          height: 1,
          position: 'relative',
          cursor: 'pointer',
        }}
      />
    );
  }

  return (
    <Grid item onClick={(event) => event.stopPropagation()} px={0} sx={getGridStyle()} height="100%">
      {showUpcomimgComponents && <FullScreenCountdown />}
      {!isMinimized && showUpcomimgComponents && displayAddToCalendar && <AddToCalendarButton />}
      <ClassicPlayerVideoStream
        videoElement={videoElement}
        handleMuteChange={handleMuteChange}
        muted={muted}
        pause={pause}
        play={play}
        seekTo={seekTo}
        overlayUIStack={overlayUIStack}
        streamingState={streamingState}
        position={position}
        onVideoRefInit={onVideoRefInit}
        showUpcomimgComponents={showUpcomimgComponents}
      />
    </Grid>
  );
};
