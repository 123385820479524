import { Box, Icon, SxProps, Typography, useTheme } from '@mui/material';
import { AiSupportChatMessage, AiSupportSender } from '../../../../../../middleware/aiAssistance/model';
import { AiResponseLoader } from '../../../../chat/aiSupportChatCommonComponents/AiResponseLoader';
import { MessageConverted } from '../../../../chat/MessageConverted';
import { useScrollChatSize } from '../../../../chat/chatAutoScrolling/useScrollChatSize';

const sxAiSupportMessageWrapper: SxProps = {
  mx: '12px',
  mb: '8px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
};

const sxAiSupportMessageInnerWrapper: SxProps = {
  mx: '12px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
};

const sxAiSupportMessageBox: SxProps = {
  px: '6px',
  py: '8px',
  borderRadius: '4px',
};

const sxAiSupportSender: SxProps = {
  variant: 'overline',
  fontWeight: 700,
  fontSize: '0.9rem',
  pr: '8px',
  textTransform: 'none',
};

const sxAiSupportText: SxProps = {
  display: 'inline',
  textTransform: 'none',
  fontSize: '0.9rem',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
};

const sxProductLinkMessage: SxProps = {
  marginRight: '4px',
  lineBreak: 'anywhere',
  wordBreak: 'break-word',
};

export const DesktopChatAiSupportMessage = (props: {
  message: AiSupportChatMessage;
  setSize: (index: number, size: number) => void;
  index: number;
}) => {
  const { message, setSize, index } = props;
  const { rowRef } = useScrollChatSize(index, setSize);
  const theme = useTheme();
  const isChatbot = message.sender === AiSupportSender.CHATBOT;

  return (
    <Box ref={rowRef} sx={isChatbot ? sxAiSupportMessageWrapper : {}}>
      {isChatbot && (
        <Icon
          baseClassName="ls-default"
          className="ls-chatbot"
          sx={{
            color: theme.palette.primary.main,
            overflow: 'visible',
          }}
        />
      )}
      <Box sx={sxAiSupportMessageInnerWrapper}>
        <Box
          sx={{
            ...sxAiSupportMessageBox,
            backgroundColor: isChatbot
              ? theme.palette.primary.main
              : theme.components?.chatMessage?.desktop?.user?.background,
          }}
        >
          <Typography
            sx={{
              ...sxAiSupportSender,
              color: isChatbot ? 'common.white' : theme.palette.primary.main,
            }}
            component="span"
          >
            {message.sender}
          </Typography>
          {message.isLoading ? (
            <AiResponseLoader />
          ) : (
            <MessageConverted
              rel="noopener noreferrer"
              message={message.input}
              isAiSupportMessage={true}
              isModerator={isChatbot}
              isProductLinkMessageClass={true}
              sxProductLinkMessage={{
                ...sxProductLinkMessage,
                textDecoration: isChatbot ? 'hover' : 'hover',
                pointerEvents: isChatbot ? 'auto' : 'auto',
                color: isChatbot
                  ? theme.components?.chatMessage?.desktop?.moderator?.linkColor
                  : theme.components?.chatMessage?.desktop?.user?.linkColor,
              }}
              sxTextMessage={{
                ...sxAiSupportText,
                color: isChatbot ? 'common.white' : theme.components?.chatMessage?.desktop?.user?.textColor,
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
