import {
  Dispatch,
  ReactNode,
  RefObject,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { MediaLibrary, Playlist, Tag } from '../../graphqlClient/mediaLibraryRequest';
import { useMediaLibraryDetails } from '../../middleware/streamMiddelware';

export interface OrderedTag extends Tag {
  readonly index?: number;
  readonly isSelected?: boolean;
}
interface MediaLibraryContextData {
  readonly isSortable: boolean;
  readonly isFiltering: boolean;
  readonly isResultNotFound: boolean;
  readonly cardHeight: number;
  readonly cardRef: RefObject<HTMLDivElement | null>;
  readonly mediaLibrary: MediaLibrary;
  readonly playlists: Playlist[];
  readonly selectedTags: Tag[];
  readonly tagList: OrderedTag[];
  readonly setIsResultNotFound: Dispatch<boolean>;
  readonly setPlaylists: Dispatch<SetStateAction<Playlist[]>>;
  readonly setSelectedTags: Dispatch<SetStateAction<Tag[]>>;
  readonly setTagList: Dispatch<SetStateAction<OrderedTag[]>>;
}

interface MediaLibraryProviderProps {
  readonly mediaLibraryId: string;
  readonly children: ReactNode;
}

const MediaLibraryContext = createContext<MediaLibraryContextData>({} as MediaLibraryContextData);
export const useMediaLibraryContext = (): MediaLibraryContextData => useContext(MediaLibraryContext);

export const MediaLibraryDataProvider = (props: MediaLibraryProviderProps) => {
  const { mediaLibraryId } = props;
  const { data: mediaLibrary } = useMediaLibraryDetails(mediaLibraryId);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [tagList, setTagList] = useState<OrderedTag[]>([]);
  const [playlists, setPlaylists] = useState<Playlist[]>([]);
  const [isResultNotFound, setIsResultNotFound] = useState(false);
  const [cardHeight, setCardHeight] = useState(0);
  const cardRef = useRef<HTMLDivElement | null>(null);
  const isFiltering = selectedTags.length !== 0;
  const isSortable = mediaLibrary?.tagSortable;

  useEffect(() => {
    if (mediaLibrary) setPlaylists(mediaLibrary.playlists);
  }, [mediaLibrary]);

  useLayoutEffect(() => {
    if (cardRef.current && playlists.length !== 0) setCardHeight(cardRef.current?.clientHeight);
  }, [cardRef, playlists]);

  const value: MediaLibraryContextData = {
    isSortable,
    isFiltering,
    isResultNotFound,
    cardHeight,
    cardRef,
    playlists,
    tagList,
    selectedTags,
    mediaLibrary,
    setIsResultNotFound,
    setPlaylists,
    setSelectedTags,
    setTagList,
  };

  return <MediaLibraryContext.Provider value={value}>{props.children}</MediaLibraryContext.Provider>;
};
