import { Icon, Stack } from '@mui/material';

export const AiResponseLoader = () => {
  const numDots = 3;
  const dots = Array.from({ length: numDots }, (_, index) => (
    <Icon
      key={index}
      baseClassName="ls-default"
      className="ls-circle-small-solid"
      sx={{ color: 'common.white', fontSize: '8px', height: 'fit-content' }}
    />
  ));

  return (
    <Stack
      component="span"
      display="inline-flex"
      direction="row"
      alignItems="center"
      justifyContent="center"
      className="ai-response-loader"
    >
      {dots}
    </Stack>
  );
};
