import { useSpring } from 'react-spring';
import { useDeviceMetadata } from '../components/providers/DeviceMetadataProvider';
import { MouseEvent, TouchEvent } from 'react';

interface JumpToHighlightTimeParams {
  seekTo?: (time: number) => void;
  highlightTime: number;
  setIsHover: (isHover: boolean) => void;
}

export const useJumpToHighlightTime = ({ seekTo, highlightTime, setIsHover }: JumpToHighlightTimeParams) => {
  const { isMobileView } = useDeviceMetadata();

  const [playIconSpring, playIconApi] = useSpring(() => ({
    from: { scale: 1, y: 0, opacity: 1 },
  }));

  const [highlightTimeSpring, highlightTimeApi] = useSpring(() => ({
    from: { scale: 1, y: 0, opacity: 1 },
  }));

  const jumpToHighlightedTimeHandler = (e: MouseEvent<HTMLElement> | TouchEvent<HTMLElement>) => {
    if (seekTo) {
      e.stopPropagation();
      e.preventDefault();
      onMouseLeaveHandler();
      seekTo(highlightTime ?? 0);
      playIconApi.start({
        from: {
          opacity: 0,
        },
        to: {
          opacity: 1,
        },
        config: {
          duration: 800,
        },
      });
    }
  };

  const onMouseEnterHandler = () => {
    if (!isMobileView) {
      playIconApi.start({
        from: {
          scale: 1,
          y: 0,
        },
        to: {
          scale: 0.8,
          y: 4,
        },
      });
      highlightTimeApi.start({
        from: {
          opacity: 0,
          y: 0,
        },
        to: {
          opacity: 1,
          y: 4,
        },
      });
    }

    setIsHover(true);
  };
  const onMouseLeaveHandler = () => {
    if (!isMobileView) {
      playIconApi.start({
        from: { scale: 0.8, y: -4 },
        to: {
          scale: 1,
          y: 0,
        },
        reset: true,
      });
      highlightTimeApi.start({
        from: {
          opacity: 1,
          y: 0,
        },
        to: {
          opacity: 0,
          y: 4,
        },
      });
    }
    setIsHover(false);
  };
  return {
    playIconSpring,
    highlightTimeSpring,
    jumpToHighlightedTimeHandler,
    onMouseEnterHandler,
    onMouseLeaveHandler,
  };
};
