import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { StyledButton } from '../UI/StyledButton';
import { useMediaLibraryContext } from './MediaLibraryProvider';

export const FilterResultNotFoundComponent = () => {
  const { t } = useTranslation();
  const { setTagList, setSelectedTags, mediaLibrary } = useMediaLibraryContext();

  const resetFilter = () => {
    setTagList(mediaLibrary.filterTags.map((tag, index) => ({ ...tag, index: index, isSelected: false })));
    setSelectedTags([]);
  };

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} pt={3} pb={5}>
      <Typography variant="body1" fontSize="16px" fontWeight={700} textAlign="center">
        {t('mediaLibrary.notFound.title')}
      </Typography>
      <Typography
        variant="body1"
        fontSize="16px"
        sx={{ marginBottom: '16px !important' }}
        fontWeight={400}
        textAlign="center"
      >
        {t('mediaLibrary.notFound.instruction')}
      </Typography>
      <StyledButton onClick={resetFilter} variant="contained" sx={{ bgcolor: 'secondary.main', padding: '8.5px 32px' }}>
        {t('mediaLibrary.notFound.reset')}
      </StyledButton>
    </Stack>
  );
};
