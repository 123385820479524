import memoize from 'memoize-one';
import { useCallback } from 'react';
import { useIVSChatContext } from '../../../../middleware/chat/useIVSChat';

const createChatData = memoize((items, toggleLongText) => ({
  items,
  toggleLongText,
}));

export const useMemorizedChatData = () => {
  const { messages, setMessages } = useIVSChatContext();

  const toggleLongText = useCallback(
    (index: number, isRepliedMsg?: boolean) => {
      if (!isRepliedMsg) {
        setMessages((prevItems) => {
          const newItems = prevItems.slice();
          newItems[index] = {
            ...newItems[index],
            isLongTextToggled: !newItems[index].isLongTextToggled,
          };
          return newItems;
        });
      } else {
        setMessages((prevItems) => {
          const newItems = prevItems.slice();
          newItems[index] = {
            ...newItems[index],
            isLongTextToggled: newItems[index].isLongTextToggled,
            customAttributes: {
              ...newItems[index].customAttributes,
              isLongTextToggled: !newItems[index].customAttributes?.isLongTextToggled,
            },
          };
          return newItems;
        });
      }
    },
    [setMessages],
  );

  const itemData = createChatData(messages, toggleLongText);

  return { itemData };
};
