import { Box, Stack, Typography } from '@mui/material';
import { ReactComponent as SwipeIcon } from '../../../assets/icons/SwipeIcon.svg';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';

export const INSTRUCTION_DURATION = 2000;

export const SwipingInstruction = () => {
  const { isMobileView } = useDeviceMetadata();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: '0',
        zIndex: '2000',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(37, 42, 46, 0.5)',
        height: '100%',
        width: '100%',
      }}
    >
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={3}>
        <SwipeIcon />
        <Typography variant={isMobileView ? 'body1' : 'h6'} color="common.white" fontWeight={700} textAlign="center">
          Swipe for more content
        </Typography>
      </Stack>
    </Box>
  );
};
