import { Icon, IconButton, useTheme } from '@mui/material';
import { ReactComponent as CartSlashIcon } from '../../../../../assets/icons/CartSlashIcon.svg';
import { useControlPanelWidgetContext } from './ControlPanelProvider';
import { useEmbededViewControl } from '../../../../../middleware/playerState/useEmbededViewControl';
import { PlayerType } from '../../../../../models/VideoViewLayerModels';

export const ProductLayoutToggleWiget = () => {
  const { handleToggleProductGrid, isProductListCardOpen } = useControlPanelWidgetContext();
  const { onPressToggleProductList } = useEmbededViewControl();

  const theme = useTheme();
  const playerType = theme.components?.playerDisplayStyle;

  if (playerType === PlayerType.MODERN) {
    return (
      <IconButton onClick={handleToggleProductGrid}>
        {isProductListCardOpen ? (
          <Icon
            fontSize="small"
            className="ls-default ls-shoppingCart-solid"
            sx={{ p: '2.5px', color: 'common.white' }}
          />
        ) : (
          <CartSlashIcon width="25px" height="25px" />
        )}
      </IconButton>
    );
  } else {
    return (
      <IconButton onClick={() => onPressToggleProductList(true)}>
        <Icon fontSize="small" className="ls-default ls-product-list" sx={{ color: theme.palette?.text?.primary }} />
      </IconButton>
    );
  }
};
