import { Box, Grid, SxProps, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useShow } from '../../../middleware/video/useShow';
import { useSearchParamContext } from '../../providers/SearchParamsProvider';
import { CAROUSEL_SLIDES_POSITION } from '../../mediaLibrary/carousel/Carousel';

interface ClassicPlayerLayoutProps {
  readonly children: ReactNode;
}

export const ClassicPlayerLayout = (props: ClassicPlayerLayoutProps) => {
  const { children } = props;
  const { uuid } = useShow();
  const { playlist } = useSearchParamContext();
  const { isMinimized, onMinimizePlayer } = useEmbededViewControl();
  const { isMobileView } = useDeviceMetadata();

  const theme = useTheme();
  const isCarousel = playlist.length > 1;

  const getBoxStyle = (): SxProps => {
    if (isMinimized || isMobileView) {
      return {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      };
    } else {
      return {
        backgroundColor: isCarousel ? 'transparent' : theme.components?.video?.backgrounds?.viewLayer,
        width: '100%',
        height: '100vh',
        maxHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    }
  };

  const getGridRootStyle = (): SxProps => {
    if (isMinimized || isMobileView) {
      return {
        display: 'contents',
      };
    } else {
      return {
        aspectRatio: '3 / 2',
        flexWrap: 'nowrap',
        maxHeight: '92%',
        maxWidth: '95%',
        display: 'flex',
        overflow: 'hidden',
        justifyContent: 'center',
        position: 'relative',
      };
    }
  };

  const backgroundClick = () => onMinimizePlayer();

  return (
    <Box sx={getBoxStyle()} onClick={backgroundClick} id={`classic-player-container-${uuid}`}>
      <Grid container sx={{ ...getGridRootStyle(), px: isCarousel ? `${CAROUSEL_SLIDES_POSITION}px` : 0 }}>
        {children}
      </Grid>
    </Box>
  );
};
