import { Icon, IconButton, useTheme } from '@mui/material';
import { useControlPanelWidgetContext } from './ControlPanelProvider';
import ExpansionControl from '../ExpansionControl';

export const MoreOptionsWidget = () => {
  const theme = useTheme();
  const { isExpansionControlOpened, handleToggleExpansionControl } = useControlPanelWidgetContext();
  return (
    <>
      <IconButton onClick={() => handleToggleExpansionControl(true)}>
        <Icon fontSize="small" className="ls-default ls-more" sx={{ px: 0.5, color: theme.palette?.text?.primary }} />
      </IconButton>
      <ExpansionControl isOpened={isExpansionControlOpened} onToggle={handleToggleExpansionControl} />
    </>
  );
};
