import { Box, Typography, useTheme } from '@mui/material';
import { StyledSquareButton } from '../../UI/StyledSquareButton';
import { ToClientEventType } from '../../../middleware/clientEvent/useClientEvent';
import { PlayerZIndex } from '../../../models/VideoViewLayerModels';
import { useVideoCTATranslation } from '../../../i18n/i18nHooks';
import { useClientEventFunction } from '../../../middleware/clientEvent/useClientEventFunctions';
import { useCallback } from 'react';
import { useShow } from '../../../middleware/video/useShow';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';

export interface EndShowCTAProps {
  displayEndShowCta: boolean;
  endShowCtaUrl: string;
  endShowCtaText: string;
}

export const useEndShowCTAButton = ({
  isVodEnded,
  isLiveShowEnded,
}: {
  isVodEnded: boolean;
  isLiveShowEnded: boolean;
}) => {
  const { isVod, endShowCtaText, endShowCtaUrl, displayEndShowCta } = useShow();
  const { isProductListOpen } = useEmbededViewControl();

  const ShowCTAButton = useCallback(() => {
    const endShowCTAProps: EndShowCTAProps = {
      displayEndShowCta: displayEndShowCta,
      endShowCtaUrl: endShowCtaUrl,
      endShowCtaText: endShowCtaText,
    };

    if ((!isProductListOpen && isVodEnded) || isLiveShowEnded) {
      return <EndShowCTAButton {...endShowCTAProps} />;
    } else if (isVod && !isVodEnded) {
      return null;
    } else {
      return null;
    }
  }, [displayEndShowCta, endShowCtaText, endShowCtaUrl, isLiveShowEnded, isProductListOpen, isVod, isVodEnded]);

  return { ShowCTAButton };
};

export const EndShowCTAButton = (props: EndShowCTAProps) => {
  const { displayEndShowCta, endShowCtaUrl, endShowCtaText } = props;
  const { closePlayer } = useClientEventFunction();
  const { t } = useVideoCTATranslation();
  const theme = useTheme();

  const onClick = () => {
    if (endShowCtaUrl) {
      window.parent.postMessage(
        JSON.stringify({
          type: ToClientEventType.OnOpenUrl,
          data: {
            url: endShowCtaUrl,
          },
        }),
        '*',
      );

      setTimeout(() => {
        closePlayer();
      }, 2000);
    }
  };

  return (
    <Box
      sx={{
        position: 'absolute',
        justifyContent: 'center',
        width: '85%',
        height: 'fit-content',
        transform: 'translate(-50%, -50%)',
        display: displayEndShowCta ? 'flex' : 'none',
        top: 'calc(46% + 50px)',
        left: '50%',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        zIndex: PlayerZIndex.ABOVE,
      }}
    >
      <Typography
        variant="caption"
        component={'span'}
        fontWeight={700}
        color={theme.components?.buttonCTA?.textColor}
        fontSize={'1.625rem'}
      >
        {t('message')}
      </Typography>
      <StyledSquareButton
        onClick={onClick}
        sx={{
          height: 40,
          width: '100%',
          minWidth: 150,
          maxWidth: 250,
          borderRadius: '8px',
          textAlign: 'center',
          textOverflow: 'ellipsis',
          position: 'relative',
          marginTop: '40px',
          backgroundColor: `${theme.components?.buttonCTA?.backgroundColor} !important`,
        }}
      >
        <Typography
          variant="caption"
          component={'span'}
          fontWeight={700}
          color={theme.components?.buttonCTA?.textColor}
          fontSize={'0.9rem'}
        >
          {endShowCtaText}
        </Typography>
      </StyledSquareButton>
    </Box>
  );
};
