import { Box, ClickAwayListener, Collapse, Typography, useTheme } from '@mui/material';
import { ItemList } from './ItemList';
import { ProductApiResponse } from '../../../../models/show.model';
import { DraggablePanel, PanelHandle, PanelPaper, PanelTitle } from '../../../UI/StyledMobilePanel';
import { CloseButton } from '../../../UI/CloseButton';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { PlayerZIndex } from '../../../../models/VideoViewLayerModels';

interface ProductsListProps {
  productsList: ProductApiResponse[];
  onProductListToggle?: (enable: boolean) => void;
  isToggled?: boolean;
  seekTo?: (time: number) => void;
}

export const Products = (props: ProductsListProps) => {
  const { productsList, onProductListToggle, isToggled, seekTo } = props;
  const { onClosePlayer } = useEmbededViewControl();
  const { isMobileView } = useDeviceMetadata();
  const { t } = useTranslation();
  const theme = useTheme();

  const ProductsPaperStylesBox = {
    top: isMobileView ? 40 : 0,
    zIndex: 10,
    height: '45px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: isMobileView ? undefined : `1px solid ${theme.palette.grey['800']}`,
    backgroundColor: theme.palette.common.white,
    mr: '1px',
  };

  const ProductPaperStylesText = {
    color: isMobileView ? theme.palette.text.primary : theme.palette.grey['800'],
    marginLeft: isMobileView ? '12px' : '42px',
    fontWeight: 700,
    fontSize: theme.typography.sectionTitle?.fontSize,
    fontFamily: theme.typography.sectionTitle?.fontFamily,
  };

  const nodeRef = useRef(null);

  const MobileProductList = () => {
    return (
      <ClickAwayListener
        onClickAway={() => onProductListToggle && onProductListToggle(false)}
        touchEvent="onTouchStart"
      >
        <Collapse
          className={isToggled ? 'MuiHelper-fullWidth' : ''}
          classes={{
            wrapperInner: 'MuiHelper-fullWidth',
            wrapper: 'MuiHelper-fullWidth',
          }}
          in={isToggled}
          sx={{
            width: 1,
            bottom: 0,
            left: 0,
            transition: '150ms !important',
            position: 'absolute',
            overflowY: 'auto',
            zIndex: PlayerZIndex.ABOVE,
          }}
        >
          <DraggablePanel nodeRef={nodeRef} onToggle={onProductListToggle}>
            <PanelPaper ref={nodeRef} sx={{ borderRadius: '16px 16px 0px 0px', maxHeight: '80vh' }}>
              <PanelHandle />
              <PanelTitle>{t('product.product')}</PanelTitle>
              {ProductListContent()}
            </PanelPaper>
          </DraggablePanel>
        </Collapse>
      </ClickAwayListener>
    );
  };

  const DesktopProductList = () => {
    return (
      <PanelPaper sx={{ borderRadius: '0px 5px 5px 0px' }}>
        <Box sx={{ ...ProductsPaperStylesBox, position: 'sticky' }}>
          <Typography variant="overline" sx={ProductPaperStylesText}>
            {t('product.product')}
          </Typography>
          <CloseButton onClick={onClosePlayer} direction={'right'} />
        </Box>
        {ProductListContent()}
      </PanelPaper>
    );
  };

  const ProductListContent = () => {
    return productsList.length > 0 ? (
      <Box className="clickable" sx={{ overflowY: 'auto' }}>
        {productsList?.map((products: ProductApiResponse) => (
          <ItemList key={products.id} item={products} seekTo={seekTo} onProductListToggle={onProductListToggle} />
        ))}
      </Box>
    ) : (
      <Box
        sx={{
          height: '25vh',
          alignItems: 'center',
          mt: '5vh',
          textAlign: 'center',
          fontSize: '18px',
          fontWeight: '400',
        }}
      >
        {isMobileView ? t('product.noProduct') : ''}
      </Box>
    );
  };

  return isMobileView ? MobileProductList() : DesktopProductList();
};
