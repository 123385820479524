import { DesktopChatPinnedMessage } from './DesktopChatPinnedMessage';
import { DesktopChatTextField } from './DesktopChatTextField';
import { GradientChatLayout } from '../../../chat/GradientChatLayout';
import { ChatBox } from '../../../../UI/StyledMobileChat';
import { DefaultProperties } from '../../../../../properties/defaultProperties';
import { VariableSizeList as ChatList } from 'react-window';
import { DesktopChatRows } from '../../../chat/ChatRows';
import { ScrollChatProvider, useScrollChatContext } from '../../../chat/chatAutoScrolling/useScrollChatContext';
import { useIVSChatContext } from '../../../../../middleware/chat/useIVSChat';
import { useModulePermissionCheck } from '../../../../../utils/useModulePermissionCheck';
import { useMemorizedChatData } from '../../../chat/chatAutoScrolling/useMemorizedChatData';
import AutoSizer from 'react-virtualized-auto-sizer';
import { useAutoScrollChat } from '../../../chat/chatAutoScrolling/useAutoScrollChat';

export const DesktopChatWrapper = () => {
  return (
    <ScrollChatProvider defaultProperty={DefaultProperties.chatMessageHeightDesktop}>
      <DesktopChat />
    </ScrollChatProvider>
  );
};

const DesktopChat = () => {
  const { pinnedMessage } = useIVSChatContext();
  const { isAiAssistanceSupported } = useModulePermissionCheck();
  const { listRef, getSize, getHeight } = useScrollChatContext();
  const { itemData } = useMemorizedChatData();
  const { onScrolling } = useAutoScrollChat(itemData.items.length, listRef);
  const totalMessages = itemData.items.length;
  const hasMessages = totalMessages > 0;

  return (
    <GradientChatLayout isOpen={true} hasMessages={hasMessages} chatboxHeight={getHeight(totalMessages)}>
      <ChatBox
        sx={{ overflowX: 'hidden', maxHeight: '40vh', mb: !isAiAssistanceSupported ? 2 : 0 }}
        height={getHeight(totalMessages)}
        id="modern-player-desktop-chat"
      >
        <AutoSizer>
          {({ height, width }) => (
            <ChatList
              ref={listRef}
              className="modernPlayerChatScrollbar"
              onItemsRendered={onScrolling}
              innerElementType="div"
              itemData={itemData}
              itemCount={totalMessages}
              itemSize={getSize}
              height={
                getHeight(totalMessages) < DefaultProperties.chatMessageHeightDesktop
                  ? getHeight(totalMessages)
                  : (height as number)
              }
              width={width as number}
            >
              {DesktopChatRows}
            </ChatList>
          )}
        </AutoSizer>
      </ChatBox>
      {!isAiAssistanceSupported && pinnedMessage.pin && <DesktopChatPinnedMessage pinnedMessage={pinnedMessage} />}
      <DesktopChatTextField />
    </GradientChatLayout>
  );
};
