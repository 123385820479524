import { useChatTranslation } from '../../../../../../i18n/i18nHooks';
import { Icon, IconButton, InputAdornment, alpha, useTheme } from '@mui/material';
import { StyledOutlinedInput } from '../../../../../UI/StyledOutlinedInput';
import { ChangeEvent, CompositionEventHandler, KeyboardEventHandler, RefObject } from 'react';

interface MobileChatAiSupportTextFieldProps {
  readonly textInput: string;
  readonly isLoading: boolean;
  readonly textFieldRef: RefObject<HTMLInputElement | null>;
  readonly onSubmitHandler: () => void;
  readonly onKeyDownHandler: KeyboardEventHandler<HTMLInputElement>;
  readonly onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  readonly onCompositionStartHandler: CompositionEventHandler<HTMLInputElement>;
  readonly onCompositionEndHandler: CompositionEventHandler<HTMLInputElement>;
  readonly aiChatMessageToggleHandler: (toggle: boolean) => void;
}

export const MobileChatAiSupportTextField = (props: MobileChatAiSupportTextFieldProps) => {
  const {
    textInput,
    isLoading,
    textFieldRef,
    onSubmitHandler,
    onKeyDownHandler,
    onChangeHandler,
    onCompositionStartHandler,
    onCompositionEndHandler,
    aiChatMessageToggleHandler,
  } = props;
  const { t } = useChatTranslation();

  const onClickHandler = () => {
    aiChatMessageToggleHandler(true);
  };

  const theme = useTheme();

  return (
    <StyledOutlinedInput
      autoComplete="off"
      inputRef={textFieldRef}
      id="mobile-ai-support-chat"
      type="text"
      fullWidth={true}
      disabled={isLoading}
      value={textInput}
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      onCompositionStart={onCompositionStartHandler}
      onCompositionEnd={onCompositionEndHandler}
      onClick={onClickHandler}
      sx={{
        borderRadius: '26px',
        bgcolor: alpha(theme.palette.common.white, 0.7),

        '& input::placeholder': {
          fontSize: '16px',
        },
      }}
      placeholder={t('aiSupport.placeholder')}
      endAdornment={
        <InputAdornment position="end">
          <IconButton aria-label="toggle password visibility" onClick={onSubmitHandler} edge="end" size="small">
            <Icon sx={{ overflow: 'visible' }} baseClassName="ls-default" className="ls-send" fontSize="small" />
          </IconButton>
        </InputAdornment>
      }
    />
  );
};
