import { Icon, useTheme } from '@mui/material';
import { CollapseButton } from '../../UI/CollapseButton';
import { StyledSquareButton } from '../../UI/StyledSquareButton';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useShow } from '../../../middleware/video/useShow';
import { PlayerZIndex } from '../../../models/VideoViewLayerModels';

export const VideoViewControlButton = () => {
  const { status } = useShow();
  const { isMinimized, onClosePlayer, onMinimizePlayer } = useEmbededViewControl();
  const isUpcoming = status === 'ready';
  const theme = useTheme();

  if (isUpcoming) {
    return (
      <StyledSquareButton
        onClick={onClosePlayer}
        sx={{
          right: '12px',
          top: theme.components?.squareButton?.spacing,
          zIndex: PlayerZIndex.ABOVE,
          position: 'absolute',
        }}
      >
        <Icon className="ls-default" baseClassName="ls-xmark-solid" />
      </StyledSquareButton>
    );
  } else {
    return <CollapseButton open={isMinimized} onClick={onMinimizePlayer} direction={'right'} vertical />;
  }
};
