import { Box, Icon, Typography, alpha, useTheme } from '@mui/material';
import { useVideoAlertsTranslation } from '../../../i18n/i18nHooks';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';

interface VideoButtonMuteProps {
  readonly onClickUnmuteButton: (value: boolean) => void;
}

export const DefaultUnMuteVideoButton = (props: VideoButtonMuteProps) => {
  const { onClickUnmuteButton } = props;
  const { t } = useVideoAlertsTranslation();
  const { isMobileView } = useDeviceMetadata();
  const { isMinimized, isProductListOpen } = useEmbededViewControl();
  const theme = useTheme();

  const handlerUnMuted = () => {
    onClickUnmuteButton(false);
  };

  return (
    <Box
      onClick={handlerUnMuted}
      id="unMutedContainerBox"
      sx={{
        display: isMinimized ? 'none' : 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '140px',
        height: '92px',
        background: alpha(theme.palette.common.white, 0.7),
        borderRadius: '8px',
        position: 'absolute',
        zIndex: isProductListOpen ? '10' : '20',
        transform: 'translate(-45%, -50%)',
        textAlign: 'center',
        top: '45%',
        left: '50%',
        cursor: 'pointer',
      }}
    >
      <Icon
        id={'unMutedContainerIcon'}
        fontSize="small"
        className="ls-default ls-mute"
        sx={{ px: 0.5, color: theme.components?.defaultValues?.color?.text }}
      />

      <Typography
        id={'unMutedContainerText'}
        marginTop={'12px'}
        variant="caption"
        component={'span'}
        fontWeight={700}
        color="grey[900]"
        fontSize={'0.9rem'}
      >
        {isMobileView ? t('mobile.unmuted') : t('web.unmuted')}
      </Typography>
    </Box>
  );
};
