import { Collapse, Grid } from '@mui/material';
import { CollapseButton } from '../../../UI/CollapseButton';
import { DesktopChatWrapper } from './desktopChat';
import { MouseEvent } from 'react';
import { useLiveChatLog } from '../../../../middleware/chat/useLiveChatLog';
import { useShow } from '../../../../middleware/video/useShow';
import { usePlayableVideoStreamContext } from '../../../../middleware/video/usePlayableVideoStreamContext';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';
import { useControlPanelWidgetContext } from '../../videoStream/videoControlPanel/Widget/ControlPanelProvider';

export const ChatGrid = () => {
  const { isChatCardOpen, handleToggleChatGrid, isFeatureEnabled } = useControlPanelWidgetContext();
  const { showEnded } = usePlayableVideoStreamContext();
  const { isMinimized, onMinimizePlayer } = useEmbededViewControl();
  const { isMobileView } = useDeviceMetadata();
  const { isVod, isClip } = useShow();
  useLiveChatLog();

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    isChatCardOpen && event.stopPropagation();
    isClip && onMinimizePlayer();
  };

  if (isMinimized || isMobileView) {
    return null;
  } else {
    return (
      <Grid
        item
        onClick={handleClick}
        id={'chatPanel'}
        height="100%"
        width="100%"
        sx={{
          position: 'relative',
          display: 'flex',
          justifyContent: 'flex-end',
          flex: '1',
          minWidth: '210px',
          maxWidth: '520px',
        }}
      >
        {isFeatureEnabled && (
          <CollapseButton open={isChatCardOpen} onClick={handleToggleChatGrid} direction={'right'} />
        )}

        {!(showEnded && !isVod) && !isClip && (
          <Collapse
            className={isChatCardOpen ? 'MuiHelper-fullWidth' : ''}
            classes={{
              wrapperInner: 'MuiHelper-fullWidth',
              wrapper: 'MuiHelper-fullWidth',
            }}
            orientation="horizontal"
            in={isChatCardOpen}
            sx={{
              backgroundColor: 'grey[800]',
              width: '100%',
              maxHeight: '100vh',
            }}
          >
            <DesktopChatWrapper />
          </Collapse>
        )}
      </Grid>
    );
  }
};
