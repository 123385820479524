import { Playlist, PlaylistType, VideoType } from '../../graphqlClient/mediaLibraryRequest';
import { Box, Icon, IconButton } from '@mui/material';
import { Swiper as SwiperClass } from 'swiper';
import { FreeMode, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDeviceMetadata } from '../providers/DeviceMetadataProvider';
import { useCallback, useState } from 'react';
import { ClipCard } from './ClipCardComponent';
import { ShowCard } from './ShowCardComponent';
import { MediaLibraryClipPlaylist, MediaLibraryShowPlayList } from '../../graphqlClient/showRequest';
import { MediaLibraryPlaylistLayout } from './MediaLibraryPlaylistLayout';
import { useMediaLibraryContext } from './MediaLibraryProvider';
import { useClientEventFunction } from '../../middleware/clientEvent/useClientEventFunctions';

const isShowPlaylist = (playlist: PlaylistType): playlist is MediaLibraryShowPlayList => {
  return (playlist as MediaLibraryShowPlayList).displayStatus !== undefined;
};

const NavigationButtons = (props: {
  isLastSlide: boolean;
  isFirstSlide: boolean;
  cardHeight: number;
  slidePrevHandler: () => void;
  slideNextHandler: () => void;
}) => {
  const { isLastSlide, isFirstSlide, cardHeight, slidePrevHandler, slideNextHandler } = props;
  return (
    <Box className="swiper-navigation">
      <IconButton
        className={`slidePrev-btn ${isFirstSlide && 'slide-disabled'}`}
        onClick={slidePrevHandler}
        sx={{ top: cardHeight / 2 }}
      >
        <Icon className="ls-default" baseClassName="ls-chevron-left" />
      </IconButton>
      <IconButton
        className={`slideNext-btn ${isLastSlide && 'slide-disabled'}`}
        onClick={slideNextHandler}
        sx={{ top: cardHeight / 2 }}
      >
        <Icon className="ls-default" baseClassName="ls-chevron-right" />
      </IconButton>
    </Box>
  );
};

export const MediaLibraryLandingPagePlaylists = (props: { defaultData: Playlist }) => {
  const { defaultData } = props;
  const { isMobileView } = useDeviceMetadata();
  const { cardHeight, cardRef } = useMediaLibraryContext();
  const { selectShow } = useClientEventFunction();
  const [swiper, setSwiper] = useState<null | SwiperClass>(null);
  const [isFirstSlide, setIsFirstSlide] = useState(false);
  const [isLastSlide, setIsLastSlide] = useState(false);
  const defaultPlaylist =
    (defaultData?.shows?.length !== 0 && defaultData?.shows) ||
    (defaultData?.clips?.length !== 0 && defaultData?.clips) ||
    [];

  const navigationToggleHandler = useCallback((swiper: SwiperClass) => {
    setSwiper(swiper);
  }, []);

  const handleShowCarouselPlaylist = (playlist: MediaLibraryShowPlayList) => {
    const carouselPlaylist = defaultPlaylist.map((playlist) => {
      return { videoType: VideoType.SHOW, videoId: playlist.id };
    });
    selectShow(playlist.id, false, carouselPlaylist, defaultData.swipeEnabled);
  };

  const handleClipCarouselPlaylist = (playlist: MediaLibraryClipPlaylist, isClip: boolean) => {
    const carouselPlaylist = defaultPlaylist.map((playlist) => {
      return { videoType: VideoType.CLIP, videoId: playlist.id };
    });
    selectShow(playlist.id, isClip, carouselPlaylist, defaultData.swipeEnabled);
  };

  const handleSlideChange = useCallback((swiper: SwiperClass) => {
    setIsFirstSlide(swiper.isBeginning);
    setIsLastSlide(swiper.isEnd);
  }, []);

  const slidePrevHandler = useCallback(() => swiper?.slidePrev(), [swiper]);
  const slideNextHandler = useCallback(() => swiper?.slideNext(), [swiper]);

  return (
    <MediaLibraryPlaylistLayout playlistTitle={defaultData.title}>
      {defaultPlaylist && (
        <Swiper
          id={`default-media-library-swiper-${defaultData.id}`}
          onSwiper={navigationToggleHandler}
          onSlideChange={handleSlideChange}
          modules={[Pagination, FreeMode]}
          className="media-library-swiper"
          simulateTouch
          spaceBetween={isMobileView ? 10 : 24}
          slidesPerView="auto"
          freeMode
          preventClicks
          preventClicksPropagation
          touchReleaseOnEdges
          touchMoveStopPropagation
          watchSlidesProgress
          pagination={{
            el: '.swiper-pagination',
            enabled: true,
            dynamicBullets: true,
            type: 'bullets',
            clickable: true,
            bulletClass: 'swiper-pagination-bullet',
          }}
        >
          {!isMobileView && (
            <NavigationButtons
              isLastSlide={isLastSlide}
              isFirstSlide={isFirstSlide}
              cardHeight={cardHeight}
              slideNextHandler={slideNextHandler}
              slidePrevHandler={slidePrevHandler}
            />
          )}
          {defaultPlaylist.map((video: MediaLibraryClipPlaylist | MediaLibraryShowPlayList, index: number) => (
            <SwiperSlide
              id={`default-slide-${video.id}`}
              key={index}
              style={{ width: isMobileView ? '180px' : '300px', height: '100%' }}
            >
              {({ isVisible }: { isVisible: boolean }) => {
                if (isShowPlaylist(video)) {
                  return (
                    <ShowCard
                      isVisible={isVisible}
                      cardRef={cardRef}
                      show={video}
                      onSelectShow={handleShowCarouselPlaylist}
                    />
                  );
                } else {
                  return (
                    <ClipCard
                      isVisible={isVisible}
                      cardRef={cardRef}
                      clip={video}
                      onSelectClip={handleClipCarouselPlaylist}
                    />
                  );
                }
              }}
            </SwiperSlide>
          ))}
          <Box className="swiper-pagination" sx={{ position: 'sticky', margin: '0 auto' }} />
        </Swiper>
      )}
    </MediaLibraryPlaylistLayout>
  );
};
