import { useScrollChatContext } from '../chatAutoScrolling/useScrollChatContext';
import { ListChildComponentProps } from 'react-window';
import { Box, useTheme } from '@mui/material';
import { MobileChatAiSupportMessage as ClassicPlayerMobileChatAiSupportMessage } from '../../classicPlayer/chat/mobileChat/aiSupport/MobileChatAiSupportMessage';
import { MobileChatAiSupportMessage as ModernPlayerMobileChatAiSupportMessage } from '../../modernPlayer/chat/mobileChat/aiSupport/MobileChatAiSupportMessage';
import { DesktopChatAiSupportMessage as ClassicPlayerDesktopChatAiSupportMessage } from '../../classicPlayer/chat/desktopChat/aiSupport/DesktopChatAiSupportMessage';
import { DesktopChatAiSupportMessage as ModernPlayerDesktopChatAiSupportMessage } from '../../modernPlayer/chat/desktopChat/aiSupport/DesktopChatAiSupportMessage';
import { PlayerType } from '../../../../models/VideoViewLayerModels';
import { memo } from 'react';

export const DesktopAiSupportChatRow = memo(({ data, index, style }: ListChildComponentProps) => {
  const { setSize } = useScrollChatContext();
  const theme = useTheme();

  return (
    <Box sx={style}>
      {theme.components?.playerDisplayStyle === PlayerType.MODERN ? (
        <ModernPlayerDesktopChatAiSupportMessage message={data[index]} index={index} setSize={setSize} />
      ) : (
        <ClassicPlayerDesktopChatAiSupportMessage message={data[index]} index={index} setSize={setSize} />
      )}
    </Box>
  );
});

DesktopAiSupportChatRow.displayName = 'DesktopAiSupportChatRow';

export const MobileAiSupportChatRow = memo(({ data, index, style }: ListChildComponentProps) => {
  const { setSize } = useScrollChatContext();
  const theme = useTheme();
  return (
    <Box sx={style}>
      {theme.components?.playerDisplayStyle === PlayerType.MODERN ? (
        <ModernPlayerMobileChatAiSupportMessage message={data[index]} setSize={setSize} index={index} />
      ) : (
        <ClassicPlayerMobileChatAiSupportMessage message={data[index]} setSize={setSize} index={index} />
      )}
    </Box>
  );
});

MobileAiSupportChatRow.displayName = 'MobileAiSupportChatRow';
