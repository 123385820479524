import { Icon, IconButton, InputAdornment, useTheme } from '@mui/material';
import { StyledTooltip } from '../../../../UI/StyledTooltip';
import { StyledTextField } from '../../../../UI/StyledTextField';
import { AccessToChatDialog } from '../../../chat/accessToChatDialog/AccessToChatDialog';
import { useChatTextfied } from '../../../../../middleware/chat/useChatTextfield';
import { useIVSChatContext } from '../../../../../middleware/chat/useIVSChat';
import { useShow } from '../../../../../middleware/video/useShow';

export const DesktopChatTextField = () => {
  const {
    inputRef,
    message,
    isUsernameValid,
    checkUsernameLoading,
    termsDialogOpen,
    tooltips,
    openTooltips,
    setUserNameValid,
    handleKeyDown,
    handleCompositionEnd,
    handleCompositionStart,
    handleClick,
    onMessageClick,
    onTooltipClose,
    onAccessChat,
    validateUsername,
    functionOnClose,
    getTextFieldPlaceholder,
    onChangeMessage,
  } = useChatTextfied();

  const { hasAcceptTerms, isSocketActive, setHasAcceptTerms } = useIVSChatContext();
  const { isVod } = useShow();
  const theme = useTheme();

  const mobileButtonStyles = {
    color:
      message.length > 0
        ? theme.components?.chatBox?.desktop?.button?.active
        : theme.components?.chatBox?.desktop?.button?.default,
    '&:active::before': {
      color: theme.components?.chatBox?.desktop?.button?.active,
    },
    fontSize: '1.25rem',
    overflow: 'visible',
  };

  return (
    <>
      {termsDialogOpen && !isVod && (
        <AccessToChatDialog
          onClose={functionOnClose}
          onAccessChat={onAccessChat}
          isUsernameValid={isUsernameValid}
          setUserNameValid={setUserNameValid}
          hasAcceptTerms={hasAcceptTerms}
          setHasAcceptTerms={setHasAcceptTerms}
          checkUsernameLoading={checkUsernameLoading}
          validateUsername={validateUsername}
        />
      )}
      {!isVod && (
        <StyledTooltip
          title={tooltips}
          open={openTooltips}
          onClose={onTooltipClose}
          placement="top"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -45],
                },
              },
            ],
          }}
        >
          <StyledTextField
            sx={{
              px: '12px',
              pb: '12px',
              boxSizing: 'border-box',
              width: '100%',
            }}
            inputRef={inputRef}
            type="text"
            placeholder={getTextFieldPlaceholder()}
            value={message}
            fullWidth={true}
            disabled={!isSocketActive}
            onChange={onChangeMessage}
            onCompositionEnd={handleCompositionEnd}
            onCompositionStart={handleCompositionStart}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={onMessageClick}>
                    <Icon className="ls-default ls-send" sx={mobileButtonStyles} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 500,
            }}
          />
        </StyledTooltip>
      )}
    </>
  );
};
