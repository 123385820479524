import { CompanyLogo } from '../videoStream/CompanyLogo';
import { MutableRefObject, useCallback } from 'react';
import { DesktopChatLayout } from './chat/desktopChat/DesktopChatLayout';
import { ProductApiResponse } from '../../../models/show.model';
import { PlayerState } from 'amazon-ivs-player';
import { VideoOverlayFrame } from '../../UI/VideoElement';
import { VideoToggleButtons } from './VideoToggleButtons';
import { VideoLabelSection } from './VideoLabelSection';
import { VideoPanelMinimized } from '../videoStream/VideoPanelMinimized';
import { FullScreenCountdown } from '../videoStream/VideoCountdown/index';
import { AddToCalendarButton } from '../videoStream/AddtoCalendar/AddToCalendarButton';
import { Stack, SxProps } from '@mui/material';
import { DefaultUnMuteVideoButton } from '../videoStream/DefaultUnMuteVideoButton';
import { useClientEventFunction } from '../../../middleware/clientEvent/useClientEventFunctions';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useShow } from '../../../middleware/video/useShow';
import { useEndShowCTAButton } from '../videoStream/EndShowCTAButton';
import { useControlPanelWidgetContext } from '../videoStream/videoControlPanel/Widget/ControlPanelProvider';
import { usePlayableVideoStreamContext } from '../../../middleware/video/usePlayableVideoStreamContext';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { useCarouselContext } from '../../mediaLibrary/carousel/useCarouselContext';
import { ImageOrientation, useCoverImageStatus } from '../../../utils/useCoverImageStatus';
import { AspectRatio } from '../../../models/VideoViewLayerModels';
import { DesktopProductLayout } from './productList/DesktopProductLayout';
import { MobileProductLayout } from './productList/MobileProductLayout';
import { DesktopVideoControlLayout } from './videoControl/DesktopVideoControlLayout';
import { MobileVideoControlLayout } from './videoControl/MobileVideoControlLayout';
interface VideoGridOverlayLayoutProps {
  readonly videoElement: MutableRefObject<HTMLVideoElement | undefined>;
  readonly streamingState: PlayerState;
  readonly position: number;
  readonly resumeControls: boolean;
  readonly products: ProductApiResponse[];
  readonly muted: boolean;
  readonly showUpcomimgComponents: boolean;
  readonly handleMuteChange: (value: boolean) => void;
  readonly pause: () => void;
  readonly play: () => void;
  readonly seekTo: (position: number) => void;
  readonly setChatCardOpen?: (value: boolean) => void;
  readonly onVideoRefInit: (videoElement?: HTMLVideoElement) => void;
}

export const VideoGridOverlayLayout = (props: VideoGridOverlayLayoutProps) => {
  const { isVod, beforeShowImageUrl, displayAddToCalendar } = useShow();
  const { unmuteIconRef } = useCarouselContext();
  const { isMobileView } = useDeviceMetadata();
  const { isMinimized } = useEmbededViewControl();
  const { isFeatureEnabled } = useControlPanelWidgetContext();
  const { coverImgOrientation } = useCoverImageStatus();
  const { pausePlayer } = useClientEventFunction();
  const { isVideoUpcoming, showEnded } = usePlayableVideoStreamContext();
  const {
    videoElement,
    pause,
    play,
    seekTo,
    streamingState,
    position,
    resumeControls,
    products,
    muted,
    handleMuteChange,
    showUpcomimgComponents,
  } = props;

  const isVodEnded = isVod && streamingState === PlayerState.ENDED;
  const isLiveShowEnded =
    showEnded && !isVod && (streamingState === PlayerState.ENDED || streamingState === PlayerState.IDLE);

  const { ShowCTAButton } = useEndShowCTAButton({ isVodEnded, isLiveShowEnded });
  const videoPlaying = streamingState === PlayerState.PLAYING;
  const displayUnmuteIcon = muted && unmuteIconRef.current && videoPlaying;

  const onPauseHandler = useCallback(() => pause(), [pause]);
  const onPlayHandler = useCallback(() => play(), [play]);
  const paused = videoElement.current?.paused;
  const onTogglePlay = useCallback(() => {
    if (paused) {
      onPlayHandler();
      pausePlayer(false);
    } else {
      onPauseHandler();
      pausePlayer(true);
    }
  }, [onPauseHandler, onPlayHandler, pausePlayer, paused]);

  const duration = videoElement.current?.duration ? +videoElement.current?.duration.toFixed(0) : 0;

  const onTimeChangeCommittedHandler = (time: number) => seekTo(time);

  const getVideoOverlayFrameStyle = (): SxProps => {
    if (isVideoUpcoming && coverImgOrientation === ImageOrientation.PORTRAIT) {
      return {
        aspectRatio: AspectRatio.PORTRAIT,
        position: 'absolute',
        left: '50%',
        top: 0,
        transform: 'translate(-50%, 0%)',
      };
    } else {
      return {
        width: '100%',
      };
    }
  };

  const onClickUnmuteButton = (value: boolean) => {
    handleMuteChange(value);
    unmuteIconRef.current = false;
  };

  return (
    <VideoOverlayFrame sx={{ ...getVideoOverlayFrameStyle() }}>
      {isMinimized ? (
        <VideoPanelMinimized
          streamingState={streamingState}
          pause={pause}
          play={play}
          muted={muted}
          handleMuteChange={handleMuteChange}
          showUpcomimgComponents={showUpcomimgComponents}
        />
      ) : !isMobileView ? (
        <Stack height="100%" width="100%" flexDirection="row" justifyContent="space-between" alignContent="flex-end">
          {showUpcomimgComponents && <FullScreenCountdown />}
          {showUpcomimgComponents && displayAddToCalendar && <AddToCalendarButton />}
          {displayUnmuteIcon && <DefaultUnMuteVideoButton onClickUnmuteButton={onClickUnmuteButton} />}
          <CompanyLogo />
          <VideoLabelSection streamingState={streamingState} resumeControls={resumeControls} />
          <VideoToggleButtons />
          <ShowCTAButton />
          <DesktopChatLayout />
          <DesktopVideoControlLayout
            position={position}
            paused={paused}
            duration={duration}
            isVodEnded={isVodEnded}
            isLiveShowEnded={isLiveShowEnded}
            showUpcomimgComponents={showUpcomimgComponents}
            isFeatureEnabled={isFeatureEnabled}
            onTimeChangeCommittedHandler={onTimeChangeCommittedHandler}
            onTogglePlay={onTogglePlay}
          />
          <DesktopProductLayout products={products} seekTo={seekTo} />
        </Stack>
      ) : (
        <Stack height="100%" width="100%" flexDirection="column" justifyContent="flex-end" alignContent="center">
          {showUpcomimgComponents && beforeShowImageUrl && <FullScreenCountdown />}
          {showUpcomimgComponents && displayAddToCalendar && <AddToCalendarButton />}
          {displayUnmuteIcon && <DefaultUnMuteVideoButton onClickUnmuteButton={onClickUnmuteButton} />}
          <CompanyLogo />
          <VideoLabelSection streamingState={streamingState} resumeControls={resumeControls} />
          <VideoToggleButtons />
          <ShowCTAButton />
          <MobileVideoControlLayout
            position={position}
            paused={paused}
            duration={duration}
            isVodEnded={isVodEnded}
            isLiveShowEnded={isLiveShowEnded}
            showUpcomimgComponents={showUpcomimgComponents}
            isFeatureEnabled={isFeatureEnabled}
            onTimeChangeCommittedHandler={onTimeChangeCommittedHandler}
            onTogglePlay={onTogglePlay}
          />
          <MobileProductLayout products={products} seekTo={seekTo} />
        </Stack>
      )}
    </VideoOverlayFrame>
  );
};
