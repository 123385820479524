import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useCoverImageStatus } from '../../../utils/useCoverImageStatus';

export const BlurryVideoBackground = (props: { videoElement?: MutableRefObject<HTMLVideoElement | undefined> }) => {
  const { videoElement } = props;
  const { isMobileView } = useDeviceMetadata();
  const { isMinimized } = useEmbededViewControl();
  const { shouldShowCoverImage } = useCoverImageStatus();

  const canvas = useRef<HTMLCanvasElement | null>(null);
  const isBlurring = useRef(false);

  const clearCanvas = () => {
    const context = canvas.current?.getContext('2d');
    if (context && canvas.current) {
      context.clearRect(0, 0, canvas.current.width, canvas.current.height);
    }
  };

  const startBlur = useCallback(
    (canvas: HTMLCanvasElement) => {
      if (canvas) {
        clearCanvas();
        isBlurring.current = true;
        const context = canvas.getContext('2d');
        if (!context) return;
        context.filter = 'blur(5px)';

        const draw = () => {
          if (isBlurring.current) {
            if (isMinimized || isMobileView) {
              isBlurring.current = false;
              return;
            }
          }
          context.drawImage(videoElement?.current as HTMLVideoElement, 0, 0, canvas.width, canvas.height);
          requestAnimationFrame(draw);
        };

        requestAnimationFrame(draw);
      }
    },
    [isMinimized, isMobileView, videoElement],
  );

  useEffect(() => {
    if (canvas.current && !isMinimized && !isMobileView && !shouldShowCoverImage) {
      startBlur(canvas.current);
    }
  }, [startBlur, isMinimized, isMobileView, shouldShowCoverImage]);

  return <canvas id={`video-blur-background`} ref={canvas} style={{ width: '100%', height: '100%' }} />;
};
