import { ClassicPlayerLayout } from './ClassicPlayerLayout';
import { PlayerProps } from '../../../models/VideoViewLayerModels';
import { ProductGrid } from './productList/ProductGrid';
import { VideoGrid } from './VideoGrid';
import { ChatGrid } from './chat/ChatGrid';

export const ClassicPlayer = (props: { playerProps: PlayerProps }) => {
  return (
    <ClassicPlayerLayout>
      <ChatGrid />
      <VideoGrid {...props.playerProps} />
      <ProductGrid {...props.playerProps} />
    </ClassicPlayerLayout>
  );
};
