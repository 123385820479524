import { Stack } from '@mui/material';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';
import { useShow } from '../../../../middleware/video/useShow';
import { MuteWidget } from '../../videoStream/videoControlPanel/Widget/MuteWidget';
import { ProductLayoutToggleWiget } from '../../videoStream/videoControlPanel/Widget/ProductLayoutToggleWiget';
import { LikeWidget } from '../../videoStream/videoControlPanel/Widget/LikeWidget';
import { MoreOptionsWidget } from '../../videoStream/videoControlPanel/Widget/MoreOptionsWidget';
import { ShareWidget } from '../../videoStream/videoControlPanel/Widget/ShareWidget';
import { ShareModalDialog } from '../../videoStream/videoControlPanel/Widget/ShareSocialMediaWidget/ShareModalDialog';

interface ControlPanelProps {
  readonly position: number;
  readonly direction?: 'row' | 'column';
  readonly justifyContent: 'center' | 'right';
}

export const ControlPanel = ({ direction, justifyContent }: ControlPanelProps) => {
  const { isMobileView } = useDeviceMetadata();
  const { isVod } = useShow();

  return (
    <Stack width="fit-content" height="auto" direction={direction} justifyContent={justifyContent} alignItems="center">
      {isMobileView ? (
        isVod ? (
          <Stack direction="row" alignItems="center">
            <MoreOptionsWidget />
            <ProductLayoutToggleWiget />
            <LikeWidget />
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center">
            <MuteWidget />
            <ProductLayoutToggleWiget />
            <LikeWidget />
          </Stack>
        )
      ) : (
        <Stack direction="row" alignItems="center">
          <ShareWidget />
          <MuteWidget />
          <LikeWidget />
        </Stack>
      )}
      <ShareModalDialog />
    </Stack>
  );
};
