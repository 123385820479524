import { Icon, SxProps } from '@mui/material';
import { StyledSquareButton } from './StyledSquareButton';

interface CloseButtonProps {
  onClick: () => void;
  direction: 'right' | 'left';
  sx?: SxProps;
}

export const CloseButton = (props: CloseButtonProps) => {
  const { onClick, direction } = props;
  const right = direction === 'right' ? '11px' : 'unset';
  const left = direction === 'left' ? '11px' : 'unset';

  return (
    <StyledSquareButton
      onClick={onClick}
      sx={{
        right: right,
        left: left,
        top: '10px',
      }}
    >
      <Icon baseClassName="ls-default" className="ls-close" sx={{ fontSize: '16px' }} />
    </StyledSquareButton>
  );
};
