import { useShow } from '../../middleware/video/useShow';
import { IVSChatContextProvider } from '../../middleware/chat/useIVSChat';
import { PlayableVideoStreamProvider } from '../../middleware/video/usePlayableVideoStreamContext';
import { HighlightedItemsProvider } from '../../middleware/highlightedItems/useHighlightedItems';

import VideoViewLayer from './VideoViewLayer';

export const ShowDetailPage = () => {
  const showDetail = useShow();
  const { channel, products, showEvents } = showDetail;
  return (
    channel && (
      <HighlightedItemsProvider channel={channel} products={products} showEvents={showEvents}>
        <PlayableVideoStreamProvider>
          <IVSChatContextProvider>
            <VideoViewLayer />
          </IVSChatContextProvider>
        </PlayableVideoStreamProvider>
      </HighlightedItemsProvider>
    )
  );
};
