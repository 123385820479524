import { Slider, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface PlayerTimeSliderProps {
  readonly position: number;
  readonly duration: number;
  readonly onChangeCommitted?: (position: number) => void;
}

export const usePlayerTimeSlide = (props: PlayerTimeSliderProps) => {
  const { position: videoPosition, onChangeCommitted } = props;
  const isActive = useRef(false);
  const [sliderPosition, setSliderPosition] = useState(videoPosition);

  const onMouseDownHandler = () => (isActive.current = true);

  const onChangedHandler = () => (_event: React.SyntheticEvent | Event, position: number | number[]) =>
    setSliderPosition(position as number);

  const onChangeCommittedHandler = () => (_event: React.SyntheticEvent | Event, position: number | number[]) => {
    onChangeCommitted?.(position as number);
    isActive.current = false;
  };

  useEffect(() => {
    if (!isActive.current) {
      setSliderPosition(+videoPosition.toFixed(0));
    }
  }, [videoPosition]);

  return { sliderPosition, onMouseDownHandler, onChangedHandler, onChangeCommittedHandler };
};

export const ClassicPlayerTimeSlider = (props: PlayerTimeSliderProps) => {
  const { sliderPosition, onMouseDownHandler, onChangedHandler, onChangeCommittedHandler } = usePlayerTimeSlide(props);
  const { duration } = props;
  const theme = useTheme();

  return (
    <Slider
      aria-label="time-indicator"
      size="small"
      value={sliderPosition}
      min={0}
      step={1}
      max={duration}
      onChange={onChangedHandler()}
      onChangeCommitted={onChangeCommittedHandler()}
      onMouseDown={onMouseDownHandler}
      sx={{
        color: theme.components?.video?.progressBar,
        height: 4,
        p: '0 0 10px 0 !important',
        '& .MuiSlider-thumb': {
          width: 10,
          height: 10,
          transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
          '&:before': {
            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.1)',
          },
          '&:hover, &.Mui-focusVisible': {
            boxShadow: '0px 0px 0px 8px rgb(255 255 255 / 16%)',
          },
          '&.Mui-active': {
            width: 18,
            height: 18,
          },
        },
        '& .MuiSlider-rail': {
          opacity: 0.9,
          color: theme.components?.video?.progressBarRail,
        },
      }}
    />
  );
};

export const ModernPlayerTimeSlider = (props: PlayerTimeSliderProps) => {
  const { sliderPosition, onMouseDownHandler, onChangedHandler, onChangeCommittedHandler } = usePlayerTimeSlide(props);
  const { duration } = props;
  const theme = useTheme();

  const getFormatedTime = (seconds: number) => {
    const hrs = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    if (hrs > 0) {
      return `${String(hrs).padStart(2, '0')}:${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    } else {
      return `${String(mins).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    }
  };

  return (
    <Slider
      valueLabelDisplay="auto"
      aria-label="time-indicator"
      size="small"
      disableSwap
      valueLabelFormat={(second) => getFormatedTime(second)}
      value={sliderPosition}
      min={0}
      step={1}
      max={duration}
      onChange={onChangedHandler()}
      onChangeCommitted={onChangeCommittedHandler()}
      onMouseDown={onMouseDownHandler}
      sx={{
        color: theme.components?.video?.progressBar,
        height: 4,
        p: '0 0 0 0 !important',
        '& .MuiSlider-thumb': {
          transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
          '&:before': {
            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.1)',
          },
          '&:hover, &.Mui-focusVisible': {
            boxShadow: '0px 0px 0px 8px rgb(255 255 255 / 16%)',
          },
          '&.Mui-active': {
            width: 20,
            height: 20,
          },
        },
        '& .MuiSlider-rail': {
          opacity: 0.9,
          color: 'common.white',
        },
        '& .MuiSlider-valueLabel': {
          lineHeight: 1.2,
          fontSize: 12,
          padding: 0.5,
          borderRadius: 0.5,
          backgroundColor: 'primary.main',
          transformOrigin: 'bottom left',
          '&::before': { display: 'none' },
        },
      }}
    />
  );
};
