import { SvgIcon } from '@mui/material';

export const GoogleCalendarIcon = () => {
  return (
    <SvgIcon>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5 7H6.5V18H17.5V7Z" fill="white" />
        <path
          d="M12.8398 10.9601L13.3438 11.6801L14.1358 11.1041V15.2801H14.9998V9.80811H14.2798L12.8398 10.9601Z"
          fill="#1E88E5"
        />
        <path
          d="M11.4715 12.3726C11.784 12.0856 11.978 11.6876 11.978 11.2481C11.978 10.3746 11.2115 9.66406 10.2695 9.66406C9.4685 9.66406 8.7835 10.1686 8.6045 10.8906L9.433 11.1011C9.5155 10.7691 9.867 10.5281 10.2695 10.5281C10.7405 10.5281 11.124 10.8511 11.124 11.2481C11.124 11.6451 10.7405 11.9681 10.2695 11.9681H9.771V12.8321H10.2695C10.81 12.8321 11.266 13.2076 11.266 13.6521C11.266 14.1041 10.833 14.4721 10.3005 14.4721C9.8195 14.4721 9.4085 14.1671 9.3435 13.7631L8.5 13.9011C8.631 14.7191 9.405 15.3361 10.3 15.3361C11.3035 15.3361 12.12 14.5806 12.12 13.6521C12.12 13.1406 11.868 12.6816 11.4715 12.3726Z"
          fill="#1E88E5"
        />
        <path d="M17 21.5H7L6.5 19.5L7 17.5H17L17.5 19.5L17 21.5Z" fill="#FBC02D" />
        <path d="M19 18L21 17.5V7.5L19 7L17 7.5V17.5L19 18Z" fill="#4CAF50" />
        <path d="M17 7.5L17.5 5.5L17 3.5H4.5C3.6715 3.5 3 4.1715 3 5V17.5L5 18L7 17.5V7.5H17Z" fill="#1E88E5" />
        <path d="M17 17.5V21.5L21 17.5H17Z" fill="#E53935" />
        <path d="M19.5 3.5H17V7.5H21V5C21 4.1715 20.3285 3.5 19.5 3.5Z" fill="#1565C0" />
        <path d="M4.5 21.5H7V17.5H3V20C3 20.8285 3.6715 21.5 4.5 21.5Z" fill="#1565C0" />
      </svg>
    </SvgIcon>
  );
};

export const AppleCalendarIcon = () => {
  return (
    <SvgIcon>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2706_2120)">
          <path
            d="M17.3623 12.5941C17.39 15.4694 19.971 16.427 19.9997 16.4384C19.9779 16.5058 19.587 17.802 18.6397 19.1408C17.8211 20.2983 16.9708 21.4514 15.6317 21.4753C14.3151 21.4987 13.8928 20.7208 12.3894 20.7208C10.886 20.7208 10.4156 21.4514 9.17017 21.4987C7.8776 21.546 6.89333 20.2471 6.06764 19.094C4.3801 16.735 3.09066 12.4283 4.82222 9.52107C5.6823 8.07707 7.21952 7.16317 8.888 7.1398C10.1574 7.11624 11.3553 7.96535 12.1302 7.96535C12.9052 7.96535 14.3613 6.94429 15.8918 7.0942C16.5326 7.12004 18.332 7.345 19.4858 8.979C19.3929 9.03467 17.34 10.1903 17.3623 12.5941ZM14.8895 5.53373C15.5759 4.73041 16.0376 3.61245 15.9113 2.5C14.9225 2.538 13.7264 3.13726 13.0171 3.94001C12.3812 4.6508 11.8243 5.78852 11.9756 6.87893C13.0782 6.96139 14.2043 6.33724 14.8905 5.53373"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_2706_2120">
            <rect width="16" height="19" fill="white" transform="translate(4 2.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export const OutlookCalendarIcon = () => {
  return (
    <SvgIcon>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2706_2125)">
          <path
            d="M20.9998 12.9C21.0008 12.7655 20.928 12.6403 20.8081 12.57H20.806L20.7984 12.566L14.5612 9.03801C14.5343 9.02062 14.5063 9.00472 14.4775 8.99041C14.2367 8.87169 13.9506 8.87169 13.7098 8.99041C13.6809 9.00473 13.653 9.02062 13.626 9.03801L7.38886 12.566L7.38133 12.57C7.1907 12.6833 7.13226 12.9228 7.2508 13.1049C7.28573 13.1586 7.33384 13.2033 7.39095 13.2352L13.6282 16.7632C13.6552 16.7804 13.6831 16.7963 13.7119 16.8108C13.9527 16.9295 14.2388 16.9295 14.4796 16.8108C14.5084 16.7963 14.5363 16.7804 14.5633 16.7632L20.8005 13.2352C20.9251 13.1658 21.0012 13.0378 20.9998 12.9Z"
            fill="#0A2767"
          />
          <path
            d="M8.11608 10.5444H12.2092V14.1296H8.11608V10.5444ZM20.1627 6.9V5.26C20.1725 4.84996 19.8329 4.50983 19.4038 4.5H8.78209C8.35298 4.50983 8.01333 4.84996 8.02316 5.26V6.9L14.3022 8.49999L20.1627 6.9Z"
            fill="#0364B8"
          />
          <path d="M8.02344 6.8999H12.2095V10.4999H8.02344V6.8999Z" fill="#0078D4" />
          <path d="M16.3955 6.8999H12.2095V10.4999L16.3955 14.0999H20.163V10.4999L16.3955 6.8999Z" fill="#28A8EA" />
          <path d="M12.2095 10.5H16.3955V14.1H12.2095V10.5Z" fill="#0078D4" />
          <path d="M12.2095 14.1001H16.3955V17.7001H12.2095V14.1001Z" fill="#0364B8" />
          <path d="M8.11621 14.1294H12.2093V17.3886H8.11621V14.1294Z" fill="#14447D" />
          <path d="M16.3955 14.1001H20.1629V17.7001H16.3955V14.1001Z" fill="#0078D4" />
          <path
            d="M20.8083 13.2131L20.8003 13.2171L14.5631 16.5691C14.5359 16.5851 14.5083 16.6003 14.4794 16.6139C14.3734 16.6621 14.2584 16.6893 14.1411 16.6939L13.8004 16.5035C13.7716 16.4897 13.7436 16.4743 13.7167 16.4575L7.39575 13.0103H7.39282L7.18604 12.8999V19.6855C7.18926 20.1382 7.57583 20.5028 8.04961 20.4999H20.1498C20.1569 20.4999 20.1632 20.4967 20.1707 20.4967C20.2708 20.4906 20.3694 20.4709 20.4637 20.4383C20.5045 20.4218 20.5438 20.4023 20.5814 20.3799C20.6094 20.3647 20.6575 20.3315 20.6575 20.3315C20.872 20.1799 20.9989 19.9404 21 19.6855V12.8999C20.9998 13.0297 20.9266 13.1494 20.8083 13.2131Z"
            fill="url(#paint0_linear_2706_2125)"
          />
          <path
            opacity="0.5"
            d="M20.6652 12.8731V13.2891L14.1434 17.5799L7.3913 13.0131C7.3913 13.0109 7.38942 13.0091 7.38711 13.0091L6.76758 12.6531V12.3531L7.02293 12.3491L7.56292 12.6451L7.57548 12.6491L7.62152 12.6771C7.62152 12.6771 13.9676 16.1371 13.9843 16.1451L14.2271 16.2811C14.248 16.2731 14.269 16.2651 14.2941 16.2571C14.3066 16.2491 20.5941 12.8691 20.5941 12.8691L20.6652 12.8731Z"
            fill="#0A2767"
          />
          <path
            d="M20.8082 13.2131L20.8003 13.2175L14.5631 16.5695C14.5359 16.5855 14.5083 16.6007 14.4794 16.6143C14.2371 16.7274 13.9539 16.7274 13.7116 16.6143C13.683 16.6007 13.655 16.5858 13.6279 16.5695L7.39073 13.2175L7.3832 13.2131C7.26278 13.1507 7.18728 13.0308 7.18604 12.8999V19.6855C7.18903 20.1381 7.57547 20.5028 8.04916 20.4999C8.04916 20.4999 8.04918 20.4999 8.0492 20.4999H20.1368C20.6105 20.5028 20.997 20.1382 21 19.6855C21 19.6855 21 19.6855 21 19.6855V12.8999C20.9998 13.0297 20.9266 13.1494 20.8082 13.2131Z"
            fill="#1490DF"
          />
          <path
            opacity="0.1"
            d="M14.654 16.52L14.5607 16.57C14.5336 16.5865 14.5057 16.6016 14.477 16.6152C14.3741 16.6635 14.2623 16.6917 14.1479 16.6984L16.521 19.38L20.6606 20.3332C20.774 20.2513 20.8643 20.1437 20.9231 20.02L14.654 16.52Z"
            fill="black"
          />
          <path
            opacity="0.05"
            d="M15.0768 16.293L14.5607 16.5702C14.5336 16.5866 14.5057 16.6017 14.477 16.6154C14.3741 16.6636 14.2623 16.6919 14.1479 16.6986L15.2598 19.6278L20.6618 20.3322C20.8747 20.1795 20.9999 19.9401 21.0001 19.6858V19.5982L15.0768 16.293Z"
            fill="black"
          />
          <path
            d="M8.06092 20.4999H20.1356C20.3214 20.5008 20.5026 20.4447 20.6525 20.3399L13.8 16.5043C13.7712 16.4905 13.7432 16.4751 13.7163 16.4583L7.39533 13.0111H7.3924L7.18604 12.8999V19.6623C7.18556 20.1244 7.57726 20.4995 8.06092 20.4999Z"
            fill="#28A8EA"
          />
          <path
            opacity="0.1"
            d="M13.0465 8.43339V16.9654C13.0458 17.2645 12.8554 17.5334 12.5651 17.6454C12.4752 17.6823 12.3783 17.7014 12.2804 17.7014H7.18604V8.10019H8.02325V7.7002H12.2805C12.7033 7.70173 13.0456 8.02929 13.0465 8.43339Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M12.6279 8.83333V17.3653C12.6289 17.4619 12.6075 17.5576 12.5651 17.6453C12.4488 17.9191 12.1711 18.0988 11.8618 18.1001H7.18604V8.10013H11.8618C11.9833 8.09897 12.103 8.12792 12.2093 8.18414C12.4659 8.30767 12.6278 8.55877 12.6279 8.83333Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M12.6279 8.83333V16.5653C12.6259 16.9692 12.2845 17.2966 11.8619 17.3001H7.18604V8.10013H11.8618C11.9833 8.09897 12.103 8.12792 12.2093 8.18414C12.4659 8.30767 12.6278 8.55877 12.6279 8.83333Z"
            fill="black"
          />
          <path
            opacity="0.2"
            d="M12.2093 8.83329V16.5653C12.2088 16.9698 11.8666 17.2981 11.4432 17.3001H7.18604V8.1001H11.4432C11.8665 8.10031 12.2095 8.4284 12.2093 8.8329C12.2093 8.83303 12.2093 8.83316 12.2093 8.83329Z"
            fill="black"
          />
          <path
            d="M3.7673 8.1001H11.442C11.8658 8.1001 12.2093 8.42837 12.2093 8.83329V16.1669C12.2093 16.5718 11.8658 16.9001 11.442 16.9001H3.7673C3.34353 16.9001 3 16.5718 3 16.1669V8.83329C3 8.42837 3.34354 8.1001 3.7673 8.1001Z"
            fill="url(#paint1_linear_2706_2125)"
          />
          <path
            d="M5.39822 11.1754C5.58734 10.7904 5.89258 10.4682 6.27478 10.2502C6.69805 10.0186 7.17996 9.90319 7.66748 9.91657C8.11933 9.90721 8.56519 10.0167 8.95637 10.233C9.32415 10.4426 9.62037 10.7496 9.80906 11.117C10.0146 11.5217 10.117 11.9672 10.1079 12.417C10.1179 12.887 10.0125 13.3528 9.80027 13.777C9.60709 14.1574 9.30148 14.4755 8.9212 14.6918C8.51495 14.9147 8.05253 15.027 7.58418 15.0166C7.12268 15.0272 6.66699 14.9165 6.26683 14.6966C5.89586 14.4867 5.59593 14.1793 5.40283 13.811C5.19612 13.412 5.09249 12.9716 5.1006 12.5266C5.09199 12.0605 5.19373 11.5986 5.39822 11.1754ZM6.33255 13.3474C6.43338 13.5908 6.60436 13.8019 6.82566 13.9562C7.05107 14.1067 7.32098 14.1844 7.59589 14.1778C7.88866 14.1888 8.17725 14.1085 8.41803 13.949C8.63653 13.7951 8.80302 13.5835 8.89691 13.3402C9.00187 13.0684 9.05366 12.7806 9.0497 12.491C9.05295 12.1986 9.00426 11.9078 8.9057 11.631C8.81865 11.381 8.65753 11.1607 8.44189 10.9966C8.20714 10.8295 7.91933 10.7447 7.62686 10.7566C7.346 10.7496 7.07004 10.8279 6.83864 10.9802C6.61359 11.1351 6.43936 11.3481 6.33631 11.5942C6.10771 12.1582 6.10652 12.7833 6.33296 13.3482L6.33255 13.3474Z"
            fill="white"
          />
          <path d="M16.3955 6.8999H20.1629V10.4999H16.3955V6.8999Z" fill="#50D9FF" />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2706_2125"
            x1="14.093"
            y1="12.8999"
            x2="14.093"
            y2="20.4999"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#35B8F1" />
            <stop offset="1" stopColor="#28A8EA" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2706_2125"
            x1="4.59984"
            y1="7.52719"
            x2="10.209"
            y2="17.6939"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1784D9" />
            <stop offset="0.5" stopColor="#107AD5" />
            <stop offset="1" stopColor="#0A63C9" />
          </linearGradient>
          <clipPath id="clip0_2706_2125">
            <rect width="18" height="16" fill="white" transform="translate(3 4.5)" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
