import { Box, Card, Icon, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { PinnedMsg } from '../../../../../models/chat.model';
import { ChatAvatar } from '../../../../UI/ChatAvatar';
import { MessageConverted } from '../../../chat/MessageConverted';

interface pinnedMessageProps {
  readonly pinnedMessage: PinnedMsg;
}

export const MobileChatPinnedMessage = (props: pinnedMessageProps) => {
  const { pinnedMessage } = props;
  const message = pinnedMessage.msg?.message ?? '';
  const username = pinnedMessage.msg?.username;
  const isMsgFromModerator = pinnedMessage.msg?.customAttributes?.isModerator === 'true';
  const theme = useTheme();

  const sxBox: SxProps = {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
  };

  const sxBoxCard: SxProps = {
    flexShrink: 0,
    px: '8px',
    py: '6px',
    width: '100%',
    background: theme.components?.chatMessage?.mobile?.pinned?.background,
    maxWidth: pinnedMessage.msg?.customAttributes?.avatarUrl ? 'calc(100% - 42px)' : '100%',
  };

  const sxBoxCardStack: SxProps = {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
  };

  const sxBoxCardStackMessageWrapper: SxProps = { display: 'flex', flexWrap: 'wrap' };

  const sxBoxCardStackName: SxProps = {
    fontSize: '0.75rem',
    fontWeight: '700',
    lineHeight: '130%',
    color: isMsgFromModerator
      ? theme.components?.chatMessage?.mobile?.pinned?.moderatorNameColor
      : theme.components?.chatMessage?.mobile?.pinned?.userNameColor,
  };

  const sxBoxCardStackIcon: SxProps = {
    color: theme.components?.chatMessage?.mobile?.pinned?.textColor,
    fontSize: '.9rem',
  };

  const sxTextMessage: SxProps = {
    overflowWrap: 'anywhere',
    marginRight: '4px',
    color: theme.components?.chatMessage?.mobile?.pinned?.textColor,
    fontSize: '0.75rem',
    lineHeight: '130%',
    textTransform: 'none',
  };
  const sxProductLinkMessage: SxProps = {
    marginRight: '4px',
    overflowWrap: 'anywhere',
    textDecoration: isMsgFromModerator ? 'hover' : 'none',
    pointerEvents: isMsgFromModerator ? 'auto' : 'none',
    fontSize: '0.75rem',
    lineHeight: '130%',
    color: isMsgFromModerator
      ? theme.components?.chatMessage?.mobile?.pinned?.linkModeratorColor
      : theme.components?.chatMessage?.mobile?.pinned?.linkUserColor,
  };

  return (
    <Box width={'80%'} mb="8px">
      <Box sx={sxBox}>
        {pinnedMessage.msg?.customAttributes?.avatarUrl && (
          <ChatAvatar isMobile={true} backgroundURL={pinnedMessage.msg?.customAttributes?.avatarUrl} />
        )}
        <Card elevation={0} sx={sxBoxCard}>
          <Stack sx={sxBoxCardStack}>
            <Typography sx={sxBoxCardStackName}>{username}</Typography>
            <Icon baseClassName="ls-default" className="ls-pin" sx={sxBoxCardStackIcon} />
          </Stack>

          <Box sx={sxBoxCardStackMessageWrapper}>
            <MessageConverted
              message={message}
              isModerator={isMsgFromModerator}
              isProductLinkMessageClass={true}
              sxProductLinkMessage={sxProductLinkMessage}
              sxTextMessage={sxTextMessage}
              rel="noopener noreferrer"
            />
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
