import { Icon, useTheme } from '@mui/material';
import { ReactComponent as CheckedIcon } from '../../assets/icons/CheckedIcon.svg';
import CheckIcon from '../../assets/icons/CheckIcon.svg';

export const CheckboxIcon = () => (
  <Icon>
    <img src={CheckIcon} height={'24px'} width={'24px'} alt="checkbox" />
  </Icon>
);

export const UncheckboxIcon = () => {
  const theme = useTheme();

  return (
    <Icon>
      <CheckedIcon height={'24px'} width={'24px'} fill={theme.components?.chatAccessDialog?.checkboxColor} />
    </Icon>
  );
};
