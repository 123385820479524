import { Stack } from '@mui/material';
import { VideoLabel } from '../videoStream/VideoLabels/VideoLabel';
import { CustomVideoLabel } from '../videoStream/VideoLabels/CustomVideoLabel';
import { PlayerZIndex } from '../../../models/VideoViewLayerModels';
import { PlayerState } from 'amazon-ivs-player';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useSearchParamContext } from '../../providers/SearchParamsProvider';

interface VideoLabelsProps {
  readonly streamingState: PlayerState;
  readonly resumeControls: boolean;
}

export const VideoLabelSection = (props: VideoLabelsProps) => {
  const { streamingState, resumeControls } = props;
  const { isMinimized } = useEmbededViewControl();
  const { customVideoLabelTitle, customVideoLabelUrl } = useSearchParamContext();

  if (resumeControls) {
    return (
      <Stack
        direction="column"
        spacing={1}
        alignItems="flex-start"
        sx={{
          position: 'absolute',
          top: '16px',
          left: '12px',
          zIndex: PlayerZIndex.ABOVE,
        }}
      >
        {!isMinimized && <VideoLabel streamingState={streamingState} />}
        {!isMinimized && !!customVideoLabelTitle && (
          <CustomVideoLabel title={customVideoLabelTitle} url={customVideoLabelUrl} />
        )}
      </Stack>
    );
  } else {
    return null;
  }
};
