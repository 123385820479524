import { Stack } from '@mui/material';
import { MobileChatAiSupportWrapper } from './aiSupport/MobileChatAiSupportWrapper';
import { MobileChatPinnedMessage } from './MobileChatPinnedMessage';
import { MobileChatRow } from '../../../chat/ChatRows';
import { GradientChatLayout } from '../../../chat/GradientChatLayout';
import { ChatBox, StyledMobileChatButton } from '../../../../UI/StyledMobileChat';
import { ChevronIcon } from '../../../../UI/ChevronIcon';
import { DefaultProperties } from '../../../../../properties/defaultProperties';
import { StyledDivider } from '../../../../UI/StyledDivider';
import { VariableSizeList as ChatList } from 'react-window';
import { ScrollChatProvider, useScrollChatContext } from '../../../chat/chatAutoScrolling/useScrollChatContext';
import { useIVSChatContext } from '../../../../../middleware/chat/useIVSChat';
import { useChatTranslation } from '../../../../../i18n/i18nHooks';
import { useModulePermissionCheck } from '../../../../../utils/useModulePermissionCheck';
import { useState } from 'react';
import { useMemorizedChatData } from '../../../chat/chatAutoScrolling/useMemorizedChatData';
import { useAutoScrollChat } from '../../../chat/chatAutoScrolling/useAutoScrollChat';
import AutoSizer from 'react-virtualized-auto-sizer';

export const MobileChatWrapper = () => {
  return (
    <ScrollChatProvider defaultProperty={DefaultProperties.chatMessageHeightMobile}>
      <MobileChat />
    </ScrollChatProvider>
  );
};

const MobileChat = () => {
  const { t } = useChatTranslation();
  const { pinnedMessage } = useIVSChatContext();
  const { isAiAssistanceSupported } = useModulePermissionCheck();
  const { listRef, getSize, getHeight } = useScrollChatContext();
  const { itemData } = useMemorizedChatData();
  const { onScrolling } = useAutoScrollChat(itemData.items.length, listRef);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const totalMessages = itemData.items.length;
  const hasMessages = totalMessages !== 0;
  const toggleChatButtonClick = () => setIsOpen(!isOpen);

  return (
    <GradientChatLayout isOpen={true} hasMessages={hasMessages} chatboxHeight={getHeight(totalMessages)}>
      <Stack direction="column" gap={1} px={1.5} mb={0.5}>
        <Stack>
          {isOpen && (
            <ChatBox style={{ overflowX: 'hidden' }} height={getHeight(totalMessages)}>
              <AutoSizer>
                {({ height, width }) => (
                  <ChatList
                    ref={listRef}
                    className="mobileChatScrollbar"
                    onItemsRendered={onScrolling}
                    innerElementType="div"
                    itemData={itemData}
                    itemCount={totalMessages}
                    itemSize={getSize}
                    height={getHeight(totalMessages) < 50 ? getHeight(totalMessages) : (height as number)}
                    width={width as number}
                  >
                    {MobileChatRow}
                  </ChatList>
                )}
              </AutoSizer>
            </ChatBox>
          )}

          {pinnedMessage.pin && <MobileChatPinnedMessage pinnedMessage={pinnedMessage} />}
          {isAiAssistanceSupported && <StyledDivider />}
        </Stack>
      </Stack>
      {isAiAssistanceSupported ? (
        <MobileChatAiSupportWrapper />
      ) : (
        <StyledMobileChatButton onClick={toggleChatButtonClick}>
          {t('chat')}
          <ChevronIcon direction={isOpen ? 'down' : 'up'} />
        </StyledMobileChatButton>
      )}
    </GradientChatLayout>
  );
};
