import { Box, Collapse, Stack } from '@mui/material';
import { DesktopChatWrapper } from '.';
import { DesktopChatAiSupportWrapper } from '../../../chat/aiSupportChatCommonComponents/DesktopChatAiSupportWrapper';
import { AspectRatio } from '../../../../../models/VideoViewLayerModels';
import { useControlPanelWidgetContext } from '../../../videoStream/videoControlPanel/Widget/ControlPanelProvider';
import { useModulePermissionCheck } from '../../../../../utils/useModulePermissionCheck';
import { useDeviceMetadata } from '../../../../providers/DeviceMetadataProvider';
import { useShow } from '../../../../../middleware/video/useShow';
import { usePlayableVideoStreamContext } from '../../../../../middleware/video/usePlayableVideoStreamContext';

export const DesktopChatLayout = () => {
  const { isChatCardOpen, isFeatureEnabled } = useControlPanelWidgetContext();
  const { isAiAssistanceSupported } = useModulePermissionCheck();
  const { isMobileView } = useDeviceMetadata();
  const { isVod, isClip } = useShow();
  const { showEnded } = usePlayableVideoStreamContext();
  const { videoAspectRatio } = usePlayableVideoStreamContext();
  const isPortraitView = !isClip && (videoAspectRatio === AspectRatio.PORTRAIT || videoAspectRatio === null);

  if (isMobileView) {
    return null;
  } else {
    return (
      <Box
        sx={{
          width: isPortraitView ? (isChatCardOpen ? '100%' : '100%') : isChatCardOpen ? '100%' : '0%',
          maxWidth: '360px',
          height: '100%',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'end',
          ml: '12px',
        }}
      >
        <Collapse
          id="modern-player-dektop-chat-layout"
          className={
            isPortraitView
              ? isChatCardOpen
                ? 'modernPlayer-collapse-open'
                : 'modernPlayer-collapse-close'
              : isChatCardOpen
              ? 'MuiHelper-fullWidth'
              : ''
          }
          classes={
            isPortraitView
              ? {
                  wrapperInner: 'modernPlayer-collapse-open',
                  wrapper: 'modernPlayer-collapse-open',
                }
              : { wrapperInner: 'MuiHelper-fullWidth', wrapper: 'MuiHelper-fullWidth' }
          }
          orientation="vertical"
          in={isChatCardOpen && isFeatureEnabled}
        >
          {!(showEnded && !isVod) && !isClip && (
            <Stack id="chat-grid" height="100%" width="100%" flexDirection="column" justifyContent="flex-end">
              <DesktopChatWrapper />
              {isAiAssistanceSupported && <DesktopChatAiSupportWrapper />}
            </Stack>
          )}
        </Collapse>
      </Box>
    );
  }
};
