import { useCallback, useState } from 'react';
import { ChatMessage, ChatMessageType, IVSReturnedChat, IVSReturnedError } from '../../models/chat.model';
import _ from 'lodash';

export const isLinkCheck = (message: string) => {
  if (message?.startsWith('http') || message?.startsWith('www.')) {
    return true;
  } else {
    return false;
  }
};

export const parseUrls = (userInput: string) => {
  const urlRegExp = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g;
  return userInput.replace(urlRegExp, (match: string) => {
    const formattedMatch = match;
    return formattedMatch;
  });
};

export const useHandleMessages = () => {
  const messageCacheSize = 100;
  const [messages, setMessages] = useState<ChatMessage[]>([]);

  const mapChat = useCallback((data: IVSReturnedChat) => {
    const newMessage: ChatMessage = {
      type: data.Attributes?.messageType ? ChatMessageType.ReplyMessage : ChatMessageType.Message,
      timestamp: data['SendTime'],
      username: data['Sender']['Attributes']['username'],
      userId: data['Sender']['UserId'],
      message: data['Content'],
      messageId: data['Id'],
      customAttributes: data['Attributes'],
    };
    return newMessage;
  }, []);

  const handleMessage = useCallback(
    (data: IVSReturnedChat) => {
      const newMessage = mapChat(data);
      setMessages((prevState: ChatMessage[]) => {
        // while changing websocket it can be happened that a message will be handled multiple times
        if (prevState.find((it) => it.messageId === newMessage.messageId)) {
          return prevState;
        }
        const newState = [...prevState, newMessage];
        if (newState.length > messageCacheSize) {
          return _.slice(newState, newState.length - messageCacheSize);
        }
        return newState;
      });
    },
    [mapChat],
  );

  const handleError = (data: IVSReturnedError) => {
    const newMessage: ChatMessage = {
      type: ChatMessageType.Error,
      timestamp: `${Date.now()}`,
      username: '',
      userId: `${data.RequestId}`,
      message: `Error ${data.ErrorCode}: ${data.ErrorMessage}`,
      messageId: `${data.Id}`,
    };
    console.warn(newMessage);
  };

  return {
    messages,
    mapChat,
    setMessages,
    handleMessage,
    handleError,
  };
};
