import { Box, CardMedia, Divider, Icon, Link, Stack, Typography, alpha, useTheme } from '@mui/material';
import { ProductApiResponse } from '../../../../models/show.model';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';
import { useState } from 'react';
import { useSearchParamContext } from '../../../providers/SearchParamsProvider';
import { JumpToHighlightButton } from '../../productList/JumpToHighlightButton';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';

interface ItemProps {
  item: ProductApiResponse;
  isHighlighted?: boolean;
  seekTo?: (time: number) => void;
  onProductListToggle?: (enable: boolean) => void;
}

export const ItemList = (props: ItemProps) => {
  const [isHover, setIsHover] = useState(false);
  const { item, seekTo, isHighlighted, onProductListToggle } = props;
  const { onClickProduct } = useEmbededViewControl();
  const { useNativePipPlayer } = useSearchParamContext();
  const { isMobileView } = useDeviceMetadata();
  const theme = useTheme();

  const formatTime = (seconds: number) => {
    const hours = String(Math.floor(seconds / 3600));
    const minutes = String(Math.floor((seconds % 3600) / 60));
    const remainingSeconds = String(seconds % 60);
    if (parseInt(hours) > 0) {
      return `${hours}:${minutes.padStart(2, '0')}:${remainingSeconds.padStart(2, '0')}`;
    } else {
      return `${minutes.padStart(2, '0')}:${remainingSeconds.padStart(2, '0')}`;
    }
  };

  const handleProductClick = () => onClickProduct(props.item);

  return (
    <Stack>
      <Link
        onClick={handleProductClick}
        href={useNativePipPlayer ? item.url : undefined}
        target="_blank"
        rel="noopener noreferrer"
        underline="none"
      >
        <Stack
          sx={{
            maxHeight: '82px',
            display: 'flex',
            borderRadius: isMobileView ? '4px' : '8px',
            padding: '8px 7px',
            boxSizing: 'border-box',
            justifyContent: 'center',
            alignItems: 'center',
            bgcolor: alpha(theme.palette.common.white, 0.7),
            border: isHighlighted ? '2px solid ' : 'none',
            borderColor: isHighlighted ? 'primary.main' : 'transparent',
            '&:hover': {
              bgcolor: theme.palette.grey[200],
            },
            cursor: 'pointer',
          }}
        >
          <Stack width="100%" height="100%" direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" width="100%" height="100%" alignItems="center" spacing={2}>
              <Box>
                {item.image ? (
                  <CardMedia
                    sx={{
                      width: '68px',
                      height: '68px',
                      borderRadius: '4px',
                      objectFit: 'cover',
                    }}
                    component="img"
                    alt={item.description || `product - ${item.title} `}
                    src={item.image}
                    decoding="async"
                  />
                ) : (
                  <Icon baseClassName="ls-light ls-image" sx={{ fontSize: '60px', overflow: 'visible' }} />
                )}
              </Box>
              <Stack
                width="fit-content"
                sx={{
                  flexGrow: 1,
                  visibility: isMobileView ? 'visible' : { xs: 'hidden', sm: 'hidden', md: 'visible' },
                }}
              >
                <Typography
                  noWrap={false}
                  variant="body1"
                  fontWeight={700}
                  fontSize="12px"
                  fontFamily={theme.typography.productTitle?.fontFamily}
                  sx={{
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: '2',
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    color: theme.components?.productItems?.name,
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  noWrap={false}
                  variant="body1"
                  sx={{ color: theme.components?.productItems?.brand }}
                  fontWeight={400}
                  fontSize="12px"
                >
                  {item.type === 'product' && item.brand}
                </Typography>
              </Stack>
            </Stack>
            <JumpToHighlightButton
              item={item}
              seekTo={seekTo}
              isHover={isHover}
              setIsHover={setIsHover}
              formatTime={formatTime}
              onProductListToggle={onProductListToggle}
            />
          </Stack>
        </Stack>
      </Link>
      {isMobileView && <Divider sx={{ my: '12px' }} />}
    </Stack>
  );
};
