import { Route, Routes } from 'react-router-dom';
import { EntryPage } from './pages/EntryPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { SearchParamsProvider } from './components/providers/SearchParamsProvider';
import { LanguageProvider } from './components/providers/LanguageProvider';
import { Box, ThemeProvider } from '@mui/material';
import { useCustomTheme } from './theming/useCustomTheme';

function App() {
  const { customTheme } = useCustomTheme();
  return (
    <ThemeProvider theme={customTheme}>
      <Box sx={{ height: '100vh', display: 'flex', alignItems: 'center' }}>
        <SearchParamsProvider>
          <LanguageProvider>
            <Routes>
              <Route path={'/'} element={<EntryPage />} />
              <Route path={'*'} element={<NotFoundPage />} />
            </Routes>
          </LanguageProvider>
        </SearchParamsProvider>
      </Box>
    </ThemeProvider>
  );
}

export default App;
