import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Icon,
  InputAdornment,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import { StyledButton } from '../../../UI/StyledButton';
import { StyledTextField } from '../../../UI/StyledTextField';
import { useAccessChatDialogTranslation } from '../../../../i18n/i18nHooks';
import { useEffect, useState } from 'react';
import { CheckboxIcon, UncheckboxIcon } from '../../../UI/CheckboxIcon';
import { useDebounce } from '../../../../utils/useDebounce';

const FormSectionFrame = styled(Box)({
  paddingBottom: '20px',
});
const FormInputLabel = styled(Box)({
  marginBottom: '12px',
  fontWeight: 'bold',
});

const SuccessIcon = styled(Icon)(({ theme }) => ({
  color: theme.components?.defaultValues?.color?.success,
  fontSize: '20px',
  lineHeight: '18px',
}));

const ErrorIcon = styled(Icon)(({ theme }) => ({
  color: theme.components?.defaultValues?.color?.error,
  fontSize: '20px',
  lineHeight: '18px',
}));

const MAXIMUM_USER_LENGTH = 30;
interface UsernamePageProps {
  readonly isUsernameValid: boolean | undefined;
  readonly checkUsernameLoading: boolean;
  readonly termsAccepted: boolean | undefined;
  readonly onTermsClick: () => void;
  readonly onSubmitClick: (username: string) => void;
  readonly setUserNameValid: (isUsernameValid: boolean | undefined) => void;
  readonly validateUsername: (username: string) => void;
  readonly onTermsAccept: () => void;
  readonly onTermsDecline: () => void;
}

export const UsernamePage = (props: UsernamePageProps) => {
  const {
    isUsernameValid,
    checkUsernameLoading,
    termsAccepted,
    validateUsername,
    onTermsClick,
    onSubmitClick,
    setUserNameValid,
    onTermsAccept,
    onTermsDecline,
  } = props;

  const { t } = useAccessChatDialogTranslation();
  const [unconfirmedUsername, setUnconfirmedUsername] = useState('');
  const [ischecked, setIschecked] = useState(false);
  const isUsernameToLong = unconfirmedUsername.trim().length > MAXIMUM_USER_LENGTH;
  const isUsernameInputed = unconfirmedUsername.trim().length <= 0;
  const throttledValue = useDebounce(unconfirmedUsername, 500);
  const resetUserNameValid = () => setUserNameValid(undefined);
  const isCheckedHandler = (e: React.ChangeEvent<HTMLInputElement>) => setIschecked(e.target.checked);
  const onSubmitHandler = () => onSubmitClick(unconfirmedUsername);
  const theme = useTheme();
  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUnconfirmedUsername(e.target.value);
    if (e.target.value === '') resetUserNameValid();
  };

  useEffect(() => {
    if (throttledValue !== '') validateUsername(throttledValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [throttledValue]);

  useEffect(() => {
    ischecked ? onTermsAccept() : onTermsDecline();
  }, [ischecked, onTermsAccept, onTermsDecline]);

  return (
    <Box width="100%" justifyContent="center" alignItems="center" display="flex" flexDirection="column">
      <FormSectionFrame width="100%">
        <FormInputLabel>
          <Typography variant="caption" component={'span'} fontWeight={700} color="grey[900]" fontSize={'0.9rem'}>
            {t('inputs.username.title')}
          </Typography>
        </FormInputLabel>
        <StyledTextField
          fullWidth
          inputRef={(input) => input && input.focus()}
          onChange={onChangeHandler}
          classes={{ root: isUsernameValid && !isUsernameToLong ? 'Mui-success' : '' }}
          placeholder={t('inputs.username.placeholder')}
          sx={{
            '.MuiInputBase-input': {
              fontSize: '16px',
              borderRadius: `${theme.components?.chatAccessDialog?.inputField?.borderRadius} !important`,
              borderColor: `${theme.components?.chatAccessDialog?.inputField?.borderColorDefault} !important`,
            },

            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: theme.components?.chatAccessDialog?.inputField?.borderColorHover,
              },
            },

            '& .Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.components?.chatAccessDialog?.inputField?.borderColorError} !important`,
            },

            '.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.components?.chatAccessDialog?.inputField?.borderColorActive} !important`,
            },

            '&.Mui-success .MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.components?.chatAccessDialog?.inputField?.borderColorSuccess} !important`,
            },
          }}
          value={unconfirmedUsername}
          error={isUsernameToLong || isUsernameValid === false}
          helperText={
            isUsernameToLong
              ? t('inputs.username.helper.toLong')
              : isUsernameValid === false && !isUsernameInputed
              ? t('inputs.username.helper.inValid')
              : null
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {checkUsernameLoading && <CircularProgress color="secondary" size="0.9rem" />}
                {!checkUsernameLoading && isUsernameValid && !isUsernameInputed && !isUsernameToLong && (
                  <SuccessIcon className={'ls-default ls-success'} />
                )}
                {((!checkUsernameLoading && isUsernameValid !== undefined && !isUsernameValid) || isUsernameToLong) && (
                  <ErrorIcon className={'ls-default ls-error'} />
                )}
              </InputAdornment>
            ),
          }}
        />
      </FormSectionFrame>
      <FormSectionFrame width="100%">
        <FormInputLabel sx={{ margin: 0, display: 'flex' }}>
          <Checkbox
            checked={ischecked}
            onChange={isCheckedHandler}
            sx={{ padding: '0 0 0 2px', transform: 'scale(1.16)', marginRight: '12px' }}
            icon={<CheckboxIcon />}
            checkedIcon={<UncheckboxIcon />}
          />
          <Box>
            <Typography component={'span'} sx={{ display: 'inline', fontWeight: 700, fontSize: '0.9rem' }}>
              {t('inputs.terms.accept')}{' '}
            </Typography>
            <Button
              variant="text"
              onClick={onTermsClick}
              sx={{
                textTransform: 'none',
                textDecoration: 'underline',
                fontWeight: 700,
                color: theme.components?.chatAccessDialog?.termsLinkColor,
                fontSize: '0.9rem',
                padding: '0',
              }}
            >
              {t('inputs.terms.button')}
            </Button>
          </Box>
        </FormInputLabel>
      </FormSectionFrame>

      <StyledButton
        sx={{
          minWidth: 161,
          width: 'fit-content',
          maxWidth: '272px',
          minHeight: '40px',
          color: theme.components?.chatAccessDialog?.button?.fontColorDefault,
          backgroundColor: theme.components?.chatAccessDialog?.button?.backgroundColorDefault,

          '&:hover': {
            color: theme.components?.chatAccessDialog?.button?.fontColorHover,
            backgroundColor: theme.components?.chatAccessDialog?.button?.backgroundColorHover,
          },

          '&:active': {
            color: theme.components?.chatAccessDialog?.button?.fontColorActive,
            backgroundColor: theme.components?.chatAccessDialog?.button?.backgroundColorActive,
          },
        }}
        variant={'contained'}
        fullWidth={true}
        onClick={onSubmitHandler}
        disabled={!termsAccepted || isUsernameToLong || !isUsernameValid || isUsernameInputed}
      >
        {t('submit')}
      </StyledButton>
    </Box>
  );
};
