import { ReactNode } from 'react';
import { Avatar, Icon, IconButton, Stack, Typography } from '@mui/material';

interface SocialMedia {
  name: string;
  icon: ReactNode;
  shareLink: (sharedUrl: string) => string;
}

const SOCIAL_MEDIALS: SocialMedia[] = [
  {
    name: 'Whatsapp',
    shareLink: (sharedUrl: string) => `https://api.whatsapp.com/send?text=${encodeURIComponent(sharedUrl)}`,
    icon: <Icon sx={{ color: 'white' }} className="ls-default" baseClassName="ls-whatsapp" />,
  },
  {
    name: 'Facebook',
    shareLink: (sharedUrl: string) => `https://www.facebook.com/sharer.php?u=${encodeURIComponent(sharedUrl)}`,
    icon: <Icon sx={{ color: 'white' }} className="ls-default" baseClassName="ls-facebook" />,
  },
  {
    name: 'X',
    shareLink: (sharedUrl: string) => `https://twitter.com/intent/tweet?url=${encodeURIComponent(sharedUrl)}`,
    icon: <Icon sx={{ color: 'white' }} className="ls-default" baseClassName="ls-twitter" />,
  },
  {
    name: 'Email',
    shareLink: (sharedUrl: string) => `mailto:?body=${encodeURIComponent(sharedUrl)}`,
    icon: <Icon sx={{ color: 'white' }} className="ls-default" baseClassName="ls-email" />,
  },
];

export const ShareSocialMediaWidget = (props: { sharedUrl: string }) => {
  const { sharedUrl } = props;
  const socialMediaShareLinkHandler = (shareLink: (sharedUrl: string) => string) =>
    window.open(`${shareLink(sharedUrl)}`);

  return (
    <Stack width="100%" mb={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
        {SOCIAL_MEDIALS.map((socialMedia) => (
          <Stack
            key={socialMedia.name}
            spacing={1}
            flex={1}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Avatar sx={{ bgcolor: 'primary.main', color: 'white' }}>
              <IconButton onClick={() => socialMediaShareLinkHandler(socialMedia.shareLink)}>
                {socialMedia.icon}
              </IconButton>
            </Avatar>
            <Typography fontSize="12px" variant="body1" color="initial">
              {socialMedia.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
