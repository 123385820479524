import { RefObject, useCallback } from 'react';
import { Box } from '@mui/material';
import { StyledDivider } from '../../../UI/StyledDivider';

export const DEFAULT_CLASSIC_PLAYER_CHAT_BOX_HEIGHT = 180;
export const DEFAULT_MODERN_PLAYER_CHAT_BOX_HEIGHT = 180;
export const MIN_CHAT_BOX_HEIGHT = 0;
export const MAX_CHAT_BOX_HEIGHT = 500;
export const MARGIN_HEIGHT = 30;

interface DesktopChatAiSupportDividerProps {
  readonly setChatBoxHeight: (height: number) => void;
  readonly chatBoxRef: RefObject<HTMLDivElement | null>;
  readonly sectionRef: RefObject<HTMLDivElement | null>;
}

export const DesktopChatAiSupportDivider = (props: DesktopChatAiSupportDividerProps) => {
  const { setChatBoxHeight, chatBoxRef, sectionRef } = props;

  const handleMouseDown = () => {
    document.addEventListener('mouseup', handleMouseUp, true);
    document.addEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mouseup', handleMouseUp, true);
    document.removeEventListener('mousemove', handleMouseMove, true);
  };

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const currentChatBoxHeight = chatBoxRef?.current?.getBoundingClientRect().bottom ?? 0;
      const sectionHeight = sectionRef?.current?.getBoundingClientRect().height ?? 0;
      const newHeight = Math.abs(e.clientY - currentChatBoxHeight + sectionHeight);

      if (e.clientY + sectionHeight > currentChatBoxHeight) {
        setChatBoxHeight(MIN_CHAT_BOX_HEIGHT);
      } else if (newHeight > MIN_CHAT_BOX_HEIGHT && newHeight < MAX_CHAT_BOX_HEIGHT) {
        setChatBoxHeight(newHeight - MARGIN_HEIGHT);
      }
    },
    [chatBoxRef, sectionRef, setChatBoxHeight],
  );

  return (
    <Box onMouseDown={handleMouseDown} sx={{ cursor: 'ns-resize', position: 'relative' }}>
      <StyledDivider />
    </Box>
  );
};
