import { Box, useMediaQuery } from '@mui/material';
import { Mousewheel } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { HighlightedProductsCard } from './HighlightedProductsCard';
import { useHighlightedItemsContext } from '../../../../middleware/highlightedItems/useHighlightedItems';

export const HighlightedProducts = () => {
  const { highlightedItems } = useHighlightedItemsContext();
  const mobile = useMediaQuery('(max-width:799px)');

  return (
    <Swiper
      style={{ width: mobile ? 'calc(100% - 4px)' : '100%', marginLeft: mobile ? '4px' : '0px' }}
      modules={[Mousewheel]}
      mousewheel={true}
      grabCursor={true}
      slidesPerView={'auto'}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
        {highlightedItems.map((item) => {
          if (item) {
            return (
              <SwiperSlide key={item.id} style={{ width: 'auto' }}>
                <HighlightedProductsCard item={item} />
              </SwiperSlide>
            );
          } else {
            return null;
          }
        })}
      </Box>
    </Swiper>
  );
};
