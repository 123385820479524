import { useSearchParamContext } from '../../providers/SearchParamsProvider';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { Box, Icon, IconButton, useTheme } from '@mui/material';
import { Swiper as SwiperClass } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ShowDetailPage } from '../../../pages/show/ShowDetailPage';
import { ShowContextProvider, useShow } from '../../../middleware/video/useShow';
import { CarouselContextProvider } from './useCarouselContext';
import { INSTRUCTION_DURATION, SwipingInstruction } from './CarouselSwipingInstruction';
import { LOADING_BACKGROUND_COLOR } from '../../../theming/theme';
import { CarouselVideo, PLAYER_STATUS } from './Carousel.model';
import { VideoInformation } from '../../../middleware/clientEvent/useClientEventFunctions';
import { VideoType } from '../../../graphqlClient/mediaLibraryRequest';

const mapVideoToStatus = (
  video: VideoInformation,
  index: number,
  realIndex: number,
  playlistLength: number,
): CarouselVideo => {
  const isActive =
    realIndex === index ||
    realIndex === index + 1 ||
    realIndex === index - 1 ||
    (realIndex === playlistLength - 1 && [playlistLength - 1, playlistLength - 2, 0].includes(index)) ||
    (realIndex === 0 && [0, 1, playlistLength - 1].includes(index));

  return {
    ...video,
    activeStatus: isActive ? PLAYER_STATUS.ACTIVE : PLAYER_STATUS.INACTIVE,
  };
};

export const CAROUSEL_SLIDES_POSITION = 30;

export const CarouselPlayer = () => {
  const { playlist, isSwipeEnabled } = useSearchParamContext();
  const { isClip } = useShow();
  const { isMobileView, isMobileDevice } = useDeviceMetadata();
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [carouselPlaylist, setcarouselPlaylist] = useState<CarouselVideo[]>([]);
  const [carouselBtnPosition, setcarouselBtnPosition] = useState(CAROUSEL_SLIDES_POSITION);
  const [shouldShowIcon, setShouldShowIcon] = useState(true);
  const [isCarouselMinimized, setCarouselMinimized] = useState(false);
  const isCarouselPlayerMuted = useRef<null | boolean>(null);
  const unmuteIconRef = useRef(isClip ? false : true);
  const theme = useTheme();

  const onCarouselInit = useCallback((swiper: SwiperClass) => {
    setSwiper(swiper);
  }, []);

  const handleSlideChange = useCallback(
    (swiper: SwiperClass) => {
      const realIndex = swiper.realIndex;
      setcarouselPlaylist((prevcarouselPlaylist: VideoInformation[]) => {
        return prevcarouselPlaylist.map((video, index) => mapVideoToStatus(video, index, realIndex, playlist.length));
      });
    },
    [playlist.length],
  );

  const slidePrevHandler = useCallback(() => swiper?.slidePrev(), [swiper]);
  const slideNextHandler = useCallback(() => swiper?.slideNext(), [swiper]);

  const resizeHandler = useCallback(() => {
    setcarouselBtnPosition(window.innerWidth - (window.innerWidth - CAROUSEL_SLIDES_POSITION));
  }, []);

  useEffect(() => {
    setcarouselPlaylist(
      playlist.map((video, index) => ({
        videoId: video.videoId,
        videoType: video.videoType,
        activeStatus:
          index === 0 || index === 1 || index === playlist.length - 1 ? PLAYER_STATUS.ACTIVE : PLAYER_STATUS.INACTIVE,
      })),
    );
  }, [playlist]);

  useEffect(() => {
    const instructionTimeOut = setTimeout(() => {
      setShouldShowIcon(false);
    }, INSTRUCTION_DURATION);
    return () => clearTimeout(instructionTimeOut);
  }, []);

  return (
    <Swiper
      className="carousel-swiper"
      style={{ background: isMobileView ? LOADING_BACKGROUND_COLOR : theme.components?.video?.backgrounds?.viewLayer }}
      onInit={onCarouselInit}
      onSlideNextTransitionEnd={handleSlideChange}
      onSlidePrevTransitionEnd={handleSlideChange}
      onResize={resizeHandler}
      direction={isMobileView ? 'vertical' : 'horizontal'}
      preventInteractionOnTransition
      preventClicks
      touchRatio={0.8}
      threshold={50}
      preventClicksPropagation
      watchSlidesProgress
      loopPreventsSliding
      touchMoveStopPropagation
      simulateTouch={isMobileView ? true : false}
      speed={500}
      slidesPerView={1}
      loopedSlides={1}
      loop
      autoHeight={true}
    >
      {shouldShowIcon && isMobileDevice && isSwipeEnabled && <SwipingInstruction />}
      {!isCarouselMinimized && !isMobileView && isSwipeEnabled && (
        <Box className="swiper-navigation">
          <IconButton className="slidePrev-btn" onClick={slidePrevHandler} sx={{ left: `${carouselBtnPosition}px` }}>
            <Icon className="ls-default" baseClassName="ls-chevron-left" />
          </IconButton>
          <IconButton className="slideNext-btn" onClick={slideNextHandler} sx={{ right: `${carouselBtnPosition}px` }}>
            <Icon className="ls-default" baseClassName="ls-chevron-right" />
          </IconButton>
        </Box>
      )}
      {carouselPlaylist.map((video, index) => (
        <SwiperSlide id={`slide-${video.videoId}`} key={index} style={{ width: '100%', height: '100%' }}>
          {({ isVisible }: { isVisible: boolean }) => {
            const showUuId =
              video.activeStatus === PLAYER_STATUS.ACTIVE && video.videoType === VideoType.SHOW ? video.videoId : '';
            const clipId =
              video.activeStatus === PLAYER_STATUS.ACTIVE && video.videoType === VideoType.CLIP ? video.videoId : '';
            return (
              <CarouselContextProvider
                isCarouselPlayerMuted={isCarouselPlayerMuted}
                unmuteIconRef={unmuteIconRef}
                isPlayerVisible={isVisible}
                isCarouselMinimized={isCarouselMinimized}
                carouselSwiper={swiper}
                setCarouselMinimized={setCarouselMinimized}
              >
                <ShowContextProvider activeShowUuId={showUuId} activeClipId={clipId}>
                  <ShowDetailPage />
                </ShowContextProvider>
              </CarouselContextProvider>
            );
          }}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
