import { Collapse, Stack, alpha, useTheme } from '@mui/material';
import { useRef } from 'react';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { useControlPanelWidgetContext } from '../videoStream/videoControlPanel/Widget/ControlPanelProvider';
import { DeviceOrientation } from '../../../utils/useDeviceOrientationChange';
import { LikeWidget } from '../videoStream/videoControlPanel/Widget/LikeWidget';
import { ProductLayoutToggleWiget } from '../videoStream/videoControlPanel/Widget/ProductLayoutToggleWiget';
import { MuteWidget } from '../videoStream/videoControlPanel/Widget/MuteWidget';
import { ChatLayoutToggleWidget } from '../videoStream/videoControlPanel/Widget/ChatLayoutToggleWidget';
import { ExtendOptionsWidget } from '../videoStream/videoControlPanel/Widget/ExtendOptionsWidget';
import { ShareWidget } from '../videoStream/videoControlPanel/Widget/ShareWidget';
import { ShareModalDialog } from '../videoStream/videoControlPanel/Widget/ShareSocialMediaWidget/ShareModalDialog';

const TIMER_DURATION = 5000;

export const ControlPanel = () => {
  const { isOptionsExtended, handleExtendedOptions } = useControlPanelWidgetContext();
  const { deviceOrientation } = useDeviceMetadata();
  const autoCloseTimerRef = useRef<NodeJS.Timeout | null>(null);
  const theme = useTheme();

  const handleAutoClose = () => {
    autoCloseTimerRef.current = setTimeout(() => handleExtendedOptions(false), TIMER_DURATION);
  };

  const handleClearTimeout = () => {
    if (autoCloseTimerRef.current) {
      clearTimeout(autoCloseTimerRef.current);
      autoCloseTimerRef.current = null;
    }
  };

  const handleResetAutoCloseTimeOut = () => {
    handleClearTimeout();
    handleAutoClose();
  };

  return (
    <Stack
      direction={deviceOrientation === DeviceOrientation.LANDSCAPE ? 'row' : 'column'}
      justifyContent="center"
      alignItems="center"
      spacing={1}
      onClick={handleResetAutoCloseTimeOut}
      sx={{ bgcolor: alpha(theme.palette.grey[600], 0.7), borderRadius: '8px', padding: '7px 3px' }}
    >
      <LikeWidget />
      <ProductLayoutToggleWiget />
      <Collapse in={isOptionsExtended} timeout={{ appear: 500, enter: 500, exit: 500 }} unmountOnExit>
        <Stack
          direction={deviceOrientation === DeviceOrientation.LANDSCAPE ? 'row' : 'column'}
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <MuteWidget />
          <ChatLayoutToggleWidget />
          <ShareWidget />
        </Stack>
      </Collapse>
      {!isOptionsExtended && <ExtendOptionsWidget setOptionsEntended={handleExtendedOptions} />}
      <ShareModalDialog />
    </Stack>
  );
};
