const INITIAL_TIMER = {
  hours: '0',
  minutes: '0',
  seconds: '0',
};

export const formatTime = (time: number) => (time < 10 ? `0${time}` : `${time}`);

export const formatShowStartTime = (showStart: Date) => {
  return {
    month: formatTime(showStart.getMonth() + 1),
    date: formatTime(showStart.getDate()),
    hours: formatTime(showStart.getHours()),
    minutes: formatTime(showStart.getMinutes()),
  };
};

export const calculateTimeLeft = (dateShow: number) => {
  let timeLeft = INITIAL_TIMER;
  const today = new Date();
  const difference = dateShow - +today;

  if (difference > 0) {
    timeLeft = {
      hours: formatTime(Math.floor(difference / (1000 * 60 * 60))),
      minutes: formatTime(Math.floor((difference / 1000 / 60) % 60)),
      seconds: formatTime(Math.floor((difference / 1000) % 60)),
    };
  }
  return timeLeft;
};
