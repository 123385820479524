import { Box, Collapse, Stack, Typography, useTheme } from '@mui/material';
import { ItemList } from './ItemList';
import { ProductApiResponse } from '../../../../models/show.model';
import { AspectRatio } from '../../../../models/VideoViewLayerModels';
import { useTranslation } from 'react-i18next';
import { useHighlightedItemsContext } from '../../../../middleware/highlightedItems/useHighlightedItems';
import { useControlPanelWidgetContext } from '../../videoStream/videoControlPanel/Widget/ControlPanelProvider';
import { usePlayableVideoStreamContext } from '../../../../middleware/video/usePlayableVideoStreamContext';
import { useShow } from '../../../../middleware/video/useShow';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';

interface ProductsListProps {
  products: ProductApiResponse[];
  seekTo: (time: number) => void;
}

export const DesktopProductLayout = (props: ProductsListProps) => {
  const { products, seekTo } = props;
  const { highlightedItems } = useHighlightedItemsContext();
  const { isProductListCardOpen, isFeatureEnabled } = useControlPanelWidgetContext();
  const { videoAspectRatio } = usePlayableVideoStreamContext();
  const { isMinimized } = useEmbededViewControl();
  const { isClip } = useShow();
  const { t } = useTranslation();
  const isPortraitView = !isClip && (videoAspectRatio === AspectRatio.PORTRAIT || videoAspectRatio === null);

  if (isMinimized) {
    return null;
  } else {
    return (
      <Box
        sx={{
          width: isPortraitView ? (isProductListCardOpen ? '100%' : '100%') : isProductListCardOpen ? '100%' : '0%',
          maxWidth: '360px',
          height: '100%',
          boxSizing: 'border-box',
          mr: '12px',
        }}
      >
        <Collapse
          id="modern-player-dektop-product-layout"
          orientation="horizontal"
          in={isProductListCardOpen && isFeatureEnabled}
          className={
            isPortraitView
              ? isProductListCardOpen
                ? 'modernPlayer-collapse-open'
                : 'modernPlayer-collapse-close'
              : isProductListCardOpen
              ? 'MuiHelper-fullWidth'
              : ''
          }
          classes={
            isPortraitView
              ? {
                  wrapperInner: 'modernPlayer-collapse-open',
                  wrapper: 'modernPlayer-collapse-open',
                }
              : { wrapperInner: 'MuiHelper-fullWidth', wrapper: 'MuiHelper-fullWidth' }
          }
          sx={{ height: '100%' }}
        >
          <Stack id="product-grid" height="100%" width="100%">
            <Stack
              spacing={2}
              height="100%"
              width="100%"
              direction="column"
              justifyContent="space-between"
              sx={{ mt: '55px', mb: 2, overflowY: 'auto' }}
            >
              {!isClip && highlightedItems?.length > 0 && (
                <Stack>
                  <TitleComponent title={t('product.highlights')} />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Stack height="100%" spacing={0.5} className="clickable" sx={{ width: '100%' }}>
                      {highlightedItems?.map((products: ProductApiResponse) => (
                        <ItemList key={products.id} item={products} seekTo={seekTo} isHighlighted={true} />
                      ))}
                    </Stack>
                  </Box>
                </Stack>
              )}

              <Stack
                className="desktopProductScrollbar clickable"
                width="100%"
                height="100%"
                sx={{ overflowY: 'auto', overflowX: 'hidden' }}
              >
                <TitleComponent title={t('product.product')} />
                <Box
                  height="100%"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Stack flexDirection="column" spacing={0.5} width="100%" height="100%" justifyContent="start">
                    {products?.map((products: ProductApiResponse) => (
                      <ItemList key={products.id} item={products} seekTo={seekTo} />
                    ))}
                  </Stack>
                </Box>
              </Stack>
            </Stack>
          </Stack>
        </Collapse>
      </Box>
    );
  }
};

const TitleComponent = (props: { title: string }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="overline"
      sx={{
        mb: '8px',
        color: 'common.white',
        fontWeight: 700,
        fontSize: theme.typography.sectionTitle?.fontSize,
        fontFamily: theme.typography.sectionTitle?.fontFamily,
      }}
    >
      {props.title}
    </Typography>
  );
};
