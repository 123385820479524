import { Stack, Typography } from '@mui/material';
import { useMediaLibraryContext } from '../../components/mediaLibrary/MediaLibraryProvider';
import { StoryBlockSwiper } from './StoryBlockSwiper/StoryBlockSwiper';
import { useInitLandingPage } from '../useInitLandingPage';

export enum DisplayStyle {
  STORYBLOCK = 'STORY_BLOCK',
  CAROUSEL = 'CAROUSEL',
}

export const StoryblockLandingPage = () => {
  const { mediaLibrary } = useMediaLibraryContext();
  const { offsetRef } = useInitLandingPage();

  return (
    <Stack width="100%" spacing={2.5} sx={{ overflow: 'hidden' }} ref={offsetRef}>
      {!mediaLibrary?.hideTitle && (
        <Typography variant="h1" fontWeight={700} textAlign="center">
          {mediaLibrary?.title}
        </Typography>
      )}

      <StoryBlockSwiper />
    </Stack>
  );
};
