import { StyledSquareButton } from './StyledSquareButton';
import { ChevronIcon } from './ChevronIcon';
import { SxProps, useTheme } from '@mui/material';

interface CollapseButtonProps {
  open: boolean;
  onClick?: () => void;
  direction: 'right' | 'left';
  vertical?: boolean;
  sx?: SxProps;
}

export const CollapseButton = (props: CollapseButtonProps) => {
  const { onClick, open, direction, vertical } = props;
  const theme = useTheme();
  const right = direction === 'right' ? '12px' : 'unset';
  const left = direction === 'left' ? '13px' : 'unset';
  const getIconDirection = () => {
    if (vertical) {
      return open ? 'down' : 'up';
    } else if (!vertical) {
      return (direction === 'right' && open) || (direction === 'left' && !open) ? 'right' : 'left';
    } else {
      return undefined;
    }
  };

  return (
    <StyledSquareButton
      onClick={(event) => {
        onClick?.();
        event.stopPropagation();
      }}
      sx={{
        right: right,
        left: left,
        top: theme.components?.squareButton?.spacing,
        zIndex: 106,
      }}
    >
      <ChevronIcon direction={getIconDirection()} />
    </StyledSquareButton>
  );
};
