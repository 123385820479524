export enum ChatMessageType {
  Message = 'MESSAGE',
  Error = 'ERROR',
  Success = 'SUCCESS',
  ReplyMessage = 'REPLY_MESSAGE',
  Event = 'EVENT',
}

export enum ChatEventName {
  SendMessage = 'SEND_MESSAGE',
  PinMessage = 'PIN_MESSAGE',
  UnPinMessage = 'UN_PIN_MESSAGE',
  BlockUser = 'BLOCK_USER',
  Highlight = 'HIGHLIGHT',
  UnHighlight = 'UN_HIGHLIGHT',
  ReplyMessage = 'REPLY_MESSAGE',
  DeleteMessage = 'aws:DELETE_MESSAGE',
  NotifyEndShow = 'NOTIFY_END_SHOW',
  StreamStart = 'STREAM_START',
  StreamEnd = 'STREAM_END',
  EndVodGeneration = 'END_VOD_GENERATION',
  EndChatLogsGeneration = 'END_CHAT_LOGS_GENERATION',
}
export interface ChatMessage {
  readonly type: ChatMessageType;
  readonly timestamp: string;
  readonly username: string;
  readonly userId: string;
  readonly message: string;
  readonly messageId: string;
  readonly customAttributes?: CustomAttributes;
  readonly isLongTextToggled?: boolean;
}
export interface PinnedMsg {
  readonly pin: boolean;
  readonly msg: ChatMessage | undefined;
}
export interface IVSReturnedAttributes {
  readonly MessageID?: string;
  readonly Reason?: string;
}
export interface CustomAttributes extends IVSReturnedAttributes {
  readonly clientIp?: string;
  readonly userId?: string;
  readonly messageType?: string;
  readonly isModerator?: string;
  readonly message?: string;
  readonly username?: string;
  readonly companyName?: string;
  readonly toBeRepliedMessage?: string;
  readonly toBeRepliedUser?: string;
  readonly toBeRepliedUserId?: string;
  readonly avatarUrl?: string;
  readonly chatLogsUrl?: string;
  readonly vodManifestUrl?: string;
  readonly highlightedItemsUpdate?: string;
  readonly isLongTextToggled?: boolean;
}

export interface IVSReturned {
  readonly Type: ChatMessageType;
  readonly Content: string;
  readonly Id: string;
  readonly RequestId: string;
  readonly SendTime: string;
  readonly Sender: Sender;
  readonly EventName: ChatEventName;
  readonly Attributes?: CustomAttributes;
}

export interface IVSReturnedChat extends IVSReturned {
  readonly Type: Exclude<ChatMessageType, ChatMessageType.Error>;
}
export interface IVSReturnedError extends IVSReturned {
  readonly Type: ChatMessageType.Error;
  readonly ErrorCode: number;
  readonly ErrorMessage: string;
}

export interface ChatLogResponse {
  readonly Type: 'MESSAGE' | 'EVENT';
  readonly Content: string | null;
  readonly Id: string;
  readonly RequestId: string;
  readonly SendTime: string;
  readonly Sender: Sender | null;
  readonly EventName?: ChatEventName;
  readonly Attributes?: CustomAttributes;
}

export interface Sender {
  readonly Attributes: {
    username: string;
  };
  readonly UserId: string;
}
export interface SendChatMessageEvent {
  requestId: string;
  action: string;
  content: string;
  attributes: { [key: string]: string | undefined };
}
