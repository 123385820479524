import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  MediaPlayer,
  PlayerError,
  PlayerEventType,
  PlayerState,
  Quality,
  TextMetadataCue,
  create,
  isPlayerSupported,
} from 'amazon-ivs-player';
import { ChatEventName, ChatLogResponse, ChatMessage, ChatMessageType } from '../../models/chat.model';
import { HighlightedItem } from '../../models/show.model';
import { usePlayerControls } from './usePlayerControls';
import { useShow } from './useShow';
import { usePlayableVideoStreamContext } from './usePlayableVideoStreamContext';
import { useIVSChatContext } from '../chat/useIVSChat';
import { Channel, VodManifest, useGqlGetHighlightedItemsByUuId } from '../../graphqlClient/showRequest';
import { FromClientEventType, useClientEventListener } from '../clientEvent/useClientEvent';
import { Status } from '../../enums/show-status.enum';
import { useLiveChatLogMethods } from '../chat/useLiveChatLog';
import { useCarouselContext } from '../../components/mediaLibrary/carousel/useCarouselContext';
import { useSearchParamContext } from '../../components/providers/SearchParamsProvider';
import { useHighlightedItemsContext } from '../highlightedItems/useHighlightedItems';
import { useHeartbeatContex } from '../heartbeat/useHeartbeatContext';
import { AspectRatio } from '../../models/VideoViewLayerModels';
import IvsPlayerConfig from './IvsPlayerConfig.json';

const TIMER_DURATION = 5000;

export interface UsePlayableVideoStreamData {
  readonly loading: boolean;
  readonly muted: boolean;
  readonly videoElement: MutableRefObject<HTMLVideoElement | undefined>;
  readonly streamingState: PlayerState;
  readonly position: number;
  readonly programDateTime?: Date;
  readonly onMuteChange: (value: boolean) => void;
  readonly pause: () => void;
  readonly play: () => void;
  readonly seekTo: (position: number) => void;
  readonly onVideoRefInit: (videoElement?: HTMLVideoElement) => void;
}

export const usePlayableVideoStream = (
  streamUrl: string,
  vodManifest: VodManifest | undefined,
): UsePlayableVideoStreamData => {
  const player = useRef<MediaPlayer | null>(null);
  const videoEl = useRef<HTMLVideoElement>();
  const { isVod, isClip, channel, vodChatLogs, uuid, status } = useShow();
  const { carouselSwiper, isPlayerVisible, isCarouselEnabled, isCarouselMinimized } = useCarouselContext();
  const { setMessages, setPinnedMessage } = useIVSChatContext();
  const { mapChatLog, mapChatLogEvent } = useLiveChatLogMethods();
  const { displayTime } = useSearchParamContext();
  const { setheartbeatStreamState, setHeartbeatShowId, setHeartbeatClipId, setHeartbeatVod } = useHeartbeatContex();
  const { updateHighlightedItems, updateVodHighlightedItems, updateLiveHighlightedItems } =
    useHighlightedItemsContext();
  const {
    showEnded,
    isLiveShowStreaming,
    setVideoAspectRatio,
    setShowStarted,
    setShowEnded,
    setShowPaused,
    setFirstLoading,
    setShowWithErrors,
    setVideoSeeking,
  } = usePlayableVideoStreamContext();

  const {
    setMuted,
    muted,
    loading,
    onMuteChange,
    setLoading,
    resetControls,
    pause,
    play: playFn,
    seekTo: seekToFn,
  } = usePlayerControls(player);
  const [streamingState, setStreamingState] = useState<PlayerState>(PlayerState.IDLE);
  const [position, setPosition] = useState<number>(0);
  const [programDateTime, setProgramDateTime] = useState<Date | undefined>();
  const [vodChatLog, setVodChatLog] = useState<ChatLogResponse[]>(vodChatLogs);
  const [retryCount, setRetryCount] = useState(0);

  const hasInitialized = useRef(false);
  const isVodEnded = useMemo(() => isVod && streamingState === PlayerState.ENDED, [isVod, streamingState]);
  const gqlQuery = useGqlGetHighlightedItemsByUuId(uuid);
  const quality: [number, number] = useMemo(() => (process.env.REACT_APP_ENV === 'prd' ? [1280, 720] : [640, 360]), []);
  const displayTimeApplied = useRef(false);
  const autoSlideTimerRef = useRef<NodeJS.Timeout | null>(null);

  const reload = useCallback(() => {
    if (videoEl.current && videoEl.current !== player.current?.getHTMLVideoElement()) {
      player.current?.attachHTMLVideoElement(videoEl.current);
    }
    player.current?.load(streamUrl);
  }, [streamUrl]);

  const onInitialized = useCallback(() => (hasInitialized.current = true), []);

  const onMetaData = useCallback(
    (cue: TextMetadataCue) => {
      const highlightedItems: HighlightedItem[] = JSON.parse(cue.text).data;
      updateHighlightedItems(highlightedItems);
    },
    [updateHighlightedItems],
  );

  const onQualityChanged = useCallback((quality: Quality) => console.log('Quality changed to:', quality), []);

  const onAspectRatiochanged = useCallback(() => {
    const width = videoEl.current?.videoWidth || 720;
    const height = videoEl.current?.videoHeight || 1280;
    const aspectRatio = width / height;
    const aspect_16_9 = 16 / 9;
    const aspect_9_16 = 9 / 16;
    if (aspectRatio.toFixed(2) === aspect_16_9.toFixed(2)) {
      setVideoAspectRatio(AspectRatio.LANDSCAPE);
    } else if (aspectRatio.toFixed(2) === aspect_9_16.toFixed(2)) {
      setVideoAspectRatio(AspectRatio.PORTRAIT);
    } else {
      setVideoAspectRatio(null);
    }
  }, [setVideoAspectRatio]);

  const onError = useCallback(
    (err: PlayerError) => {
      console.warn('Player Event - ERROR:', err, player.current);
      if (hasInitialized.current) {
        setRetryCount((pre) => pre + 1);
      }
      //Reload video when unknown error is encountered
      if (err.code === -1) {
        reload();
      }
    },
    [reload],
  );

  const getCurrentProgramDateTime = useCallback(
    (position: number) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const currentQuality = player.current?.getQuality() as any;
      const qualityName = currentQuality.name === '' ? vodManifest?.shows[0].resolutions[0].name : currentQuality.group;

      if (vodManifest) {
        for (const show of vodManifest.shows) {
          const currentResolution = show.resolutions.find((resolution) => resolution.name === qualityName);
          if (currentResolution) {
            for (let index = 0; index < currentResolution.m3u8Tags.length; index++) {
              const tag = currentResolution.m3u8Tags[index];
              const nextTag = currentResolution.m3u8Tags[index + 1];
              if (tag.startPosition <= position && (nextTag === undefined || position < nextTag.startPosition)) {
                const gap = position - tag.startPosition;
                return new Date(new Date(tag.programDateTime).getTime() + gap * 1000);
              }
            }
          }
        }
      }
    },
    [vodManifest],
  );

  const updatePDT = useCallback(
    (position: number) => {
      const pdt = getCurrentProgramDateTime(position);
      if (pdt) {
        setProgramDateTime(() => pdt);
      }
    },
    [getCurrentProgramDateTime],
  );

  const onTimeUpdate = useCallback(
    (newPosition: number) => {
      if (isVod) {
        setPosition(() => Math.round(newPosition));
        updatePDT(newPosition);
        if (+newPosition.toFixed(0) === 0) {
          setMessages([]);
          setVodChatLog(vodChatLogs);
          setPinnedMessage({ pin: false, msg: undefined });
          !isClip && updateHighlightedItems([]);
        }
      }
    },
    [isVod, isClip, updatePDT, setMessages, vodChatLogs, setPinnedMessage, updateHighlightedItems],
  );

  const onSeekCompleted = useCallback(
    (seekedTime: number) => {
      setVideoSeeking(false);
      if (isVod) {
        const pdt = getCurrentProgramDateTime(seekedTime);
        if (pdt) {
          updateVodHighlightedItems(pdt);
          setProgramDateTime(() => pdt);
        }
        if (seekedTime < position) {
          setMessages([]);
          setVodChatLog(vodChatLogs);
          setPinnedMessage({ pin: false, msg: undefined });
        }
      }
    },
    [
      isVod,
      getCurrentProgramDateTime,
      setVideoSeeking,
      position,
      updateVodHighlightedItems,
      setMessages,
      vodChatLogs,
      setPinnedMessage,
    ],
  );

  const destroyPlayer = useCallback(() => {
    if (!player.current) return;
    // delete and nullify player
    player.current.pause();
    player.current.delete();
    player.current = null;
    // remove possible stale src
    videoEl.current?.removeAttribute('src');
  }, []);

  const createPlayer = useCallback(() => {
    if (!isPlayerSupported) return;

    // If a player instance already exists, Player it before creating a new one
    player.current && destroyPlayer();
    player.current = create(IvsPlayerConfig);
    // reset player state controls to initial values
    resetControls();
    player.current?.setVolume(1);
  }, [destroyPlayer, resetControls]);

  const onVideoRefInit = useCallback(
    (videoElement?: HTMLVideoElement) => {
      if (videoElement) {
        videoEl.current = videoElement;
        videoEl.current.id = uuid;
        if (player.current?.getHTMLVideoElement() !== videoElement) {
          player.current?.attachHTMLVideoElement(videoElement);
        }
      }
    },
    [uuid],
  );

  const onAutoPlayIsBlocked = useCallback(() => setShowWithErrors(true), [setShowWithErrors]);

  const play = useCallback(() => {
    if (isVodEnded) {
      reload();
    } else {
      playFn();
    }
  }, [isVodEnded, playFn, reload]);

  const seekTo = useCallback(
    (position: number) => {
      if (isVodEnded) reload();
      setVideoSeeking(true);
      seekToFn(position);
    },
    [setVideoSeeking, seekToFn, isVodEnded, reload],
  );

  const onStateChange = useCallback(() => {
    const newState = player.current?.getState();
    console.log(`Player State - ${newState}`);
    setLoading(newState !== PlayerState.PLAYING);
    setStreamingState(newState ?? PlayerState.IDLE);
    isPlayerVisible && setheartbeatStreamState(newState ?? PlayerState.IDLE);
    switch (newState) {
      case PlayerState.READY:
        if (displayTime && !displayTimeApplied.current) {
          seekTo(displayTime);
          displayTimeApplied.current = true;
        }
        player.current?.setAutoMaxVideoSize(...quality);

        break;
      case PlayerState.IDLE:
      case PlayerState.BUFFERING:
        break;
      case PlayerState.PLAYING:
        setFirstLoading(false);
        setShowStarted(true);
        setShowPaused(false);
        setShowWithErrors(false);
        if (autoSlideTimerRef.current) {
          clearTimeout(autoSlideTimerRef.current);
          autoSlideTimerRef.current = null;
        }
        break;

      case PlayerState.ENDED:
        setShowPaused(true);
        if ((isVod || showEnded) && isPlayerVisible) {
          setShowEnded(true);
          setShowStarted(false);
          setTimeout(() => onTimeUpdate(0));

          if (isCarouselEnabled && isVod && isPlayerVisible && !isCarouselMinimized) {
            if (isClip) {
              carouselSwiper?.slideNext();
            } else {
              autoSlideTimerRef.current = setTimeout(() => carouselSwiper?.slideNext(), TIMER_DURATION);
            }
          }
        }
        break;
      default:
        break;
    }
  }, [
    setLoading,
    isPlayerVisible,
    setheartbeatStreamState,
    displayTime,
    quality,
    setFirstLoading,
    setShowStarted,
    setShowPaused,
    setShowWithErrors,
    isVod,
    showEnded,
    seekTo,
    setShowEnded,
    isCarouselEnabled,
    onTimeUpdate,
    isClip,
    isCarouselMinimized,
    carouselSwiper,
  ]);

  const onAudioBlocked = useCallback(() => setMuted(true), [setMuted]);

  useClientEventListener(FromClientEventType.OnVisibilityChanged, (visibility) => {
    if (visibility === 'visible' && !showEnded && !isVod) {
      gqlQuery()
        .then((data) => {
          const channel: Channel = data.getIvsChannelByUuid.items[0];
          const highlightedItems = channel.highlightedItems;
          updateLiveHighlightedItems(highlightedItems);
          return null;
        })
        .catch((err) => console.log(err));
    }
  });

  useEffect(() => {
    createPlayer();
    return () => destroyPlayer();
  }, [createPlayer, destroyPlayer]);

  useEffect(() => {
    if (channel && isPlayerVisible) {
      setheartbeatStreamState(PlayerState.IDLE);
      setHeartbeatVod(channel.status === 'ended');
      setHeartbeatShowId(channel.id);
      setHeartbeatClipId(channel.uuid);
    }
  }, [channel, isPlayerVisible, setHeartbeatClipId, setHeartbeatVod, setHeartbeatShowId, setheartbeatStreamState]);

  useEffect(() => {
    videoEl.current?.addEventListener('loadeddata', onAspectRatiochanged);
    return () => {
      videoEl.current?.removeEventListener('loadeddata', onAspectRatiochanged);
    };
  }, [onAspectRatiochanged]);

  useEffect(() => {
    player.current?.addEventListener(PlayerEventType.INITIALIZED, onInitialized);
    return () => player.current?.removeEventListener(PlayerEventType.INITIALIZED, onInitialized);
  }, [onInitialized]);

  useEffect(() => {
    player.current?.addEventListener(PlayerEventType.TEXT_METADATA_CUE, onMetaData);
    return () => player.current?.removeEventListener(PlayerEventType.TEXT_METADATA_CUE, onMetaData);
  }, [onMetaData]);

  useEffect(() => {
    player.current?.addEventListener(PlayerEventType.QUALITY_CHANGED, onQualityChanged);
    return () => player.current?.removeEventListener(PlayerEventType.QUALITY_CHANGED, onQualityChanged);
  }, [onQualityChanged]);

  useEffect(() => {
    player.current?.addEventListener(PlayerEventType.ERROR, onError);
    return () => player.current?.removeEventListener(PlayerEventType.ERROR, onError);
  }, [onError]);

  useEffect(() => {
    player.current?.addEventListener(PlayerState.IDLE, onStateChange);
    player.current?.addEventListener(PlayerState.READY, onStateChange);
    player.current?.addEventListener(PlayerState.PLAYING, onStateChange);
    player.current?.addEventListener(PlayerState.BUFFERING, onStateChange);
    player.current?.addEventListener(PlayerState.ENDED, onStateChange);
    return () => {
      player.current?.removeEventListener(PlayerState.IDLE, onStateChange);
      player.current?.removeEventListener(PlayerState.READY, onStateChange);
      player.current?.removeEventListener(PlayerState.PLAYING, onStateChange);
      player.current?.removeEventListener(PlayerState.BUFFERING, onStateChange);
      player.current?.removeEventListener(PlayerState.ENDED, onStateChange);
    };
  }, [onStateChange]);

  useEffect(() => {
    player.current?.addEventListener(PlayerEventType.TIME_UPDATE, onTimeUpdate);
    return () => player.current?.removeEventListener(PlayerEventType.TIME_UPDATE, onTimeUpdate);
  }, [onTimeUpdate]);

  useEffect(() => {
    player.current?.addEventListener(PlayerEventType.SEEK_COMPLETED, onSeekCompleted);
    return () => player.current?.removeEventListener(PlayerEventType.SEEK_COMPLETED, onSeekCompleted);
  }, [onSeekCompleted]);

  useEffect(() => {
    player.current?.addEventListener(PlayerEventType.PLAYBACK_BLOCKED, onAutoPlayIsBlocked);
    return () => player.current?.removeEventListener(PlayerEventType.PLAYBACK_BLOCKED, onAutoPlayIsBlocked);
  }, [onAutoPlayIsBlocked]);

  useEffect(() => {
    player.current?.addEventListener(PlayerEventType.AUDIO_BLOCKED, onAudioBlocked);
    return () => player.current?.removeEventListener(PlayerEventType.AUDIO_BLOCKED, onAudioBlocked);
  }, [onAudioBlocked]);

  //ChatLog
  useEffect(() => {
    if (player.current && isVod && !isClip && programDateTime && vodChatLog.length !== 0) {
      vodChatLog.forEach((chat: ChatLogResponse) => {
        if (Date.parse(chat.SendTime) < programDateTime?.getTime()) {
          switch (chat.Type) {
            case ChatMessageType.Message: {
              setMessages((prevMessages: ChatMessage[]) => [...prevMessages, mapChatLog(chat)]);
              break;
            }
            case ChatMessageType.Event: {
              switch (chat.EventName) {
                case ChatEventName.PinMessage: {
                  setPinnedMessage({ pin: true, msg: mapChatLogEvent(chat) });
                  break;
                }
                case ChatEventName.UnPinMessage: {
                  setPinnedMessage({ pin: false, msg: undefined });
                  break;
                }
                case ChatEventName.DeleteMessage: {
                  setMessages((prevMessages: ChatMessage[]) => {
                    return prevMessages.filter((it) => it.messageId !== chat.Attributes?.MessageID);
                  });
                  break;
                }
              }
              break;
            }

            default: {
              console.log(chat);
            }
          }
          setVodChatLog((prevChatLog) => prevChatLog.filter((log) => log.Id !== chat.Id));
        }
      });
    }
  }, [
    isVod,
    isClip,
    programDateTime,
    position,
    vodChatLog,
    setMessages,
    setPinnedMessage,
    mapChatLog,
    mapChatLogEvent,
    updateHighlightedItems,
  ]);

  // When user access VOD or a show is already LIVE
  useEffect(() => {
    if (status === Status.STREAMING || isVod) {
      reload();
    }
  }, [status, isVod, reload]);

  //Set to autoPlay if there's just one single video
  useEffect(() => {
    if (isPlayerVisible && !isCarouselEnabled) {
      player.current?.setAutoplay(true);
    }
  }, [isPlayerVisible, isCarouselEnabled]);

  // Play the video only when the carousel slide is visible
  useEffect(() => {
    if (!isCarouselEnabled) return;
    if (isPlayerVisible) {
      playFn();
    } else {
      pause();
      seekToFn(0);
    }
  }, [isPlayerVisible, isCarouselEnabled, reload, playFn, pause, seekToFn]);

  // When user access a show before LIVE or between LIVE (such as host disconnects)
  useEffect(() => {
    if (isLiveShowStreaming && retryCount < 3) {
      reload();
    }
  }, [isLiveShowStreaming, retryCount, reload]);

  return {
    muted,
    videoElement: videoEl,
    loading,
    streamingState,
    position,
    programDateTime,
    pause,
    play,
    seekTo,
    onMuteChange,
    onVideoRefInit,
  };
};
