import {
  Breakpoint,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useControlPanelWidgetContext } from '../ControlPanelProvider';
import { useShow } from '../../../../../../middleware/video/useShow';
import { StyledTextField } from '../../../../../UI/StyledTextField';
import { CheckboxIcon, UncheckboxIcon } from '../../../../../UI/CheckboxIcon';
import { ShareSocialMediaWidget } from '.';
import { secondsToMms } from './utils';

interface ShareModalDialogProps {
  readonly maxWidth?: false | Breakpoint | undefined;
}

const FlexContaniar = styled(Container)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: '16px',
  padding: '12px 16px',
  width: '100%',
});

export const ShareModalDialog = ({ maxWidth = 'xs' }: ShareModalDialogProps) => {
  const { t } = useTranslation();
  const { isVod } = useShow();
  const {
    isShareModalOpened,
    handleCloseShareModalDialog,
    sharedUrl,
    isStartAtTimeOptionChecked,
    handleStartAtTimeOptionChanged,
    asyncPosition,
    isLinkCopied,
    handleLinkCopied,
  } = useControlPanelWidgetContext();

  return (
    <Dialog onClose={handleCloseShareModalDialog} open={isShareModalOpened} maxWidth={maxWidth} fullWidth={true}>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <DialogTitle
          sx={{
            color: (theme) => theme.palette.secondary.dark,
            paddingLeft: 0,
          }}
        >
          {t('video.controlPannel.share')}
        </DialogTitle>
        <IconButton onClick={handleCloseShareModalDialog}>
          <Icon baseClassName="ls-default" className="ls-close" sx={{ fontSize: '16px' }} />
        </IconButton>
      </Container>

      <DialogContent>
        <ShareSocialMediaWidget sharedUrl={sharedUrl} />
        <StyledTextField
          value={sharedUrl}
          fullWidth={true}
          InputProps={{
            readOnly: true,
          }}
        />
        <FlexContaniar
          sx={{
            backgroundColor: (theme) => theme.palette.grey[100],
          }}
        >
          <Typography variant="body1">
            {isLinkCopied ? t('video.controlPannel.linkCopied') : t('video.controlPannel.copyLink')}
          </Typography>
          <IconButton onClick={handleLinkCopied}>
            {isLinkCopied ? (
              <Icon
                baseClassName="ls-default"
                className="ls-check"
                sx={{
                  fontSize: '20px',
                  color: 'primary.main',
                }}
              />
            ) : (
              <Icon baseClassName="ls-default" className="ls-copy" sx={{ fontSize: '20px' }} />
            )}
          </IconButton>
        </FlexContaniar>
        {isVod && (
          <FlexContaniar
            sx={{
              justifyContent: 'flex-start',
            }}
          >
            <Checkbox
              checked={isStartAtTimeOptionChecked}
              onChange={handleStartAtTimeOptionChanged}
              sx={{
                padding: '0 0 0 2px',
                transform: 'scale(1.16)',
                marginRight: '12px',
              }}
              icon={<CheckboxIcon />}
              checkedIcon={<UncheckboxIcon />}
            />
            <Typography variant="body1">
              {t('video.controlPannel.startIn')}
              <Typography
                variant="body1"
                component="span"
                sx={{
                  marginLeft: '4px',
                  textDecoration: 'underline',
                }}
              >
                {secondsToMms(asyncPosition)}
              </Typography>
            </Typography>
          </FlexContaniar>
        )}
      </DialogContent>
    </Dialog>
  );
};
