import { Typography } from '@mui/material';
import { MutableRefObject, ReactNode, createContext, useContext, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DisplayStyle } from '../../pages/Storyblock/StoryblockLandingPage';
import { VideoInformation } from '../../middleware/clientEvent/useClientEventFunctions';
import { EmbededPdpConfig } from '../../models/EmbededPdpModels';

interface SearchParamsContext {
  readonly publicApiKey: string;
  readonly clientId: string;
  readonly playlist: VideoInformation[];
  readonly activeShowUuId: string;
  readonly activeClipId: string;
  readonly mediaLibraryId: string;
  readonly initDisplayMinimized: MutableRefObject<boolean>;
  readonly displayTime: number;
  readonly hostUrl?: URL;
  readonly modules: string;
  readonly useNativePipPlayer: boolean;
  readonly embededPdpConfig: EmbededPdpConfig | null;
  readonly clientLanguage: string;
  readonly isSwipeEnabled: boolean;
  readonly customVideoLabelTitle: string;
  readonly customVideoLabelUrl: string;
  readonly displayStyle: DisplayStyle;
  readonly isSharedVideo: boolean;
}

const searchParamContext = createContext<SearchParamsContext>({} as SearchParamsContext);

export const useSearchParamContext = (): SearchParamsContext => useContext(searchParamContext);

interface SearchParamsProviderProps {
  readonly children: ReactNode;
}

export const SearchParamsProvider = (props: SearchParamsProviderProps) => {
  const [searchParams] = useSearchParams();
  const publicApiKey = String(searchParams.get('publicApiKey' || ''));
  const clientId = String(searchParams.get('clientId') || '');
  const clientLanguage = String(searchParams.get('clientLanguage') || '');
  const activeShowUuId = String(searchParams.get('activeShowId') || '');
  const activeClipId = String(searchParams.get('activeClipId') || '');
  const mediaLibraryId = String(searchParams.get('mediaLibraryId') || '');
  const displayMinimized = Boolean(searchParams.get('displayMinimized') === 'true');
  const modules = String(searchParams.get('modules') || '');
  const hostHref = searchParams.get('hostHref');
  const hostUrl = hostHref ? new URL(hostHref) : undefined;
  const isSwipeEnabled = Boolean(searchParams.get('swipeEnabled') === 'true');
  const playlistDraft = searchParams.get('carouselPlaylist') || '';
  const playlist = playlistDraft ? JSON.parse(playlistDraft) : [];
  const useNativePipPlayer = Boolean(searchParams.get('useNativePipPlayer') === 'true');
  const customVideoLabelTitle = String(searchParams.get('customVideoLabelTitle') || '');
  const customVideoLabelUrl = String(searchParams.get('customVideoLabelUrl') || '');
  const embededPdpConfig: EmbededPdpConfig = JSON.parse(String(searchParams.get('embededPdpConfig'))) || null;
  const isSharedVideo = hostHref?.includes('isClip') || false;
  const displayStyle =
    String(searchParams.get('displayStyle') || '') === DisplayStyle.STORYBLOCK
      ? DisplayStyle.STORYBLOCK
      : DisplayStyle.CAROUSEL;

  const displayTime = Number(searchParams.get('displayTime') || embededPdpConfig?.highlightTime) || 0;

  const initDisplayMinimized = useRef(displayMinimized);

  const value: SearchParamsContext = {
    publicApiKey,
    clientId,
    playlist,
    activeShowUuId,
    activeClipId,
    mediaLibraryId,
    initDisplayMinimized,
    displayTime,
    hostUrl,
    modules,
    useNativePipPlayer,
    embededPdpConfig,
    clientLanguage,
    isSwipeEnabled,
    customVideoLabelTitle,
    customVideoLabelUrl,
    displayStyle,
    isSharedVideo,
  };
  if (!activeShowUuId && !activeClipId && !mediaLibraryId && !embededPdpConfig) {
    return <Typography variant="h3">Your search parameters are not complete.</Typography>;
  } else {
    return <searchParamContext.Provider value={value}>{props.children}</searchParamContext.Provider>;
  }
};
