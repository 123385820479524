import { Box, Grid } from '@mui/material';
import { Playlist, PlaylistType, VideoType } from '../../graphqlClient/mediaLibraryRequest';
import { MediaLibraryClipPlaylist, MediaLibraryShowPlayList } from '../../graphqlClient/showRequest';
import { MediaLibraryPlaylistLayout } from './MediaLibraryPlaylistLayout';
import { ClipCard } from './ClipCardComponent';
import { ShowCard } from './ShowCardComponent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';
import { useDeviceMetadata } from '../providers/DeviceMetadataProvider';
import { useMediaLibraryContext } from './MediaLibraryProvider';
import { useClientEventFunction } from '../../middleware/clientEvent/useClientEventFunctions';

const isShowPlaylist = (playlist: PlaylistType): playlist is MediaLibraryShowPlayList => {
  return (playlist as MediaLibraryShowPlayList).displayStatus !== undefined;
};

export const MediaLibraryFilteredPlaylists = (props: { filteredData: Playlist }) => {
  const { filteredData } = props;
  const { isMobileView } = useDeviceMetadata();
  const { cardRef } = useMediaLibraryContext();
  const { selectShow } = useClientEventFunction();
  const filteredPlaylist =
    (filteredData?.shows?.length !== 0 && filteredData?.shows) ||
    (filteredData?.clips?.length !== 0 && filteredData?.clips) ||
    [];

  const handleShowCarouselPlaylist = (playlist: MediaLibraryShowPlayList) => {
    const carouselPlaylist = filteredPlaylist.map((playlist) => {
      return { videoType: VideoType.SHOW, videoId: playlist.id };
    });
    selectShow(playlist.id, false, carouselPlaylist, filteredData.swipeEnabled);
  };

  const handleClipCarouselPlaylist = (playlist: MediaLibraryClipPlaylist, isClip: boolean) => {
    const carouselPlaylist = filteredPlaylist.map((playlist) => {
      return { videoType: VideoType.CLIP, videoId: playlist.id };
    });
    selectShow(playlist.id, isClip, carouselPlaylist, filteredData.swipeEnabled);
  };

  return (
    <MediaLibraryPlaylistLayout playlistTitle={filteredData.title}>
      {filteredPlaylist && !isMobileView ? (
        <Grid container spacing={isMobileView ? 1 : 3} columns={{ xs: 3 }} height="100%" width="100%">
          {filteredPlaylist.map((video: MediaLibraryClipPlaylist | MediaLibraryShowPlayList, index: number) => (
            <Grid item key={index}>
              <Box sx={{ maxWidth: isMobileView ? '180px' : '300px', height: '100%' }}>
                {isShowPlaylist(video) ? (
                  <ShowCard isVisible={true} cardRef={cardRef} show={video} onSelectShow={handleShowCarouselPlaylist} />
                ) : (
                  <ClipCard isVisible={true} cardRef={cardRef} clip={video} onSelectClip={handleClipCarouselPlaylist} />
                )}
              </Box>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Swiper
          id={`filtered-media-library-swiper-${filteredData.id}`}
          modules={[Pagination, FreeMode]}
          className="media-library-swiper"
          simulateTouch
          spaceBetween={10}
          slidesPerView="auto"
          freeMode
          preventClicks
          preventClicksPropagation
          touchMoveStopPropagation
          watchSlidesProgress
          pagination={{
            el: '.swiper-pagination',
            enabled: true,
            dynamicBullets: true,
            type: 'bullets',
            clickable: true,
            bulletClass: 'swiper-pagination-bullet',
          }}
        >
          {filteredPlaylist.map((video: MediaLibraryClipPlaylist | MediaLibraryShowPlayList, index: number) => (
            <SwiperSlide
              id={`filtered-slide-${video.id}`}
              key={index}
              style={{ width: isMobileView ? '180px' : '300px', height: '100%' }}
            >
              {({ isVisible }: { isVisible: boolean }) => {
                if (isShowPlaylist(video)) {
                  return (
                    <ShowCard
                      isVisible={isVisible}
                      cardRef={cardRef}
                      show={video}
                      onSelectShow={handleShowCarouselPlaylist}
                    />
                  );
                } else {
                  return (
                    <ClipCard
                      isVisible={isVisible}
                      cardRef={cardRef}
                      clip={video}
                      onSelectClip={handleClipCarouselPlaylist}
                    />
                  );
                }
              }}
            </SwiperSlide>
          ))}
          <Box className="swiper-pagination" sx={{ position: 'sticky', margin: '0 auto' }} />
        </Swiper>
      )}
    </MediaLibraryPlaylistLayout>
  );
};
