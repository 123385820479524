import { Dialog, DialogContent, DialogTitle, Icon, IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useAccessChatDialogTranslation } from '../../../../i18n/i18nHooks';
import { UsernamePage } from './UsernamePage';
import { TermsPage } from './TermsPage';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';

export interface AccessToChatDialogProps {
  readonly hasAcceptTerms: boolean;
  readonly checkUsernameLoading: boolean;
  readonly isUsernameValid: boolean | undefined;
  readonly onClose: () => void;
  readonly onAccessChat: (username: string) => void;
  readonly setUserNameValid: (isUsernameValid: boolean | undefined) => void;
  readonly validateUsername: (username: string) => void;
  readonly setHasAcceptTerms: (hasAcceptTerms: boolean) => void;
}

const desktopDialogStyle = {
  borderRadius: 4,
  width: '330px',
  padding: '8px 0 0 0',
};
const mobileDialogStyle = {
  borderRadius: 4,
  width: 'calc(100% - 20px)',
  maxWidth: '760px',
  margin: '44px 10px',
  padding: '8px 0 4px 0',
};

export const AccessToChatDialog = (props: AccessToChatDialogProps) => {
  const {
    onClose,
    onAccessChat,
    isUsernameValid,
    setUserNameValid,
    checkUsernameLoading,
    validateUsername,
    hasAcceptTerms,
    setHasAcceptTerms,
  } = props;
  const { t } = useAccessChatDialogTranslation();
  const { isMobileView } = useDeviceMetadata();
  const [page, setPage] = useState<'USERNAME' | 'TERMS'>('USERNAME');
  const theme = useTheme();
  const functionSetTermsPage = () => setPage('TERMS');
  const functionSetUsernamePage = () => setPage('USERNAME');

  const onTermsDecline = () => {
    setHasAcceptTerms(false);
    setPage('USERNAME');
  };

  const onTermsAccept = () => {
    setHasAcceptTerms(true);
    setPage('USERNAME');
  };

  return (
    <Dialog
      scroll="paper"
      sx={{
        width: '100%',
      }}
      fullWidth={isMobileView}
      onClose={onClose}
      open={true}
      PaperProps={{
        sx: isMobileView ? mobileDialogStyle : desktopDialogStyle,
      }}
    >
      <DialogTitle sx={{ p: '12px 20px 20px' }}>
        <Stack spacing={4} direction="row" alignItems="center" sx={{ display: 'flex' }}>
          {page === 'TERMS' && (
            <IconButton
              onClick={functionSetUsernamePage}
              size="medium"
              sx={{
                position: 'absolute',
                left: 8,
                top: 15,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Icon baseClassName="ls-light" className="ls-arrow-left" />
            </IconButton>
          )}
          <Typography
            component="span"
            variant={'h5'}
            color="secondary.dark"
            sx={{
              lineHeight: '120%',
              color: theme.components?.chatAccessDialog?.headlineColor,
            }}
          >
            {page === 'USERNAME' ? t('title') : t('terms.title')}
          </Typography>
        </Stack>
        <IconButton
          aria-label="close"
          onClick={onClose}
          size={'medium'}
          sx={{
            position: 'absolute',
            right: 8,
            top: 12,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icon sx={{ fontSize: '16px' }} baseClassName="ls-default" className="ls-close" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: '20px' }}>
        {page === 'USERNAME' ? (
          <UsernamePage
            termsAccepted={hasAcceptTerms}
            isUsernameValid={isUsernameValid}
            checkUsernameLoading={checkUsernameLoading}
            onTermsClick={functionSetTermsPage}
            onSubmitClick={onAccessChat}
            setUserNameValid={setUserNameValid}
            onTermsAccept={onTermsAccept}
            onTermsDecline={onTermsDecline}
            validateUsername={validateUsername}
          />
        ) : (
          <TermsPage />
        )}
      </DialogContent>
    </Dialog>
  );
};
