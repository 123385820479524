export interface AiSupportChatMessage {
  readonly sender: string;
  readonly input: string;
  readonly isLoading: boolean;
}

export enum AiSupportSender {
  USER = 'You',
  CHATBOT = 'Chatbot',
}

export const DEFAULT_WELCOME_MESSAGE = {
  sender: AiSupportSender.CHATBOT,
  input: '',
  isLoading: false,
};
