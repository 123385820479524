import { useEffect, useRef } from 'react';
import { DefaultProperties } from '../../../../properties/defaultProperties';
import { useWindowResize } from '../../../../utils/useWindowResize';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';

export const useScrollChatSize = (
  index: number,
  setSize: (index: number, size: number) => void,
  isLongTextToggled?: boolean,
) => {
  const { isMobileView } = useDeviceMetadata();
  const [windowWidth] = useWindowResize();
  const rowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!isMobileView || (!isMobileView && isLongTextToggled)) {
      setSize(index, rowRef.current?.getBoundingClientRect().height || DefaultProperties.chatMessageHeightDesktop);
    }
  }, [setSize, isLongTextToggled, index, windowWidth, isMobileView]);

  useEffect(() => {
    if (isMobileView || (isMobileView && isLongTextToggled)) {
      const size =
        rowRef.current && rowRef.current?.getBoundingClientRect().height > 4
          ? Math.round(rowRef.current?.getBoundingClientRect().height - 4)
          : rowRef.current?.getBoundingClientRect().height || DefaultProperties.chatMessageHeightMobile;
      setSize(index, size);
    }
  }, [setSize, index, isMobileView, isLongTextToggled]);

  return { rowRef };
};
