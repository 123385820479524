import '../../../theming/desktopSwiper.scss';
import '../../../theming/effectCarousel.scss';
import EffectCarousel from './effect-carousel.esm.js';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination } from 'swiper/modules';
import { MediaLibraryClipPlaylist, MediaLibraryShowPlayList } from '../../../graphqlClient/showRequest';
import { PlaylistType, VideoType } from '../../../graphqlClient/mediaLibraryRequest';
import { ShowCard } from '../../../components/mediaLibrary/ShowCardComponent';
import { ClipCard } from '../../../components/mediaLibrary/ClipCardComponent';
import { Box, useTheme } from '@mui/material';
import { useClientEventFunction } from '../../../middleware/clientEvent/useClientEventFunctions';
import { useMediaLibraryContext } from '../../../components/mediaLibrary/MediaLibraryProvider';

const BULLET_PAGINATION_DEFAULT_COLOR = '#515558';

export const StoryBlockSwiper = () => {
  const { selectShow } = useClientEventFunction();
  const { cardRef, playlists } = useMediaLibraryContext();

  const videoPlaylist = playlists.reduce((result: PlaylistType[], playlist) => {
    const { shows, clips } = playlist;
    return [...result, ...shows, ...clips];
  }, []);
  const reapeatedPlaylist = [...videoPlaylist, ...videoPlaylist];
  const totalVideos = videoPlaylist.length;
  const loop = !(totalVideos < 5 && totalVideos !== 1);
  const isPlaylistRepeated = totalVideos > 4 && totalVideos < 10;
  const theme = useTheme();
  const customBulletPaginationColor = theme.components?.mediaLibrary?.bulletPaginationColor;

  const swiperParameters = {
    modules: [A11y, Navigation, Pagination, EffectCarousel],
    initialSlide: totalVideos < 3 ? 1 : 2,
    breakpoints: {
      320: {
        slidesPerView: 2,
      },
      480: {
        slidesPerView: 4,
      },
    },
    centeredSlides: true,
    grabCursor: true,
    loop,
    effect: 'carousel',
    speed: 100,
    navigation: {
      prevEl: '.swiper-button-prev',
      nextEl: '.swiper-button-next',
    },
    pagination: { el: '.swiper-pagination', clickable: true },
    lazy: { enabled: true },
    watchSlidesProgress: true,
    resistanceRatio: 0.35,
    threshold: 63,
    slidesPerGroupAuto: false,
  };

  const carouselPlaylistIds = playlists.flatMap((item) => {
    if (item.type === VideoType.SHOW) {
      return item.shows.map((show) => ({ videoType: VideoType.SHOW, videoId: show.id }));
    } else if (item.type === VideoType.CLIP) {
      return item.clips.map((clip) => ({ videoType: VideoType.CLIP, videoId: clip.id }));
    } else {
      return [];
    }
  });

  const isShowPlaylist = (playlist: PlaylistType): playlist is MediaLibraryShowPlayList => {
    return (playlist as MediaLibraryShowPlayList).displayStatus !== undefined;
  };

  const handleCarouselPlaylist = (playlist: MediaLibraryShowPlayList | MediaLibraryClipPlaylist) => {
    selectShow(playlist?.id, undefined, carouselPlaylistIds, true);
  };

  return (
    <Swiper {...swiperParameters} style={{ width: '100%', height: '100%' }}>
      {(isPlaylistRepeated ? reapeatedPlaylist : videoPlaylist).map((video: PlaylistType, index: number) => {
        return (
          <SwiperSlide key={video.id + index} className="swiper-slide-aedc story-block-swiper-slide">
            {isShowPlaylist(video) ? (
              <ShowCard
                isVisible={false}
                cardRef={cardRef}
                show={video as MediaLibraryShowPlayList}
                onSelectShow={handleCarouselPlaylist}
                isStoryBlock={true}
              />
            ) : (
              <ClipCard
                cardRef={cardRef}
                clip={video as MediaLibraryClipPlaylist}
                onSelectClip={handleCarouselPlaylist}
                isStoryBlock={true}
                isVisible={false}
              />
            )}
          </SwiperSlide>
        );
      })}

      <Box
        className="swiper-pagination"
        sx={{
          position: 'sticky',
          my: '30px',
          '--swiper-pagination-color': customBulletPaginationColor || BULLET_PAGINATION_DEFAULT_COLOR,
          '--swiper-pagination-bullet-inactive-color': customBulletPaginationColor || BULLET_PAGINATION_DEFAULT_COLOR,
          ' --swiper-navigation-size': '16px',
        }}
      />
    </Swiper>
  );
};
