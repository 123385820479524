import { AnimationResult } from 'react-spring';

export enum HeartReactionSubscription {
  ConnectionInit = 'connection_init',
  ConnectionAck = 'connection_ack',
  ConnectionError = 'connection_error',
  START = 'start',
  StartAck = 'start_ack',
  DATA = 'data',
  STOP = 'stop',
  ERROR = 'error',
  KA = 'ka',
}

export type AnimatedHeartPropsType = {
  onComplete: (event: AnimationResult) => void;
  left: number;
  color: string;
  isMobile: boolean;
};

export type HeartType = {
  id: number;
  left: number;
  color: string;
};
export type HeartShape = {
  color: string;
};

export type HeartFloaterProps = {
  isVod: boolean;
  isMobile: boolean;
};
