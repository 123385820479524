import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { useEmbededViewControl } from '../../../middleware/playerState/useEmbededViewControl';
import { useShow } from '../../../middleware/video/useShow';
import { useSearchParamContext } from '../../providers/SearchParamsProvider';

interface ModernPlayerLayoutProps {
  readonly children: ReactNode;
}

export const ModernPlayerLayout = (props: ModernPlayerLayoutProps) => {
  const { children } = props;
  const { uuid } = useShow();
  const { playlist } = useSearchParamContext();
  const { isMobileView } = useDeviceMetadata();
  const { isMinimized, onMinimizePlayer } = useEmbededViewControl();
  const theme = useTheme();
  const isCarousel = playlist.length > 1;

  const getBoxStyle = (): SxProps<Theme> => {
    if (isMinimized || isMobileView) {
      return {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      };
    } else {
      return {
        backgroundColor: isCarousel ? 'transparent' : theme.components?.video?.backgrounds?.viewLayer,
        width: '100%',
        height: '100vh',
        maxHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      };
    }
  };

  const backgroundClick = () => onMinimizePlayer();

  return (
    <Box sx={getBoxStyle()} onClick={backgroundClick} id={`modern-player-container-${uuid}`}>
      {children}
    </Box>
  );
};
