import { Collapse, Grid } from '@mui/material';
import { MouseEvent } from 'react';
import { Products } from './Products';
import { PlayerProps } from '../../../../models/VideoViewLayerModels';
import { CollapseButton } from '../../../UI/CollapseButton';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';
import { useControlPanelWidgetContext } from '../../videoStream/videoControlPanel/Widget/ControlPanelProvider';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';

export const ProductGrid = (props: PlayerProps) => {
  const { isMinimized } = useEmbededViewControl();
  const { isProductListCardOpen, handleToggleProductGrid, isFeatureEnabled } = useControlPanelWidgetContext();
  const { isMobileView } = useDeviceMetadata();
  const { products, seekTo } = props;
  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (isProductListCardOpen) {
      event.stopPropagation();
    }
  };
  return isMinimized || isMobileView ? null : (
    <Grid
      onClick={handleClick}
      id={'productPanel'}
      item
      height="100%"
      width="100%"
      sx={{
        position: 'relative',
        display: 'flex',
        flex: '1',
        minWidth: '230px',
        maxWidth: '520px',
      }}
    >
      {isFeatureEnabled && (
        <CollapseButton open={isProductListCardOpen} onClick={handleToggleProductGrid} direction={'left'} />
      )}
      <Collapse
        className={isProductListCardOpen ? 'MuiHelper-fullWidth' : ''}
        classes={{
          wrapperInner: 'MuiHelper-fullWidth',
          wrapper: 'MuiHelper-fullWidth',
        }}
        orientation={'horizontal'}
        in={isProductListCardOpen}
        sx={{
          height: 1,
          transition: '150ms !important',
        }}
      >
        <Products productsList={products} seekTo={seekTo} />
      </Collapse>
    </Grid>
  );
};

ProductGrid.displayName = 'ProductsGrid';
