import { Box, Icon, IconButton, InputAdornment, alpha, useTheme } from '@mui/material';
import { StyledTooltip } from '../../../../UI/StyledTooltip';
import { AccessToChatDialog } from '../../../chat/accessToChatDialog/AccessToChatDialog';
import { StyledOutlinedInput } from '../../../../UI/StyledOutlinedInput';
import { useChatTextfied } from '../../../../../middleware/chat/useChatTextfield';
import { useIVSChatContext } from '../../../../../middleware/chat/useIVSChat';
import { useCarouselContext } from '../../../../mediaLibrary/carousel/useCarouselContext';
import { useDeviceMetadata } from '../../../../providers/DeviceMetadataProvider';
import { useShow } from '../../../../../middleware/video/useShow';

export const DesktopChatTextField = () => {
  const {
    inputRef,
    message,
    isUsernameValid,
    checkUsernameLoading,
    termsDialogOpen,
    tooltips,
    openTooltips,
    setUserNameValid,
    handleKeyDown,
    handleClick,
    onMessageClick,
    onTooltipClose,
    onAccessChat,
    validateUsername,
    functionOnClose,
    getTextFieldPlaceholder,
    onChangeMessage,
    handleCompositionEnd,
    handleCompositionStart,
  } = useChatTextfied();
  const { isVod } = useShow();
  const { hasAcceptTerms, isSocketActive, setHasAcceptTerms } = useIVSChatContext();
  const { enableCarousel, disableCarousel } = useCarouselContext();
  const { isMobileView } = useDeviceMetadata();

  const theme = useTheme();
  const mobileButtonStyles = {
    color:
      message.length > 0
        ? theme.components?.chatBox?.mobile?.button?.active
        : theme.components?.chatBox?.mobile?.button?.default,
    '&:active::before': {
      color: theme.components?.chatBox?.mobile?.button?.active,
    },
    fontSize: '1.25rem',
    overflow: 'visible',
  };

  if (!isVod) {
    return (
      <Box my={2}>
        <StyledTooltip
          title={tooltips}
          open={openTooltips}
          onClose={onTooltipClose}
          placement="top"
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [-30, -60],
                },
              },
            ],
          }}
        >
          <StyledOutlinedInput
            inputRef={inputRef}
            sx={{
              borderRadius: '26px',
              bgcolor: alpha(theme.palette.common.white, 0.7),

              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& input::placeholder': {
                fontSize: isMobileView ? '3.5vw' : '16px',
              },
            }}
            id="message-input"
            type="text"
            fullWidth={true}
            value={message}
            onChange={onChangeMessage}
            onCompositionEnd={handleCompositionEnd}
            onCompositionStart={handleCompositionStart}
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            onFocus={disableCarousel}
            onBlur={enableCarousel}
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="send message icon" onClick={onMessageClick} edge="end">
                  <Icon sx={mobileButtonStyles} className="ls-default ls-send" />
                </IconButton>
              </InputAdornment>
            }
            disabled={!isSocketActive}
            placeholder={getTextFieldPlaceholder()}
          />
        </StyledTooltip>
        {termsDialogOpen && (
          <AccessToChatDialog
            hasAcceptTerms={hasAcceptTerms}
            setHasAcceptTerms={setHasAcceptTerms}
            onClose={functionOnClose}
            onAccessChat={onAccessChat}
            isUsernameValid={isUsernameValid}
            setUserNameValid={setUserNameValid}
            checkUsernameLoading={checkUsernameLoading}
            validateUsername={validateUsername}
          />
        )}
      </Box>
    );
  } else {
    return null;
  }
};
