import { useEffect, useState } from 'react';
import { useAccessChatDialogTranslation } from '../../i18n/i18nHooks';
import { usePlayableVideoStreamContext } from '../video/usePlayableVideoStreamContext';

interface UseTooltips {
  tooltips: string;
  openTooltips: boolean;
  setOpenTootips: (openTooltips: boolean) => void;
}

export const useChatTooltip = (): UseTooltips => {
  const { t } = useAccessChatDialogTranslation();
  const { showEnded, showStarted } = usePlayableVideoStreamContext();
  const [tooltips, setTootips] = useState<string>('');
  const [openTooltips, setOpenTootips] = useState<boolean>(false);

  useEffect(() => {
    if (showStarted && !showEnded) {
      setTootips('');
    } else if (showEnded) {
      setTootips(t('tooltipChatNotAvailable'));
    } else if (!showStarted) {
      setTootips(t('tooltipChatSoonAvailable'));
    }
  }, [showEnded, showStarted, t]);

  return { tooltips, openTooltips, setOpenTootips };
};
