import { Divider, styled } from '@mui/material';

export const StyledDivider = styled(Divider)((theme) => {
  return {
    border: `1px solid ${theme.theme.palette.grey['200']} `,
    boxShadow: '6px 0px 18px 0px rgba(192, 192, 192, 0.10)',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      py: 2,
      backgroundColor: 'transparent',
      height: '1px',
    },
  };
});
