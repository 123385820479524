import { useChatTranslation } from '../../../../../../i18n/i18nHooks';
import { Icon, IconButton, InputAdornment } from '@mui/material';
import { StyledTextField } from '../../../../../UI/StyledTextField';
import { ChangeEvent, CompositionEventHandler, KeyboardEventHandler, RefObject } from 'react';

interface DesktopChatAiSupportTextFieldProps {
  readonly textInput: string;
  readonly isLoading: boolean;
  readonly textFieldRef: RefObject<HTMLInputElement | null>;
  readonly onSubmitHandler: () => void;
  readonly onKeyDownHandler: KeyboardEventHandler<HTMLInputElement>;
  readonly onChangeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  readonly onCompositionStartHandler: CompositionEventHandler<HTMLInputElement>;
  readonly onCompositionEndHandler: CompositionEventHandler<HTMLInputElement>;
}

export const DesktopChatAiSupportTextField = (props: DesktopChatAiSupportTextFieldProps) => {
  const {
    textInput,
    isLoading,
    textFieldRef,
    onChangeHandler,
    onKeyDownHandler,
    onSubmitHandler,
    onCompositionStartHandler,
    onCompositionEndHandler,
  } = props;
  const { t } = useChatTranslation();

  return (
    <StyledTextField
      autoComplete="off"
      id="web-ai-support-chat"
      type="text"
      inputRef={textFieldRef}
      sx={{ px: '12px', pb: '12px', boxSizing: 'border-box' }}
      disabled={isLoading}
      placeholder={t('aiSupport.placeholder')}
      value={textInput}
      fullWidth={true}
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      onCompositionStart={onCompositionStartHandler}
      onCompositionEnd={onCompositionEndHandler}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton edge="end" onClick={onSubmitHandler} disabled={isLoading}>
              <Icon baseClassName="ls-default" className="ls-send" sx={{ overflow: 'visible' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      inputProps={{
        maxLength: 500,
      }}
    />
  );
};
