import { useState } from 'react';
import { useToggle } from '../../../../utils/useToggle';
import { useAiSupport } from '../../../../middleware/aiAssistance/useAiSupport';
import { DesktopChatAiSupportTextField as ClassicDesktopChatAiSupportTextField } from '../../classicPlayer/chat/desktopChat/aiSupport/DesktopChatAiSupportTextField';
import { DesktopChatAiSupportTextField as ModernDesktopChatAiSupportTextField } from '../../modernPlayer/chat/desktopChat/aiSupport/DesktopChatAiSupportTextField';
import { Stack, useTheme } from '@mui/material';
import { AiResponseAlert } from './AiResponseAlert';
import { PlayerType } from '../../../../models/VideoViewLayerModels';
import { DesktopAiSupportChatBox } from './AiResponseChatBox';

export const DesktopChatAiSupportWrapper = () => {
  const [isAlertOpen, setAlertOpen] = useState(true);
  const { isToggled, setToggle } = useToggle();
  const {
    textInput,
    isLoading,
    textFieldRef,
    aiSupportMessages,
    onChangeHandler,
    onKeyDownHandler,
    onSubmitHandler,
    onCompositionStartHandler,
    onCompositionEndHandler,
  } = useAiSupport();

  const theme = useTheme();

  return (
    <Stack>
      {!isToggled && isAlertOpen && <AiResponseAlert isAlertOpen={isAlertOpen} setAlertOpen={setAlertOpen} />}

      <DesktopAiSupportChatBox
        isAiChatMessageToggled={isToggled}
        setAiChatMessageToggled={setToggle}
        aiSupportMessages={aiSupportMessages}
      />

      {theme.components?.playerDisplayStyle === PlayerType.CLASSIC ? (
        <ClassicDesktopChatAiSupportTextField
          textInput={textInput}
          isLoading={isLoading}
          textFieldRef={textFieldRef}
          onChangeHandler={onChangeHandler}
          onKeyDownHandler={onKeyDownHandler}
          onSubmitHandler={onSubmitHandler}
          onCompositionStartHandler={onCompositionStartHandler}
          onCompositionEndHandler={onCompositionEndHandler}
        />
      ) : (
        <ModernDesktopChatAiSupportTextField
          textInput={textInput}
          isLoading={isLoading}
          textFieldRef={textFieldRef}
          onChangeHandler={onChangeHandler}
          onKeyDownHandler={onKeyDownHandler}
          onSubmitHandler={onSubmitHandler}
          onCompositionStartHandler={onCompositionStartHandler}
          onCompositionEndHandler={onCompositionEndHandler}
        />
      )}
    </Stack>
  );
};
