import { useDeviceMetadata } from '../../providers/DeviceMetadataProvider';
import { useCarouselContext } from '../../mediaLibrary/carousel/useCarouselContext';
import { CircularProgress, Stack } from '@mui/material';
import { LOADING_BACKGROUND_COLOR } from '../../../theming/theme';
import PowerOnDialog from '../../UI/PowerOnDialog';

interface CircularProgressVideoProps {
  showWithErrors: boolean;
  showShowLoadingSpinner: boolean;
  onPlayHandler: () => void;
}

export const CircularProgressVideo = (props: CircularProgressVideoProps) => {
  const { showWithErrors, onPlayHandler, showShowLoadingSpinner } = props;
  const { isMobileView } = useDeviceMetadata();
  const { isCarouselEnabled } = useCarouselContext();
  const replayHandler = () => showWithErrors && onPlayHandler();
  if (showShowLoadingSpinner) {
    return (
      <Stack
        id={'loadingScreen'}
        sx={{
          position: 'absolute',
          zIndex: '2000',
          background: isCarouselEnabled && isMobileView ? 'transparent' : LOADING_BACKGROUND_COLOR,
          height: '100%',
          width: '100%',
        }}
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        onClick={replayHandler}
      >
        <CircularProgress sx={{ color: 'white' }} size={isMobileView ? '55px' : '90px'} />
        {showWithErrors && <PowerOnDialog />}
      </Stack>
    );
  } else {
    return null;
  }
};
