import React from 'react';
import { styled } from '@mui/material/styles';
import { Menu } from '@mui/material';

export const StyledMenu = styled(Menu)<{ component?: React.ElementType }>(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '2px',
    border: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: '0px 4px 24px rgba(211, 212, 213, 0.2)',
    '& .MuiMenu-list': {
      pt: '0',
      pb: '0',
      '.MuiMenuItem-root': {
        width: '148px',
        height: '35px',
        pl: '16px',
        transition: 'none',
        '&:hover': {
          backgroundColor: theme.palette.grey[200],
          transition: 'none',
        },
        '&:active': {
          backgroundColor: theme.palette.primary.light,
          transition: 'none',
        },
      },
    },
  },
}));
