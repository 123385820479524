import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ChatEventName,
  ChatMessage,
  ChatMessageType,
  IVSReturnedChat,
  IVSReturnedError,
  PinnedMsg,
  SendChatMessageEvent,
} from '../../models/chat.model';
import { useHandleMessages } from './useHandleMessages';
import { useQqlGetChatRoomTokenWithBlockedUser } from '../../graphqlClient/showRequest';
import { useCarouselContext } from '../../components/mediaLibrary/carousel/useCarouselContext';
import { useShow } from '../video/useShow';
import { useHandleChatEvent } from './useHandleChatEvent';
import { useUserIdContext } from '../../utils/userIdProvider';
import ChatroomService from '../../utils/chatroom.service';
import { useHeartbeatContex } from '../heartbeat/useHeartbeatContext';
import { UserEventType } from '../heartbeat/useHeartbeat';

const config = {
  CHAT_WEBSOCKET: 'wss://edge.ivschat.eu-west-1.amazonaws.com',
  TOKEN_EXPIRATION_IN_MINUTES: 55,
  TOKEN_REFRESH_IN_MINUTES: 45,
};

const IVSChatContext = createContext<UseIVSChatData>({} as UseIVSChatData);
export const useIVSChatContext = () => useContext(IVSChatContext);

export interface IVSChatContextProviderProps {
  readonly children: ReactNode;
}

export const IVSChatContextProvider = (props: IVSChatContextProviderProps) => {
  const { chatroomArn: chatRoomId, termsUrl } = useShow();
  const { isPlayerVisible } = useCarouselContext();
  const data = useIVSChat({ chatRoomId, termsUrl, isPlayerVisible });

  return <IVSChatContext.Provider value={data}>{props.children}</IVSChatContext.Provider>;
};

export interface UseIVSChatData {
  readonly messages: ChatMessage[];
  readonly sendMessage: (message: string) => void;
  readonly isSocketActive: boolean;
  readonly setIsSocketActive: (isSocketActive: boolean) => void;
  readonly hasAcceptTerms: boolean;
  readonly setHasAcceptTerms: (hasAcceptTerms: boolean) => void;
  readonly loginToChat: (username: string) => void;
  readonly pinnedMessage: PinnedMsg;
  readonly setPinnedMessage: Dispatch<SetStateAction<PinnedMsg>>;
  readonly termsUrl: string;
  readonly setMessages: Dispatch<SetStateAction<ChatMessage[]>>;
}

export interface UseIVSChatProps {
  readonly chatRoomId: string;
  readonly termsUrl: string;
  readonly isPlayerVisible: boolean;
  readonly messageCacheSize?: number;
}

export const useIVSChat = (props: UseIVSChatProps): UseIVSChatData => {
  const { userId } = useUserIdContext();
  const { messages, setMessages, handleMessage, handleError } = useHandleMessages();
  const defaultUsername = ChatroomService.readUsernameFromSessionStorage() ?? `guest-${userId}`;
  const recordedBlockedList = ChatroomService.readBlockUserToSessionStorage();
  const { chatRoomId, termsUrl, isPlayerVisible } = props;
  const { addLiveUserEvent } = useHeartbeatContex();
  const { isVodVideoAndChatReady } = useShow();
  const [clientIp, setClientIP] = useState<string>('');
  const [hasAcceptTerms, setHasAcceptTerms] = useState<boolean>(ChatroomService.readHasAcceptTermsFromSessionStorage());
  const [username, setUsername] = useState<string>(defaultUsername);
  const [initialized, setInitialized] = useState<boolean>(false);
  const [isSocketActive, setIsSocketActive] = useState<boolean>(false);
  const [chatToken, setChatToken] = useState<string>();
  const [refreshTimer, setRefreshTimer] = useState<NodeJS.Timeout>();
  const [pinnedMessage, setPinnedMessage] = useState<PinnedMsg>({
    pin: false,
    msg: undefined,
  });
  const [blockedList, setBlockedList] = useState<string[]>(recordedBlockedList);
  const [isSilentlyBlocked, setSilentlyBlocked] = useState(false);
  const { handleEvent } = useHandleChatEvent({ setMessages, setPinnedMessage, setBlockedList });

  const tokenQuery = useQqlGetChatRoomTokenWithBlockedUser(chatRoomId, userId, username);
  const connectionRef = useRef<WebSocket | null>(null);

  const requestTokenCallback = useCallback(() => {
    const initConnection = (token: string) => {
      const connectionInit = new WebSocket(config.CHAT_WEBSOCKET, token);
      connectionRef.current = connectionInit;

      connectionInit.onopen = () => {
        connectionRef.current?.readyState === 1 ? setIsSocketActive(true) : setIsSocketActive(false);
      };

      connectionInit.onclose = () => {
        setChatToken(undefined);
        setIsSocketActive(false);
        reconnect();
      };

      connectionInit.onerror = () => {
        setChatToken(undefined);
        setIsSocketActive(false);
        reconnect();
      };

      connectionInit.addEventListener('message', onMessage);
    };

    const initSocket = () => {
      return tokenQuery()
        .then((response) => {
          setIsSocketActive(true);
          setChatToken(response.getChatroomTokenWithBlock.token);
          initConnection(response.getChatroomTokenWithBlock.token);
          setClientIP(response.getChatroomTokenWithBlock.clientIp);
          setSilentlyBlocked(response.getChatroomTokenWithBlock.isBlocked === 'true' ? true : false);
          return response;
        })
        .catch(() => {
          setChatToken(undefined);
          setIsSocketActive(false);
          reconnect();
        });
    };

    const reconnect = () =>
      setTimeout(() => {
        if (connectionRef.current) {
          connectionRef.current.removeEventListener('message', onMessage);
          initSocket();
        }
      });

    initSocket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenQuery, setMessages, handleError, handleMessage]);

  const onMessage = useCallback((event: MessageEvent) => {
    const data: IVSReturnedChat | IVSReturnedError = JSON.parse(event.data);
    switch (data.Type) {
      case ChatMessageType.Event:
        handleEvent(data);
        break;
      case ChatMessageType.Error:
        handleError(data);
        break;
      case ChatMessageType.Message:
        handleMessage(data);
        break;
      default:
        console.error('Unknown message received:', event);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage = useCallback(
    (message: string) => {
      if (!hasAcceptTerms && username !== `guest-${userId}`) {
        return;
      }

      if (isSilentlyBlocked) {
        setMessages((prevState: ChatMessage[]) => {
          const newMessage: ChatMessage = {
            type: ChatMessageType.Message,
            timestamp: '',
            userId: userId,
            message: message,
            messageId: 'Null',
            username: username,
          };
          return [...prevState, newMessage];
        });
      } else {
        const data: SendChatMessageEvent = {
          requestId: userId,
          action: ChatEventName.SendMessage,
          content: message.toString(),
          attributes: {
            clientIp,
          },
        };
        connectionRef.current?.send(JSON.stringify(data));
        addLiveUserEvent(UserEventType.SendChatMessage);
      }
    },
    [hasAcceptTerms, username, userId, isSilentlyBlocked, setMessages, clientIp, addLiveUserEvent],
  );

  const acceptTerms = () => {
    ChatroomService.writeAcceptTermsToSessionStorage();
  };
  const changeUsername = (name: string) => {
    setUsername(name);
    ChatroomService.writeUsernameToSessionStorage(name);
  };
  const loginToChat = (username: string) => {
    acceptTerms();
    changeUsername(username);
  };

  useEffect(() => {
    if (!initialized && !isVodVideoAndChatReady && isPlayerVisible) {
      requestTokenCallback();
      setInitialized(true);
    }
  }, [initialized, isVodVideoAndChatReady, isPlayerVisible, requestTokenCallback]);

  // reconnect to chat with new username
  useEffect(() => {
    if (connectionRef?.current) {
      connectionRef.current.close();
      connectionRef.current = null;
      requestTokenCallback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    // If there is no current token, don't request a new token.
    if (chatToken === null) {
      return;
    }
    // If there's a timer that was running previously, clear it
    if (refreshTimer) {
      clearTimeout(refreshTimer);
    }

    // Request a new token after the refresh timeout has passed
    const timer = setTimeout(
      () => {
        // Close the current connection
        connectionRef.current?.close();

        // Request a new token and connect
        // requestTokenCallback();
      },
      config.TOKEN_REFRESH_IN_MINUTES * 60 * 1000,
    );

    setRefreshTimer(timer);

    // Clear the timer when the component is dismounted.
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatToken, handleMessage]);

  useEffect(() => {
    if (!isSilentlyBlocked) {
      ChatroomService.writeBlockUserToSessionStorage(JSON.stringify(blockedList));
      blockedList.forEach((blocked: string) => {
        if (blocked === clientIp) {
          setSilentlyBlocked(true);
          addLiveUserEvent(UserEventType.UserBlocked);
        }
      });
    }
  }, [addLiveUserEvent, blockedList, isSilentlyBlocked, clientIp]);

  useEffect(() => {
    if (isVodVideoAndChatReady && connectionRef.current) {
      connectionRef.current.close();
      connectionRef.current = null;
    }
  }, [isVodVideoAndChatReady]);

  return {
    messages,
    sendMessage,
    isSocketActive,
    setIsSocketActive,
    hasAcceptTerms,
    setHasAcceptTerms,
    loginToChat,
    pinnedMessage,
    setPinnedMessage,
    termsUrl,
    setMessages,
  };
};
