import { Box, Icon, Stack, SxProps, Typography, alpha, useTheme } from '@mui/material';
import { ChatBubble } from '../../../../../UI/StyledMobileChat';
import { MessageConverted } from '../../../../chat/MessageConverted';
import { AiSupportChatMessage, AiSupportSender } from '../../../../../../middleware/aiAssistance/model';
import { AiResponseLoader } from '../../../../chat/aiSupportChatCommonComponents/AiResponseLoader';
import { useScrollChatSize } from '../../../../chat/chatAutoScrolling/useScrollChatSize';

interface MobileChatMessageProps {
  readonly message: AiSupportChatMessage;
  readonly setSize: (index: number, size: number) => void;
  readonly index: number;
}

export const MobileChatAiSupportMessage = (props: MobileChatMessageProps) => {
  const { message, setSize, index } = props;
  const { rowRef } = useScrollChatSize(index, setSize);
  const isChatbot = message.sender === AiSupportSender.CHATBOT;
  const theme = useTheme();

  const sxChatBubble: SxProps = {
    maxWidth: 'fit-content',
    background: isChatbot ? alpha(theme.palette.primary.main, 0.7) : alpha(theme.palette.text.primary, 0.7),
    my: 1,
  };

  const sxChatBox: SxProps = {
    display: 'flex',
    flexWrap: 'wrap',
  };

  const sxChatBubbleName: SxProps = {
    display: 'inline',
    mr: '8px',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    lineHeight: '130%',
    color: isChatbot ? '#FFFFFF' : theme.components?.chatMessage?.mobile?.user?.nameColor,
  };

  const sxProductLinkMessage: SxProps = {
    marginRight: '4px',
    textDecoration: isChatbot ? 'hover' : 'none',
    pointerEvents: isChatbot ? 'auto' : 'none',
    fontSize: '14px',
    lineHeight: '130%',
    color: isChatbot
      ? theme.components?.chatMessage?.mobile?.moderator?.linkColor
      : theme.components?.chatMessage?.mobile?.user?.linkColor,
  };

  const sxTextMessage: SxProps = {
    textTransform: 'none',
    marginRight: '4px',
    wordBreak: 'break-word',
    fontSize: '14px',
    lineHeight: '130%',
    display: 'inline',
    color: isChatbot ? '#FFFFFF' : theme.components?.chatMessage?.mobile?.user?.textColor,
  };

  return (
    <Stack ref={rowRef} direction="row" spacing={1}>
      {isChatbot && (
        <Icon
          baseClassName="ls-default"
          className="ls-chatbot"
          sx={{
            color: theme.palette.primary.main,
            overflow: 'visible',
          }}
        />
      )}
      <ChatBubble sx={sxChatBubble}>
        <Box sx={sxChatBox}>
          <Typography component="span" sx={sxChatBubbleName}>
            {message.sender}
          </Typography>
          {message.isLoading ? (
            <AiResponseLoader />
          ) : (
            <MessageConverted
              message={message.input}
              isAiSupportMessage={true}
              isModerator={isChatbot}
              isProductLinkMessageClass={true}
              sxProductLinkMessage={sxProductLinkMessage}
              sxTextMessage={sxTextMessage}
              rel="noopener noreferrer"
            />
          )}
        </Box>
      </ChatBubble>
    </Stack>
  );
};
