import { useShow } from '../../../../middleware/video/useShow';
import { useState } from 'react';
import { useSearchParamContext } from '../../../providers/SearchParamsProvider';
import { useAddToCalendarTranslation } from '../../../../i18n/i18nHooks';
import { Box, Icon, ListItemIcon, ListItemText, MenuItem } from '@mui/material';
import { StyledButton } from '../../../UI/StyledButton';
import { StyledMenu } from '../../../UI/StyledMenu';
import { AppleCalendarIcon, GoogleCalendarIcon, OutlookCalendarIcon } from './AddToCalendarButtonIcons';
import { CalendarOptions, GoogleCalendar, ICalendar, OutlookCalendar } from 'datebook';
import fs from 'file-saver';

interface Calendar {
  calendar: string;
  link: string;
  icon: JSX.Element;
}

const ONE_HOUR = 60 * 60 * 1000;
export const AddToCalendarButton = () => {
  const { showtime, title, description } = useShow();
  const { hostUrl } = useSearchParamContext();
  const { t } = useAddToCalendarTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const eventName = title;
  const eventLocation = '';
  const eventUrl = hostUrl?.href || '';
  const encodedURL = encodeURIComponent(eventUrl);
  const eventStartTime = new Date(showtime);
  const eventEndTime = new Date(eventStartTime.getTime() + ONE_HOUR);
  const eventAttendees = [''];
  const eventDescription = '';
  const googleEventDescription = `<b>${description}</b></br></br> <a href="${eventUrl}">${eventUrl} </a>`;
  const outLookEventDescription = `<b>${description}</b></br></br> <a href="${encodedURL}">${encodedURL} </a>`;
  const icsEventDescription = `${description} ${eventUrl}`;

  const eventConfig: CalendarOptions = {
    title: eventName,
    description: eventDescription,
    location: eventLocation,
    start: eventStartTime,
    end: eventEndTime,
    attendees: [{ name: eventAttendees[0], email: '' }],
  };

  const iCalendar = new ICalendar({ ...eventConfig, description: icsEventDescription });
  const googleCalendar = new GoogleCalendar({ ...eventConfig, description: googleEventDescription });
  const outlookCalendar = new OutlookCalendar({ ...eventConfig, description: outLookEventDescription });

  const CALENDAR_OPTION: Calendar[] = [
    { calendar: 'Google Calendar', link: googleCalendar.render(), icon: <GoogleCalendarIcon /> },
    { calendar: 'Apple', link: iCalendar.render(), icon: <AppleCalendarIcon /> },
    { calendar: 'Outlook', link: outlookCalendar.render(), icon: <OutlookCalendarIcon /> },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleSelectCalendar = (cal: Calendar) => {
    let blob;
    switch (cal.calendar) {
      case 'Google Calendar':
        window.open(cal.link, '_blank', 'noopener noreferrer');
        break;
      case 'Apple':
        blob = new Blob([cal.link], {
          type: 'text/calendar',
        });
        fs.saveAs(blob, `${eventName}.ics`);
        break;
      case 'Outlook':
        window.open(cal.link, '_blank', 'noopener noreferrer');
        break;
      default:
        break;
    }
    handleClose();
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'absolute',
        display: 'flex',
        left: '50%',
        top: '60%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
      }}
    >
      <StyledButton
        id="add-to-calendar-button"
        aria-controls={open ? 'calendar-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        color="secondary"
        startIcon={<Icon className="ls-default ls-calendar" />}
        sx={{ py: '8.5px', minWidth: '200px', margin: '0 auto' }}
      >
        {t('title')}
      </StyledButton>
      <StyledMenu
        sx={{ mt: 0.5, '& .MuiPaper-root': { borderRadius: '4px', border: 'none' } }}
        id="calendar-menu"
        MenuListProps={{
          'aria-labelledby': 'add-to-calendar-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {CALENDAR_OPTION.map((option: Calendar) => (
          <MenuItem
            key={option.calendar}
            onClick={() => handleSelectCalendar(option)}
            disableRipple
            sx={{ minWidth: '200px' }}
          >
            <ListItemIcon>{option.icon}</ListItemIcon>
            <ListItemText> {option.calendar}</ListItemText>
          </MenuItem>
        ))}
      </StyledMenu>
    </Box>
  );
};
