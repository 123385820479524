import { ChangeEvent, CompositionEventHandler, KeyboardEventHandler, RefObject, useRef, useState } from 'react';
import { useIVSChatContext } from './useIVSChat';
import { useQqlValidateUserName } from '../../graphqlClient/showRequest';
import { usePlayableVideoStreamContext } from '../video/usePlayableVideoStreamContext';
import { useChatTooltip } from './useChatTooltip';
import { useShow } from '../video/useShow';
import { useChatTranslation } from '../../i18n/i18nHooks';
import { useCarouselContext } from '../../components/mediaLibrary/carousel/useCarouselContext';
import { useDeviceMetadata } from '../../components/providers/DeviceMetadataProvider';
import { useHeartbeatContex } from '../heartbeat/useHeartbeatContext';
import ChatroomService from '../../utils/chatroom.service';
import { UserEventType } from '../heartbeat/useHeartbeat';

interface UseChatTextfied {
  inputRef: RefObject<HTMLInputElement>;
  message: string;
  tooltips: string;
  openTooltips: boolean;
  termsDialogOpen: boolean;
  isUsernameValid: boolean | undefined;
  checkUsernameLoading: boolean;
  setUserNameValid: (isUsernameValid: boolean | undefined) => void;
  handleClick: () => void;
  handleKeyDown: KeyboardEventHandler<HTMLInputElement>;
  onMessageClick: () => void;
  validateUsername: (username: string) => Promise<void>;
  onAccessChat: (username: string) => void;
  onTooltipClose: () => void;
  functionOnClose: () => void;
  getTextFieldPlaceholder: () => string;
  onChangeMessage: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCompositionEnd: CompositionEventHandler<HTMLInputElement>;
  handleCompositionStart: CompositionEventHandler<HTMLInputElement>;
}

export const useChatTextfied = (): UseChatTextfied => {
  const { t } = useChatTranslation();
  const { id: activeShowId } = useShow();
  const { showStarted } = usePlayableVideoStreamContext();
  const { addLiveUserEvent, setheartbaetUsername, heartbeatUsername } = useHeartbeatContex();
  const { getValidUserName } = useQqlValidateUserName();
  const { tooltips, openTooltips, setOpenTootips } = useChatTooltip();
  const { isSocketActive, hasAcceptTerms, loginToChat, sendMessage, setIsSocketActive } = useIVSChatContext();
  const { enableCarousel } = useCarouselContext();
  const { isMobileView } = useDeviceMetadata();

  const [message, setMessage] = useState<string>('');
  const [termsDialogOpen, setTermsDialogOpen] = useState<boolean>(false);
  const [isComposing, setIsComposing] = useState(false);
  const [isUsernameValid, setUserNameValid] = useState<boolean | undefined>(
    ChatroomService.readUsernameFromSessionStorage() ? true : undefined,
  );
  const [checkUsernameLoading, setCheckUsernameLoading] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeMessage = (event: ChangeEvent<HTMLInputElement>) => setMessage(event.target.value);

  const handleClick = () => {
    if (!hasAcceptTerms || !isUsernameValid) {
      setUserNameValid(undefined);
      setTermsDialogOpen(true);
    }
  };

  const handleButtonClick = () => {
    if (inputRef.current) inputRef.current.focus();
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter' && !isComposing) onMessageClick();
  };

  const handleCompositionStart: CompositionEventHandler<HTMLInputElement> = () => setIsComposing(true);

  const handleCompositionEnd: CompositionEventHandler<HTMLInputElement> = () => setIsComposing(false);

  const onMessageClick = () => {
    if (showStarted) {
      const username = ChatroomService.readUsernameFromSessionStorage();
      if (username !== null) {
        const isContentEmpty = message.trim() === '';
        if (!isContentEmpty) {
          isMobileView && enableCarousel();
          sendMessage(message);
          setMessage('');
          handleButtonClick();
        } else {
          setMessage('');
        }
      } else {
        handleClick();
      }
    } else {
      setOpenTootips(true);
      setMessage('');
    }
  };

  const validateUsername = async (username: string) => {
    if (username) {
      setCheckUsernameLoading(true);
      await getValidUserName(activeShowId, username.trim())
        .then((validation) => {
          setUserNameValid(validation);
          setCheckUsernameLoading(false);
          return validation;
        })
        .catch(() => {
          setUserNameValid(false);
          setCheckUsernameLoading(false);
        });
    } else {
      setUserNameValid(false);
    }
  };

  const onAccessChat = async (username: string) => {
    const trimmedUsername = username.trim();
    setIsSocketActive(false);
    setTermsDialogOpen(false);
    setCheckUsernameLoading(false);
    setheartbaetUsername(trimmedUsername);
    loginToChat(trimmedUsername);
    await addLiveUserEvent(UserEventType.AcceptTerms);
  };

  const onTooltipClose = () => {
    if (!showStarted) {
      setOpenTootips(false);
    }
  };
  const functionOnClose = () => {
    setTermsDialogOpen(false);
    setUserNameValid(false);
  };

  const getTextFieldPlaceholder = (): string => {
    const storedHasAcceptTerms = ChatroomService.readHasAcceptTermsFromSessionStorage();
    const isUsernameSelected = heartbeatUsername !== '';
    if (!isSocketActive) {
      return t('connecting');
    } else if (!isUsernameSelected && !storedHasAcceptTerms) {
      return t('chooseNickname');
    } else {
      return t('writeMessage');
    }
  };

  return {
    inputRef,
    message,
    tooltips,
    openTooltips,
    termsDialogOpen,
    isUsernameValid,
    checkUsernameLoading,
    setUserNameValid,
    handleClick,
    handleKeyDown,
    handleCompositionEnd,
    handleCompositionStart,
    onMessageClick,
    validateUsername,
    onAccessChat,
    onTooltipClose,
    functionOnClose,
    getTextFieldPlaceholder,
    onChangeMessage,
  };
};
