import { PlayerType } from '../../../../../models/VideoViewLayerModels';
import { Icon, IconButton, useTheme } from '@mui/material';
import { useControlPanelWidgetContext } from './ControlPanelProvider';

interface ShareWidgetProps {
  readonly disableClick?: boolean;
}

export const ShareWidget = ({ disableClick }: ShareWidgetProps) => {
  const { handleOpenShareModalDialog } = useControlPanelWidgetContext();
  const theme = useTheme();
  const playerType = theme.components?.playerDisplayStyle;

  return (
    <IconButton size="small" onClick={disableClick ? undefined : () => handleOpenShareModalDialog()}>
      <Icon
        fontSize="small"
        className={playerType === PlayerType.MODERN ? 'ls-default ls-share-nodes-solid' : 'ls-default ls-share'}
        sx={{
          px: 0.5,
          color: playerType === PlayerType.MODERN ? theme.palette.common.white : theme.palette?.text?.primary,
        }}
      />
    </IconButton>
  );
};
