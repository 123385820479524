import { isLinkCheck, parseUrls } from '../../../middleware/chat/useHandleMessages';
import { ProductLinkMessage } from './ProductLinkMessage';
import { Icon, SxProps, Typography } from '@mui/material';

interface MessageConvertedProps {
  rel?: string;
  message: string;
  sxProductLinkMessage: SxProps;
  sxTextMessage: SxProps;
  isModerator?: boolean;
  isProductLinkMessageClass?: boolean;
  isAiSupportMessage?: boolean;
  isLongTextToggled?: boolean;
}

const MAX_CHARACTER_LENGTH = 80;

export const MessageConverted = (props: MessageConvertedProps) => {
  const {
    rel,
    message,
    isModerator,
    isProductLinkMessageClass,
    isLongTextToggled,
    isAiSupportMessage,
    sxProductLinkMessage,
    sxTextMessage,
  } = props;
  const formattedMessage = parseUrls(message);
  const words = formattedMessage.split(' ');
  const linkRel = rel ? rel : 'noopener';
  const ProductLinkMessageClass = isProductLinkMessageClass ? 'chat-line__link' : undefined;

  const collapseLongTextButton = () => (
    <Icon
      className="ls-default"
      baseClassName={`ls-chevron-${isLongTextToggled ? 'left' : 'right'}`}
      color="primary"
      sx={{ fontSize: '14px', ml: 1, lineHeight: 1 }}
    />
  );

  const isMessageClickable = words.some((word) => isModerator && isLinkCheck(word) && !word.startsWith('@'));

  const clickableMessage = () => {
    return (
      <>
        {words.map((word, index) => {
          const renderMessageIsClickable = isModerator && isLinkCheck(word) && !word.startsWith('@');

          if (renderMessageIsClickable) {
            return (
              <ProductLinkMessage
                url={word}
                key={index}
                rel={linkRel}
                className={ProductLinkMessageClass}
                sx={sxProductLinkMessage}
              />
            );
          } else {
            return (
              <Typography key={index} sx={sxTextMessage}>
                {word}
              </Typography>
            );
          }
        })}
      </>
    );
  };

  const defaultMessage = () => {
    if (message.length > MAX_CHARACTER_LENGTH && !isAiSupportMessage) {
      if (isLongTextToggled) {
        return (
          <Typography sx={sxTextMessage}>
            {message}
            {collapseLongTextButton()}
          </Typography>
        );
      } else {
        return (
          <Typography sx={{ ...sxTextMessage, lineHeight: 1 }}>
            {props.message.slice(0, MAX_CHARACTER_LENGTH) + '...'}
            {collapseLongTextButton()}
          </Typography>
        );
      }
    } else {
      return <Typography sx={sxTextMessage}>{message}</Typography>;
    }
  };

  return isMessageClickable ? clickableMessage() : defaultMessage();
};
