import { Box } from '@mui/material';
import { useIVSChatContext } from '../../../../middleware/chat/useIVSChat';
import { useEffect, useState } from 'react';

export const TermsPage = () => {
  const { termsUrl } = useIVSChatContext();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [termsHtml, setTermsHtml] = useState<any>();

  useEffect(() => {
    fetch(termsUrl)
      .then(function (response) {
        return response.text();
      })
      .then(function (html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const docArticle = doc.querySelector('body')?.innerHTML;
        setTermsHtml(docArticle);
        return html;
      })
      .catch(function (err) {
        console.log('Failed to fetch page: ', err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Box component="div" dangerouslySetInnerHTML={{ __html: termsHtml }} />;
};
