import { PlayerState } from 'amazon-ivs-player';
import { ClipCard, LiveCard, RecordedCard, UpcomingCard } from '../../../UI/StyledCard';
import { useShow } from '../../../../middleware/video/useShow';
import { memo } from 'react';
import { Status } from '../../../../enums/show-status.enum';

export const VideoLabel = memo((props: { streamingState: PlayerState }) => {
  const { isClip, isVod, status } = useShow();
  const { streamingState } = props;
  const showClipCard = isClip;
  const showRecordedCard = isVod && (streamingState === PlayerState.PLAYING || streamingState === PlayerState.IDLE);
  const showLiveCard = streamingState === PlayerState.PLAYING;
  const showUpcomingCard =
    !isVod && status !== Status.READY && status !== Status.PAUSED && streamingState === PlayerState.IDLE;

  if (showClipCard) {
    return <ClipCard />;
  } else if (showRecordedCard) {
    return <RecordedCard />;
  } else if (showLiveCard) {
    return <LiveCard />;
  } else if (showUpcomingCard) {
    return <UpcomingCard />;
  } else {
    return null;
  }
});

VideoLabel.displayName = 'VideoLabel';
