import { MediaPlayer } from 'amazon-ivs-player';
import { RefObject, useCallback, useMemo, useState } from 'react';
import { useCarouselContext } from '../../components/mediaLibrary/carousel/useCarouselContext';
import { usePlayableVideoStreamContext } from './usePlayableVideoStreamContext';
import { useShow } from './useShow';
import { useSearchParamContext } from '../../components/providers/SearchParamsProvider';

export const usePlayerControls = (player: RefObject<MediaPlayer>) => {
  const { isCarouselPlayerMuted, unmuteIconRef } = useCarouselContext();
  const { isSharedVideo } = useSearchParamContext();
  const [muted, setMuted] = useState(false);
  const [loading, setLoading] = useState(true);
  const { setShowPaused } = usePlayableVideoStreamContext();
  const { isClip } = useShow();

  const onMuteChange = useCallback(
    (mute: boolean) => {
      if (!player.current) return;
      const muteNext = mute || !player.current.isMuted();
      player.current.setMuted(muteNext);
      setMuted(muteNext);
      if (isCarouselPlayerMuted) isCarouselPlayerMuted.current = muteNext;
      unmuteIconRef.current = false;
    },
    [player, isCarouselPlayerMuted, unmuteIconRef],
  );

  const play = useCallback(() => {
    if (!player.current) return;
    player.current.play();
    setShowPaused(false);
  }, [player, setShowPaused]);

  const pause = useCallback(() => {
    if (!player.current) return;
    player.current.pause();
    setShowPaused(true);
  }, [player, setShowPaused]);

  const seekTo = useCallback(
    (position: number) => {
      if (!player.current) return;
      player.current.seekTo(position);
    },
    [player],
  );

  const resetControls = useCallback(() => {
    if (isSharedVideo) {
      setMuted(true);
      return;
    }

    if (isCarouselPlayerMuted?.current !== null && isCarouselPlayerMuted) {
      setMuted(isCarouselPlayerMuted?.current);
    } else {
      const defaultMuteState = isClip ? false : true;
      setMuted(defaultMuteState);
      if (isCarouselPlayerMuted?.current !== undefined) {
        isCarouselPlayerMuted.current = defaultMuteState;
      }
    }

    setShowPaused(false);
    setLoading(true);
  }, [isCarouselPlayerMuted, isClip, setShowPaused, isSharedVideo]);

  return useMemo(
    () => ({
      loading,
      muted,
      setMuted,
      onMuteChange,
      play,
      pause,
      setLoading,
      seekTo,
      resetControls,
    }),
    [loading, muted, pause, play, resetControls, onMuteChange, seekTo],
  );
};
