import { Box, ClickAwayListener, Collapse, Stack } from '@mui/material';
import { DraggablePanel, PanelHandle, PanelPaper, PanelTitle } from '../../../UI/StyledMobilePanel';
import { useTranslation } from 'react-i18next';
import { ItemList } from './ItemList';
import { ProductApiResponse } from '../../../../models/show.model';
import { useDeviceMetadata } from '../../../providers/DeviceMetadataProvider';
import { useRef } from 'react';
import { useControlPanelWidgetContext } from '../../videoStream/videoControlPanel/Widget/ControlPanelProvider';

interface MobileProductProps {
  products: ProductApiResponse[];
  seekTo: (time: number) => void;
}

export const MobileProductLayout = (props: MobileProductProps) => {
  const { seekTo, products } = props;
  const { handleToggleProductGrid, isProductListCardOpen } = useControlPanelWidgetContext();
  const { isMobileView } = useDeviceMetadata();
  const { t } = useTranslation();
  const nodeRef = useRef(null);

  return (
    <ClickAwayListener onClickAway={() => isProductListCardOpen && handleToggleProductGrid()} touchEvent="onTouchStart">
      <Collapse
        className={isProductListCardOpen ? 'MuiHelper-fullWidth' : ''}
        classes={{
          wrapperInner: 'MuiHelper-fullWidth',
          wrapper: 'MuiHelper-fullWidth',
        }}
        in={isProductListCardOpen}
        sx={{
          width: '100%',
          bottom: 0,
          left: 0,
          zIndex: 1000,
          transition: '150ms !important',
          position: 'absolute',
          overflowY: 'auto',
        }}
      >
        <DraggablePanel nodeRef={nodeRef} onToggle={handleToggleProductGrid}>
          <PanelPaper ref={nodeRef} sx={{ borderRadius: '16px 16px 0px 0px', maxHeight: '80vh' }}>
            <PanelHandle />
            <PanelTitle>{t('product.product')}</PanelTitle>
            {products.length > 0 ? (
              <Stack className="clickable" sx={{ overflowY: 'auto', mx: '26px' }}>
                {products?.map((products: ProductApiResponse) => (
                  <ItemList
                    key={products.id}
                    item={products}
                    seekTo={seekTo}
                    onProductListToggle={handleToggleProductGrid}
                  />
                ))}
              </Stack>
            ) : (
              <Box
                sx={{
                  height: '25vh',
                  alignItems: 'center',
                  mt: '5vh',
                  mx: '26px',
                  textAlign: 'center',
                  fontSize: '18px',
                  fontWeight: '400',
                }}
              >
                {isMobileView ? t('product.noProduct') : ''}
              </Box>
            )}
          </PanelPaper>
        </DraggablePanel>
      </Collapse>
    </ClickAwayListener>
  );
};
