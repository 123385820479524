import { Stack, Typography, useTheme } from '@mui/material';
import { useDeviceMetadata } from '../providers/DeviceMetadataProvider';
import { ReactNode } from 'react';

interface PlaylistsLayoutPros {
  readonly children: ReactNode;
  readonly playlistTitle: string;
}

export const MediaLibraryPlaylistLayout = (props: PlaylistsLayoutPros) => {
  const { playlistTitle, children } = props;
  const { isMobileView } = useDeviceMetadata();
  const theme = useTheme();
  return (
    <Stack direction="column" width="100%" height="100%" alignItems="flex-start" mb={isMobileView ? 3 : 3.75}>
      <Typography
        mb={isMobileView ? 2 : 3}
        mr={isMobileView ? 1.5 : undefined}
        fontSize={theme?.typography?.playlistTitle?.fontSize}
        fontFamily={theme?.typography?.playlistTitle?.fontFamily}
        fontWeight={700}
        textAlign="left"
        color={theme?.components?.mediaLibrary?.playlistTitleColor}
      >
        {playlistTitle}
      </Typography>
      {children}
    </Stack>
  );
};
