import { Card, CardActionArea, CardMedia, Icon, Link, Stack, Typography, useTheme } from '@mui/material';
import { ProductApiResponse } from '../../../../models/show.model';
import { useEmbededViewControl } from '../../../../middleware/playerState/useEmbededViewControl';
import { useSearchParamContext } from '../../../providers/SearchParamsProvider';

interface Props {
  readonly item: ProductApiResponse;
}

export const HighlightedProductsCard = (props: Props) => {
  const { item } = props;
  const { onClickProduct } = useEmbededViewControl();
  const { useNativePipPlayer } = useSearchParamContext();
  const handleProductClick = () => onClickProduct(item);
  const theme = useTheme();

  return (
    <Link
      onClick={handleProductClick}
      href={useNativePipPlayer ? item.url : undefined}
      target="_blank"
      rel="noopener noreferrer"
      underline="none"
    >
      <Card
        elevation={0}
        sx={{
          boxSizing: 'border-box',
          maxWidth: '172px',
          ml: '6px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '8px',
          borderRadius: theme.components?.highlightedItems?.cardBorderRadius,
        }}
      >
        <CardActionArea
          sx={{
            p: theme.components?.highlightedItems?.cardPadding,
            height: '66px',
          }}
        >
          <Stack direction="row" alignItems="center" justifyContent="flex-start" spacing={1}>
            {item?.image ? (
              <CardMedia
                sx={{
                  maxWidth: '58px',
                  maxHeight: '58px',
                  width: 'auto',
                  objectFit: 'contain',
                  borderRadius: theme.components?.highlightedItems?.imageBorderRadius,
                }}
                component="img"
                title=""
                src={item?.image}
              />
            ) : (
              <Icon baseClassName="ls-default ls-image" sx={{ fontSize: '64px' }} />
            )}

            <Stack
              width={0}
              sx={{
                flex: 1,
                width: 'max-content',
                overflow: 'auto',
              }}
              direction="column"
              spacing={1}
            >
              <Typography
                fontWeight={700}
                fontSize={'11px'}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '3',
                  WebkitBoxOrient: 'vertical',
                  width: '100%',
                  textTransform: 'none',
                  color: theme.components?.highlightedItems?.name,
                  lineHeight: '130%',
                }}
              >
                {item?.title}
              </Typography>
              <Typography
                noWrap
                fontWeight={400}
                fontSize={'11px'}
                sx={{
                  textTransform: 'none',
                  mt: '2px !important',
                  color: theme.components?.highlightedItems?.brand,
                  lineHeight: '130%',
                }}
              >
                {item?.brand}
              </Typography>
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>
    </Link>
  );
};
