/* eslint-disable react/prop-types */
import { ListChildComponentProps, areEqual } from 'react-window';
import { Box, useTheme } from '@mui/material';
import { PlayerType } from '../../../models/VideoViewLayerModels';
import { ChatMessageType } from '../../../models/chat.model';
import { DesktopChatRepliedMessage as ModernPlayerDesktopChatRepliedMessage } from '../modernPlayer/chat/desktopChat/DesktopChatRepliedMessage';
import { DesktopChatDefaultMessage as ModernPlayerDesktopChatDefaultMessage } from '../modernPlayer/chat/desktopChat/DesktopChatDefaultMessage';
import { DesktopChatRepliedMessage as ClassicPlayerDesktopChatRepliedMessage } from '../classicPlayer/chat/desktopChat/DesktopChatRepliedMessage';
import { DesktopDefaultMessage as ClassicPlayerDesktopChatDefaultMessage } from '../classicPlayer/chat/desktopChat/DesktopChatDefaultMessage';
import { MobileChatRepliedMessage as ModernPlayerMobileChatRepliedMessage } from '../modernPlayer/chat/mobileChat/MobileChatRepliedMessage';
import { MobileChatDefaultMessage as ModernPlayerMobileChatDefaultMessage } from '../modernPlayer/chat/mobileChat/MobileChatDefaultMessage';
import { MobileChatRepliedMessage as ClassicPlayerMobileChatRepliedMessage } from '../classicPlayer/chat/mobileChat/MobileChatRepliedMessage';
import { MobileChatDefaultMessage as ClassicPlayerMobileChatDefaultMessage } from '../classicPlayer/chat/mobileChat/MobileChatDefaultMessage';
import { useScrollChatContext } from './chatAutoScrolling/useScrollChatContext';
import { memo } from 'react';

export const DesktopChatRows = memo(({ data, index, style }: ListChildComponentProps) => {
  const { setSize } = useScrollChatContext();
  const { items, toggleLongText } = data;
  const theme = useTheme();

  const ModernPlayerDesktopChatMessage = () => {
    switch (items[index]?.type) {
      case ChatMessageType.ReplyMessage:
        return (
          <ModernPlayerDesktopChatRepliedMessage
            toggleLongText={toggleLongText}
            data={items[index]}
            setSize={setSize}
            index={index}
          />
        );
      case ChatMessageType.Message:
        return (
          <ModernPlayerDesktopChatDefaultMessage
            data={items[index]}
            setSize={setSize}
            index={index}
            toggleLongText={toggleLongText}
          />
        );
      default:
        console.info('Received unsupported message:', data);
        return null;
    }
  };

  const ClassicPlayerDesktopChatMessage = () => {
    switch (items[index]?.type) {
      case ChatMessageType.ReplyMessage:
        return (
          <ClassicPlayerDesktopChatRepliedMessage
            toggleLongText={toggleLongText}
            data={items[index]}
            index={index}
            setSize={setSize}
          />
        );
      case ChatMessageType.Message:
        return (
          <ClassicPlayerDesktopChatDefaultMessage
            data={items[index]}
            index={index}
            setSize={setSize}
            toggleLongText={toggleLongText}
          />
        );
      default:
        console.info('Received unsupported message:', items);
        return null;
    }
  };

  return (
    <Box key={index} sx={{ ...style }}>
      {theme.components?.playerDisplayStyle === PlayerType.MODERN
        ? ModernPlayerDesktopChatMessage()
        : ClassicPlayerDesktopChatMessage()}
    </Box>
  );
}, areEqual);

DesktopChatRows.displayName = 'DesktopChatRows';

export const MobileChatRow = memo(({ data, index, style }: ListChildComponentProps) => {
  const { setSize } = useScrollChatContext();
  const { items, toggleLongText } = data;
  const theme = useTheme();

  const ModernPlayerMobileChatMessage = () => {
    switch (items[index]?.type) {
      case ChatMessageType.ReplyMessage:
        return (
          <ModernPlayerMobileChatRepliedMessage
            toggleLongText={toggleLongText}
            data={items[index]}
            setSize={setSize}
            index={index}
          />
        );
      case ChatMessageType.Message:
        return (
          <ModernPlayerMobileChatDefaultMessage
            data={items[index]}
            setSize={setSize}
            index={index}
            toggleLongText={toggleLongText}
          />
        );
      default:
        console.info('Received unsupported message:', data);
        return null;
    }
  };

  const ClassicPlayerMobileChatMessage = () => {
    switch (items[index]?.type) {
      case ChatMessageType.ReplyMessage:
        return (
          <ClassicPlayerMobileChatRepliedMessage
            toggleLongText={toggleLongText}
            data={items[index]}
            setSize={setSize}
            index={index}
          />
        );
      case ChatMessageType.Message:
        return (
          <ClassicPlayerMobileChatDefaultMessage
            data={items[index]}
            setSize={setSize}
            index={index}
            toggleLongText={toggleLongText}
          />
        );
      default:
        console.info('Received unsupported message:', data);
        return null;
    }
  };

  return (
    <Box
      key={index}
      sx={{
        ...style,
        width: theme.components?.playerDisplayStyle === PlayerType.MODERN ? '100%' : '80%',
      }}
    >
      {theme.components?.playerDisplayStyle === PlayerType.MODERN
        ? ModernPlayerMobileChatMessage()
        : ClassicPlayerMobileChatMessage()}
    </Box>
  );
}, areEqual);

MobileChatRow.displayName = 'MobileChatRow';
