import { PlayerZIndex } from '../../../../models/VideoViewLayerModels';

export const styles = {
  constainer: {
    position: 'absolute',
    display: 'flex',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: PlayerZIndex.ABOVE,
    color: 'common.white',
    flexDirection: 'column',
  },
  minimized: {
    fontWeight: 700,
    fontSize: '22px',
    lineHeight: '31px',
    alignItems: 'center',
    textAlign: 'center',
    width: '100vh',
  },
  desktop: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '39px',
    alignItems: 'center',
    textAlign: 'center',
  },
  boxMinimized: {
    whiteSpace: 'break-spaces',
    width: '50%',
    margin: 'auto',
  },
  boxDesktop: {
    whiteSpace: 'nowrap',
  },
  countDownTitleDesktop: {
    display: 'flex',
    lineHeight: '130%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontWeight: 700,
    fontSize: '18px',
    color: 'common.white',
  },
  countDownTitleMobile: {
    whiteSpace: 'normal',
    overflow: 'hidden',
    display: 'flex',
    lineHeight: '130%',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '18px',
    color: 'common.white',
  },
};
