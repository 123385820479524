import { PlayerState } from 'amazon-ivs-player';
import { CircularProgressVideo } from './CircularProgressVideo';
import { VideoAndControlsContainer } from './VideoAndControlsContainer';
import { Video } from '../../UI/VideoElement';
import { RotateVideoHint } from '../modernPlayer/RotateVideoHint';
import { usePlayableVideoStreamContext } from '../../../middleware/video/usePlayableVideoStreamContext';
import { useCallback, useEffect } from 'react';
import { useShow } from '../../../middleware/video/useShow';
import { useCarouselContext } from '../../mediaLibrary/carousel/useCarouselContext';

interface PlayableVideoStreamProps {
  readonly controls: boolean;
  readonly muted: boolean;
  readonly position: number;
  readonly streamingState: PlayerState;
  readonly pause: () => void;
  readonly play: () => void;
  readonly seekTo: (position: number) => void;
  readonly onVideoRefInit: (videoElement: HTMLVideoElement) => void;
}

export const PlayableVideoStream = (props: PlayableVideoStreamProps) => {
  const { muted, play, controls, streamingState, onVideoRefInit } = props;
  const { isVideoUpcoming, isVideoSeeking, firstLoading, showWithErrors, setShowStarted, setShowEnded, setShowPaused } =
    usePlayableVideoStreamContext();
  const { isCarouselPlayerMuted } = useCarouselContext();
  const { isVod, status, uuid } = useShow();

  const onPlayHandler = useCallback(() => play(), [play]);
  const isUpcoming = status === 'ready';
  const isEnded = status === 'ended';
  const isCancel = status === 'cancel';
  const isPaused = status === 'paused';

  const isPlayerNotPlaying = streamingState !== PlayerState.PLAYING && streamingState !== PlayerState.BUFFERING;
  const isVodLoaded = firstLoading && isVod;
  const isLiveStreamPaused = !isVod && isPaused;
  const isLiveStreamUpcoming = !isVod && firstLoading && !(isUpcoming && isPlayerNotPlaying && !isEnded && !isCancel);
  const showShowLoadingSpinner = ((isVodLoaded || isLiveStreamUpcoming) && !isLiveStreamPaused) || showWithErrors;

  const isMuted =
    isCarouselPlayerMuted && isCarouselPlayerMuted?.current !== null ? isCarouselPlayerMuted?.current : muted;

  useEffect(() => {
    if (status === 'paused') {
      setShowStarted(true);
      setShowEnded(false);
      setShowPaused(true);
    } else if (status === 'ended') {
      setShowStarted(false);
      setShowEnded(true);
      setShowPaused(false);
    }
  }, [setShowEnded, setShowPaused, setShowStarted, status]);

  return (
    <VideoAndControlsContainer>
      <Video
        sx={{ opacity: isVideoSeeking ? 0.7 : streamingState === PlayerState.ENDED || isVideoUpcoming ? 0 : 1 }}
        id={`live-shopping-video-${uuid}`}
        ref={onVideoRefInit}
        muted={isMuted}
        playsInline={true}
        controls={controls}
      />

      <CircularProgressVideo
        showShowLoadingSpinner={showShowLoadingSpinner}
        showWithErrors={showWithErrors}
        onPlayHandler={onPlayHandler}
      />

      <RotateVideoHint hideHint={isPlayerNotPlaying} />
    </VideoAndControlsContainer>
  );
};
