import { useShow } from '../../middleware/video/useShow';
import { usePlayableVideoStream } from '../../middleware/video/usePlayableVideoStream';
import { usePlayableVideoStreamContext } from '../../middleware/video/usePlayableVideoStreamContext';
import { Status } from '../../enums/show-status.enum';
import { EmbededViewControlProvider } from '../../middleware/playerState/useEmbededViewControl';
import { ClassicPlayer } from '../../components/videoPlayers/classicPlayer';
import { PlayerProps, PlayerType } from '../../models/VideoViewLayerModels';
import { ModernPlayer } from '../../components/videoPlayers/modernPlayer';
import { Box, useTheme } from '@mui/material';
import { isPlayerSupported } from 'amazon-ivs-player';
import { ControlPanelWidgetLogicProvider } from '../../components/videoPlayers/videoStream/videoControlPanel/Widget/ControlPanelProvider';

export default function VideoViewLayer() {
  const { vodManifest, streamUrl, products, status } = useShow();
  const { showStarted, showEnded, showPaused, isLiveShowStreaming } = usePlayableVideoStreamContext();
  const { videoElement, onMuteChange, muted, pause, play, seekTo, streamingState, position, onVideoRefInit } =
    usePlayableVideoStream(streamUrl, vodManifest);
  const theme = useTheme();

  const playerType = theme.components?.playerDisplayStyle;
  const isUpcoming = status === Status.READY;
  const isCanceled = status === Status.CANCEL;

  const showUpcomimgComponents =
    (isUpcoming || isCanceled) && !(isLiveShowStreaming || showStarted) && !showEnded && !showPaused;

  const playerProps: PlayerProps = {
    videoElement,
    muted,
    pause,
    play,
    seekTo,
    streamingState,
    showUpcomimgComponents,
    position,
    onVideoRefInit,
    products,
  };

  return (
    <EmbededViewControlProvider videoElement={videoElement}>
      <ControlPanelWidgetLogicProvider
        muted={playerProps.muted}
        position={playerProps.position}
        onMuteChange={onMuteChange}
      >
        {isPlayerSupported ? (
          playerType === PlayerType.MODERN ? (
            <ModernPlayer playerProps={playerProps} />
          ) : (
            <ClassicPlayer playerProps={playerProps} />
          )
        ) : (
          <Box>This stream is not supported by your browser.</Box>
        )}
      </ControlPanelWidgetLogicProvider>
    </EmbededViewControlProvider>
  );
}
