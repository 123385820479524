import { animated, useSpring } from 'react-spring';
import { AnimatedHeartPropsType, HeartShape } from './HeartReaction.interface';
import { Icon, useTheme } from '@mui/material';
import { PlayerType } from '../../../../models/VideoViewLayerModels';

const HeartComponent = (props: HeartShape) => {
  const { color } = props;
  return <Icon baseClassName="ls-default" className="ls-heart-solid" sx={{ color }} />;
};

export const AnimatedHeart = (props: AnimatedHeartPropsType) => {
  const { left: randomLeft, onComplete, color, isMobile } = props;
  const theme = useTheme();
  const playerType = theme.components?.playerDisplayStyle;
  const heartButton = document.getElementById('heart-button');
  const heartOffsetLeft = (heartButton?.offsetLeft ?? 0) + 5;
  const heartStartPosition =
    playerType === PlayerType.MODERN ? window.innerHeight - (heartButton?.getBoundingClientRect().bottom || 0) : 25;
  const mobileHeartEndPosition =
    playerType === PlayerType.MODERN ? Math.ceil(window.innerHeight) : Math.ceil(window.innerHeight * 0.5);
  const desktopHeartEndPosition =
    playerType === PlayerType.MODERN ? window.innerHeight : (heartButton?.offsetTop || 0) / 2;

  const { bottom, opacity, scale, left } = useSpring({
    from: {
      bottom: heartStartPosition,
      opacity: 1,
      scale: 0.5,
      left: heartOffsetLeft,
    },
    to: {
      bottom: isMobile ? mobileHeartEndPosition : desktopHeartEndPosition,
      opacity: 0,
      scale: 1,
      left: heartOffsetLeft + randomLeft,
    },
    config: {
      duration: 3000,
    },
    onRest: onComplete,
  });

  const heartAnimationStyle = {
    opacity,
    bottom,
    left,
    scale,
  };

  return (
    <animated.div className="heartWrap" style={heartAnimationStyle}>
      <HeartComponent color={color} />
    </animated.div>
  );
};
