import { Card, Stack, SxProps, Typography, useTheme } from '@mui/material';
import { ChatMessage } from '../../../../../models/chat.model';
import { ChatAvatar } from '../../../../UI/ChatAvatar';
import { MessageConverted } from '../../../chat/MessageConverted';
import { MAX_CHARACTER_LENGTH } from '../../../chat/chatAutoScrolling/useScrollChatContext';
import { useScrollChatSize } from '../../../chat/chatAutoScrolling/useScrollChatSize';

interface DesktopChatMessageProps {
  readonly data: ChatMessage;
  readonly index: number;
  readonly setSize: (index: number, size: number) => void;
  readonly toggleLongText: (index: number) => void;
}

export const DesktopDefaultMessage = (props: DesktopChatMessageProps) => {
  const { data, index, setSize, toggleLongText } = props;
  const { rowRef } = useScrollChatSize(index, setSize, data?.isLongTextToggled);
  const isModerator = data.customAttributes?.isModerator === 'true';
  const theme = useTheme();

  const handleToggleLongText = () => toggleLongText(index);

  const sxChatMessageCard: SxProps = {
    mx: '12px',
    mb: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  };

  const sxMessageCardStack: SxProps = {
    px: '6px',
    py: '8px',
    borderRadius: '4px',
    background: isModerator
      ? theme.components?.chatMessage?.desktop?.moderator?.background
      : theme.components?.chatMessage?.desktop?.user?.background,
  };

  const sxMessageCardName: SxProps = {
    fontWeight: 700,
    fontSize: '0.9rem',
    pr: '8px',
    textTransform: 'none',
    color: isModerator
      ? theme.components?.chatMessage?.desktop?.moderator?.nameColor
      : theme.components?.chatMessage?.desktop?.user?.nameColor,
  };

  const sxProductLinkMessage: SxProps = {
    marginRight: '4px',
    lineBreak: 'anywhere',
    textDecoration: isModerator ? 'hover' : 'none',
    pointerEvents: isModerator ? 'auto' : 'none',
    color: isModerator
      ? theme.components?.chatMessage?.desktop?.moderator?.linkColor
      : theme.components?.chatMessage?.desktop?.user?.linkColor,
  };

  const sxTextMessage: SxProps = {
    textTransform: 'none',
    fontSize: '0.9rem',
    marginRight: '4px',
    wordBreak: 'break-word',
    color: isModerator
      ? theme.components?.chatMessage?.desktop?.moderator?.textColor
      : theme.components?.chatMessage?.desktop?.user?.textColor,
  };

  return (
    <Card
      elevation={0}
      ref={rowRef}
      sx={{ ...sxChatMessageCard, cursor: data.message.length > MAX_CHARACTER_LENGTH ? 'pointer' : 'auto' }}
      onClick={data.message.length > MAX_CHARACTER_LENGTH ? handleToggleLongText : undefined}
    >
      {data.customAttributes?.avatarUrl && (
        <ChatAvatar isMobile={false} backgroundURL={data.customAttributes?.avatarUrl} />
      )}
      <Stack direction="row" sx={sxMessageCardStack}>
        <Typography variant="overline" sx={sxMessageCardName}>
          {data.username}
        </Typography>
        <MessageConverted
          message={data.message}
          isLongTextToggled={data.isLongTextToggled}
          isModerator={isModerator}
          sxProductLinkMessage={sxProductLinkMessage}
          sxTextMessage={sxTextMessage}
        />
      </Stack>
    </Card>
  );
};
