import { ChangeEvent, ReactNode, createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSearchParamContext } from '../../../../providers/SearchParamsProvider';
import { useCarouselContext } from '../../../../mediaLibrary/carousel/useCarouselContext';
import { usePlayableVideoStreamContext } from '../../../../../middleware/video/usePlayableVideoStreamContext';
import { useDeviceMetadata } from '../../../../providers/DeviceMetadataProvider';
import { useShow } from '../../../../../middleware/video/useShow';
import { updateHostUrl } from './ShareSocialMediaWidget/utils';

interface ControlPanelWidgetProps {
  readonly position: number;
  readonly muted: boolean;
  readonly children?: ReactNode;
  readonly onMuteChange: (value: boolean) => void;
}

interface ControlPanelWidgetContextData {
  readonly handleToggleExpansionControl: (state: boolean) => void;
  readonly handleToggleProductGrid: () => void;
  readonly handleToggleChatGrid: () => void;
  readonly handleMuteChange: (value: boolean) => void;
  readonly handleOpenShareModalDialog: () => void;
  readonly handleCloseShareModalDialog: () => void;
  readonly handleStartAtTimeOptionChanged: (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  readonly handleLinkCopied: () => void;
  readonly handleExtendedOptions: (enable: boolean) => void;
  readonly handleOpenProductList: (enable: boolean) => void;
  readonly handleOpenPlaybackSection: (enable: boolean) => void;
  readonly asyncPosition: number;
  readonly isPlaybackSectionOpen: boolean;
  readonly isShareModalOpened: boolean;
  readonly isExpansionControlOpened: boolean;
  readonly isStartAtTimeOptionChecked: boolean;
  readonly isLinkCopied: boolean;
  readonly isFeatureEnabled: boolean;
  readonly isChatCardOpen: boolean;
  readonly isProductListCardOpen: boolean;
  readonly isOptionsExtended: boolean;
  readonly sharedUrl: string;
  readonly muted: boolean;
}

const ControlPanelWidgetContext = createContext<ControlPanelWidgetContextData>({} as ControlPanelWidgetContextData);
export const useControlPanelWidgetContext = () => useContext(ControlPanelWidgetContext);

export const ControlPanelWidgetLogicProvider = (props: ControlPanelWidgetProps) => {
  const { position, muted, onMuteChange } = props;
  const { carouselSwiper, disableCarousel, enableCarousel } = useCarouselContext();
  const { hostUrl } = useSearchParamContext();
  const { showStarted } = usePlayableVideoStreamContext();
  const { isMobileView } = useDeviceMetadata();
  const { isClip, channel } = useShow();

  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const [isChatCardOpen, setChatCardOpen] = useState(false);
  const [isProductListCardOpen, setProductListCardOpen] = useState(false);
  const [isShareModalOpened, setShareModalOpened] = useState(false);
  const [isExpansionControlOpened, setIsExpansionControlOpened] = useState(false);
  const [isOptionsExtended, setOptionsExtended] = useState(false);
  const [isStartAtTimeOptionChecked, setIsStartAtTimeOptionChecked] = useState(false);
  const [isPlaybackSectionOpen, setIsPlaybackSectionOpen] = useState(false);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [asyncPosition, setAsyncPosition] = useState(position);
  const [sharedUrl, setShareUrl] = useState(
    updateHostUrl({
      hostUrl: hostUrl,
      withTime: false,
      asyncPosition,
      uuid: channel.uuid,
      isClip,
    }),
  );

  const shouldTrackShowStart = useRef(true);

  const handleLinkCopied = () => {
    navigator.clipboard.writeText(sharedUrl);
    setIsLinkCopied(true);
  };

  const handleToggleExpansionControl = (state: boolean) => {
    setIsExpansionControlOpened(state);
    if (!carouselSwiper) return;
    if (!state) {
      disableCarousel();
    } else {
      enableCarousel();
    }
  };

  const handleMuteChange = useCallback((value: boolean) => onMuteChange(value), [onMuteChange]);

  const handleStartAtTimeOptionChanged = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setIsLinkCopied(false);
      setIsStartAtTimeOptionChecked(checked);
      const newUrl = updateHostUrl({ hostUrl: hostUrl, withTime: checked, asyncPosition, uuid: channel.uuid, isClip });
      setShareUrl(() => newUrl);
    },
    [asyncPosition, channel.uuid, hostUrl, isClip],
  );

  const handleOpenPlaybackSection = (enable: boolean) => {
    isMobileView && setIsPlaybackSectionOpen(enable);
  };

  const handleOpenShareModalDialog = () => {
    setAsyncPosition(+position.toFixed(0));
    setShareModalOpened(true);
  };

  const handleCloseShareModalDialog = () => {
    setIsLinkCopied(false);
    setShareModalOpened(false);
  };

  const handleExtendedOptions = (enable: boolean) => {
    setOptionsExtended(enable);
  };

  const handleOpenProductList = (enable: boolean) => {
    setProductListCardOpen(enable);
    if (enable) {
      disableCarousel();
    } else {
      enableCarousel();
    }
  };

  const handleToggleProductGrid = () => setProductListCardOpen(!isProductListCardOpen);

  const handleToggleChatGrid = () => setChatCardOpen(!isChatCardOpen);

  useEffect(() => {
    if (shouldTrackShowStart.current && showStarted) {
      !isClip && setChatCardOpen(true);
      setIsFeatureEnabled(true);
      shouldTrackShowStart.current = false;
    }
    if (showStarted && !isMobileView) {
      setProductListCardOpen(true);
    }
  }, [showStarted, isMobileView, isClip]);

  useEffect(() => {
    if (isProductListCardOpen && isMobileView) {
      disableCarousel();
    } else {
      enableCarousel();
    }
  }, [disableCarousel, enableCarousel, isMobileView, isProductListCardOpen]);

  const value = {
    muted,
    handleMuteChange,
    handleOpenShareModalDialog,
    handleCloseShareModalDialog,
    handleStartAtTimeOptionChanged,
    handleToggleProductGrid,
    handleToggleChatGrid,
    handleOpenPlaybackSection,
    isFeatureEnabled,
    isChatCardOpen,
    isProductListCardOpen,
    isExpansionControlOpened,
    isStartAtTimeOptionChecked,
    isShareModalOpened,
    isLinkCopied,
    isOptionsExtended,
    isPlaybackSectionOpen,
    handleExtendedOptions,
    handleOpenProductList,
    handleToggleExpansionControl,
    sharedUrl,
    asyncPosition,
    handleLinkCopied,
  };

  return <ControlPanelWidgetContext.Provider value={value}>{props.children}</ControlPanelWidgetContext.Provider>;
};
