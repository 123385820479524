import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#252A2E',
    borderRadius: 4,
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '17px',
    textAlign: 'center',
    padding: '8px',
    maxWidth: '300px',
  },
}));
